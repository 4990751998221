import React, { useCallback, useMemo, useState } from 'react';
import { Redirect, Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { selectOfflineInfo } from '../app/offline';
import superlogin from '../api/superlogin';
import { selectCurrentUserId, type ReduxState } from '../contexts/usersSlice';
import useAuth from '../hooks/useAuth';
import useLocationParams from '../hooks/useLocationParams';
import { LocationState } from './types';
import DisclaimerModal from '../components/DisclaimerModal';
import { agreeToDisclaimer } from '../api/auth';

const SESSION_EXPIRED_MSG = 'Session expired.';
const LOGIN_MSG = 'Please log in.';
const LOGIN_WITH_OFFLINE_SYNC_MSG = 'Please log in with the same email to sync your offline work.';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required'),
});

const Login = () => {
  const location = useLocation<LocationState>();
  const { searchParams } = useLocationParams(location);
  const hasActions = useSelector((state) => selectOfflineInfo(state).hasActions);
  const currentUserId = useSelector((state: ReduxState) => selectCurrentUserId(state));
  const [globalErrors, setGlobalErrors] = useState(null);
  const [redirectTrigger, setRedirectTrigger] = useState(false);
  const [loginInProgress, setloginInProgress] = useState(false);
  const { onAuthenticationSuccess, logout } = useAuth();
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const [disclaimerText, setDisclamerText] = useState('');

  // The current redirect url if present, or undefined.
  const redirect = useMemo(() => searchParams.get('redirect'), [searchParams]);

  /**
   * A login prompt to show the user depending on various authentication scenarios.
   *
   * Eg, if a user's session has expired but they have unsaved offline work,
   * we need to show a message to prompt them to reauthenticate.
   */
  const flash = useMemo(() => {
    if (location.state && location.state.flash) {
      return location.state.flash;
    }
    if (!currentUserId || loginInProgress) {
      return;
    }
    let flash = SESSION_EXPIRED_MSG;
    if (hasActions) {
      flash += ` ${LOGIN_WITH_OFFLINE_SYNC_MSG}`;
    } else {
      flash += ` ${LOGIN_MSG}`;
    }
    return flash;
  }, [location.state, currentUserId, loginInProgress, hasActions]);

  const onAuthenticationFailure = useCallback((error) => {
    setGlobalErrors(error.message);
  }, []);

  const login = useCallback(
    async (email, password) => {
      setGlobalErrors(null);
      setloginInProgress(true);
      try {
        const session = await superlogin.login({
          username: email,
          password,
        });
        if (session.disclaimer_text) {
          setDisclamerText(session.disclaimer_text);
          setShowDisclaimerModal(true);
        } else {
          await onAuthenticationSuccess(session);
          setRedirectTrigger(true);
        }
      } catch (error) {
        onAuthenticationFailure(error);
      }
    },
    [onAuthenticationSuccess, onAuthenticationFailure]
  );

  const onAgree = useCallback(async () => {
    try {
      setShowDisclaimerModal(false);
      const session = await agreeToDisclaimer();
      await onAuthenticationSuccess(session);
      setRedirectTrigger(true);
    } catch (error) {
      onAuthenticationFailure(error);
    }
  }, [onAuthenticationSuccess, onAuthenticationFailure]);

  const onDecline = useCallback(() => {
    setloginInProgress(false);
    setShowDisclaimerModal(false);
    logout();
  }, [logout]);

  // Redirect to home page or original app path.
  if (redirectTrigger === true) {
    return <Redirect to={redirect || '/'} />;
  }

  return (
    <div className="px-12 py-4">
      {showDisclaimerModal && <DisclaimerModal disclaimer={disclaimerText} onAgree={onAgree} onDecline={onDecline} />}
      {/* Sets the document title */}
      <Helmet>
        <title>Epsilon3</title>
      </Helmet>
      <div className="items-start max-w-md mx-auto">
        {flash && (
          <div className="my-4 py-2 px-3 bg-green-100 border rounded border-black border-opacity-20 text-green-700">
            {flash}
          </div>
        )}
        <h1 className="mb-4">Log in to your account</h1>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={async (values) => login(values.email, values.password)}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="flex flex-col">
                <label htmlFor="email">Email address</label>
                <Field
                  id="email"
                  name="email"
                  placeholder="Email address"
                  type="email"
                  className="w-full border-1 border-gray-400 rounded "
                />
                {errors.email && touched.email ? <div className="text-red-700">{errors.email}</div> : null}

                <label htmlFor="email" className="mt-2">
                  Password
                </label>
                <Field
                  id="password"
                  name="password"
                  placeholder="Password"
                  type="password"
                  className="w-full border-1 border-gray-400 rounded"
                />
                {errors.password && touched.password ? <div className="text-red-700">{errors.password}</div> : null}
                {globalErrors && <p className="self-center mt-2 text-red-700">{globalErrors}</p>}
                <button className="btn self-center mt-2" type="submit" disabled={isSubmitting ? true : undefined}>
                  Log In
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="flex flex-col">
          <Link
            to={(location) => ({
              // Preserve search url params when navigating through login flows.
              ...location,
              pathname: '/sso/provider',
            })}
            className="btn-secondary self-center mt-2"
          >
            Log In with SSO
          </Link>
        </div>
        <div className="flex justify-between mt-4">
          <Link to="/forgot-password" className="link">
            Forgot your password?
          </Link>
          <div>
            <a target="_blank" rel="noreferrer" href="https://epsilon3.io/privacy" className="link mr-2">
              Privacy
            </a>
            <a target="_blank" rel="noreferrer" href="https://epsilon3.io/terms" className="link">
              Terms
            </a>
          </div>
        </div>
        <div className="text-right">© 2024 Epsilon3</div>
      </div>
    </div>
  );
};

export default Login;
