import React, { useMemo } from 'react';
import { FieldArray } from 'formik';
import EventDependency from './EventDependency';
import { DateTime } from 'luxon';

const getEventLabel = (event) => {
  if (!event.start) {
    return event.name;
  }
  return `${event.name} (${(event.start as DateTime).toFormat('yyyy-MM-dd')})`;
};

const EventDependencies = ({ field, form, isEditing, events, currentTeamId }) => {
  const options = useMemo(() => {
    // Convert to react-select options
    const options = events
      .filter((e) => e.id !== form.values.id)
      .map((e) => ({
        label: getEventLabel(e),
        value: e.id,
        isDisabled: false,
        deleted: e.deleted,
      }));

    // Aggregate linked events
    const inUseDeps = {};
    for (const edge of form.values.dependencies.in) {
      inUseDeps[edge.source] = true;
    }
    for (const edge of form.values.dependencies.out) {
      inUseDeps[edge.target] = true;
    }

    // Mark options already in use
    for (const option of options) {
      if (inUseDeps[option.value]) {
        option.isDisabled = true;
      }
    }
    return options;
  }, [events, form.values.dependencies.in, form.values.dependencies.out, form.values.id]);

  const handleChange = (idx, newValue) => {
    const path = `${field.name}[${idx}]`;
    form.setFieldValue(path, newValue);
  };

  return (
    <FieldArray
      name={field.name}
      render={(arrayHelpers) => (
        <div className="flex flex-col space-y-2 h-auto">
          {field.value.length === 0 && !isEditing && <span className="italic px-2 text-gray-400">None</span>}
          {field.value.map((dep, idx) => (
            <EventDependency
              key={`${field.name}[${idx}]`}
              dependency={dep}
              options={options}
              onChange={(val) => handleChange(idx, val)}
              onRemove={() => arrayHelpers.remove(idx)}
              isEditing={isEditing}
              currentTeamId={currentTeamId}
            />
          ))}
          <EventDependency
            dependency={null}
            options={options}
            onChange={(val) => arrayHelpers.insert(field.value.length, val)}
            onRemove={null}
            isEditing={isEditing}
            currentTeamId={currentTeamId}
          />
        </div>
      )}
    />
  );
};

export default EventDependencies;
