import { AxiosResponse } from 'axios';
import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';
import { Issue, IssueCreate, IssueUpdate } from '../types';
import {
  Metadata,
  Severity,
  Status,
  StatusType,
  Issue as ServerIssue,
  RunActions,
  ReferenceType,
  SubStatus,
} from 'shared/lib/types/postgres/issues';
import { Activity, Mention } from 'shared/lib/types/postgres/util';

const isRunAction = (
  activity: RunActions | Activity
): activity is RunActions => {
  return (activity as RunActions).type !== undefined;
};

export const mapServerIssueToClient = (serverIssue: ServerIssue): Issue => {
  const clientIssue: Issue = {
    id: serverIssue.id,
    title: serverIssue.title,
    status: statusMapper(serverIssue.status),
    subStatus: serverIssue.sub_status
      ? subStatusMapper(serverIssue.sub_status)
      : serverIssue.sub_status,
    assignee: serverIssue.assignees?.[0],
    createdAt: serverIssue.created_at,
    createdBy: serverIssue.created_by,
    runId: serverIssue.run_id,
    notes: serverIssue.notes || '',
    referenceId: serverIssue.reference_id,
    referenceType: serverIssue.reference_type,
    activity: [],
    details: serverIssue.details,
    steps: serverIssue.steps || [],
    projectId: serverIssue.project_id,
  };

  if (serverIssue.attachments && serverIssue.attachments?.length > 0) {
    clientIssue.attachments = [];
    for (const serverAttachment of serverIssue.attachments) {
      const attachment = {
        id: serverAttachment.attachment_id,
        attachment_id: serverAttachment.attachment_id,
        name: serverAttachment.name,
        content_type: serverAttachment.content_type,
      };

      // @ts-ignore - only attachment_id and name are really needed to resolve attachments
      clientIssue.attachments.push(attachment);
    }
  }

  if (serverIssue.severity?.id) {
    clientIssue.severity = severityMapper(serverIssue.severity);
  }

  if (serverIssue.activity && serverIssue.activity?.length > 0) {
    clientIssue.activity = [];
    for (const serverActivity of serverIssue.activity) {
      if (isRunAction(serverActivity)) {
        clientIssue.activity.push({
          type: serverActivity.type,
          user_id: serverActivity.user_id,
          timestamp: serverActivity.timestamp,
        });
      } else {
        clientIssue.activity.push({
          id: serverActivity.id,
          comment: serverActivity.comment,
          user_id: serverActivity.user_id,
          timestamp: serverActivity.timestamp,
          action: serverActivity.action,
          old_value: serverActivity.old_value,
          new_value: serverActivity.new_value,
          mention_list: serverActivity.mention_list,
          updated_at: serverActivity.updated_at,
        } as Activity);
      }
    }
  }
  return clientIssue;
};

const mapClientIssueToServer = (issue: Issue) => {
  const serverIssue: IssueUpdate = {
    title: issue.title,
    status_id: issue.status.id,
    sub_status_id: issue.subStatus?.id ?? null,
    severity_id: issue.severity?.id,
    assignee: issue.assignee,
    notes: issue.notes || null,
    attachments: issue.attachments,
    details: issue.details,
    steps: issue.steps,
    project_id: issue.projectId,
  };

  return serverIssue;
};

const severityMapper = (severity): Severity => {
  const updated = { ...severity };
  updated.color = `bg-${severity.color}`;
  updated.pausesRun = severity.pauses_run;
  return updated;
};

const statusMapper = (status: Status) => {
  const updated = { ...status };
  updated.color = `bg-${status.color}`;
  return updated;
};

const subStatusMapper = (subStatus: SubStatus) => {
  const updated = { ...subStatus };
  updated.color = `bg-${subStatus.color}`;
  return updated;
};

class NCRService {
  teamId: string;
  restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    const baseUrl = `${API_URL}/teams/${this.teamId}`;
    this.restUrl = `${baseUrl}/issues`;
  }

  async listOpenIssues(
    referenceId?: string,
    referenceType?: ReferenceType
  ): Promise<ServerIssue[]> {
    return this.listIssues(
      [StatusType.Draft, StatusType.Active],
      referenceId,
      referenceType
    );
  }

  async listClosedIssues(
    referenceId?: string,
    referenceType?: ReferenceType
  ): Promise<ServerIssue[]> {
    return this.listIssues([StatusType.Closed], referenceId, referenceType);
  }

  async listIssues(
    statusTypes?: StatusType[],
    referenceId?: string,
    referenceType?: ReferenceType
  ): Promise<ServerIssue[]> {
    const params: {
      status_types?: StatusType[];
      reference_id?: string;
      reference_type?: ReferenceType;
    } = {};
    if (statusTypes) {
      params.status_types = statusTypes;
    }
    if (referenceId) {
      params.reference_id = referenceId;
    }
    if (referenceType) {
      params.reference_type = referenceType;
    }
    const response = await superlogin.getHttp().get(this.restUrl, { params });
    return response.data.data;
  }

  async listOpenIssuesForRun(runId: string): Promise<Issue[]> {
    return this.listIssuesForRun(runId, [StatusType.Draft, StatusType.Active]);
  }

  async listClosedIssuesForRun(runId: string): Promise<Issue[]> {
    return this.listIssuesForRun(runId, [StatusType.Closed]);
  }

  async listIssuesForRun(
    runId: string,
    statusTypes: StatusType[]
  ): Promise<Issue[]> {
    const params = {
      run_id: runId,
      status_types: statusTypes,
    };
    const response = await superlogin.getHttp().get(this.restUrl, { params });
    return response.data.data.map(mapServerIssueToClient);
  }

  async getIssue(issueId: string): Promise<Issue | undefined> {
    const url = `${this.restUrl}/${issueId}`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async createIssue(issue: IssueCreate): Promise<Issue> {
    const body = {
      title: issue.title,
      run_id: issue.runId,
      reference_id: issue.referenceId,
      reference_type: issue.referenceType,
      assignee: issue.assignee,
      severity_id: issue.severityId,
      notes: issue.notes,
      attachments: issue.attachments,
      details: issue.details,
      project_id: issue.projectId,
    };
    const response = await superlogin.getHttp().post(this.restUrl, body);
    return response.data;
  }

  async updateIssue(issue: Issue): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${issue.id}`;
    return superlogin.getHttp().patch(url, mapClientIssueToServer(issue));
  }

  async addCommentToIssue(
    issueId: number,
    comment: string,
    mentionList?: Array<Mention>
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${issueId}/comments`;
    const body = {
      comment,
      mention_list: mentionList,
    };
    return superlogin.getHttp().post(url, body);
  }

  async editCommentInIssue(
    issueId: number,
    comment: string,
    commentId: string,
    mentionList?: Array<Mention>,
    updatedAt?: string
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${issueId}/comments`;
    const body = {
      comment,
      mention_list: mentionList,
      commentId,
      updated_at: updatedAt,
    };
    return superlogin.getHttp().patch(url, body);
  }

  async getMetadata(): Promise<Metadata | undefined> {
    const url = `${this.restUrl}/metadata-options`;
    const response = await superlogin.getHttp().get(url);
    return {
      status: response.data.status.map(statusMapper),
      severity: response.data.severity.map(severityMapper),
      sub_status: response.data.sub_status.map(subStatusMapper),
    };
  }

  async signOffIssueStep({
    issueId,
    stepIndex,
    signoffId,
    operator,
    timestamp,
  }: {
    issueId: number;
    stepIndex: number;
    signoffId: string;
    operator: string;
    timestamp: string;
  }): Promise<void> {
    const url = `${this.restUrl}/${issueId}/steps/signoff`;
    const body = {
      step_index: stepIndex,
      signoff_id: signoffId,
      operator,
      timestamp,
    };
    return superlogin.getHttp().post(url, body);
  }
}

export default NCRService;
