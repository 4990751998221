import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import Select from 'react-select';
import { UsageType } from 'shared/lib/types/api/manufacturing/usage_types/models';
import { NumberSelectOption as SelectOption } from '../../../lib/formik';
import { reactSelectStyles } from '../../../lib/styles';
import useUsageTypes from '../../hooks/useUsageTypes';

export type UsageTypeSelectorProps = {
  usageTypeIds: number | Array<number> | null;
  availableUsageTypeIds?: Array<number>;
  onChange?: (options: SelectOption | Array<SelectOption>) => void;
  isMulti?: boolean;
};

const UsageTypeSelector = ({ usageTypeIds, availableUsageTypeIds, onChange, isMulti }: UsageTypeSelectorProps) => {
  const { usageTypes } = useUsageTypes();

  const usageTypeToSelectOption = (usageType: UsageType): SelectOption => {
    return {
      label: `${usageType.name}`,
      value: usageType.id,
    };
  };

  const usageTypeOptions: Array<SelectOption> = useMemo(() => {
    if (!usageTypes) {
      return [];
    }
    if (isMulti) {
      return usageTypes.map(usageTypeToSelectOption);
    }
    const availableUsageTypes = usageTypes.filter((usageType) => availableUsageTypeIds?.includes(usageType.id));
    return availableUsageTypes.map(usageTypeToSelectOption);
  }, [availableUsageTypeIds, isMulti, usageTypes]);

  const value = useMemo(() => {
    if (!usageTypes) {
      return null;
    }
    if (isMulti) {
      return usageTypes
        .filter((usageType) => (usageTypeIds as Array<number>).includes(usageType.id))
        .map(usageTypeToSelectOption);
    }
    const usageType = usageTypes.find((usageType) => usageType.id === usageTypeIds);
    if (usageType) {
      return usageTypeToSelectOption(usageType);
    }
  }, [isMulti, usageTypeIds, usageTypes]);

  return (
    <>
      <div className={`${isMulti ? 'w-80' : 'w-48'}`}>
        <Select
          inputId="usage_type_id"
          name="usage_type"
          styles={reactSelectStyles}
          classNamePrefix="react-select"
          isClearable={isMulti}
          isMulti={isMulti}
          options={usageTypeOptions}
          value={value}
          formatOptionLabel={(option: SelectOption) => {
            const icon = option.value === 1 ? 'refresh' : option.value === 2 ? 'hourglass' : null;
            return (
              <>
                {icon && <FontAwesomeIcon className="w-6 text-blue-400" icon={icon} />}
                {capitalize(option.label)}
              </>
            );
          }}
          onChange={onChange}
          placeholder={`Select usage type${isMulti ? 's' : ''}`}
          aria-label={`Select usage type${isMulti ? 's' : ''}`}
        />
      </div>
    </>
  );
};

export default UsageTypeSelector;
