import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { Duration } from 'luxon';

const UNITS = ['days', 'hours', 'minutes', 'seconds'];

const DurationGenerator = ({ value, onChange }) => {
  const valueDurations = UNITS.map((unit) => {
    const duration = value.split(', ').find((d) => d.includes(unit));

    if (duration) {
      const [value] = duration.split(' ');
      return { value: Number(value), unit };
    }

    return { value: 0, unit };
  });

  const [durations, setDurations] = useState(valueDurations);

  const getDuration = (value, unit) => {
    return Duration.fromObject({ [unit]: value });
  };

  const totalDuration = durations.reduce((total, duration) => {
    return total.plus(getDuration(duration.value, duration.unit));
  }, Duration.fromObject({}));

  useEffect(() => {
    onChange(totalDuration);
  }, [durations, totalDuration, onChange]);

  const updateDuration = (index, newValue) => {
    setDurations((durations) => {
      return durations.map((duration, i) => {
        if (i === index) {
          if (newValue && newValue.trim() !== '') {
            return { value: newValue, unit: duration.unit };
          } else {
            return { value: '0', unit: duration.unit };
          }
        } else {
          return duration;
        }
      });
    });
  };

  return (
    <div className="flex flex-row items-center relative">
      {UNITS.map((unit, index) => (
        <>
          <Field
            name={`durationValue${index}`}
            defaultValue={durations[index]?.value ?? '0'}
            aria-label={`Duration Value ${index}`}
            component="input"
            type="number"
            min="0"
            className={`${
              index !== 0 ? 'ml-4 ' : ''
            }border-1 border-gray-400 p-2 w-[8rem] rounded mr-2 disabled:bg-gray-100`}
            onChange={(event) => {
              const inputValue = event.target.value;
              if (inputValue >= 0) {
                updateDuration(index, inputValue);
              }
            }}
          />
          <span>
            {unit}
            {index !== UNITS.length - 1 ? ', ' : ''}
          </span>
        </>
      ))}
    </div>
  );
};

export default DurationGenerator;
