import React, { useCallback, useMemo } from 'react';

import ExpandCollapseCaret from './ExpandCollapse/ExpandCollapseCaret';
import BlockText from './Blocks/BlockText';
import BlockAttachment from './Blocks/BlockAttachment';
import ProcedureBlockRun from './Blocks/ProcedureBlockRun';
import { generateHiddenClassString } from '../lib/styles';
import withBlockRedlining from '../hocs/withBlockRedlining';
import withFieldRedlining from '../hocs/withFieldRedlining';
import { isRedlineSupported } from './Blocks/BlockTypes';
import { useRunContext } from '../contexts/RunContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMixpanel } from '../contexts/MixpanelContext';
import revisionsUtil from '../lib/revisions';
import { cloneDeep } from 'lodash';
import ProcedureField from './ProcedureField';
import validateUtil from '../lib/validateUtil';
import { useRedline } from '../hooks/useRedline';
import { RUN_STATE } from '../lib/runUtil';
import procedureUtil from '../lib/procedureUtil';
import { DraftRedlineComment, Header, ReviewComment, RunHeader } from 'shared/lib/types/views/procedures';
import { AxiosResponse } from 'axios';
import ReviewCommenting from './ReviewCommenting';
import reviewUtil from '../lib/reviewUtil';
import RunTableInput from './TableInput/RunTableInput';
import CommentWrapper from './CommentWrapper';
const BlockTextWithRedlining = withBlockRedlining(BlockText);
const ProcedureBlockWithRedlining = withBlockRedlining(ProcedureBlockRun);
const HeaderFieldWithRedlining = withFieldRedlining(ProcedureField);

interface ProcedureHeaderParams {
  projectId?: string | null;
  header: Header;
  isCollapsed: boolean;
  onCollapse: (id, collapse) => void;
  docState?: string;
  isRedlineFeatureEnabled?: boolean;
  saveHeaderRedline?: (header, headerRedlineMetadata, isRedline) => undefined | Promise<void>;
  onAcceptPendingRedline?: (headerId, redlineIndex) => Promise<AxiosResponse | void>;
  onRefChanged?: (id: string, element: HTMLElement) => void;
  scrollToBufferRem?: number;
  isPreviewMode?: boolean;
  onResolveReviewComment?: (commentId: string) => void;
  onUnresolveReviewComment?: (commentId: string) => void;
  saveReviewComment?: (comment: ReviewComment) => Promise<void>;
  comments?: Array<DraftRedlineComment | ReviewComment>;
  showReviewComments?: boolean;
}

const ProcedureHeader = ({
  projectId = null,
  header,
  isCollapsed,
  onCollapse,
  docState = '',
  isRedlineFeatureEnabled = false,
  saveHeaderRedline,
  onAcceptPendingRedline,
  onRefChanged,
  scrollToBufferRem = 0,
  isPreviewMode = false,
  onResolveReviewComment,
  onUnresolveReviewComment,
  saveReviewComment,
  comments,
  showReviewComments,
}: ProcedureHeaderParams) => {
  const { isUserParticipant, isIntroductionVisible, isRun } = useRunContext();
  const { mixpanel } = useMixpanel();

  const onHeaderRefChanged = useCallback(
    (element) => {
      if (!onRefChanged) {
        return;
      }
      onRefChanged(header.id, element);
    },
    [header.id, onRefChanged]
  );

  const onExpandCollapse = useCallback(() => {
    onCollapse(header.id, !isCollapsed);
  }, [header, isCollapsed, onCollapse]);

  const {
    isRedlineEnabled,
    onToggleRedline,
    redlineState,
    setRedlineState,
    showsRedlineButton,
    clearRedlineState,
    setIsEditing,
  } = useRedline({
    docState,
    isPreviewMode,
    isRedlineDisabledBecauseOfSectionSnippet: false,
    isRedlineDisabledBecauseOfStepSnippet: false,
    isRedlineDisabledBecauseOfRepeat: false,
    isRedlineFeatureEnabled,
    isUserParticipant,
    projectId,
  });

  const dirtyContentChangeHandler = useCallback(
    (dirty, contentIndex) => {
      setRedlineState((state) => ({
        ...state,
        dirtyContent: {
          ...state.dirtyContent,
          [contentIndex]: dirty,
        },
      }));
    },
    [setRedlineState]
  );

  const dirtyFieldChangeHandler = useCallback(
    (dirty, field) => {
      setRedlineState((state) => ({
        ...state,
        dirtyFields: {
          ...state.dirtyFields,
          [field]: dirty,
        },
      }));
    },
    [setRedlineState]
  );

  const redlineBlockChanges = useCallback(
    (contentIndex) => {
      // Only show redlines for runs.
      if (!isRun) {
        return [];
      }
      const block = header.content[contentIndex];
      return revisionsUtil.getBlockChanges(block, contentIndex, (header as RunHeader).redlines);
    },
    [isRun, header]
  );

  const redlineHeaderFieldChanges = useCallback(
    (field) => {
      // Only show redlines for runs.
      if (!isRun) {
        return [];
      }
      return revisionsUtil.getHeaderFieldChanges(header, field, (header as RunHeader).redlines);
    },
    [isRun, header]
  );

  const onSaveRedlineBlock = useCallback(
    (contentIndex, block, isRedline) => {
      const latestHeader = revisionsUtil.getLatestHeaderRevision(header);
      const headerCopy = cloneDeep(latestHeader);
      headerCopy.content[contentIndex] = block;
      if (saveHeaderRedline) {
        return saveHeaderRedline(headerCopy, { contentId: block.id }, isRedline);
      }
    },
    [header, saveHeaderRedline]
  );

  const getHeaderFieldKey = useCallback((headerField) => procedureUtil.getFieldKey(headerField), []);

  const onSaveRedlineField = useCallback(
    (headerField, isRedline) => {
      const latestHeader = revisionsUtil.getLatestHeaderRevision(header);
      const headerCopy = {
        ...cloneDeep(latestHeader),
        ...headerField,
      };
      if (saveHeaderRedline) {
        return saveHeaderRedline(headerCopy, { field: getHeaderFieldKey(headerField) }, isRedline)?.then(
          clearRedlineState
        );
      }
    },
    [header, getHeaderFieldKey, saveHeaderRedline, clearRedlineState]
  );

  const saveRedlineHandler = useCallback(
    (contentIndex, block) => {
      const isRedline = block.editType !== 'Blueline';
      delete block.editType;
      if (mixpanel) {
        mixpanel.track(`${isRedline ? 'Redline' : 'Blueline'} Created`, { 'Block Type': block.type });
      }
      if (onSaveRedlineBlock) {
        return (
          onSaveRedlineBlock(contentIndex, block, isRedline)
            ?.then(clearRedlineState)
            // Ignored for now, user can click "Save" again
            .catch(() => undefined)
        );
      }
    },
    [onSaveRedlineBlock, mixpanel, clearRedlineState]
  );

  const saveRedlineHeaderFieldHandler = useCallback(
    (headerField) => {
      const key = getHeaderFieldKey(headerField);
      if (!key) {
        return;
      }

      const isRedline = headerField.editType !== 'Blueline';

      if (mixpanel) {
        mixpanel.track(`${isRedline ? 'Redline' : 'Blueline'} Created`, { 'Field Name': key });
      }
      if (onSaveRedlineField) {
        return (
          onSaveRedlineField({ [key]: headerField[key] }, isRedline)
            ?.then(() => {
              setRedlineState((state) => ({
                ...state,
                dirtyFields: { [key]: false },
              }));
            })
            // Ignored for now, user can click "Save" again
            .catch(() => undefined)
        );
      }
    },
    [onSaveRedlineField, mixpanel, getHeaderFieldKey, setRedlineState]
  );

  const isAcceptRedlineEnabled = useMemo(() => {
    return docState === RUN_STATE.RUNNING;
  }, [docState]);

  const acceptPendingRedline = useCallback(
    (redlineIndex) => {
      if (onAcceptPendingRedline) {
        return onAcceptPendingRedline(header.id, redlineIndex);
      }
    },
    [header.id, onAcceptPendingRedline]
  );

  if (isRun && !isIntroductionVisible()) {
    return null;
  }

  return (
    <table
      aria-label="Procedure Header"
      role="region"
      className="table-fixed w-full border-collapse"
      cellSpacing="0"
      cellPadding="0"
      border={0}
    >
      <thead>
        <tr>
          <th className="w-4"></th>
          <th className="w-full"></th>
          <th className="w-0"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="align-top">
            {/* Header name */}
            <div className="mt-3" ref={onHeaderRefChanged} style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}>
              <ExpandCollapseCaret
                isExpanded={!isCollapsed}
                onClick={onExpandCollapse}
                ariaLabel={isCollapsed ? 'Expand header' : 'Collapse header'}
              />
            </div>
          </td>
          <td className="break-words">
            <div className="mt-3 ml-4 text-xl min-w-0">
              <HeaderFieldWithRedlining
                fieldName="name"
                fieldValue={header.name}
                redlines={redlineHeaderFieldChanges('name')}
                placeholder="Header name*"
                showsRedlineAction={redlineState.isActive}
                isEditing={redlineState.isEditing}
                setIsEditing={setIsEditing}
                onSubmitEdit={saveRedlineHeaderFieldHandler}
                onDirtyChanged={dirtyFieldChangeHandler}
                validate={validateUtil.validateFieldHeaderName}
                onLabelClick={onExpandCollapse}
                onAcceptPendingRedline={isAcceptRedlineEnabled ? acceptPendingRedline : undefined}
                step={undefined}
              />
            </div>
          </td>
        </tr>
      </tbody>
      {/* Header content */}
      <tbody aria-label="Content Block" role="region" className={generateHiddenClassString('', isCollapsed)}>
        {header.content &&
          header.content.map((content, contentIndex) => {
            return (
              <CommentWrapper content={content}>
                {/* Check type of content.text because empty string is falsey */}
                {content.type.toLowerCase() === 'text' && typeof content.text === 'string' && (
                  <BlockTextWithRedlining
                    block={content}
                    blockLabel={undefined}
                    contentIndex={contentIndex}
                    isHidden={false}
                    redlines={redlineBlockChanges(contentIndex)}
                    showsRedlineAction={redlineState.isActive}
                    isEditing={redlineState.isEditing}
                    setIsEditing={setIsEditing}
                    onSubmitEdit={(block) => saveRedlineHandler(contentIndex, block)}
                    onDirtyChanged={dirtyContentChangeHandler}
                    onAcceptPendingRedline={isAcceptRedlineEnabled ? acceptPendingRedline : undefined}
                    onRefChanged={onRefChanged}
                    scrollMarginTopValueRem={scrollToBufferRem}
                  />
                )}
                {content.type.toLowerCase() === 'alert' && (
                  <ProcedureBlockWithRedlining
                    block={content}
                    blockLabel={undefined}
                    isHidden={false}
                    isDark={true}
                    redlines={redlineBlockChanges(contentIndex)}
                    contentIndex={contentIndex}
                    showsRedlineAction={redlineState.isActive && isRedlineSupported(content.type)}
                    isEditing={redlineState.isEditing}
                    setIsEditing={setIsEditing}
                    onSubmitEdit={(block) => saveRedlineHandler(contentIndex, block)}
                    onDirtyChanged={dirtyContentChangeHandler}
                    onAcceptPendingRedline={isAcceptRedlineEnabled ? acceptPendingRedline : undefined}
                    onContentRefChanged={onRefChanged}
                    scrollMarginTopValueRem={scrollToBufferRem}
                  />
                )}
                {content.type.toLowerCase() === 'attachment' && (
                  <BlockAttachment
                    blockLabel={undefined}
                    attachment={content}
                    isHidden={isCollapsed}
                    isSpacerHidden={false}
                  />
                )}
                {content.type.toLowerCase() === 'table_input' && (
                  <RunTableInput
                    content={content}
                    contentIndex={contentIndex}
                    isHidden={false}
                    isEnabled={false}
                    isSpacerHidden={false}
                    blockLabel={null}
                    recorded={null}
                    onFieldValueChange={null}
                    onFieldErrorsChanged={null}
                    saveTextComment={undefined}
                    editTextComment={undefined}
                  />
                )}
              </CommentWrapper>
            );
          })}
        {/* Review comments */}
        {showReviewComments && onResolveReviewComment && onUnresolveReviewComment && saveReviewComment && (
          <tr>
            <td></td>
            <td colSpan={2}>
              <div className={generateHiddenClassString('mb-2', isCollapsed)}></div>
              <div className={generateHiddenClassString('mb-2', isCollapsed)}>
                <ReviewCommenting
                  stepId={header.id}
                  onResolveReviewComment={onResolveReviewComment}
                  onUnresolveReviewComment={onUnresolveReviewComment}
                  saveReviewComment={saveReviewComment}
                  reviewComments={reviewUtil.getStepReviewComments(comments ?? [], header.id)}
                />
              </div>
            </td>
          </tr>
        )}
        {/* Redlining */}
        {showsRedlineButton && (
          <tr>
            <td></td>
            <td colSpan={2}>
              <button
                className="ml-8 mt-3 btn-link text-sm font-bold uppercase rounded hover:bg-gray-200 disabled:bg-transparent disabled:text-gray-300 disabled:cursor-default whitespace-nowrap print:hidden"
                onClick={onToggleRedline}
                disabled={!isRedlineEnabled}
              >
                <FontAwesomeIcon icon="pencil-alt" />
                {redlineState.isActive ? 'Close Edits' : 'Suggest Edits'}
              </button>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default React.memo(ProcedureHeader);
