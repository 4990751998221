import { useCallback, useEffect, useState } from 'react';
import { Item, ItemStatus } from '../types';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useSettings } from '../../contexts/SettingsContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';

interface ItemsState {
  allItems?: Item[];
  activeItems?: Item[];
  getItem: (itemId: string) => Item | undefined;
  refreshItems: () => Promise<void>;
}

const useItems = (): ItemsState => {
  const [allItems, setAllItems] = useState<Array<Item>>();
  const [activeItems, setActiveItems] = useState<Array<Item>>();
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { isManufacturingEnabled } = useSettings();

  const getItem = (itemId) => allItems?.find((item) => item.id === itemId);

  const refreshItems = useCallback(async () => {
    const items: Item[] = await services.manufacturing.listItems();
    setAllItems(items);
    setActiveItems(
      items.filter(
        (item) => item.amount > 0 && item.status !== ItemStatus.Scrap
      )
    );
  }, [services.manufacturing]);

  useEffect(() => {
    if (!isManufacturingEnabled?.()) {
      return;
    }
    refreshItems();
  }, [isManufacturingEnabled, refreshItems]);

  return {
    allItems,
    activeItems,
    getItem,
    refreshItems,
  };
};

export default useItems;
