import { useState, useCallback, useEffect } from 'react';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { useSettings, PIN_SIGNOFF_ENABLED_KEY } from '../../contexts/SettingsContext';
import userSettings from '../../api/userSettings';
import Button, { BUTTON_TYPES } from '../Button';
import EphemeralSecretModal from '../EphemeralSecretModal';

const CONFIRM_CREATE_PIN_MESSAGE =
  'Are you sure you want to generate a new PIN? Generating a new PIN will make any previously generated PINs unusable.';
const EXISTING_PIN_MESSAGE = 'You currently have a PIN issued to you.';
const MIXPANEL_EVENT_NAME = 'PIN generated';

type HasExistingPin = {
  loading: boolean;
  pinExists: boolean | null;
};

const PinSettings = () => {
  const [pin, setPin] = useState<string | null>(null);
  const [hasExistingPin, setHasExistingPin] = useState<HasExistingPin>({
    loading: true,
    pinExists: null,
  });
  const { mixpanel } = useMixpanel();
  const { getSetting } = useSettings();

  const isPinSignoffEnabled = getSetting(PIN_SIGNOFF_ENABLED_KEY, false);

  const setPinExists = () => {
    setHasExistingPin({
      loading: false,
      pinExists: true,
    });
  };

  const setNoExistingPin = () => {
    setHasExistingPin({
      loading: false,
      pinExists: false,
    });
  };

  const checkPinExists = useCallback(async () => {
    try {
      await userSettings.pinExists();
      setPinExists();
    } catch {
      setNoExistingPin();
    }
  }, []);

  useEffect(() => {
    checkPinExists();
  }, [checkPinExists]);

  const onDismiss = () => {
    setPin(null);
    setPinExists();
  };

  const onGeneratePin = async () => {
    if (hasExistingPin.pinExists && !window.confirm(CONFIRM_CREATE_PIN_MESSAGE)) {
      return;
    }
    const generated = await userSettings.generatePin();
    setPin(generated);
    if (mixpanel) {
      mixpanel.track(MIXPANEL_EVENT_NAME);
    }
  };

  if (hasExistingPin.loading) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-2">
      {pin && <EphemeralSecretModal secretName="PIN" fullSecret={pin} onDismiss={onDismiss} size="md" />}
      <div className="text-lg font-medium">PIN</div>
      {hasExistingPin.pinExists && <div className="text-sm">{EXISTING_PIN_MESSAGE}</div>}
      <div className="flex">
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={onGeneratePin}
          isDisabled={!isPinSignoffEnabled}
          leadingIcon="plus"
        >
          Generate New PIN
        </Button>
      </div>
    </div>
  );
};

export default PinSettings;
