import { useCallback, useEffect, useState } from 'react';
import { Tool } from 'shared/lib/types/api/manufacturing/tools/models';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import fromClient from '../lib/tools/fromClient';

interface ToolState {
  tool: Tool | undefined;
}

const useTool = ({ toolId }: { toolId: number }): ToolState => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [tool, setTool] = useState<Tool>();

  const getTool = useCallback(async () => {
    const tool = await services.tools.getTool(toolId);
    setTool(fromClient.toTool(tool));
  }, [services.tools, toolId]);

  useEffect(() => {
    getTool();
  }, [getTool]);

  return {
    tool,
  };
};

export default useTool;
