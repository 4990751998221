import { useEffect, useMemo } from 'react';
import MarkdownView from './Markdown/MarkdownView';
import { BlockProvider } from '../contexts/BlockContext';
import ReferenceDetail from './ReferenceDetail';
import useUnits from '../hooks/useUnits';
import useExpression from '../hooks/useExpression';
import { ExpressionReferences, RunTextBlock } from 'shared/lib/types/views/procedures';
import { isLegacyTextBlock } from 'shared/lib/procedureUtil';

interface BlockTextProps {
  block: RunTextBlock;
  isValid?: boolean;
  setExpressionRecorded?: (recorded: { value?: string; display?: string; references?: ExpressionReferences }) => void;
}

const ReferenceTextView = ({ block, isValid = true, setExpressionRecorded }: BlockTextProps) => {
  const { findDefinedUnit } = useUnits();

  const legacyTextActive = useMemo(() => isLegacyTextBlock(block), [block]);

  const { expressionResult } = useExpression({
    tokens: block.tokens,
    recorded: block.recorded,
    findDefinedUnit,
    shouldEvaluate: false,
  });

  useEffect(() => {
    if (!legacyTextActive) {
      setExpressionRecorded && setExpressionRecorded(expressionResult);
    }
  }, [expressionResult, legacyTextActive, setExpressionRecorded]);

  const displayText = useMemo(() => {
    if (legacyTextActive) {
      return block.text;
    }

    return expressionResult.display ?? '';
  }, [legacyTextActive, expressionResult.display, block.text]);

  const containsReferences = useMemo(() => {
    if (block.tokens) {
      return block.tokens.some((token) => token.type === 'reference');
    }
    return false;
  }, [block.tokens]);

  return (
    <BlockProvider value={{ isValid }}>
      <MarkdownView
        text={displayText}
        tooltipContent={
          <ReferenceDetail
            tokens={block.tokens ?? []}
            displayText={displayText}
            references={expressionResult.references}
          />
        }
        tooltipVisible={containsReferences}
      />
    </BlockProvider>
  );
};

export default ReferenceTextView;
