import React, { useMemo, useState, useCallback } from 'react';
import revisions, { DMPDiffType } from '../../lib/revisions';
import { generateHiddenClassString } from '../../lib/styles';
import SuggestedEditsModal from '../SuggestedEditsModal';
import useBlockRedlines from '../../hooks/useBlockRedlines';
import ButtonActionIcon from '../ButtonActionIcon';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import { BlockValues, RedlineBlock } from '../Blocks/BlockTypes';
import ReferenceTextViewDiff from '../ReferenceTextViewDiff';
import ProcedureDiffText from '../ProcedureDiffText';

interface BlockTextProps {
  block: BlockValues<'text'>;
  blockLabel: string;
  redlines?: Array<RedlineBlock<'text'>>;
  isHidden?: boolean;
  showsRedlineAction?: boolean;
  onRedlineAction?: () => void;
  onAcceptPendingRedline?: (redlineIndex: number) => Promise<void>;
  onRefChanged?: (id: string, element) => void;
  scrollMarginTopValueRem?: number;
}

/*
 * Component for rendering a text block.
 *
 * block: Block object of type "text", with text to display
 * redlines: Array of RedlineBlock objects with change history
 * isHidden: Whether this block is hidden (expanded/collapsed)
 * showsRedlineAction: True to display an additional action to begin a redline edit.
 *                     False to cancel or close any existing redline edit.
 * onRedlineAction: Function callback, triggered when redline action button is clicked.
 */
const ReviewBlockText = ({
  block,
  blockLabel,
  redlines,
  isHidden,
  showsRedlineAction,
  onRedlineAction = () => {
    /* no-op */
  },
  onAcceptPendingRedline,
  onRefChanged,
  scrollMarginTopValueRem,
}: BlockTextProps) => {
  const [showsRedlineModal, setShowsRedlineModal] = useState(false);
  const { latestApprovedBlock, latestRedline, hasRedlines, allRedlineUserIds, hasPendingRedlines } = useBlockRedlines({
    block,
    redlines,
  });

  const onTextBlockRefChanged = useCallback(
    (element) => {
      return onRefChanged && onRefChanged(block.id, element);
    },
    [block.id, onRefChanged]
  );

  const acceptRedline = useCallback(() => {
    if (!onAcceptPendingRedline) {
      return Promise.resolve();
    }
    return onAcceptPendingRedline(latestRedline.redlineIndex);
  }, [latestRedline, onAcceptPendingRedline]);

  const acceptRedlineEnabled = useMemo(
    () => Boolean(hasPendingRedlines && onAcceptPendingRedline),
    [hasPendingRedlines, onAcceptPendingRedline]
  );

  const redlineDiffs = useMemo(() => {
    if (!block || !latestRedline) {
      return [];
    }
    return revisions.getTextDiffs(block.text, latestRedline.block.text);
  }, [block, latestRedline]);

  const getDiffTypeClass = (type) => {
    switch (type) {
      case DMPDiffType.insertion:
        return 'text-red-600';
      case DMPDiffType.deletion:
        return 'line-through';
      default:
        return '';
    }
  };

  // Layout is intended for CSS grid template defined in Run.js and Procedure.js
  return (
    <>
      <tr aria-label="Content Block" role="region">
        <td></td>
        <td colSpan={2}>
          <div className={generateHiddenClassString('', isHidden)}></div>
          <div
            aria-label="Text Content"
            className={generateHiddenClassString('mt-2 mr-8 flex page-break', isHidden)}
            ref={onTextBlockRefChanged}
            style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
          >
            {/* Redline changes modal */}
            {showsRedlineModal && (
              <SuggestedEditsModal
                hideModal={() => setShowsRedlineModal(false)}
                allRedlineUserIds={allRedlineUserIds}
                latestTimestamp={latestRedline.createdAt}
                acceptAction={acceptRedlineEnabled ? acceptRedline : undefined}
                redlinePending={hasPendingRedlines}
                isBlueline={latestRedline.run_only}
              >
                <div className="min-w-0 whitespace-pre-line break-words">
                  {redlineDiffs.map(([type, text], index) => (
                    <span key={index} className={getDiffTypeClass(type)}>
                      {text}
                    </span>
                  ))}
                </div>
              </SuggestedEditsModal>
            )}
            <Spacer />
            <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
            {/* Content */}
            <div className="min-w-0 w-full self-start">
              {latestApprovedBlock && latestApprovedBlock.text && (
                <div className="max-w-full break-words">
                  {latestApprovedBlock.tokens && <ReferenceTextViewDiff block={latestApprovedBlock} />}
                  {!latestApprovedBlock.tokens && (
                    <ProcedureDiffText
                      diffValue={latestApprovedBlock.text}
                      diffChangeState={latestApprovedBlock.diff_change_state}
                      useMarkdownWhenNoDiff={true}
                    />
                  )}
                </div>
              )}
            </div>
            {/* Show action button for showing redline modal */}
            {hasRedlines && (
              <span className="ml-1">
                <ButtonActionIcon
                  icon="strikethrough"
                  iconType="caution"
                  onAction={() => setShowsRedlineModal(true)}
                  ariaLabel="Suggested Edits"
                  pendingAction={hasPendingRedlines}
                />
              </span>
            )}
            {/* Show action button for making new redline edit */}
            {showsRedlineAction && (
              <span className="ml-1">
                <ButtonActionIcon icon="pencil-alt" onAction={onRedlineAction} ariaLabel="Edit" />
              </span>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default ReviewBlockText;
