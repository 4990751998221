import { Activity, Attachment, EntityDetailWithValue, Id } from './util';
import { RealtimeData } from '../realtimeUpdatesTypes';
import { Step } from '../views/procedures';

export type ReferenceType =
  | 'step'
  | 'run'
  | 'item'
  | 'part'
  | 'order'
  | 'unlinked';

export enum ReferenceTypeEnum {
  Step = 'step',
  Run = 'run',
  Item = 'item',
  Part = 'part',
  Order = 'order',
  Unlinked = 'unlinked',
}

export type RunActions = {
  type: string;
  timestamp: string;
  user_id: string;
};

export enum StatusType {
  Draft = 'draft',
  Active = 'active',
  Closed = 'closed',
}

export type Severity = {
  id: number;
  name: string;
  color: string;
  pausesRun: boolean;
};

export type Status = {
  id: number;
  status: StatusType;
  name: string;
  color: string;
};

export type SubStatus = {
  id: number;
  status_id: number;
  value: string;
  label: string;
  color: string;
};

export type Issue = RealtimeData & {
  title: string;
  notes?: string;
  created_by: string;
  run_id?: string;
  reference_id: string;
  reference_type: ReferenceType;
  severity: Severity;
  status: Status;
  sub_status: SubStatus | null;
  attachments?: Array<Attachment>;
  assignees?: Array<string>;
  activity?: Array<Activity | RunActions>;
  details?: Array<EntityDetailWithValue>;
  steps: Array<Step>;
  project_id?: string;
};

export type Metadata = {
  severity: Array<Severity>;
  status: Array<Status>;
  sub_status: Array<SubStatus>;
};
