import {
  Part,
  ComponentPart,
} from 'shared/lib/types/postgres/manufacturing/types';
import { Attachment } from 'shared/lib/types/attachments';
import { ItemUsage } from 'shared/lib/types/api/manufacturing/items/models';

export const EMPTY_TRACKING_DISPLAY_VALUE = '--';
export const EMPTY_REVISION_DISPLAY_VALUE = '--';

export type PartParams = {
  partId: string;
};

export type ItemParams = {
  itemId: string;
};

export type TotalUsageRecord = {
  name: string;
  totalAmount: number;
  limit?: number;
};

export type ComponentItem = ComponentPart & {
  item_id: string;
};

export enum ItemStatus {
  InInventory = 'In Inventory',
  Scrap = 'Scrap',
  CheckedOut = 'Checked Out',
  Consumed = 'Consumed',
}

export type Item = {
  id: string;
  rev: string;
  part_revision_id: string;
  part_no: string;
  part: Part;
  amount: number;
  serial?: string;
  lot?: string;
  location_id?: string;
  unit_cost_cents?: number; // Unit cost in US cents
  notes?: string;
  created_at?: string;
  usage?: Array<ItemUsage>;
  kit_components?: Array<ComponentItem>;
  components?: Array<ComponentItem>;
  attachments?: Array<Attachment>;
  details?: Array<ItemDetail>;
  status: ItemStatus;
};

export enum ComponentItemType {
  Kit = 'kit',
  UserDefined = 'user',
  Missing = 'missing',
}

export type DenormalizedComponentItem = ComponentItem & {
  type: ComponentItemType;
  item?: Item;
  part?: ComponentPart;
};

export type ItemRun = {
  content_type: string;
  procedure_code: string;
  procedure_name: string;
  procedure_id: string;
  run_id: string;
  run_start_time: string;
  section_id: string;
  step_finish_time: string | undefined;
  step_id: string;
  step_state: string;
};

export type ItemOrder = {
  id: string;
  order_id: string;
  item_id: string;
  location_id?: string;
  quantity: number;
  unit_cost_cents: number;
  created_at: string;
};

export type Vendor = {
  id: string;
  name: string;
  address: string;
  contact?: string;
  notes?: string;
};

export type Location = {
  id: string;
  parent: string;
  name: string;
  code: string;
  full_code?: string;
  items_enabled?: boolean;
};

export type LocationMap = {
  [id: string]: Location;
};

export type Tag = {
  id: string;
  name: string;
};

export type PartTag = {
  id: string;
  part_id: string;
  tag_id: string;
};

export type ItemDetail = {
  id: string;
  name: string;
  value?: { recorded: string };
};
