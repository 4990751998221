import {
  NotificationType,
  NotifyAtMentionContext,
  NotifyAutomationContext,
  NotifyReviewerContext,
  NotificationChannel,
  NotificationPreferenceDetails,
} from './postgres/notifications';
import { NotificationStatus } from '../notifications';
import { RealtimeData } from './realtimeUpdatesTypes';

export interface AppNotificationDisplay extends RealtimeData {
  status: NotificationStatus;
  actor_id: string;
  user_id: string;
  type: NotificationType;
  context:
    | NotifyReviewerContext
    | NotifyAutomationContext
    | NotifyAtMentionContext;
  active?: boolean;
}

export interface AppNotificationReviewersDisplay
  extends AppNotificationDisplay {
  type: NotificationTypes.Reviewers;
  context: NotifyReviewerContext;
}

export enum NotificationChannels {
  Email = 'email',
  InApp = 'in_app',
  SlackChannel = 'slack_channel',
  SlackDM = 'slack_dm',
  APIClient = 'api_client',
}

export enum NotificationTypes {
  Reviewers = 'reviewers',
  AtMention = 'at_mention',
  Automation = 'automation',
  UserInvite = 'user_invite',
}

export type NotificationChannelPreferences = {
  [channel in NotificationChannel]?: {
    is_enabled: boolean;
    details?: NotificationPreferenceDetails;
  };
};

export type FormattedNotificationPreferences = {
  notification_type: NotificationType;
} & NotificationChannelPreferences;

export type NotificationChannelSettings = {
  channel: NotificationChannel;
  details?: NotificationPreferenceDetails;
};

export type SlackChannelInfo = {
  slack_channel_id: string;
  slack_channel_name: string;
};
