import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Error from '../../components/Error';
import EventDependency from './EventDependency';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { eventPath } from '../../lib/pathUtil';
import { filterPredecessors } from 'shared/lib/scheduleUtil';
interface IDependency {
  label: string;
  source: string;
  start: DateTime;
  end: DateTime;
}

const RelativeEventSelect = ({ field, form, isEditing, events, currentTeamId }) => {
  const [dependency, setDependency] = useState<IDependency | null>(null);
  const error = form.errors[field.name];
  const { setFieldValue } = form;
  const { duration } = form.values;
  const history = useHistory();

  const safePredecessors = filterPredecessors(events, form.values.id) || [];

  const options = safePredecessors
    .filter((e) => e.id !== form.values.id && e.start)
    .map((e) => ({
      label: `${e.name} (${(e.start as DateTime).toFormat('yyyy-MM-dd')})`,
      value: e.id,
      isDisabled: false,
      deleted: e.deleted,
      start: e.start,
      end: e.end,
    }))
    .sort((e1, e2) => (e1.start && e2.start && e1.start < e2.start ? 1 : -1));

  const handleLabelClick = () => {
    if (!isEditing) {
      return;
    }
  };

  const selectedEvent = useMemo(() => {
    return options.find((e) => e.value === form.values.predecessor_id);
  }, [form.values.predecessor_id, options]);

  const navigateToEvent = useCallback(() => {
    if (selectedEvent) {
      history.push(eventPath(currentTeamId, selectedEvent.value));
    }
  }, [history, currentTeamId, selectedEvent]);

  useEffect(() => {
    if (dependency) {
      const newStart = dependency.end ? dependency.end : dependency.start;
      if (duration) {
        setFieldValue('end', newStart.plus(duration));
      }
      setFieldValue('predecessor_id', dependency.source);
    }
  }, [dependency, setFieldValue, duration]);

  return (
    <div className="flex flex-row items-center h-[42px]">
      {isEditing && (
        <EventDependency
          placeholder={selectedEvent?.label ? selectedEvent.label : 'Select Predecessor Event'}
          dependency={selectedEvent}
          options={options}
          onChange={(val) => {
            setDependency(val);
          }}
          onRemove={() => {
            form.setFieldValue('predecessor_id', null);
            setDependency(null);
          }}
          isEditing={isEditing}
          isRelative={true}
          currentTeamId={currentTeamId}
        />
      )}
      {!isEditing && (
        <div
          className={`flex flex-row w-fit px-2 h-[42px] rounded border items-center group ${
            isEditing ? ' cursor-pointer bg-white border-gray-400' : 'border-transparent'
          }`}
          onClick={handleLabelClick}
        >
          {!selectedEvent && <span className="italic text-gray-400">N/A</span>}
          {selectedEvent && (
            <span className="px-2 link cursor-pointer" onClick={navigateToEvent}>
              {selectedEvent.label}
            </span>
          )}
          {isEditing && (
            <div className="rounded p-1 ml-2 group-hover:bg-gray-200">
              <FontAwesomeIcon className="text-gray-500" icon="edit" />
            </div>
          )}
        </div>
      )}
      {error && <Error text={error} />}
    </div>
  );
};

export default RelativeEventSelect;
