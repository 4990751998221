import React from 'react';
import PropTypes from 'prop-types';
import { toPairs } from 'lodash';
import numericalFieldHandler from '../../../utils/numericalFieldHandler';
import { capitalizeFirstLetter } from 'shared/lib/text';

const RepeatWeekly = ({ id, weekly: { interval, days, options }, handleChange }) => {
  let daysArray = toPairs(days);
  if (options.weekStartsOnSunday) {
    daysArray = daysArray.slice(-1).concat(daysArray.slice(0, -1));
  }

  return (
    <div className="ml-3 space-y-1">
      <div className="flex flex-row items-center space-x-2">
        <span>every</span>
        <input
          id={`${id}-interval`}
          name="repeat.weekly.interval"
          aria-label="Repeat weekly interval"
          className="rounded border h-[38px] w-12 text-center"
          value={interval}
          onChange={numericalFieldHandler(handleChange)}
        />
        <span>{`${interval === 1 ? 'week' : 'weeks'}`}</span>
      </div>

      <div className="flex flex-row space-x-2">
        {daysArray.map(([dayName, isDayActive]) => (
          <label
            htmlFor={`${id}-${dayName}`}
            key={dayName}
            className={`btn btn-secondary ${isDayActive ? 'active' : ''}`}
          >
            <input
              type="checkbox"
              id={`${id}-${dayName}`}
              name={`repeat.weekly.days[${dayName}]`}
              className="rounded text-sm border-gray-300"
              checked={isDayActive}
              onChange={(event) => {
                const editedEvent = {
                  ...event,
                  target: {
                    ...event.target,
                    value: !isDayActive,
                    name: event.target.name,
                  },
                };

                handleChange(editedEvent);
              }}
            />
            <span className="pl-1">{capitalizeFirstLetter(dayName)}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

RepeatWeekly.propTypes = {
  id: PropTypes.string.isRequired,
  weekly: PropTypes.shape({
    interval: PropTypes.number.isRequired,
    days: PropTypes.shape({
      mon: PropTypes.bool.isRequired,
      tue: PropTypes.bool.isRequired,
      wed: PropTypes.bool.isRequired,
      thu: PropTypes.bool.isRequired,
      fri: PropTypes.bool.isRequired,
      sat: PropTypes.bool.isRequired,
      sun: PropTypes.bool.isRequired,
    }).isRequired,
    options: PropTypes.shape({
      weekStartsOnSunday: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RepeatWeekly;
