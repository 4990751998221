import superlogin from './superlogin';
import { API_URL } from '../config';
import { SearchResult } from 'shared/lib/types/search';

class SearchService {
  teamId: string;
  url: string;

  constructor(teamId: string | null) {
    if (!teamId) {
      throw new Error('Missing teamId');
    }
    this.teamId = teamId;
    this.url = `${API_URL}/teams/${this.teamId}/search`;
  }

  async getSearchResults(query: string): Promise<Array<SearchResult>> {
    try {
      const response = await superlogin.getHttp().post(this.url, { query });
      return response.data.data;
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }
}

export default SearchService;
