import AttachmentPreview from '../../AttachmentPreview';
import { useMemo } from 'react';
import NoValueRecorded from './NoValueRecordedDisplay';
import LabelDisplay from './LabelDisplay';
import { FieldInputAttachmentBlock, RunFieldInputAttachmentBlock } from 'shared/lib/types/views/procedures';
import ReferenceTokenTooltip from './ReferenceTokenTooltip';

interface AttachmentDisplayProps {
  block: FieldInputAttachmentBlock;
  referenceId?: string;
}

const AttachmentDisplay = ({ block, referenceId }: AttachmentDisplayProps) => {
  const hasRecordedAttachment = useMemo(() => {
    return Boolean((block as RunFieldInputAttachmentBlock).recorded?.value);
  }, [block]);

  return (
    <div className="flex flex-row space-x-2 items-start">
      <div className="mt-1">
        <LabelDisplay text={block.name} />
      </div>
      <div className="group">
        {referenceId && <ReferenceTokenTooltip referenceId={referenceId} />}
        {hasRecordedAttachment && (
          <div className="mt-0.5">
            <AttachmentPreview attachment={(block as RunFieldInputAttachmentBlock).recorded?.value} />
          </div>
        )}
        {!hasRecordedAttachment &&
          (referenceId ? (
            <NoValueRecorded text="No file chosen" />
          ) : (
            <div className="mt-1">
              <input type="file" className="text-gray-400" disabled />
            </div>
          ))}
      </div>
    </div>
  );
};

export default AttachmentDisplay;
