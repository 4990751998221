import { copyStepWithoutActiveContent } from './runUtil';
import { IRedlineId } from './types/couch/procedures';
import { RunAddedStep, RunStep } from './types/views/procedures';

export const REDLINE_STATE = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  RESOLVED: 'resolved',
  UNRESOLVED: 'unresolved',
};

export const getRedlineId = <T extends IRedlineId>(
  redline: T
): string | undefined => redline.redline_id ?? redline.redlineId;

export const isRedlineAddedStep = (step: RunStep): boolean => {
  return (
    (step as RunAddedStep).created_during_run &&
    !(step as RunAddedStep).run_only
  );
};

export const convertToRedlineAddedStep = (
  runAddedStep: RunAddedStep
): RunAddedStep => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const redlineAddedStep = copyStepWithoutActiveContent(
    runAddedStep
  ) as RunAddedStep;
  if (!redlineAddedStep.redline_id) {
    redlineAddedStep.redline_id = getRedlineId(redlineAddedStep);
  }
  delete redlineAddedStep.repeat_step_enabled;
  return redlineAddedStep;
};
