import { FieldInputCustomListBlock } from 'shared/lib/types/views/procedures';
import SelectDisplay from './SelectDisplay';

interface CustomListDisplayProps {
  block: FieldInputCustomListBlock;
  referenceId?: string;
}

const CustomListDisplay = ({ block, referenceId }: CustomListDisplayProps) => {
  return <SelectDisplay block={block} values={block.options} referenceId={referenceId} />;
};

export default CustomListDisplay;
