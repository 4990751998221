import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';

const DELAY_CLOSE_TIME_MS = 1000;

export interface TooltipOverlayProps {
  /** Wrapped element for the tooltip trigger area */
  children: JSX.Element;
  /** Tooltip Overlay content */
  content: React.ReactNode;
  height?: 'full';
  /** If set, includes the tooltip overlay wrapper */
  visible?: boolean;
  /** Controls whether the overlay will stay open for a short time after onMouseLeave. Allows for clicking in the overlay. */
  delayClose?: boolean;
}

const TooltipOverlay = ({ children, content, height, visible = true, delayClose = false }: TooltipOverlayProps) => {
  const overlayRef = useRef<OverlayPanel>(null);
  const hideTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const delayTimeMs = useMemo(() => (delayClose ? DELAY_CLOSE_TIME_MS : 0), [delayClose]);

  const hideTooltip = useCallback(() => {
    overlayRef.current?.hide();
  }, []);

  useEffect(() => {
    if (overlayRef.current) {
      document.addEventListener('scroll', hideTooltip, { capture: true });
    }
    // Remove listener when menu visibility changes or component unmounts
    return () => {
      document.removeEventListener('scroll', hideTooltip, { capture: true });
    };
  }, [hideTooltip]);

  return visible ? (
    <>
      <div
        className={height === 'full' ? 'h-full' : ''}
        data-testid="tooltip-overlay-trigger"
        onMouseEnter={(e) => {
          if (hideTimeoutRef.current) {
            clearTimeout(hideTimeoutRef.current);
          }
          overlayRef.current?.toggle(e);
        }}
        onMouseLeave={(e) => {
          hideTimeoutRef.current = setTimeout(hideTooltip, delayTimeMs);
        }}
      >
        {children}
      </div>
      <OverlayPanel ref={overlayRef}>
        <div
          className="p-3"
          data-testid="tooltip-overlay-contents"
          onClick={hideTooltip}
          onMouseEnter={(e) => {
            if (hideTimeoutRef.current) {
              clearTimeout(hideTimeoutRef.current);
            }
          }}
          onMouseLeave={(e) => {
            hideTimeoutRef.current = setTimeout(hideTooltip, delayTimeMs);
          }}
        >
          {content}
        </div>
      </OverlayPanel>
    </>
  ) : (
    children
  );
};

export default TooltipOverlay;
