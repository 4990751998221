import {
  Tool,
  ToolInstance,
} from 'shared/lib/types/api/manufacturing/tools/models';
import {
  CreateTool,
  CreateToolInstance,
} from 'shared/lib/types/api/manufacturing/tools/requests';
import { Id } from 'shared/lib/types/postgres/util';
import { Attachment } from '../../../hooks/useAttachment';

/**
 * Represents a display tool on /tools. Because tools and inventory are displayed on the same page,
 * this type represents either a group row (tool revision) or row (tool instance). For tools without
 * instances, a single row is returned with `is_instance` false. For tools with instances, one
 * row per instance is returned, with `is_instance` true for each.
 */
export type DisplayTool = (Tool | ToolAndToolInstance) & {
  is_instance: boolean;
};

export type ToolAndToolInstance = ToolWithoutId & ToolInstance;

/**
 * This type makes explicit the fact that the `id` property on `ToolAndToolInstance` comes from
 * `ToolInstance` and represents the tool instance id. The tool id on that type is `tool_id`.
 */
type ToolWithoutId = Omit<Tool, keyof Id>;

export type DisplayCreateUpdateTool = CreateTool;

export type DisplayCreateUpdateToolInstance = CreateToolInstance &
  Pick<CreateTool, 'name' | 'tool_number'>;

/**
 * These methods convert to display types.
 */
const toDisplay = {
  fromToolsAndToolInstances: (
    tools: Array<Tool>,
    toolInstances: Array<ToolInstance>
  ): Array<DisplayTool> => {
    const displayTools: Array<DisplayTool> = [];
    const toolInstancesByToolId = toolInstances.reduce((acc, toolInstance) => {
      const currToolInstances = acc.get(toolInstance.tool_id) ?? [];
      currToolInstances.push(toolInstance);
      return acc.set(toolInstance.tool_id, currToolInstances);
    }, new Map<number, Array<ToolInstance>>());

    for (const tool of tools) {
      const toolInstancesForTool = toolInstancesByToolId.get(tool.id) ?? [];
      if (toolInstancesForTool.length === 0) {
        displayTools.push({
          ...tool,
          is_instance: false,
        });
      }
      for (const toolInstance of toolInstancesForTool) {
        displayTools.push({
          ...toDisplay.fromToolAndToolInstance(tool, toolInstance),
          is_instance: true,
        });
      }
    }
    return displayTools;
  },

  fromToolAndToolInstance: (
    tool: Tool,
    toolInstance: ToolInstance
  ): ToolAndToolInstance => {
    const toolAndToolInstance: ToolAndToolInstance = {
      ...tool,
      id: toolInstance.id,
      tool_id: toolInstance.tool_id,
      serial_number: toolInstance.serial_number,
      created_at: toolInstance.created_at,
      updated_at: toolInstance.updated_at,
      check_in_outs: toolInstance.check_in_outs,
      usage: toolInstance.usage,
      location_id: toolInstance.location_id,
    };
    if (toolInstance.notes) {
      toolAndToolInstance.notes = toolInstance.notes;
    }
    if (toolInstance.checked_out) {
      toolAndToolInstance.checked_out = toolInstance.checked_out;
    }
    if (toolInstance.last_maintained_by) {
      toolAndToolInstance.last_maintained_by = toolInstance.last_maintained_by;
    }
    if (toolInstance.last_maintained_at) {
      toolAndToolInstance.last_maintained_at = toolInstance.last_maintained_at;
    }
    if (toolInstance.location_id) {
      toolAndToolInstance.location_id = toolInstance.location_id;
    }
    return toolAndToolInstance;
  },

  fromTool: (tool: Tool): DisplayCreateUpdateTool => {
    const displayCreateUpdateTool: DisplayCreateUpdateTool = {
      tool_number: tool.tool_number,
      name: tool.name,
      usage_type_ids: tool.usage_type_ids,
    };
    if (tool.description) {
      displayCreateUpdateTool.description = tool.description;
    }
    if (tool.image_attachment_id) {
      displayCreateUpdateTool.image_attachment_id = tool.image_attachment_id;
    }
    if (tool.maintenance_interval_days) {
      displayCreateUpdateTool.maintenance_interval_days =
        tool.maintenance_interval_days;
    }
    return displayCreateUpdateTool;
  },

  fromToolImageProps: (
    tool?: Tool | DisplayTool | DisplayCreateUpdateTool
  ): Attachment | undefined => {
    if (!tool?.image_attachment_id) {
      return;
    }
    return {
      attachment_id: tool.image_attachment_id,
    };
  },
};

export default toDisplay;
