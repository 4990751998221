import { useMemo, useCallback, useState, useEffect, KeyboardEvent, ReactElement } from 'react';
import Select from 'react-select';
import VirtualizedList from './VirtualizedList';
import { useSettings } from '../../contexts/SettingsContext';
import { reactSelectStyles } from '../../lib/styles';
import { OperationId, CouchLikeOperationSummary } from 'shared/lib/types/operations';
import RunStatusLabel from '../RunStatusLabel';
import { RunState } from 'shared/lib/types/couch/procedures';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';

interface OperationSelectorProps {
  operationId?: OperationId;
  enabled: boolean;
  onSaveOperation?: (operation: string) => void;
  canClearOperation?: boolean;
  onClearOperation?: () => void;
  runState?: RunState;
}

type OperationOption = {
  label: ReactElement;
  value: string;
};

const TRUNCATE_LENGTH = 32;
const truncate = (name: string) => (name.length > TRUNCATE_LENGTH ? `${name.substring(0, TRUNCATE_LENGTH)}...` : name);

const OperationSelector = ({
  operationId,
  enabled,
  onSaveOperation,
  onClearOperation,
  canClearOperation,
  runState,
}: OperationSelectorProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { operations, setOperationsPagination } = useSettings();
  const [currentOperation, setCurrentOperation] = useState<CouchLikeOperationSummary | null>(null);
  const [currentOperationOption, setCurrentOperationOption] = useState<OperationOption | null>(null);
  const [isClearable, setIsClearable] = useState(true);

  const allOperations: Array<CouchLikeOperationSummary> = useMemo(
    () => Object.values((operations?.summary && operations?.operations) ?? {}),
    [operations]
  );

  useEffect(() => {
    if (enabled) {
      setOperationsPagination &&
        setOperationsPagination({
          planning_page: 0,
          running_page: 0,
          ended_page: 0,
          limit: Number.MAX_SAFE_INTEGER,
        });
    }
  }, [enabled, setOperationsPagination]);

  useEffect(() => {
    if (!enabled && operationId) {
      services.settings.getOperation(operationId.key).then(setCurrentOperation);
    }
  }, [enabled, operationId, services.settings]);

  useEffect(() => {
    if (!currentOperation) {
      setCurrentOperationOption(null);
      return;
    }

    setCurrentOperationOption({
      value: currentOperation.name,
      label: (
        <>
          <RunStatusLabel statusText={currentOperation.state} /> {truncate(currentOperation.name)}
        </>
      ),
    });
  }, [currentOperation]);

  useEffect(() => {
    const operation = allOperations.find((op) => op.key === operationId?.key);
    setCurrentOperation(operation ?? null);
  }, [allOperations, operationId?.key]);

  const options: Array<OperationOption> = useMemo(() => {
    return allOperations
      .filter((operation) =>
        runState === 'running' || runState === 'paused'
          ? operation.state === 'running'
          : ['running', 'ended'].includes(operation.state)
      )
      .sort((op1, op2) => {
        if (op1.state !== op2.state) {
          return op1.state === 'running' ? -1 : 1;
        }
        return op1.name.localeCompare(op2.name);
      })
      .map((operation) => ({
        value: operation.name,
        label: (
          <>
            <RunStatusLabel statusText={operation.state} /> {truncate(operation.name)}
          </>
        ),
      }));
  }, [allOperations, runState]);

  const onChange = useCallback(
    (selection: OperationOption) => {
      if (!selection && typeof onClearOperation === 'function') {
        onClearOperation();
        setCurrentOperationOption(null);
        return;
      }

      if (typeof onSaveOperation === 'function') {
        onSaveOperation(selection.value);
        setCurrentOperationOption(selection);
      }
    },
    [onClearOperation, onSaveOperation]
  );

  const onKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsClearable(true);
    }
  }, []);

  // Remove the dropdown indicator components when disabled
  const customComponents = useMemo(() => {
    if (!enabled) {
      return {
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      };
    } else {
      return { MenuList: VirtualizedList };
    }
  }, [enabled]);

  return (
    <div className="w-full items-center max-w-md">
      <label className="mb-1 font-semibold text-sm">Operation</label>
      <div className="w-full">
        <Select
          classNamePrefix="react-select"
          value={currentOperationOption}
          styles={reactSelectStyles}
          components={customComponents}
          onChange={onChange}
          isDisabled={!enabled}
          onKeyDown={onKeyDown}
          isClearable={canClearOperation && runState === 'running' && isClearable}
          placeholder="Select operation"
          options={options}
          aria-label="operation selector"
        />
      </div>
    </div>
  );
};

export default OperationSelector;
