import React, { useEffect, useState } from 'react';
import useMasterProcedureListHelpers from '../../../hooks/useMasterProcedureListHelpers';
import ProcedureSelect from './ProcedureSelect';
import RunSelect from './RunSelect';
import { DatabaseServices } from '../../../contexts/proceduresSlice';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import Select from 'react-select';

const PLANNING_OPTIONS = [{ value: 'procedure', label: 'New Run' }];
const ALL_OPTIONS = [...PLANNING_OPTIONS, { value: 'run', label: 'Existing Run' }];

const EventProcedure = ({ form }) => {
  const { syncMasterProcedureList } = useMasterProcedureListHelpers();
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [existingRunIds, setExistingRunIds] = useState<Set<string> | null>(null);
  const [type, setType] = useState({ value: 'procedure', label: 'New Run' });

  const { procedure_id, procedure_section_id, run_id, operation } = form.values;

  const isPlanning = operation?.state === 'planning';

  useEffect(() => {
    syncMasterProcedureList();
  }, [syncMasterProcedureList]);

  useEffect(() => {
    services.events.getEventRunIds().then((ids) => setExistingRunIds(ids));
  }, [services.events]);

  const procedureIds = {
    procedure: procedure_id,
    section: procedure_section_id || (procedure_id && ''),
    run: run_id,
  };

  const handleChangeType = (newType) => {
    form.setFieldValue('procedure_id', null);
    form.setFieldValue('procedure_section_id', null);
    form.setFieldValue('run_id', null);
    setType(newType);
  };

  return (
    <div className="flex flex-row space-x-1 items-center mb-3">
      <div className="w-36">
        <Select
          value={type}
          classNamePrefix="react-select"
          className="text-sm border-1 border-gray-400 rounded mr-1"
          options={isPlanning ? PLANNING_OPTIONS : ALL_OPTIONS}
          onChange={handleChangeType}
          isSearchable={false}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      {type.value === 'procedure' && (
        <ProcedureSelect form={form} isDisabled={!!run_id} procedureIds={procedureIds} filterUnreleased={true} />
      )}
      {type.value === 'run' && <RunSelect form={form} isDisabled={!!procedure_id} existingRunIds={existingRunIds} />}
    </div>
  );
};

export default EventProcedure;
