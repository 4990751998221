import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ExpandCollapseButtonsProps {
  buttonClasses: string;
  onExpand: () => void;
  onCollapse: () => void;
  isDisabled: boolean;
  showLabel?: boolean;
}

const ExpandCollapseButtons = ({
  buttonClasses,
  onExpand,
  onCollapse,
  isDisabled,
  showLabel = false,
}: ExpandCollapseButtonsProps) => {
  return (
    <>
      <button className={buttonClasses} type="button" title="Expand Sections" disabled={isDisabled} onClick={onExpand}>
        <FontAwesomeIcon fixedWidth={true} icon="expand-alt" />
        {showLabel && <div className="visible ml-2 lg:hidden">Expand Sections</div>}
      </button>
      <button
        className={buttonClasses}
        type="button"
        title="Collapse Sections"
        disabled={isDisabled}
        onClick={onCollapse}
      >
        <FontAwesomeIcon fixedWidth={true} icon="compress-alt" />
        {showLabel && <div className="visible ml-2 lg:hidden">Collapse Sections</div>}
      </button>
    </>
  );
};

export default ExpandCollapseButtons;
