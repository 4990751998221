import React, { Fragment, useMemo, useCallback, useState } from 'react';
import ExpandCollapseCaret from '../ExpandCollapse/ExpandCollapseCaret';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PERM } from '../../lib/auth';
import { useAuth } from '../../contexts/AuthContext';
import { useMixpanel } from '../../contexts/MixpanelContext';
import procedureUtil from '../../lib/procedureUtil';
import runUtil from '../../lib/runUtil';
import { useRunContext } from '../../contexts/RunContext';
import ProcedureStepEdit from '../ProcedureStepEdit';
import { useRunFilter } from '../../contexts/RunFilterContext';
import { RUN_STATE, isStepEnded } from 'shared/lib/runUtil';
import RunProgressBar from '../RunProgressBar';
import snippetUtil, { ERROR_DYNAMIC_STEPS_NOT_SUPPORTED_IN_SNIPPETS } from '../../lib/snippetUtil';
import ReviewSectionHeader from './ReviewSectionHeader';
import DiffContainer from '../Diff/DiffContainer';
import diffUtil from '../../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import {
  PROCEDURE_STATE_COMPLETED,
  PROCEDURE_STATE_IN_REVIEW,
  PROCEDURE_STATE_RELEASED,
} from 'shared/lib/procedureUtil';
import DiffContainerTableAdapter from '../Diff/DiffContainerTableAdapter';
import { useReviewContext } from '../../contexts/ReviewContext';
import useDiff from '../../hooks/useDiff';
import { SelectionContextProvider } from '../../contexts/Selection';
import ProcedureDiffText from '../ProcedureDiffText';
import { MenuContextAction } from '../MenuContext';
import ReviewProcedureStep from './ReviewProcedureStep';
import ThreeDotMenu from '../../elements/ThreeDotMenu';

const CONFIRM_END_SECTION_STR = 'Are you sure? All unfinished steps in this section will be marked skipped.';
const CONFIRM_REPEAT_SECTION_STR =
  'Are you sure you want to repeat this section? All unfinished steps in this section will be marked skipped.';

const ReviewProcedureSection = ({
  section,
  sectionIndex, // TODO (jon): remove sectionIndex!
  sectionKey,
  sourceName,
  repeatKey,
  projectId,
  runId,
  docState,
  operation,
  readings,
  onRepeatStep,
  onRepeatSection,
  isRepeatable,
  onSkipStep,
  onSkipSection,
  onStepComplete,
  onFailStep,
  onRefChanged,
  onStartLinkedRun,
  onRecordValuesChanged,
  isRedlineFeatureEnabled,
  onSaveRedlineBlock,
  onSaveRedlineStepField,
  onSaveRedlineStepComment,
  onAcceptPendingRedline,
  saveSectionHeaderRedline,
  acceptPendingSectionHeaderRedline,
  saveNewComment,
  onResolveReviewComment,
  onUnresolveReviewComment,
  saveReviewComment,
  addStepAfter,
  comments,
  showReviewComments,
  isCollapsedMap,
  onCollapse,
  onExpandCollapseAllSteps,
  allStepsAreExpanded,
  scrollToBufferRem = 0,
  notifyRemainingStepOperators,
  notificationListenerConnected,
  stepCounts,
  runStatus,
  isPreviewMode,
  onAddStepIssue,
  areRedlineCommentsExpanded,
  expandRedlineComments,
}) => {
  // default function if it is not valid in the props.
  onRefChanged = onRefChanged
    ? onRefChanged
    : () => {
        /* no-op */
      };

  const [precedingStepIdToStepMap, setPrecedingStepIdToStepMap] = useState({});
  const { auth } = useAuth();
  const { mixpanel } = useMixpanel();
  const { isUserParticipant, isSectionVisible, isSingleCardEnabled, isRun } = useRunContext();

  const { isSectionFiltered, isStepFiltered } = useRunFilter();

  const { onScrollToDiffRefChanged } = useReviewContext();
  const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });

  const allStepsEnded = useMemo(() => {
    for (const stepIndex in section.steps) {
      const step = section.steps[stepIndex];
      if (!isStepEnded(step)) {
        return false;
      }
    }
    return true;
  }, [section.steps]);

  const isSectionCollapsed = useMemo(() => {
    // Sections are always expanded in single card view
    if (isSingleCardEnabled) {
      return false;
    }
    return isCollapsedMap[section.id];
  }, [section.id, isCollapsedMap, isSingleCardEnabled]);

  const displaySteps = useMemo(() => {
    const steps = runUtil.getCollectionWithoutRepeats(section.steps);

    // Add in draft steps.
    for (const [precedingStepId, step] of Object.entries(precedingStepIdToStepMap)) {
      const index = steps.findIndex((step) => step.id === precedingStepId);
      steps.splice(index + 1, 0, step);
    }

    // Add in repeated steps.
    const repeats = section.steps.filter((step) => step.repeat_of);
    for (const repeat of repeats) {
      const index = steps.findIndex((step) => step.id === repeat.repeat_of);
      steps.splice(index + 1, 0, repeat);
    }

    return steps;
  }, [section.steps, precedingStepIdToStepMap]);

  const isPendingStep = useCallback((step) => {
    return Boolean(step.precedingStepId);
  }, []);

  const onAddStepBelow = useCallback(
    (step) => {
      if (snippetUtil.isSnippet(section)) {
        window.alert(ERROR_DYNAMIC_STEPS_NOT_SUPPORTED_IN_SNIPPETS);
        return;
      }

      let originalStepIndex = section.steps.findIndex((s) => s.id === step.id);
      while (section.steps[originalStepIndex].repeat_of) {
        originalStepIndex--;
      }
      const precedingStepId = section.steps[originalStepIndex].id;

      setPrecedingStepIdToStepMap((map) => {
        const pending = procedureUtil.newStep();
        pending.precedingStepId = precedingStepId;
        return {
          ...map,
          [precedingStepId]: pending,
        };
      });
    },
    [section]
  );

  const removePendingStep = useCallback((precedingStepId) => {
    return setPrecedingStepIdToStepMap((map) => {
      const updated = { ...map };
      delete updated[precedingStepId];
      return updated;
    });
  }, []);

  const addStepAfterHandler = useCallback(
    async (sectionId, precedingStepId, step) => {
      // Remove step from pending list.
      await removePendingStep(precedingStepId);
      // Save step
      await addStepAfter(sectionId, precedingStepId, step);
    },
    [addStepAfter, removePendingStep]
  );

  const sectionStepIndexMap = useMemo(() => {
    const map = {};
    section.steps.forEach((step, index) => {
      map[step.id] = index;
    });
    return map;
  }, [section.steps]);

  const isReview = useMemo(() => docState === PROCEDURE_STATE_IN_REVIEW, [docState]);
  const isReleased = useMemo(() => docState === PROCEDURE_STATE_RELEASED, [docState]);
  const isCompleted = useMemo(() => docState === PROCEDURE_STATE_COMPLETED, [docState]);
  const isNotEditing = useMemo(
    () => runUtil.isRunStateActive(docState) || isReleased || isReview || isCompleted,
    [docState, isCompleted, isReleased, isReview]
  );

  const removedStepMap = useMemo(() => sharedDiffUtil.getContainerMap(section.steps, 'old'), [section.steps]);
  const nonRemovedStepMap = useMemo(() => sharedDiffUtil.getContainerMap(section.steps, 'new'), [section.steps]);

  const getStepKey = useCallback(
    (step, stepIndex) => {
      const stepIndexForKey = isReview
        ? diffUtil.getIndexForKey(step, removedStepMap, nonRemovedStepMap) ?? 0
        : stepIndex;

      return runUtil.displayStepKey(section.steps, stepIndexForKey);
    },
    [isReview, nonRemovedStepMap, removedStepMap, section.steps]
  );

  const toggleIsSectionCollapsed = useCallback(() => {
    if (typeof onCollapse === 'function') {
      onCollapse(section.id, !isSectionCollapsed);
    }
  }, [isSectionCollapsed, section.id, onCollapse]);

  // TODO (jon): clean up these checks, look into prop typing
  const repeatStep = function (sectionIndex, stepIndex, recorded) {
    if (typeof onRepeatStep === 'function') {
      onRepeatStep(sectionIndex, stepIndex, recorded);
    }
  };

  const repeatSection = useCallback(
    (sectionIndex) => {
      if (typeof onRepeatSection === 'function') {
        if (allStepsEnded || window.confirm(CONFIRM_REPEAT_SECTION_STR)) {
          if (mixpanel) {
            mixpanel.track('Section Repeated');
          }
          onRepeatSection(sectionIndex);
        }
      }
    },
    [onRepeatSection, allStepsEnded, mixpanel]
  );

  const skipStep = function (sectionIndex, stepIndex, recorded) {
    if (typeof onSkipStep === 'function') {
      onSkipStep(sectionIndex, stepIndex, recorded);
    }
  };

  const skipSection = useCallback(
    (sectionIndex) => {
      if (typeof onSkipSection === 'function') {
        if (window.confirm(CONFIRM_END_SECTION_STR)) {
          if (mixpanel) {
            mixpanel.track('Section Skipped');
          }
          onSkipSection(sectionIndex);
        }
      }
    },
    [onSkipSection, mixpanel]
  );

  const failStep = function (sectionIndex, stepIndex, recorded) {
    if (typeof onFailStep === 'function') {
      onFailStep(sectionIndex, stepIndex, recorded);
    }
  };

  const startLinkedRun = function (sectionIndex, stepIndex, contentIndex, linkedRunId) {
    if (typeof onStartLinkedRun === 'function') {
      onStartLinkedRun(sectionIndex, stepIndex, contentIndex, linkedRunId);
    }
  };

  const recordValuesChanged = useCallback(
    (stepId, contentId, recorded) => {
      if (typeof onRecordValuesChanged !== 'function') {
        return;
      }
      onRecordValuesChanged(section.id, stepId, contentId, recorded);
    },
    [section.id, onRecordValuesChanged]
  );

  const onSectionRefChanged = useCallback(
    (element) => {
      onRefChanged(section.id, element);
    },
    [section.id, onRefChanged]
  );

  const menuActions = useMemo(() => {
    const skipSectionAction: MenuContextAction = {
      type: 'label',
      label: 'Skip Section',
      data: {
        icon: 'step-forward',
        title: 'Skip Section',
        onClick: () => skipSection(sectionIndex),
        disabled: docState !== RUN_STATE.RUNNING || !isUserParticipant || allStepsEnded,
      },
    };
    const repeatSectionAction: MenuContextAction = {
      type: 'label',
      label: 'Repeat Section',
      data: {
        icon: 'redo',
        title: 'Repeat Section',
        onClick: () => repeatSection(sectionIndex),
        disabled: docState !== RUN_STATE.RUNNING || !isUserParticipant || !isRepeatable,
      },
    };
    const buttonShowsCollapse = allStepsAreExpanded && !isSectionCollapsed;
    const expandCollapseAllStepsInSection: MenuContextAction = {
      type: 'label',
      label: `${buttonShowsCollapse ? 'Collapse' : 'Expand'} All Steps in Section`,
      data: {
        icon: buttonShowsCollapse ? 'compress-alt' : 'expand-alt',
        onClick: () => {
          if (isSectionCollapsed) {
            toggleIsSectionCollapsed();
          }
          if (buttonShowsCollapse === allStepsAreExpanded) {
            onExpandCollapseAllSteps();
          }
        },
      },
    };
    if (runUtil.isRunStateActive(docState) && auth.hasPermission(PERM.RUNS_EDIT, projectId)) {
      return [skipSectionAction, repeatSectionAction, expandCollapseAllStepsInSection];
    } else {
      return [expandCollapseAllStepsInSection];
    }
  }, [
    docState,
    isUserParticipant,
    allStepsEnded,
    isRepeatable,
    allStepsAreExpanded,
    isSectionCollapsed,
    auth,
    projectId,
    skipSection,
    sectionIndex,
    repeatSection,
    toggleIsSectionCollapsed,
    onExpandCollapseAllSteps,
  ]);

  /**
   * @returns {Object} with stepId's as keys and step completion/skipped as values.
   * {
   *  [step.id]: true - Step has been completed or skipped.
   *  [step.id]: false - Step has not been completed or skipped.
   * }
   */
  const stepCompletionMap = useMemo(() => {
    const completionMap = {};

    section.steps.forEach((step) => {
      completionMap[step.id] = isStepEnded(step);
    });

    return completionMap;
  }, [section.steps]);

  const sectionBannerClass = useMemo(() => {
    if (runUtil.getSectionEndedPercent(section) === 100) {
      if (runUtil.getSectionCompletedPercent(section) === 100) {
        return 'bg-app-green-200';
      } else if (runUtil.getSectionSkippedPercent(section) === 100) {
        return 'bg-app-gray-400';
      } else if (runUtil.getSectionFailedPercent(section) === 100) {
        return 'bg-red-200';
      } else {
        return '';
      }
    }
    return '';
  }, [section]);

  /**
   * Checks whether the previous step (discounting repeats) has a completion status (completed, failed, or skipped).
   * This is only needed for runs.
   */
  const isPreviousStepComplete = useCallback(
    (stepIndex) => {
      // If procedures is not a run, or it is the first step in the section, there is no previous step requirement.
      if (!runStatus || stepIndex === 0) {
        return true;
      }

      const step = section.steps[stepIndex];
      let originalStepIndex = stepIndex;

      // If its a repeated step, work backwards until the original step.
      if (step.repeat_of) {
        while (section.steps[originalStepIndex].repeat_of) {
          originalStepIndex--;

          /**
           * If the original step is the first in the section,
           * return true, because previous step is only valid in the current section.
           *
           * TODO (Deep): Future improvement of previous step by checking the last step of the
           * previous section.
           */
          if (originalStepIndex === 0) {
            return true;
          }
        }
      }

      const stepsWithoutRepeats = runUtil.getCollectionWithoutRepeats(section.steps);

      // Gets the index of the original step in the array without any repeats.
      const originalStep = section.steps[originalStepIndex];
      const originalIndex = stepsWithoutRepeats.findIndex((s) => s.id === originalStep.id);

      // Get the previous step in the array without any repeats.
      const previousStep = runUtil.getCollectionWithoutRepeats(section.steps)[originalIndex - 1];

      return stepCompletionMap[previousStep.id];
    },
    [runStatus, section.steps, stepCompletionMap]
  );

  const hasPreviousStep = useCallback(
    (stepId) => {
      return !(sectionIndex === 0 && sectionStepIndexMap[stepId] === 0);
    },
    [sectionStepIndexMap, sectionIndex]
  );

  const isSectionSnippet = useMemo(() => snippetUtil.isSnippet(section), [section]);

  const sectionNameDiffChangeState = useMemo(
    () => (sharedDiffUtil.isChanged(section, 'name') ? ARRAY_CHANGE_SYMBOLS.MODIFIED : ARRAY_CHANGE_SYMBOLS.UNCHANGED),
    [section]
  );

  const showSectionHeaderReviewCommentsProp = useCallback(
    (sectionHeader) => {
      return showReviewComments && sectionHeader.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED;
    },
    [showReviewComments]
  );

  const showStepReviewCommentsProp = useCallback(
    (step) => {
      return showReviewComments && step.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED;
    },
    [showReviewComments]
  );

  const isLatestSection = useMemo(() => isRepeatable, [isRepeatable]);

  if (isRun && !isSectionVisible(section)) {
    return null;
  }

  if (isSectionFiltered && !isSectionFiltered(section.id)) {
    return null;
  }

  return (
    <DiffContainerTableAdapter
      label="Section"
      diffChangeState={section.diff_change_state}
      showModified={Boolean(isSectionCollapsed)}
      onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged(section.id, element)}
    >
      <tbody>
        <tr>
          <td colSpan={3} className="break-words">
            {/* TODO EPS-4221: Remove this diff container when ready to enable full diffs. */}
            <DiffContainer
              label="Section Name"
              diffChangeState={sectionNameDiffChangeState}
              showModified={!isSectionCollapsed}
              onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged(section.id, element)}
            >
              <div
                ref={onSectionRefChanged}
                className={`flex flex-row justify-between items-center my-2 page-break border-b-2 border-gray-300 ${sectionBannerClass}`}
                style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
              >
                <div className="flex flex-column">
                  {!isSingleCardEnabled && (
                    <ExpandCollapseCaret
                      isExpanded={!isSectionCollapsed}
                      onClick={toggleIsSectionCollapsed}
                      ariaLabel="Expand Collapse Section Toggle"
                      isHidden={!onCollapse}
                    />
                  )}
                  {/* Section name */}
                  {/* min-w-0 on flex child fixes long words pushing the size of this element
                  out past the parent container. */}
                  <div
                    className="flex text-xl text-left min-w-0"
                    aria-label={`Section ${sectionKey}${repeatKey ? `, Repeat ${repeatKey}` : ''}`}
                  >
                    <button className="max-w-full text-left cursor-pointer" onClick={toggleIsSectionCollapsed}>
                      <span>Section {sectionKey}: </span>
                      <ProcedureDiffText diffValue={section.name} />
                    </button>
                    {repeatKey && (
                      <>
                        <span className="ml-4 text-base self-center text-gray-600">
                          <FontAwesomeIcon icon="redo" />
                        </span>
                        <span className="ml-1 text-base self-center font-bold text-gray-600 italic whitespace-nowrap">
                          Repeat {repeatKey}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start ml-4">
                  {(runUtil.isRunStateActive(docState) || docState === RUN_STATE.COMPLETED) && (
                    <RunProgressBar runStatus={runStatus} stepCounts={stepCounts} isSection={true} />
                  )}
                  {onCollapse && isNotEditing && <ThreeDotMenu menuActions={menuActions} menuLabel="Section Menu" />}
                </div>
              </div>
            </DiffContainer>
          </td>
        </tr>
      </tbody>
      {/* Section Header */}
      {section.headers && !isSectionCollapsed && (
        <tbody className="mt-3">
          {section.headers.map((sectionHeader) => {
            return (
              <tr
                key={sharedDiffUtil.getDiffValue<string>(sectionHeader, 'id', 'new')}
                aria-label="Section Header"
                role="region"
              >
                <td colSpan={3}>
                  <ReviewSectionHeader
                    key={sharedDiffUtil.getDiffValue<string>(sectionHeader, 'id', 'new')}
                    projectId={projectId}
                    sectionHeader={sectionHeader}
                    isCollapsed={isCollapsedMap[sharedDiffUtil.getDiffValue<string>(sectionHeader, 'id', 'new')]}
                    docState={docState}
                    isRedlineDisabledBecauseOfRepeat={!isLatestSection}
                    isRedlineFeatureEnabled={false} // Redlines are implemented, but disabled for now. See EPS-2438
                    isPreviewMode={isPreviewMode}
                    saveSectionHeaderRedline={saveSectionHeaderRedline}
                    onAcceptPendingRedline={acceptPendingSectionHeaderRedline}
                    onRefChanged={onRefChanged}
                    scrollToBufferRem={scrollToBufferRem}
                    comments={comments}
                    showReviewComments={showSectionHeaderReviewCommentsProp(sectionHeader)}
                    saveReviewComment={saveReviewComment}
                    onResolveReviewComment={onResolveReviewComment}
                    onUnresolveReviewComment={onUnresolveReviewComment}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      )}
      {!isSectionCollapsed &&
        displaySteps &&
        displaySteps.map((step, stepIndex) => {
          if (isStepFiltered && !isStepFiltered(step.id) && !isPendingStep(step)) {
            return null;
          }
          return (
            <Fragment key={`section.${section.id}.step.${step.id}`}>
              {!isPendingStep(step) && (
                <ReviewProcedureStep
                  runId={runId}
                  projectId={projectId}
                  step={step}
                  stepKey={getStepKey(step, stepIndex)}
                  sectionKey={sectionKey}
                  sectionId={section.id}
                  sourceName={sourceName}
                  docState={docState}
                  operation={operation}
                  isRepeatable={isRepeatable && !runUtil.hasARepeat(section.steps, sectionStepIndexMap[step.id])}
                  repeatKey={runUtil.displayRepeatKey(section.steps, sectionStepIndexMap[step.id])}
                  onRepeat={(recorded) => repeatStep(sectionIndex, sectionStepIndexMap[step.id], recorded)}
                  onSkip={(recorded) => skipStep(sectionIndex, sectionStepIndexMap[step.id], recorded)}
                  onComplete={(recorded) => onStepComplete(section.id, step.id, recorded)}
                  onFailStep={(recorded) => failStep(sectionIndex, sectionStepIndexMap[step.id], recorded)}
                  onRefChanged={onRefChanged}
                  onStartLinkedRun={(contentIndex, linkedRun) =>
                    startLinkedRun(sectionIndex, sectionStepIndexMap[step.id], contentIndex, linkedRun)
                  }
                  onRecordValuesChanged={recordValuesChanged}
                  onAddStepBelow={onAddStepBelow}
                  isRedlineFeatureEnabled={isRedlineFeatureEnabled}
                  onSaveRedlineBlock={(contentIndex, block) =>
                    onSaveRedlineBlock(sectionIndex, sectionStepIndexMap[step.id], contentIndex, block)
                  }
                  onSaveRedlineStepField={(stepField) => onSaveRedlineStepField(step.id, stepField)}
                  onSaveRedlineStepComment={(text) => onSaveRedlineStepComment(step.id, text)}
                  onAcceptPendingRedline={(redlineIndex) => onAcceptPendingRedline(step.id, redlineIndex)}
                  saveNewComment={saveNewComment}
                  onResolveReviewComment={onResolveReviewComment}
                  onUnresolveReviewComment={onUnresolveReviewComment}
                  saveReviewComment={saveReviewComment}
                  comments={comments}
                  showReviewComments={showStepReviewCommentsProp(step)}
                  isHidden={isSectionCollapsed}
                  isCollapsed={isCollapsedMap[step.id]}
                  isPreviousStepComplete={isPreviousStepComplete(sectionStepIndexMap[step.id])}
                  onStepCollapse={onCollapse}
                  scrollToBufferRem={scrollToBufferRem}
                  notifyRemainingStepOperators={notifyRemainingStepOperators}
                  notificationListenerConnected={notificationListenerConnected}
                  hasPreviousStep={hasPreviousStep(step.id)}
                  isInSectionSnippet={isSectionSnippet}
                  isPreviewMode={isPreviewMode}
                  onAddIssue={(issue) => onAddStepIssue(issue, step.id)}
                  areRedlineCommentsExpanded={areRedlineCommentsExpanded}
                  expandRedlineComments={expandRedlineComments}
                />
              )}
              {isPendingStep(step) && (
                <SelectionContextProvider>
                  <ProcedureStepEdit
                    step={step}
                    isPending={true}
                    docState={docState}
                    precedingStepId={step.precedingStepId}
                    onCancel={() => removePendingStep(step.precedingStepId)}
                    onSave={(values) => addStepAfterHandler(section.id, step.precedingStepId, values)}
                    configurePartKitBlock={() => {
                      /* no-op */
                    }}
                    configurePartBuildBlock={() => {
                      /* no-op */
                    }}
                  />
                </SelectionContextProvider>
              )}
            </Fragment>
          );
        })}
    </DiffContainerTableAdapter>
  );
};

export default ReviewProcedureSection;
