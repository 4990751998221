import superlogin from './superlogin';
import { API_URL } from '../config';
import { ORG_SESSION_TIMEOUT_SECONDS } from 'shared/lib/constants/session';
import { OrganizationSettings } from 'shared/lib/types/settings';
import { OrgUsersType } from '../components/Settings/types';

class OrgService {
  orgId: string;

  constructor(orgId: string) {
    this.orgId = orgId;
  }

  async listUsers(): Promise<OrgUsersType> {
    const url = `${API_URL}/organizations/${this.orgId}/users`;
    const users = await superlogin.getHttp().get(url);
    return users.data;
  }

  async updateUsers(
    emails: Array<string>,
    organizationRole: string | null
  ): Promise<void> {
    const url = `${API_URL}/organizations/${this.orgId}/users`;
    const body = {
      ids: emails,
      organization_role_id: organizationRole,
    };

    return superlogin.getHttp().patch(url, body);
  }

  /**
   * Fetches the number of billable users.
   */
  async getBillableUsers(): Promise<number> {
    const url = `${API_URL}/organizations/${this.orgId}/billable-users-count`;
    const response = await superlogin.getHttp().get(url, {});
    return response.data.billable_users;
  }

  /**
   * Fetches the number of non-viewer users.
   */
  async getNonViewerUsers(): Promise<number> {
    const url = `${API_URL}/organizations/${this.orgId}/nonviewer-users-count`;
    const response = await superlogin.getHttp().get(url, {});
    return response.data.nonviewer_users;
  }

  /**
   * Fetches the number of billable users and non-viewer users.
   */
  async getAllUsers(): Promise<[number, number]> {
    const url = `${API_URL}/organizations/${this.orgId}/billable-users-count`;
    const url2 = `${API_URL}/organizations/${this.orgId}/nonviewer-users-count`;
    const [response, response2] = await Promise.all([
      superlogin.getHttp().get(url, {}),
      superlogin.getHttp().get(url2, {}),
    ]);
    return [response.data.billable_users, response2.data.nonviewer_users];
  }

  /**
   * Queries whether generating api keys is enabled for a given org.
   */
  async isApiKeyGenerationEnabled(): Promise<boolean> {
    const url = `${API_URL}/organizations/${this.orgId}/api-key-generation-enabled`;
    const response = await superlogin.getHttp().get(url, {});
    return response.data.enabled;
  }

  /**
   * Queries whether inviting and removing users is enabled for a given org.
   */
  async isInviteRemoveUsersDisabled(): Promise<boolean> {
    const url = `${API_URL}/organizations/${this.orgId}/invite-remove-users-disabled`;
    const response = await superlogin.getHttp().get(url, {});
    return response.data.disabled;
  }

  /**
   * Indicates whether the Billing section should be rendered
   */
  async isBillingPortalEnabled(): Promise<boolean> {
    const url = `${API_URL}/organizations/${this.orgId}/billing-portal-enabled`;
    const response = await superlogin
      .getHttp()
      .get<{ enabled: boolean }>(url, {});
    return response.data.enabled;
  }

  /**
   * Generates the Billing Portal URL for user's org.
   */
  async generateBillingPortalUrl({
    redirectUrl,
  }: {
    redirectUrl: string;
  }): Promise<string> {
    const params = new URLSearchParams();
    params.append('redirectUrl', redirectUrl);
    const url = `${API_URL}/organizations/${
      this.orgId
    }/billing-portal-url?${params.toString()}`;
    const response = await superlogin
      .getHttp()
      .get<{ billing_portal_url: string }>(url, {});
    return response.data.billing_portal_url;
  }

  /**
   * Queries for locked org users.
   */
  async getLockedUsers(): Promise<Array<string>> {
    const url = `${API_URL}/organizations/${this.orgId}/locked-users`;
    const response = await superlogin.getHttp().get(url, {});
    return response.data.users;
  }

  /**
   * Unlocks user.
   */
  async unlockUser(email: string): Promise<void> {
    const url = `${API_URL}/organizations/${this.orgId}/unlock-user`;
    await superlogin.getHttp().post(url, { email });
  }

  /**
   * Gets org settings filtered by settings keys
   */
  async getSessionExpiration(): Promise<number> {
    const url = `${API_URL}/organizations/${this.orgId}/settings`;
    const params = { keys: [ORG_SESSION_TIMEOUT_SECONDS] };
    const settings = await superlogin.getHttp().get(url, { params });

    return settings.data.settings[ORG_SESSION_TIMEOUT_SECONDS];
  }

  /**
   * Sets org settings. Not required to pass in all settings, just the ones you want to modify
   */
  async setSessionExpiration(
    expiration_seconds: number
  ): Promise<OrganizationSettings> {
    const url = `${API_URL}/organizations/${this.orgId}/settings`;
    return superlogin
      .getHttp()
      .patch(url, { [ORG_SESSION_TIMEOUT_SECONDS]: expiration_seconds });
  }
}

export default OrgService;
