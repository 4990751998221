import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Redirect, Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { getSSOProvider, loginWithSSO, agreeToDisclaimer } from '../api/auth';
import { API_URL } from '../config';
import useAuth from '../hooks/useAuth';
import useLocationParams from '../hooks/useLocationParams';
import { LocationState } from './types';
import DisclaimerModal from '../components/DisclaimerModal';

const EmailSchema = Yup.object().shape({ email: Yup.string().email().required('Required') });
const DEFAULT_LOGIN_ERROR_STR = 'There was an error signing in. Please try again.';

const LoginSSO = () => {
  const location = useLocation<LocationState>();
  const { searchParams } = useLocationParams(location);
  const { action } = useParams<{ action: string }>();
  const history = useHistory();
  const { flash } = location.state || { flash: '' };
  const [redirectTrigger, setRedirectTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [login, setLogin] = useState({
    // Use error passed in through routing state, if present.
    error: location.state && location.state.error,
    isAuthenticating: false,
  });
  const { onAuthenticationSuccess, logout } = useAuth();
  const isMounted = useRef(true);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const [disclaimerText, setDisclamerText] = useState('');

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const token = useMemo(() => searchParams.get('token'), [searchParams]);
  const redirect = useMemo(() => searchParams.get('redirect') || '', [searchParams]);

  // On error, send to the beginning of SSO flow with error message.
  const onAuthenticationFailure = useCallback(
    (error) => {
      history.replace({ pathname: '/sso/provider' });
      setLogin({
        error: error.message,
        isAuthenticating: false,
      });
    },
    [history]
  );

  const onSSOLogin = useCallback(
    async (token) => {
      // Protect from calling multiple times.
      if (login.isAuthenticating || login.error) {
        return;
      }
      setLogin({
        error: undefined,
        isAuthenticating: true,
      });
      try {
        const session = await loginWithSSO(token);
        if (session.disclaimer_text) {
          setDisclamerText(session.disclaimer_text);
          setShowDisclaimerModal(true);
        } else {
          await onAuthenticationSuccess(session);
          setRedirectTrigger(true);
        }
      } catch {
        onAuthenticationFailure(new Error(DEFAULT_LOGIN_ERROR_STR));
      }
    },
    [login, onAuthenticationSuccess, onAuthenticationFailure]
  );

  const onAgree = useCallback(async () => {
    try {
      setShowDisclaimerModal(false);
      const session = await agreeToDisclaimer();
      await onAuthenticationSuccess(session);
      setRedirectTrigger(true);
    } catch (error) {
      onAuthenticationFailure(error);
    }
  }, [onAuthenticationSuccess, onAuthenticationFailure]);

  const onDecline = useCallback(() => {
    setLogin({
      error: undefined,
      isAuthenticating: false,
    });
    setShowDisclaimerModal(false);
    logout();
  }, [logout]);

  // Query for SSO identity provider and begin authentication flow.
  const onSubmitEmail = useCallback(
    (values, { setSubmitting }) => {
      setLogin(() => ({
        error: undefined,
        isAuthenticating: false,
      }));

      return getSSOProvider(values.email)
        .then((results) => {
          if (!isMounted.current) {
            return;
          }
          if (!results.provider) {
            onAuthenticationFailure(new Error('No identity provider found.'));
            setSubmitting(false);
            return;
          }
          /**
           * Start SSO authentication flow for the given identity provider, and
           * passing in an optional redirect pathname. The backend will hand
           * `redirect` back on auth success, so we send the user back the original
           * task. Eg, accepting a team invite.
           */
          const url = `${API_URL}/sso/initiate-idp-login?provider=${results.provider}&redirect=${redirect}`;
          window.location.assign(url);
        })
        .catch((error) => {
          if (!isMounted.current) {
            return;
          }
          onAuthenticationFailure(error);
          setSubmitting(false);
        });
    },
    [onAuthenticationFailure, redirect]
  );

  // Finish authentication flow by requesting a superlogin session.
  useEffect(() => {
    if (action === 'login') {
      onSSOLogin(token);
    }
    setIsLoading(false);
  }, [action, login, onSSOLogin, token]);

  // Redirect to home page or original app path.
  if (redirectTrigger === true) {
    return <Redirect to={redirect || '/'} />;
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="px-12 py-4">
      {showDisclaimerModal && <DisclaimerModal disclaimer={disclaimerText} onAgree={onAgree} onDecline={onDecline} />}
      <div className="items-start max-w-md mx-auto">
        {login.isAuthenticating && <div className="my-4 py-2 px-3 text-center">Authenticating...</div>}
        {!login.isAuthenticating && (
          <>
            {flash && (
              <div className="my-4 py-2 px-3 bg-green-100 border rounded border-black border-opacity-20 text-green-700">
                {flash}
              </div>
            )}
            <h1 className="mb-4">Log in via Single Sign-On (SSO)</h1>
            <Formik initialValues={{ email: '' }} validationSchema={EmailSchema} onSubmit={onSubmitEmail}>
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <div className="flex flex-col">
                    <label htmlFor="email">Enter your email address</label>
                    <Field
                      id="email"
                      name="email"
                      placeholder="Email address"
                      type="email"
                      disabled={isSubmitting}
                      className="w-full border-1 border-gray-400 rounded disabled:bg-opacity-20"
                    />
                    {errors.email && touched.email && <div className="text-red-700">{errors.email}</div>}

                    {login.error && <p className="self-center mt-2 text-red-700">{login.error}</p>}
                    <button
                      className="btn self-center mt-2 disabled:bg-opacity-80"
                      type="submit"
                      disabled={isSubmitting ? true : undefined}
                    >
                      Continue
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="flex flex-col">
              <Link
                to={(location) => ({
                  // Preserve search url params when navigating through login flows.
                  ...location,
                  pathname: '/login',
                })}
                className="link self-center mt-2"
              >
                Back to Login
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginSSO;
