import { isEmpty } from 'lodash';
import { ChangeEvent } from 'react';
import { PartAutoNumbering } from 'shared/lib/types/couch/settings';
import { SequenceId } from 'shared/lib/types/postgres/information_schema';
import { StringSelectOption as SelectOption } from '../../../lib/formik';
import AutoNumberingAddClear from './AutoNumberingAddClear';
import AutoNumberingDelimiter from './AutoNumberingDelimiter';
import AutoNumberingPrefix from './AutoNumberingPrefix';
import NumberInput from './NumberInput';

export type FullNumberInputProps = {
  type: SequenceId['type'];
  id?: string; // supports htmlFor on the NumberInput
  autoNumbering?: PartAutoNumbering;
  showAutoNumberingInputs?: boolean; // this is for part numbers until we support editing part numbers
  prefixName?: string;
  prefix?: string;
  onPrefixChange?: (option: SelectOption | null) => void;
  value?: string;
  onInputBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  entityId?: string;
  onAdd?: (entityId?: string) => void;
  onClear?: (entityId?: string) => void;
  disabled?: boolean;
  useFormik?: boolean;
  testId?: string;
};

const FullNumberInput = ({
  id,
  showAutoNumberingInputs = true,
  autoNumbering,
  prefixName = 'prefix',
  prefix,
  onPrefixChange,
  value,
  onInputBlur,
  onInputChange,
  entityId,
  type,
  onAdd,
  onClear,
  testId,
  disabled,
  useFormik = true,
}: FullNumberInputProps) => {
  const placeholder = type === 'part_numbers' ? 'Part Number' : type === 'serials' ? 'Serial #' : 'Lot #';

  return (
    <div
      className={`flex flex-row h-[42px] border border-gray-300 rounded ${disabled && 'bg-gray-100'} relative text-sm`}
    >
      {autoNumbering?.enabled && autoNumbering.prefixes.length > 0 && showAutoNumberingInputs && (
        <AutoNumberingPrefix
          name={prefixName}
          prefixes={autoNumbering?.prefixes}
          value={prefix}
          onChange={onPrefixChange}
          useFormik={useFormik}
          isDisabled={disabled}
        />
      )}
      {autoNumbering?.enabled && autoNumbering?.delimiter && showAutoNumberingInputs && (
        <AutoNumberingDelimiter delimiter={autoNumbering?.delimiter} isVisible={Boolean(prefix)} />
      )}
      <NumberInput
        id={id}
        prefix={prefix}
        value={value}
        hasDelimiter={Boolean(autoNumbering?.delimiter)}
        placeholder={placeholder}
        onBlur={onInputBlur}
        onChange={onInputChange}
        isDisabled={disabled || !isEmpty(prefix)}
        testId={testId}
      />
      {autoNumbering?.enabled && !disabled && onAdd && onClear && showAutoNumberingInputs && (
        <AutoNumberingAddClear entityId={entityId} type={type} currValue={value} onAdd={onAdd} onClear={onClear} />
      )}
    </div>
  );
};

export default FullNumberInput;
