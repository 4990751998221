import { useMemo } from 'react';
import { ConditionalDiffElement, Rule, RangeDiffElement } from 'shared/lib/types/views/procedures';
import { STEP_STATE } from 'shared/lib/runUtil';
import { CONDITIONAL_TYPE, CONDITIONAL_STATE, CONDITIONAL_TERNARY_STATE } from 'shared/lib/stepConditionals';
import sharedDiffUtil from 'shared/lib/diffUtil';
import { useRunContext } from '../../contexts/RunContext';
import procedureUtil from '../../lib/procedureUtil';
import { getNewRange } from '../../hooks/useDisplayRule';

interface ConditionalStateTagProps {
  conditional: ConditionalDiffElement;
  size: string;
  conditionalStepId: string;
}

const ConditionalStateTag = ({ conditional, size, conditionalStepId }: ConditionalStateTagProps) => {
  const { run } = useRunContext();

  const conditionalSourceRange = useMemo(() => {
    if (conditional.source_type !== CONDITIONAL_TYPE.CONTENT_TERNARY) {
      return null;
    }

    const contentId = conditional.content_id;
    if (!contentId) {
      return null;
    }

    const conditionalStep = procedureUtil.getStepById(run, conditionalStepId);
    if (!conditionalStep) {
      return null;
    }

    const conditionalSourceBlock = conditionalStep.content.find((block) => block.id === contentId);
    if (!conditionalSourceBlock) {
      return null;
    }

    if ('range' in conditionalSourceBlock) {
      return getNewRange(conditionalSourceBlock.range as RangeDiffElement);
    }
    if ('rule' in conditionalSourceBlock) {
      const rule = conditionalSourceBlock.rule as Rule;
      if ('range' in rule) {
        return getNewRange(rule.range as RangeDiffElement);
      }
    }
    return null;
  }, [conditional.content_id, conditional.source_type, conditionalStepId, run]);

  const { min, max, include_min: includeMin, include_max: includeMax } = conditionalSourceRange || {};

  const chipClasses = useMemo(() => {
    let classes = 'font-medium rounded-full text-xs whitespace-nowrap';
    if (size === 'sm') {
      classes += ' px-1.5 py-0.5';
    } else {
      classes += ' px-2 py-1';
    }
    return classes;
  }, [size]);

  const chipColor = useMemo(() => {
    if (
      conditional.source_type === CONDITIONAL_TYPE.STEP ||
      conditional.source_type === CONDITIONAL_TYPE.CONTENT_BINARY ||
      conditional.source_type === CONDITIONAL_TYPE.CONTENT_TERNARY
    ) {
      if (
        conditional.state === STEP_STATE.COMPLETED ||
        conditional.state === CONDITIONAL_STATE.PASS ||
        conditional.state === CONDITIONAL_TERNARY_STATE.PASS
      ) {
        return 'bg-app-green-200';
      } else if (
        conditional.state === STEP_STATE.FAILED ||
        conditional.state === CONDITIONAL_STATE.FAIL ||
        conditional.state === CONDITIONAL_TERNARY_STATE.FAIL_HIGH ||
        conditional.state === CONDITIONAL_TERNARY_STATE.FAIL_LOW ||
        conditional.state === CONDITIONAL_TERNARY_STATE.FAIL_NO_DATA
      ) {
        return 'bg-red-200';
      }
    }
    return 'bg-blue-200';
  }, [conditional]);

  const stateText = useMemo(() => {
    const conditionalState = sharedDiffUtil.getDiffValue(conditional, 'state', 'new');
    if (sharedDiffUtil.getDiffValue(conditional, 'source_type', 'new') === CONDITIONAL_TYPE.STEP) {
      if (conditionalState === STEP_STATE.COMPLETED) {
        return 'Completed';
      } else if (conditionalState === STEP_STATE.FAILED) {
        return 'Failed';
      }
    }
    if (sharedDiffUtil.getDiffValue(conditional, 'source_type', 'new') === CONDITIONAL_TYPE.CONTENT_BINARY) {
      if (conditionalState === CONDITIONAL_STATE.PASS) {
        return 'Pass';
      } else if (conditionalState === CONDITIONAL_STATE.FAIL) {
        return 'Fail';
      }
    }
    if (sharedDiffUtil.getDiffValue(conditional, 'source_type', 'new') === CONDITIONAL_TYPE.CONTENT_TERNARY) {
      if (conditionalState === CONDITIONAL_TERNARY_STATE.PASS) {
        return 'Pass';
      } else if (conditionalState === CONDITIONAL_TERNARY_STATE.FAIL_HIGH) {
        return max ? (includeMax ? `Fail (input > ${max})` : `Fail (input ≥ ${max})`) : 'Fail (too high)';
      } else if (conditionalState === CONDITIONAL_TERNARY_STATE.FAIL_LOW) {
        return min ? (includeMin ? `Fail (input < ${min})` : `Fail (input ≤ ${min})`) : 'Fail (too low)';
      } else if (conditionalState === CONDITIONAL_TERNARY_STATE.FAIL_NO_DATA) {
        return 'Fail (no value entered)';
      }
    }
    return conditionalState;
  }, [conditional, includeMax, includeMin, max, min]);

  return <div className={`${chipColor} ${chipClasses}`}>{stateText}</div>;
};

export default ConditionalStateTag;
