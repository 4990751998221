import { useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { CouchLikeOperation } from 'shared/lib/types/operations';
import { Participant, Release, Run, RunState, RunStatus } from 'shared/lib/types/views/procedures';
import Button, { BUTTON_TYPES } from '../Button';
import { useHistory } from 'react-router-dom';
import RunStatusLabel from '../RunStatusLabel';
import runUtil, { PARTICIPANT_TYPE } from '../../lib/runUtil';
import AvatarStack from '../AvatarStack';
import DateTimeDisplay from '../DateTimeDisplay';
import RunProgressBar, { StepCounts } from '../RunProgressBar';
import { RunStatus as BadgeRunStatus } from '../RunStatusBadge';
import { procedureViewPath, runViewPath, eventPath } from '../../lib/pathUtil';
import { Event } from 'shared/schedule/types/event';
import { DateTime, Duration, Interval } from 'luxon';
import { ProcedureState } from 'shared/lib/types/couch/procedures';
import Grid from '../../elements/Grid';
import { getState } from 'shared/lib/procedureUtil';
import { newRunDoc } from 'shared/lib/runUtil';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { useUserInfo } from '../../contexts/UserContext';
import externalDataUtil from '../../lib/externalDataUtil';
import { INVALID_ITEMS_MESSAGE } from '../ButtonsProcedure';
import usePendingChangesPrompt from '../../hooks/usePendingChangesPrompt';
import _ from 'lodash';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import RunLabel from '../RunLabel';

type OperationEventRow = {
  event_id: string;
  event_name: string;
  event_status: string;
  event_start?: DateTime;
  event_duration?: Duration;
  procedure_id?: string;
  procedure_code?: string;
  procedure_name?: string;
  procedure_status?: ProcedureState;
  run_id?: string;
  run_number?: number;
  run_status?: RunStatus | RunState;
  run_badge_status?: BadgeRunStatus;
  run_start?: DateTime;
  run_duration?: Duration;
  participants?: Array<string>;
  stepCounts?: StepCounts;
};

const nonViewerParticipants = (participants: Array<Participant>) => {
  const nonViewers = participants.filter((p) => {
    return p.type === PARTICIPANT_TYPE.PARTICIPATING;
  });
  return nonViewers.map((p) => p.user_id);
};

interface OperationProceduresListProps {
  operation: CouchLikeOperation;
  procedures: Array<Release>;
  setProcedures: Dispatch<SetStateAction<Array<Release>>>;
  runs: Array<Run>;
  events: Array<Event>;
  loadEvents: () => void;
  usedVerticalSpace: () => number;
}

interface ProcedureMap {
  [procedureId: string]: {
    name: string;
    code: string;
    status: ProcedureState;
  };
}

interface RunMap {
  [runId: string]: {
    code: string;
    name: string;
    run_number?: number;
    participants: Array<string>;
    stepCounts: StepCounts;
    status?: RunState | RunStatus;
    badge_status: BadgeRunStatus;
    startTime: string;
    duration: Duration;
  };
}

// https://stackoverflow.com/a/70351417
const normalize = (duration: Duration) => {
  const durationObject = duration.shiftTo('years', 'months', 'days', 'hours', 'minutes', 'seconds').toObject();

  // filter out units with 0 values.
  const resultObject = Object.keys(durationObject).reduce((result, key) => {
    const value = durationObject[key];
    return value ? { ...result, [key]: value } : result;
  }, {});

  return Duration.fromObject(resultObject);
};

const formatDuration = (duration: Duration) => {
  return normalize(duration).toHuman({ unitDisplay: 'short' });
};

const compareRows = (r1: OperationEventRow, r2: OperationEventRow): number => {
  if (!r1.event_start && !r2.event_start) {
    return r1.event_name.localeCompare(r2.event_name);
  }
  // sort events without a start before events with a start
  if (!r1.event_start && r2.event_start) {
    return -1;
  }
  if (r1.event_start && !r2.event_start) {
    return 1;
  }
  if (r1.event_start && r2.event_start) {
    return r1.event_start < r2.event_start ? -1 : r1.event_start > r2.event_start ? 1 : 0;
  }
  // this can never happen but the compiler doesn't recognize this
  return 0;
};

const ACTUAL_TIME_COLOR_CLS = 'text-blue-400';

const DualDateHeader = (label: string) => () => {
  return (
    <div className="flex flex-col h-10 leading-none space-y-0.5 justify-center">
      <span>{label}</span>
      <div className="text-xs">
        ( Scheduled / <span className={`${ACTUAL_TIME_COLOR_CLS}`}>Actual</span> )
      </div>
    </div>
  );
};

const OperationEventList = ({
  operation,
  procedures,
  setProcedures,
  runs,
  events,
  loadEvents,
  usedVerticalSpace,
}: OperationProceduresListProps) => {
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();
  const history = useHistory();
  const { mixpanel } = useMixpanel();
  const { userInfo } = useUserInfo();
  const userId = userInfo.session.user_id;
  const confirmPendingChanges = usePendingChangesPrompt();

  const mixpanelTrack = useCallback(
    (trackingKey: string, properties?: object | undefined) => {
      if (mixpanel) {
        mixpanel.track(trackingKey, properties);
      }
    },
    [mixpanel]
  );

  // Creates a run doc and updates with dynamic data if online.
  const createRun = useCallback(
    async (procedure: Release) => {
      const run = newRunDoc({ procedure, userId });
      try {
        const externalItems = await services.externalData.getAllExternalItems(run);
        return externalDataUtil.updateProcedureWithItems(run, externalItems);
      } catch (err) {
        // Ignore any errors and fall back to using procedure without dynamic data.
        return run;
      } finally {
        await loadEvents();
      }
    },
    [services.externalData, userId, loadEvents]
  );

  const startProcedure = useCallback(
    async (procedureId: string) => {
      const procedure = procedures.find((p) => p._id === procedureId);
      if (!procedure || !operation || !(await confirmPendingChanges(procedure))) {
        return;
      }

      const run = await createRun(procedure);
      const hasInvalidExternalItems = externalDataUtil.hasInvalidExternalItems(run);
      if (hasInvalidExternalItems && !window.confirm(INVALID_ITEMS_MESSAGE)) {
        return;
      }

      try {
        run.operation = _.pick(operation, 'name', 'key');
        setProcedures(procedures.concat(run));
        const results = await services.runs.startRun(run);
        mixpanelTrack('Run Procedure', { Source: 'Operation Detail' });
        return results;
      } catch (err) {
        setProcedures(procedures);
      } finally {
        await loadEvents();
      }
    },
    [procedures, confirmPendingChanges, createRun, mixpanelTrack, operation, services.runs, setProcedures, loadEvents]
  );

  const procedureMap: ProcedureMap = useMemo(
    () =>
      Object.fromEntries(
        procedures.map((proc) => [
          proc._id,
          {
            name: proc.name,
            code: proc.code,
            status: getState(proc),
          },
        ])
      ),
    [procedures]
  );

  const runMap: RunMap = useMemo(
    () =>
      Object.fromEntries(
        runs.map((run) => [
          run._id,
          {
            code: run.code,
            name: run.name,
            run_number: run.run_number,
            participants: run.participants ? nonViewerParticipants(run.participants) : [],
            stepCounts: runUtil.getRunStepCounts(run).runCounts as StepCounts,
            status: runUtil.getStatus(run.state, run.status),
            badge_status: runUtil.getRunStatus(run),
            startTime: run.starttime,
            duration: Interval.fromDateTimes(
              DateTime.fromISO(run.starttime),
              DateTime.fromISO(run.completedAt)
            ).toDuration(),
          },
        ])
      ),
    [runs]
  );

  const rows: Array<OperationEventRow> = useMemo(
    () =>
      events
        .map((event) => ({
          event_name: event.name,
          event_id: event.id,
          event_status: event.status || 'planning',
          event_start: event.start,
          event_duration: event.duration,
          ...(event.procedure_id
            ? {
                procedure_id: event.procedure_id,
                procedure_name: procedureMap[event.procedure_id]?.name,
                procedure_code: procedureMap[event.procedure_id]?.code,
                procedure_status: procedureMap[event.procedure_id]?.status,
              }
            : {}),
          ...(event.run_id
            ? {
                run_id: event.run_id,
                run_number: runMap[event.run_id]?.run_number,
                participants: runMap[event.run_id]?.participants,
                stepCounts: runMap[event.run_id]?.stepCounts,
                run_status: runMap[event.run_id]?.status,
                run_badge_status: runMap[event.run_id]?.badge_status,
                run_start: runMap[event.run_id]?.startTime,
                run_duration: runMap[event.run_id]?.duration.isValid ? runMap[event.run_id]?.duration : undefined,
                // Overwrite should match, just for tests
                procedure_name: runMap[event.run_id]?.name,
                procedure_code: runMap[event.run_id]?.code,
              }
            : {}),
        }))
        .sort(compareRows),
    [events, procedureMap, runMap]
  );

  const columns = useMemo(
    () => [
      {
        key: 'event_name',
        name: 'Event',
        renderCell: ({ row }: { row: OperationEventRow }) => {
          return (
            <span
              onClick={() => history.push(eventPath(currentTeamId, row.event_id), { from: 'operation' })}
              className="font-medium text-blue-600 tracking-wider hover:underline cursor-pointer"
            >
              {row.event_name}
            </span>
          );
        },
      },
      {
        key: 'procedure',
        name: 'Procedure',
        renderCell: ({ row }: { row: OperationEventRow }) => {
          const linkPath = row.run_id
            ? {
                pathname: runViewPath(currentTeamId, row.run_id),
                state: { operation: operation.key },
              }
            : row.procedure_id
            ? procedureViewPath(currentTeamId, row.procedure_id)
            : null;
          return linkPath ? (
            <div className="leading-4">
              {row.procedure_code && (
                <RunLabel
                  code={row.procedure_code}
                  name={row.procedure_name}
                  runNumber={row.run_number}
                  link={linkPath}
                />
              )}
            </div>
          ) : null;
        },
      },
      {
        key: 'status',
        name: 'Status',
        width: '100px',
        renderCell: ({ row }: { row: OperationEventRow }) => {
          if (!row.event_status) {
            return <></>;
          }
          return <RunStatusLabel statusText={row.event_status as unknown as RunStatus} />;
        },
      },
      {
        key: 'start',
        name: 'Start',
        width: '200px',
        renderHeaderCell: DualDateHeader('Start'),
        renderCell: ({ row }: { row: OperationEventRow }) => {
          if (row.event_start) {
            return (
              <div className="flex flex-col h-full text-xs justify-center space-y-1">
                <div className="h-4">
                  {row.event_start ? (
                    <DateTimeDisplay timestamp={row.event_start} wrap={true} hasTooltip={true} />
                  ) : (
                    '-'
                  )}
                </div>
                <div className={`h-4 ${ACTUAL_TIME_COLOR_CLS}`}>
                  {row.run_start ? <DateTimeDisplay timestamp={row.run_start} wrap={true} hasTooltip={true} /> : '-'}
                </div>
              </div>
            );
          }
          return null;
        },
      },
      {
        key: 'duration',
        name: 'Duration',
        width: '250px',
        renderHeaderCell: DualDateHeader('Duration'),
        renderCell: ({ row }: { row: OperationEventRow }) => {
          return (
            <div className="flex flex-col h-full text-xs justify-center space-y-1">
              <div className="h-4">{row.event_duration ? <span>{formatDuration(row.event_duration)}</span> : '-'}</div>
              <div className={`h-4 ${ACTUAL_TIME_COLOR_CLS}`}>
                {row.run_duration ? <span>{formatDuration(row.run_duration)}</span> : '-'}
              </div>
            </div>
          );
        },
      },
      ...(operation.state !== 'planning'
        ? [
            {
              key: 'participants',
              name: 'Run Participants',
              renderCell: ({ row }: { row: OperationEventRow }) => {
                return <AvatarStack userIds={row.participants} />;
              },
            },
            {
              key: 'progress',
              name: 'Progress',
              width: '150px',
              renderCell: ({ row }: { row: OperationEventRow }) => {
                if (
                  !row.run_id &&
                  row.procedure_id &&
                  row.procedure_status === 'released' &&
                  operation.state === 'running'
                ) {
                  return (
                    <Button
                      type={BUTTON_TYPES.PRIMARY}
                      onClick={() => startProcedure(row.procedure_id || '')}
                      size="sm"
                      title="Start"
                    >
                      Start
                    </Button>
                  );
                }

                if (!row.run_badge_status || !row.stepCounts) {
                  return null;
                }

                return (
                  <RunProgressBar runStatus={row.run_badge_status} stepCounts={row.stepCounts} isResponsive={true} />
                );
              },
            },
          ]
        : []),
    ],
    [currentTeamId, operation.key, operation.state, startProcedure, history]
  );

  if (!procedures) {
    return null;
  }

  if (!operation) {
    return null;
  }

  return (
    <Grid
      key={columns.length} // Force RDG to re-render columns when op starts
      columns={columns}
      rows={rows}
      usedVerticalSpace={usedVerticalSpace}
    />
  );
};

export default OperationEventList;
