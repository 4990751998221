import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { isEmptyValue } from 'shared/lib/text';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { KitItem } from 'shared/lib/types/views/procedures';
import RunControlledInput from '../../elements/RunControlledInput';
import { inventoryItemPath } from '../../lib/pathUtil';
import useItems from '../hooks/useItems';
import useLocations from '../hooks/useLocations';
import { getItemTopLabel } from '../lib/items';
import { Item } from '../types';
import ComponentPartLabel from './ComponentPartLabel';
import ItemSelect from './ItemSelect';
import { isPartRestricted } from '../lib/parts';
import RestrictedInfo from './RestrictedInfo';

type FieldInputInventoryItemProps = {
  part?: Part;
  /** Authored version of this item */
  item: KitItem;
  /** Authored version + values captured during run for this item */
  recorded?: KitItem;
  isEnabled: boolean;
  teamId: string;
  onRecordValuesChanged: (recorded: KitItem) => void;
  isStepComplete: boolean;
  selectedItemIds: string[];
  canRemove: boolean;
  onRemove: () => void;

  /** Set to recorded values of previous tab, if part of batch steps */
  previousTabRecorded?: KitItem;
};

const FieldInputInventoryItem = ({
  part,
  item,
  recorded,
  isEnabled,
  teamId,
  onRecordValuesChanged,
  isStepComplete,
  selectedItemIds,
  canRemove,
  onRemove,
  previousTabRecorded,
}: FieldInputInventoryItemProps) => {
  const { activeItems, refreshItems, getItem } = useItems();
  const { getLocationLabel } = useLocations();
  const partRestricted = isPartRestricted(part);

  const onChangeItem = useCallback(
    (inventoryItem: Item) => {
      const values = {
        ...item,
        ...recorded,
        item_id: inventoryItem?.id || undefined,
      };
      onRecordValuesChanged?.(values);
    },
    [item, recorded, onRecordValuesChanged]
  );

  const updateAmount = (amount) => {
    const values = {
      ...item,
      ...recorded,
      amount,
    };
    onRecordValuesChanged?.(values);
  };

  const onChangeAmount = (value: string) => {
    if (isEmptyValue(value)) {
      return;
    }
    const valueInt = parseInt(value);
    const amount = Math.max(valueInt, 0);
    updateAmount(amount);
  };

  // Serial items have the additional constraint that quantity can be max 1
  const onChangeAmountSerial = (value: string) => {
    if (isEmptyValue(value)) {
      return;
    }
    const valueInt = parseInt(value);
    const amount = Math.min(Math.max(valueInt, 0), 1); // amount is either 0 or 1
    updateAmount(amount);
  };

  const amount = useMemo(() => {
    let amount = item.amount;
    if (!isEmptyValue(recorded?.amount)) {
      amount = recorded?.amount as number;
    }
    return amount;
  }, [item.amount, recorded?.amount]);

  // refresh inventory in batch mode when switching tabs
  const [itemIdForLastRefresh, setItemIdForLastRefresh] = useState(item.id);
  useEffect(() => {
    if (itemIdForLastRefresh !== item.id) {
      setItemIdForLastRefresh(item.id);
      refreshItems();
    }
  }, [item.id, itemIdForLastRefresh, refreshItems]);

  /** propagate item checked out on previously completed tab to current tab as long as enough available quantity remains */
  useEffect(() => {
    if (item.tracking === 'serial') {
      return;
    }
    const itemIdToPropagate = previousTabRecorded?.item_id;
    if (recorded || !itemIdToPropagate) {
      return;
    }
    const inventoryItem = activeItems?.find((activeItem) => activeItem.id === itemIdToPropagate);
    if (inventoryItem && inventoryItem.amount >= item.amount) {
      onChangeItem(inventoryItem);
    }
  }, [item.tracking, previousTabRecorded, recorded, activeItems, item.amount, onChangeItem]);

  const itemOptions = useMemo(() => {
    if (!isEnabled) {
      return undefined;
    }
    return (activeItems ?? []).filter((itemOption) => {
      if (recorded?.item_id === itemOption.id) {
        return true;
      }

      if (item.revision_id && item.revision_id !== itemOption.part_revision_id) {
        return false;
      }
      if (item.revision && item.revision !== itemOption.part.rev) {
        return false;
      }
      if (item.part_id !== itemOption.part.id) {
        return false;
      }

      const alreadySelectedElsewhere = selectedItemIds.some((selectedId) => selectedId === itemOption.id);
      return !alreadySelectedElsewhere;
    });
  }, [isEnabled, activeItems, recorded?.item_id, item.revision_id, item.revision, item.part_id, selectedItemIds]);

  const StepCompletedPartKit = ({ teamId }: { teamId: string }) => {
    if (recorded && recorded.amount && recorded.item_id) {
      const hydratedItem = getItem(recorded.item_id);
      if (!hydratedItem) {
        return null;
      }
      return (
        <>
          <td>
            <div className="text-sm p-1">{recorded.amount}</div>
          </td>
          <td>
            <div className="flex items-center justify-between">
              <Link to={inventoryItemPath(teamId, hydratedItem.id)}>
                <div className="text-sm p-1 text-blue-600">
                  {getItemTopLabel(hydratedItem as Item, getLocationLabel)}
                </div>
              </Link>
              <div>
                <FontAwesomeIcon icon="check-square" className=" text-green-500" />
              </div>
            </div>
          </td>
          <td></td>
        </>
      );
    }
    return null;
  };

  return (
    <tr className="h-10 text-sm border-b items-center w-full">
      <td>
        <ComponentPartLabel teamId={teamId} component={item} part={part} />
      </td>
      {!isStepComplete && (
        <>
          <td>
            <div className="p-1">
              {!partRestricted && (
                <RunControlledInput
                  type="number"
                  className="w-full max-w-[14rem] text-sm border-1 border-gray-400 rounded disabled:bg-gray-100"
                  disabled={!isEnabled}
                  recorded={`${amount}`}
                  onRecordValue={part?.tracking === 'serial' ? onChangeAmountSerial : onChangeAmount}
                  data-testid="quantity-input"
                />
              )}
              {partRestricted && (
                <div className="ml-0.5">
                  <RestrictedInfo />
                </div>
              )}
            </div>
          </td>
          <td>
            {!partRestricted && part?.tracking && (
              <ItemSelect
                itemId={recorded?.item_id}
                itemOptions={itemOptions}
                minAmount={amount}
                onChangeItem={onChangeItem}
                isDisabled={!isEnabled}
              />
            )}
            {partRestricted && (
              <div className="ml-0.5">
                <RestrictedInfo />
              </div>
            )}
          </td>
          <td>
            {isEnabled && canRemove && (
              <button type="button" className="secondary text-gray-400 disabled:text-gray-200" onClick={onRemove}>
                <FontAwesomeIcon className="ml-1 group-hover:text-gray-500" icon="times-circle" />
              </button>
            )}
          </td>
        </>
      )}
      {isStepComplete && <StepCompletedPartKit teamId={teamId} />}
    </tr>
  );
};

export default FieldInputInventoryItem;
