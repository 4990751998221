import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { PERM } from '../../lib/auth';
import procedureUtil from '../../lib/procedureUtil';
import runUtil from '../../lib/runUtil';
import { useAuth } from '../../contexts/AuthContext';
import { useSettings } from '../../contexts/SettingsContext';
import useProcedureObserver from '../../hooks/useProcedureObserver';
import useRunObserver from '../../hooks/useRunObserver';
import { generateHiddenClassString } from '../../lib/styles';
import RunProgressBar from '../RunProgressBar';
import { useUserInfo } from '../../contexts/UserContext';
import externalDataUtil from '../../lib/externalDataUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { INVALID_ITEMS_MESSAGE } from '../ButtonsProcedure';
import { ProcedureLinkBlockDiffElement, Run } from 'shared/lib/types/views/procedures';
import SubstepNumber from '../SubstepNumber';
import { procedureSnapshotViewPathWithSourceUrl, runViewPath } from '../../lib/pathUtil';
import usePendingChangesPrompt from '../../hooks/usePendingChangesPrompt';
import DiffContainer from '../Diff/DiffContainer';
import sharedDiffUtil from 'shared/lib/diffUtil';
import configUtil from '../../lib/configUtil';

/**
 * @param onStartLinkedRun - Callback function for starting a linked procedure.
 * @param run - the run id, see {@link https://gitlab.com/epsilon3inc/spock/-/blob/c6f526ec2ac53a616d47af56cf8faa562b23a471/web/src/components/Blocks/BlockProcedureLink.js#L37 usage in BlockProcedureLink.js}
 */
export type ReviewBlockProcedureLinkProps = {
  contentIndex: number;
  docId: string;
  docState: string;
  operation: string;
  link: ProcedureLinkBlockDiffElement;
  onStartLinkedRun: ((contentIndex: number, linkedRun: string) => void) | undefined;
  procedure: string;
  run: string;
  section: string;
  isHidden: boolean;
  isEnabled: boolean;
  blockLabel?: string;
  showLabels: boolean;
  wrapInsideTable: boolean;
  spreadContent: boolean;
  sourceName?: string;
};

/**
 * Component for rendering a linked procedure.
 */
const ReviewBlockProcedureLink = ({
  contentIndex,
  docId,
  docState,
  operation,
  link,
  onStartLinkedRun,
  isHidden,
  isEnabled,
  blockLabel,
  showLabels = true,
  wrapInsideTable = true,
  spreadContent = false,
  sourceName = 'Untitled',
}: ReviewBlockProcedureLinkProps) => {
  const { procedure: linkedProcedure } = useProcedureObserver({
    id: sharedDiffUtil.getDiffValue<string>(link, 'procedure', 'new'),
  });
  const { services, currentTeamId } = useDatabaseServices();
  const { run } = useRunObserver({ id: link.run });
  const { auth } = useAuth();
  const { userInfo } = useUserInfo();
  const userId = userInfo.session.user_id;
  const { getSetting, config } = useSettings();
  const isMounted = useRef(true);
  const history = useHistory();
  const confirmPendingChanges = usePendingChangesPrompt();

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const section = useMemo(() => sharedDiffUtil.getDiffValue<string>(link, 'section', 'new'), [link]);

  const hasSection = useMemo(() => {
    return !(section === undefined || section === null || section === '');
  }, [section]);

  const linkedSectionIndex = useMemo((): number | null => {
    if (!section) {
      return null;
    }
    return procedureUtil.getSectionIndexById(linkedProcedure, section);
  }, [linkedProcedure, section]);

  const containsLinkedSection = useMemo(() => {
    if (!linkedProcedure || !linkedProcedure.sections) {
      return true;
    }

    return linkedSectionIndex !== null;
  }, [linkedProcedure, linkedSectionIndex]);

  // Creates a run doc and updates with dynamic data if online.
  const createLinkedProcedureRun = useCallback(() => {
    let userParticipantType;
    if (config) {
      userParticipantType = configUtil.getUserParticipantType(config);
    }
    let run = runUtil.newLinkedProcedureRunDoc({
      procedure: linkedProcedure,
      linkedSectionIndex,
      runId: docId,
      operation,
      userId,
      userParticipantType,
    });
    return services.externalData
      .getAllExternalItems(run)
      .then((externalItems) => {
        run = externalDataUtil.updateProcedureWithItems(run, externalItems);
        return run;
      })
      .catch(() => {
        // Ignore any errors and fall back to using procedure without dynamic data.
        return run;
      });
  }, [config, linkedProcedure, linkedSectionIndex, docId, operation, userId, services.externalData]);

  /**
   * If linked only to a section, return whether there is an invalid item in the linked section.
   * If linked to the whole procedure, return whether there is an invalid item in the whole procedure.
   */
  const hasInvalidExternalItems = useCallback(
    (linkedRun: Run): boolean => {
      if (linkedSectionIndex !== null) {
        // Only one section is linked.
        const linkedSection = linkedRun.sections[linkedSectionIndex];
        return externalDataUtil.sectionHasInvalidExternalItems(linkedSection);
      }

      // The whole procedure is linked.
      return externalDataUtil.hasInvalidExternalItems(linkedRun);
    },
    [linkedSectionIndex]
  );

  const runProcedureLink = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (typeof onStartLinkedRun !== 'function') {
        return;
      }
      if (!linkedProcedure) {
        return;
      }

      // Warn the user if any part of the procedure has potential changes
      if (!(await confirmPendingChanges(linkedProcedure))) {
        return;
      }

      // Create new run document.
      return createLinkedProcedureRun().then((linkedRun) => {
        if (hasInvalidExternalItems(linkedRun) && !window.confirm(INVALID_ITEMS_MESSAGE)) {
          return;
        }
        // Issue callback with new run document.
        onStartLinkedRun(contentIndex, linkedRun);
        const newRunLink = runViewPath(currentTeamId, linkedRun._id);
        if (e.metaKey || e.ctrlKey) {
          window.open(newRunLink);
        } else {
          history.push(newRunLink);
        }
      });
    },
    [
      onStartLinkedRun,
      linkedProcedure,
      createLinkedProcedureRun,
      hasInvalidExternalItems,
      contentIndex,
      history,
      confirmPendingChanges,
      currentTeamId,
    ]
  );

  const displaySection = useMemo(() => {
    if (linkedSectionIndex !== null) {
      return `[Section ${procedureUtil.displaySectionKey(
        linkedSectionIndex,
        getSetting('display_sections_as', 'letters')
      )}]`;
    }
    return null;
  }, [linkedSectionIndex, getSetting]);

  const displayLinkText = useMemo(() => {
    if (linkedProcedure && linkedProcedure.code && linkedProcedure.name) {
      return `${linkedProcedure.code} - ${linkedProcedure.name}${displaySection ? ` ${displaySection}` : ''}`;
    }
    return null;
  }, [displaySection, linkedProcedure]);

  const showRunAction = useMemo(() => {
    if (!linkedProcedure) {
      return false;
    }
    // Check if the section exists in the procedure
    if (hasSection && !containsLinkedSection) {
      return false;
    }
    return !linkedProcedure.archived && auth.hasPermission(PERM.RUNS_EDIT, linkedProcedure.project_id);
  }, [linkedProcedure, hasSection, containsLinkedSection, auth]);

  const runStatus = useMemo(() => {
    return runUtil.getRunStatus(run);
  }, [run]);

  const runStepCounts = useMemo(() => {
    return runUtil.getRunStepCounts(run);
  }, [run]);

  const pathToProcedure = useMemo(() => {
    const sourceUrl = `${history.location.pathname}${history.location.search ?? ''}`;
    return procedureSnapshotViewPathWithSourceUrl({
      teamId: currentTeamId,
      procedureId: link.procedure as string,
      sectionId: link.section as string,
      sourceUrl,
      sourceName,
    });
  }, [link, currentTeamId, sourceName, history.location]);

  const marginClasses = onStartLinkedRun ? 'mt-3 ml-4' : '';

  // Layout is intended for CSS grid template defined in Run.js and Procedure.js.
  const content = (
    <div className={generateHiddenClassString(`${marginClasses} flex page-break`, isHidden)}>
      {blockLabel && <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />}
      {linkedProcedure && (
        <DiffContainer
          label="Link to procedure"
          diffChangeState={link.diff_change_state}
          isTextSticky={false}
          width="fit"
        >
          <div className="flex flex-row flex-grow justify-between items-center">
            {/* Render procedure link definition. */}
            <div className="flex gap-x-2 self-center pt-0 mr-2">
              {showLabels && <div className="whitespace-nowrap">Linked procedure:</div>}
              {displayLinkText && (
                <Link className="link" to={pathToProcedure}>
                  {displayLinkText}
                </Link>
              )}
            </div>
            {/* Render procedure archived message */}
            {linkedProcedure.archived && (
              <div className="ml-2 pt-2 italic text-sm flex items-end self-center">
                (This procedure has been archived.)
              </div>
            )}
            {!linkedProcedure.archived && hasSection && !containsLinkedSection && (
              <div className="ml-2 pt-2 italic text-sm flex items-end self-center">
                (This section has been removed.)
              </div>
            )}
            {/* Render run procedure link. */}
            {!link.run && runUtil.isRunStateActive(docState) && (
              <>
                {showRunAction && (
                  <div className="self-start pt-0">
                    {onStartLinkedRun !== undefined && (
                      <button className="btn-small" disabled={!isEnabled} onClick={(e) => runProcedureLink(e)}>
                        Run
                      </button>
                    )}
                  </div>
                )}
              </>
            )}
            {/* Render link to generated procedure run. */}
            {link.run && run && (
              <div
                className={generateHiddenClassString(
                  `${spreadContent ? 'self-center' : 'self-start'} pt-0 w-56`,
                  isHidden
                )}
              >
                <RunProgressBar stepCounts={runStepCounts.runCounts} runStatus={runStatus} showStatus={true} />
              </div>
            )}
          </div>
        </DiffContainer>
      )}
    </div>
  );

  return wrapInsideTable ? (
    <tr>
      <td></td>
      <td colSpan={2}>{content}</td>
    </tr>
  ) : (
    content
  );
};

export default ReviewBlockProcedureLink;
