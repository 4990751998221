import React, { useCallback, useMemo, useState, useRef } from 'react';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import Select, { components } from 'react-select';
import { reactSelectStyles } from '../../../lib/styles';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { cloneDeep } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExternalDataValue, FieldInputExternalSearchBlock } from 'shared/lib/types/views/procedures';
import { useRunContext } from '../../../contexts/RunContext';
import DiffContainer from '../../Diff/DiffContainer';
import diffUtil from '../../../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

const SEARCH_RESULTS_NAME = 'search-results';
const BUTTON_COLOR_CLASSES = 'text-white bg-blue-500 border border-blue-500 hover:bg-blue-600 hover:border-blue-600';
const BUTTON_DISABLED_CLASSES = 'disabled:text-gray-400 disabled:bg-gray-200 disabled:border-transparent';

const Details = (props) => {
  return (
    <table className="table-auto border-collapse">
      <thead>
        <tr>
          <th className="text-left" colSpan={2}>
            {props.header}
          </th>
        </tr>
      </thead>
      <tbody>
        {props.details.map((detail) => (
          <tr key={detail.name}>
            <td className="py-1 text-gray-500">{detail.name}:</td>
            <td className="py-1">{detail.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const LabeledClearIndicator = ({ children, ...props }) => {
  const innerProps = {
    ...props.innerProps,
    'aria-label': 'Clear selection',
  };
  return (
    <components.ClearIndicator {...props} innerProps={innerProps}>
      {children}
    </components.ClearIndicator>
  );
};

const itemToOption = (item) => {
  if (!item) {
    return null;
  }
  const label = item.label ? item.label : `${item.id} ${item.name}`;
  return {
    value: item.id,
    label,
    item,
  };
};

/*
 * Component for rendering a field input with an external data search type.
 * Conforms to TypedBlockInterface, see comments in useBlockComponents.js
 */
interface FieldInputExternalSearchReviewProps {
  block: FieldInputExternalSearchBlock;
  recorded?: { value: ExternalDataValue };
  isEnabled?: boolean;
  onRecordValuesChanged?: (recorded: { value: ExternalDataValue }) => void;
  onContentRefChanged?: (id: string, element: HTMLElement) => void;
  scrollMarginTopValueRem?: number;
}

const FieldInputExternalSearchReview = React.memo(
  ({
    block,
    recorded,
    isEnabled,
    onRecordValuesChanged,
    onContentRefChanged,
    scrollMarginTopValueRem,
  }: FieldInputExternalSearchReviewProps) => {
    const {
      services: { externalData },
    } = useDatabaseServices();

    const [selectData, setSelectData] = useState({
      options: [],
      isLoading: false,
      inputValue: '',
    });
    const [searchError, setSearchError] = useState('');
    const [enableResults, setEnableResults] = useState(false);
    const runContext = useRunContext();

    const selectValue = useMemo(() => itemToOption(recorded && recorded.value), [recorded]);

    const buttonText = useMemo(() => {
      return selectData.isLoading ? 'Searching...' : 'Search';
    }, [selectData]);

    const filterDetails = useCallback(
      (details) => {
        if (
          block?.external_search_type?.filter_options &&
          block?.external_search_type?.filter_options?.length > 0 &&
          details
        ) {
          return block.external_search_type.filter_options.map((option) => {
            return {
              name: option,
              value: details.find((detail) => detail.name === option)?.value || 'Record Not Found',
            };
          });
        }
        return details;
      },
      [block]
    );

    const updateExternalItem = useCallback(
      (item) => {
        if (typeof onRecordValuesChanged !== 'function') {
          return;
        }

        const newItem = item?.item ? cloneDeep(item.item) : null;
        if (newItem) {
          newItem.details = filterDetails(newItem.details);
        }

        const recorded = { value: newItem };
        onRecordValuesChanged(recorded);
      },
      [onRecordValuesChanged, filterDetails]
    );

    const onFieldInputRefChanged = useCallback(
      (element) => {
        return typeof onContentRefChanged === 'function' && onContentRefChanged(block.id, element);
      },
      [block.id, onContentRefChanged]
    );

    const searchItemOptions = useCallback(
      (query) => {
        if (block?.external_search_type?.data_type) {
          const runId = runContext.run && runContext.run._id;
          return externalData
            .searchItems(
              block.external_search_type.data_type,
              block.external_search_type.data_type_dictionary_id,
              query,
              runId
            )
            .then((data) => {
              if (data && data.success) {
                const options = data.items.map((item) => itemToOption(item));
                setEnableResults(true);
                return options;
              } else {
                setSearchError(data ? data.message : 'Search Failed');
                setEnableResults(false);
                return [];
              }
            });
        }
        return [];
      },
      [externalData, block, runContext.run]
    );

    const loadOptions = useCallback(
      (inputValue) => {
        setSelectData({
          options: [],
          inputValue,
          isLoading: true,
        });
        searchItemOptions(inputValue).then((options) => {
          setSelectData((data) => {
            if (data.inputValue !== inputValue) {
              return data;
            }
            return {
              options,
              inputValue,
              isLoading: false,
            };
          });
          if (options.length > 0) {
            // auto select first search result
            updateExternalItem(options[0]);
          } else {
            setSearchError('No Results Found');
            setEnableResults(false);
          }
        });
      },
      [searchItemOptions, updateExternalItem]
    );

    const ref = useRef<FormikProps<FormikValues>>(null);

    const onSearchSubmit = useCallback(() => {
      updateExternalItem(null);
      setSearchError('');
      loadOptions(ref?.current?.values?.searchText);
    }, [loadOptions, updateExternalItem]);

    return (
      <DiffContainer
        label="External search field input"
        diffChangeState={
          sharedDiffUtil.isChanged(block?.external_search_type, 'data_type') ||
          block?.external_search_type?.filter_options?.some((filter) => diffUtil.isDiffPair(filter))
            ? ARRAY_CHANGE_SYMBOLS.MODIFIED
            : undefined
        }
        width="fit"
        isTextSticky={false}
      >
        <div
          ref={(element) => onFieldInputRefChanged(element)}
          style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
          className="grow"
        >
          <Formik
            initialValues={{ searchText: '' }}
            onSubmit={() => {
              /* no-op */
            }}
            validateOnChange={false}
            innerRef={ref}
          >
            {(props) => (
              <Form>
                <div className="w-full mt-2 flex flex-wrap flex-col">
                  <div className="flex flex-wrap items-end">
                    {/* Search Textbox */}
                    <div className="flex items-end">
                      <FontAwesomeIcon
                        icon="search"
                        aria-label="Search"
                        className="text-gray-300 lg:text-lg mr-1 mb-2"
                      />
                      <div className="flex">
                        <Field
                          name="searchText"
                          type="text"
                          disabled={!isEnabled}
                          placeholder={
                            block?.external_search_type?.data_type
                              ? `Search ${sharedDiffUtil.getDiffValue(block.external_search_type, 'data_type', 'new')}`
                              : 'Search text'
                          }
                          className="text-sm border-1 border-gray-400 rounded-l-lg"
                        />
                      </div>

                      {/* Search Button */}
                      <button
                        className={`w-24 mt-2 py-1.5 rounded-r-lg ${BUTTON_COLOR_CLASSES} ${BUTTON_DISABLED_CLASSES}`}
                        onClick={onSearchSubmit}
                        disabled={!isEnabled || !props.values.searchText || selectData.isLoading}
                        type="button"
                      >
                        {buttonText}
                      </button>
                    </div>
                    <div className="flex items-center mt-2">
                      <div className="justify-end flex ml-2 mr-1">Results</div>
                      {/* Search results & select */}
                      <div className="w-64 flex">
                        <div className="w-full">
                          <Select
                            key={block?.external_search_type?.data_type || `${SEARCH_RESULTS_NAME}-key`}
                            styles={reactSelectStyles}
                            classNamePrefix="react-select"
                            components={{ ClearIndicator: LabeledClearIndicator }}
                            name={SEARCH_RESULTS_NAME}
                            isLoading={selectData.isLoading}
                            options={selectData.isLoading ? [] : selectData.options}
                            onChange={updateExternalItem}
                            placeholder="Select item"
                            value={selectValue}
                            isDisabled={!isEnabled || selectData.isLoading || !enableResults}
                            isClearable={true}
                            isSearchable={true}
                            defaultOptions
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Search Error */}
                {searchError && <div className="text-red-700 mt-2">{searchError}</div>}

                {/* Selection results table */}
                {recorded?.value?.details && (
                  <div className="flex flex-row mt-2">
                    <div className="border-l border-gray-300 border-1.5 mr-2" />
                    <div className="flex flex-nowrap w-full ml-2">
                      <Details header={selectValue?.label} details={recorded.value.details} />
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </DiffContainer>
    );
  }
);

export default FieldInputExternalSearchReview;
