import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../elements/Tooltip';
import useScroll, { ScrollEntry } from '../hooks/useScroll';
import Button from './Button';
import SingleDigitNotificationBadge from './SingleDigitNotificationBadge';

interface NavigationRedlinesProps {
  redlineFieldList: Array<ScrollEntry>;
  goToRedline: (scrollEntry: ScrollEntry) => void;
  unresolvedActionsCount: number;
  unresolvedDescription?: string;
  totalActionsCount?: number;
}

const NavigationRedlines = ({
  redlineFieldList,
  goToRedline,
  unresolvedActionsCount,
  totalActionsCount,
  unresolvedDescription = 'Unresolved',
}: NavigationRedlinesProps) => {
  const { onGoToPrevious, onGoToNext } = useScroll({
    list: redlineFieldList,
    goToField: goToRedline,
  });

  return (
    <div className="flex flex-row items-center">
      <Button type="tertiary" onClick={onGoToPrevious}>
        <FontAwesomeIcon icon="angle-up" />
      </Button>

      <Tooltip
        content={
          <div className="px-2">
            <span className="font-medium mr-1">Suggested Edits:</span>
            <span>
              {unresolvedActionsCount} {unresolvedDescription}{' '}
              {totalActionsCount !== undefined && `/ ${totalActionsCount} Total`}
            </span>
          </div>
        }
      >
        <div className="relative flex justify-center">
          <div>
            <FontAwesomeIcon aria-label="Suggested Edits" icon="strikethrough" className="text-lg" />
          </div>
          <div
            className="block absolute z-10 -top-1 -right-2"
            aria-label="Number of Actionable Pending Suggested Edits"
          >
            {unresolvedActionsCount > 0 && <SingleDigitNotificationBadge count={unresolvedActionsCount} />}
          </div>
        </div>
      </Tooltip>

      <Button type="tertiary" onClick={onGoToNext}>
        <FontAwesomeIcon icon="angle-down" />
      </Button>
    </div>
  );
};

export default NavigationRedlines;
