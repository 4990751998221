import React, { useRef, useState } from 'react';
import ModalBase from './ModalBase';
import ModalActionBar from './ModalActionBar';
import ModalContent from './ModalContent';
import Button, { BUTTON_TYPES } from './Button';
import SketchCanvas from 'react-signature-canvas';
import TextAreaAutoHeight from './TextAreaAutoHeight';
import { Field } from 'formik';
import procedureUtil from '../lib/procedureUtil';

interface ModalSketchProps {
  label: string;
  onFileInputChange: (file: File) => Promise<void>;
  setIsSketching: React.Dispatch<React.SetStateAction<boolean>>;
  updateInputValue: (e: Event, value: string, formikField: typeof Field) => void;
}

const ModalSketch = ({ label, onFileInputChange, setIsSketching, updateInputValue }: ModalSketchProps) => {
  const [isCanvasEmpty, setIsCanvasEmpty] = useState<boolean>(true);
  const sketchCanvas = useRef<SketchCanvas>(null);

  const canSave = !isCanvasEmpty;

  const onEnd = () => {
    setIsCanvasEmpty(sketchCanvas.current?.isEmpty());
  };

  const onClear = () => {
    if (sketchCanvas.current) {
      sketchCanvas.current.clear();
      setIsCanvasEmpty(true);
    }
  };

  const onCancel = () => {
    setIsSketching(false);
  };

  const onSubmit = async () => {
    if (!canSave) {
      return;
    }
    await sketchCanvas.current?.getTrimmedCanvas().toBlob((blob) => {
      const fileName = `${procedureUtil.generateImageNameId()}.png`;
      const file = new File([blob], fileName, { type: 'image/png' });
      onFileInputChange(file);
      setIsSketching(false);
    });
  };

  return (
    <ModalBase size="md">
      <ModalContent title={`Sketch: ${label}`}>
        <div className="flex flex-col space-y-2">
          <div className="w-full flex justify-center">
            <div className="w-[700px] h-[500px] rounded border border-gray-400">
              <SketchCanvas
                penColor="black"
                canvasProps={{ width: 700, height: 500 }}
                ref={sketchCanvas}
                onEnd={onEnd}
              />
            </div>
          </div>
          <Field name="text">
            {({ field: formikField }) => {
              return (
                <TextAreaAutoHeight
                  {...formikField}
                  aria-label="Enter Text"
                  placeholder="Enter Text"
                  onInput={(e) => updateInputValue(e, e.target.value, formikField)}
                  style={{ minWidth: '12rem' }}
                />
              );
            }}
          </Field>
        </div>
      </ModalContent>
      <ModalActionBar>
        <div className="flex justify-between">
          <div className="flex gap-x-2">
            <Button type={BUTTON_TYPES.TERTIARY} isDisabled={isCanvasEmpty} onClick={onClear}>
              Clear
            </Button>
            <Button type={BUTTON_TYPES.SECONDARY} ariaLabel="Cancel" isDisabled={false} onClick={onCancel}>
              Cancel
            </Button>
            <Button type={BUTTON_TYPES.PRIMARY} ariaLabel="Submit Sketch" isDisabled={!canSave} onClick={onSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </ModalActionBar>
    </ModalBase>
  );
};

export default ModalSketch;
