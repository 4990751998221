import { useNavState } from '../contexts/NavContext';
import ToggleComments from './Comments/ToggleComments';
import React, { useMemo } from 'react';
import { ProcedureState } from 'shared/lib/types/views/procedures';
import procedureUtil from '../lib/procedureUtil';
import ReviewStatusLabel from './ReviewStatusLabel';
import DraftStatusLabel from './DraftStatusLabel';
import ReleaseStatusLabel from './ReleaseStatusLabel';
import ExpandCollapseButtons from './ExpandCollapse/ExpandCollapseButtons';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';
import BackTo from './StickyHeader/Buttons/BackTo';

interface ProcedureStickyHeaderProps {
  code: string;
  name: string;
  hasComments: boolean;
  showComments: boolean;
  setShowComments?: React.Dispatch<React.SetStateAction<boolean>>;
  state?: ProcedureState;
  isApproved: boolean;
  onExpandAll?: () => void;
  onCollapseAll?: () => void;
  source?: {
    title: string;
    onClick: () => void;
  };
}
const ProcedureStickyHeader = ({
  code,
  name,
  hasComments,
  showComments,
  setShowComments,
  state,
  isApproved,
  onExpandAll,
  onCollapseAll,
  source,
}: ProcedureStickyHeaderProps) => {
  const navState = useNavState();

  const title = useMemo(() => procedureUtil.getProcedureTitle(code, name), [code, name]);

  return (
    <div
      data-testid="procedureHeader"
      className={`fixed top-0 ${
        navState.collapsed ? 'left-16' : 'left-64'
      } transition-all right-0 px-3 h-10 flex flex-row justify-between align-middle shadow-md bg-gray-50 z-50 print:hidden`}
    >
      <div className="flex flex-row items-center gap-x-2 w-1/2">
        {source && <BackTo label={source.title} onClick={source.onClick} />}
        <div className="truncate">{title}</div>
        {state === 'draft' && <DraftStatusLabel />}
        {state === 'in_review' && <ReviewStatusLabel isReviewApproved={isApproved} />}
        {state === 'released' && <ReleaseStatusLabel />}
      </div>
      <div className="flex flex-row gap-x-2">
        {onExpandAll && onCollapseAll && (
          <div className="h-full bg-gray-50 items-center flex flex-row justify-end">
            <ExpandCollapseButtons
              buttonClasses={stickyHeaderUtil.getButtonClassNames(false, false)}
              onExpand={onExpandAll}
              onCollapse={onCollapseAll}
              isDisabled={false}
            />
          </div>
        )}
        {hasComments && setShowComments && (
          <div className="flex items-center">
            <ToggleComments showComments={showComments} setShowComments={setShowComments} />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ProcedureStickyHeader);
