import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import { getIcon } from '../lib/usage';

const UsageTypeLabel = ({ usageType }: { usageType: string }) => {
  return (
    <div key={usageType}>
      <FontAwesomeIcon className="mr-1 text-blue-400" icon={getIcon(usageType)} />
      {capitalize(usageType)}
    </div>
  );
};

export default UsageTypeLabel;
