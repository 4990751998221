import { Popover, Typography } from '@mui/material';
import BlockProcedureLink from '../../components/Blocks/BlockProcedureLink';
import { Event } from 'shared/schedule/types/event';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { formatDuration, getEventDuration } from '../lib/event';
import useEventNav from '../hooks/useEventNav';
import RunStatusLabel from '../../components/RunStatusLabel';

interface EventPopupProps {
  showPopOver: boolean;
  event: Event;
  closePopup: () => void;
  position: { top: number; left: number };
  horizontalAnchor?: number | 'center' | 'left' | 'right';
}

const EventPopup = ({ showPopOver, event, position, closePopup, horizontalAnchor = 'center' }: EventPopupProps) => {
  const { navigateToEventPage } = useEventNav();

  const procedureIds = {
    procedure: event?.procedure_id,
    section: event?.procedure_section_id || (event?.procedure_id && ''),
    run: event?.run_id,
  };

  return (
    <>
      {showPopOver && (
        <Popover
          open={showPopOver}
          anchorReference="anchorPosition"
          anchorPosition={position}
          onClose={closePopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: horizontalAnchor,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: horizontalAnchor,
          }}
        >
          <div className="flex flex-col w-full p-4 space-y-1 max-w-xl">
            <div className="flex flex-col w-full">
              <div className="flex justify-between items-center">
                <strong
                  className="text-lg cursor-pointer hover:underline line-clamp-2"
                  onClick={() => navigateToEventPage(event)}
                >
                  {event?.name}
                </strong>
                <div className="flex pl-4">
                  <FontAwesomeIcon icon={faTimes} className="cursor-pointer hover:text-red-500" onClick={closePopup} />
                </div>
              </div>
              <div>
                <Typography color="textPrimary">
                  <RunStatusLabel statusText={event.status || 'planning'} />
                </Typography>
              </div>
              <span className="text-blue-500 cursor-pointer hover:underline" onClick={() => navigateToEventPage(event)}>
                Details &gt;
              </span>
            </div>
            {event.operation?.name && (
              <div>
                <Typography color="textPrimary">
                  <span className="text-gray-600">Operation: </span>
                  {event.operation?.name}
                </Typography>
              </div>
            )}
            {event?.description && (
              <div>
                <Typography color="textPrimary">
                  <span className="text-gray-600">Description: </span>
                  {event?.description}
                </Typography>
              </div>
            )}
            {event?.start && event?.end && (
              <div>
                <Typography color="textPrimary">
                  <span className="text-gray-600">Duration: </span>
                  {formatDuration(getEventDuration(event))}
                </Typography>
              </div>
            )}
            {event?.start && (
              <div>
                <Typography color="textPrimary">
                  <span className="text-gray-600">Start: </span>
                  {event?.start.toFormat('LLL dd yyyy, t')}
                </Typography>
              </div>
            )}
            {event?.end && (
              <div>
                <Typography color="textPrimary">
                  <span className="text-gray-600">End: &nbsp;</span>
                  {event?.end.toFormat('LLL dd yyyy, t')}
                </Typography>
              </div>
            )}
            {event?.procedure_id && (
              <div>
                <Typography color="textPrimary">
                  <strong>Procedure: </strong>
                  <BlockProcedureLink
                    docId=""
                    docState="running"
                    operation=""
                    // @ts-ignore, TODO convert procedure links to typescript
                    link={procedureIds}
                    onStartLinkedRun={undefined}
                    isHidden={false}
                    isEnabled={false}
                    procedure=""
                    run=""
                    section=""
                    showLabels={false}
                    sourceName={event.name}
                  />
                </Typography>
              </div>
            )}
          </div>
        </Popover>
      )}
    </>
  );
};

export default EventPopup;
