import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useMixpanel } from '../../contexts/MixpanelContext';
import useBlockRedlines from '../../hooks/useBlockRedlines';
import useBlockComponents from '../../hooks/useBlockComponents';
import SuggestedEditsModal from '../SuggestedEditsModal';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import DiffContainer from '../Diff/DiffContainer';

/*
 * Component for rendering a procedure block when running or viewing a procedure.
 * Supports showing redline history changes.
 *
 * block: A Block object to render.
 * redlines: A list of RedlineBlock objects with revision history.
 * recorded: An arbitrary object with recorded values for the given Block.
 * contentIndex: The current contentIndex of this Block in the parent Step.
 *               In the future, this could be refactored out.
 * isEnabled: True if recording new Block values should be enabled.
 * isHidden: True to hide all block content (expand/collapse)
 * onRecordValuesChanged: Callback for when user enters new recorded values.
 *                        Type fn(recorded), where recorded is any arbitrary object.
 * onRecordErrorsChanged: Callback when there are errors in user-submitted recorded
 *                        values. Type fn(errors), where errors is a dictionary
 *                        of type { fieldName: String error }
 * onRecordUploadingChanged: Callback when user recorded values are uploading. Type
 *                           fn(isUploading), where isUploading is a boolean that is
 *                           true if the content block is currently uploading data.
 * actions: A list of Action objects to render within the procedure block.
 */
const ProcedureBlockReview = ({
  blockLabel,
  block,
  redlines,
  recorded,
  contentIndex,
  isEnabled,
  isHidden,
  onRecordValuesChanged,
  onRecordErrorsChanged,
  onRecordUploadingChanged,
  actions,
  isSpacerHidden,
  isDark,
  onContentRefChanged,
  scrollMarginTopValueRem,
  onAcceptPendingRedline,
}) => {
  const { mixpanel } = useMixpanel();
  const [showsRedlineModal, setShowsRedlineModal] = useState(false);
  const { latestApprovedBlock, latestRedline, hasRedlines, allRedlineUserIds, hasPendingRedlines } = useBlockRedlines({
    block,
    redlines,
  });

  const acceptRedline = useCallback(() => {
    if (!onAcceptPendingRedline) {
      return Promise.resolve();
    }
    return onAcceptPendingRedline(latestRedline.redlineIndex);
  }, [latestRedline, onAcceptPendingRedline]);

  const acceptRedlineEnabled = useMemo(
    () => Boolean(hasPendingRedlines && onAcceptPendingRedline),
    [hasPendingRedlines, onAcceptPendingRedline]
  );

  const { TypedProcedureBlock, TypedBlock } = useBlockComponents({ blockType: block.type, isReview: true });

  const recordValueChangedHandler = useCallback(
    (recorded) => {
      onRecordValuesChanged && onRecordValuesChanged(block.id, recorded);
    },
    [block.id, onRecordValuesChanged]
  );

  const recordErrorsChangedHandler = useCallback(
    (errors) => {
      onRecordErrorsChanged && onRecordErrorsChanged(contentIndex, errors);
    },
    [contentIndex, onRecordErrorsChanged]
  );

  const recordUploadingChangedHandler = useCallback(
    (isUploading) => {
      onRecordUploadingChanged(contentIndex, isUploading);
    },
    [contentIndex, onRecordUploadingChanged]
  );

  const showRedlineModal = useCallback(() => {
    if (mixpanel) {
      mixpanel.track('Redline Viewed', { 'Block Type': block.type });
    }
    setShowsRedlineModal(true);
  }, [block.type, mixpanel]);

  const extendedActions = useMemo(() => {
    const showRedlineModalAction = {
      icon: 'strikethrough',
      iconType: 'caution',
      onAction: showRedlineModal,
      ariaLabel: 'Suggested Edits',
      pendingAction: hasPendingRedlines,
    };
    return hasRedlines ? [showRedlineModalAction, ...actions] : actions;
  }, [actions, hasRedlines, showRedlineModal, hasPendingRedlines]);

  const diffContainerLabel = useMemo(() => {
    const type = sharedDiffUtil.getDiffValue(block, 'type', 'new');
    if (type === 'alert') {
      const subtype = sharedDiffUtil.getDiffValue(block, 'subtype', 'new');
      return typeof subtype === 'string' && subtype.length > 0
        ? [...subtype][0].toUpperCase() + subtype.slice(1)
        : 'Alert';
    }
    if (type === 'input') {
      return 'Field input';
    }
    if (type === 'requirement') {
      return 'Requirement';
    }
    if (type === 'external_item') {
      return 'External item';
    }
    return 'Block';
  }, [block]);

  const diffChangeState = useMemo(() => {
    // We provide a more detailed diff for these fields, so we don't need to show the diff container box when they are modified.
    return block.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED &&
      ['alert', 'requirement', 'input'].some((type) => type === block.type)
      ? ARRAY_CHANGE_SYMBOLS.UNCHANGED
      : block.diff_change_state;
  }, [block]);

  return (
    <Fragment>
      {/* Redline changes modal */}
      {showsRedlineModal && (
        <SuggestedEditsModal
          hideModal={() => setShowsRedlineModal(false)}
          allRedlineUserIds={allRedlineUserIds}
          latestTimestamp={latestRedline.createdAt}
          acceptAction={acceptRedlineEnabled ? acceptRedline : undefined}
          redlinePending={hasPendingRedlines}
          isBlueline={latestRedline.run_only}
        >
          <TypedBlock
            block={block}
            redlined={latestRedline} // TODO: remove redlined when older code no longer needs to use it.
            redlinedBlock={latestRedline.block}
            isEnabled={false}
          />
        </SuggestedEditsModal>
      )}

      {/* Render specific block content */}
      <TypedProcedureBlock
        blockLabel={blockLabel}
        isHidden={isHidden}
        actions={extendedActions}
        isSpacerHidden={isSpacerHidden}
        hasExtraVerticalSpacing={isEnabled && block.inputType === 'checkbox'}
      >
        <DiffContainer diffChangeState={diffChangeState} label={diffContainerLabel} isTextSticky={false}>
          {/* Pass the block id to use in case the latest approved block is not
         the same as the original block.*/}
          <TypedBlock
            block={latestApprovedBlock}
            blockId={block.id}
            recorded={recorded}
            isEnabled={isEnabled}
            isDark={isDark}
            onRecordValuesChanged={recordValueChangedHandler}
            onRecordErrorsChanged={recordErrorsChangedHandler}
            onRecordUploadingChanged={recordUploadingChangedHandler}
            onContentRefChanged={onContentRefChanged}
            scrollMarginTopValueRem={scrollMarginTopValueRem}
          />
        </DiffContainer>
      </TypedProcedureBlock>
    </Fragment>
  );
};

export default ProcedureBlockReview;
