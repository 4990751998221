import { DefaultRootState, useSelector } from 'react-redux';
import {
  ExpressionBlock,
  ExpressionToken,
  FieldInputBlock,
  Run,
  RunExpressionBlock,
  StepState,
  V2RunVariable,
} from 'shared/lib/types/views/procedures';
import { Unit } from 'shared/lib/types/api/settings/units/models';
import { useRunContext } from '../contexts/RunContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useSettings } from '../contexts/SettingsContext';
import { GetReferencedContentContext } from './useProcedureAdapter';
import { useCallback } from 'react';
import procedureVariableUtil from '../lib/procedureVariableUtil';
import runUtil from '../lib/runUtil';
import { getStepState } from 'shared/lib/runUtil';
import { getStepDisplayIndex } from '../lib/batchSteps';
import { ReferenceContext, RunExpressionReferenceTargetBlock } from 'shared/lib/types/expressions';
import validateUtil, { EXPRESSION_CYCLIC_ERROR } from '../lib/validateUtil';
import { evaluate, hasCyclicReference, resolveTokens } from 'shared/lib/expressionUtil';
import { selectRunStep } from '../contexts/runsSlice';
import { mapValues } from 'lodash';

type GetExpressionResult = ({
  state,
  tokens,
  recorded,
  findDefinedUnit,
  shouldEvaluate,
}: {
  state: DefaultRootState;
  tokens?: Array<ExpressionToken>;
  recorded?: RunExpressionBlock['recorded'];
  findDefinedUnit?: (unit: string) => Unit | undefined;
  shouldEvaluate?: boolean;
}) => NonNullable<RunExpressionBlock['recorded']>;

interface UseExpressionHelperProps {
  displaySectionAs: 'letters' | 'numbers';
  run: Run | null;
  isRun: boolean;
  isPreviewMode: boolean;
  currentTeamId: string;
}

type UseExpressionHelperReturn = {
  getExpressionResult: GetExpressionResult;
  getReferencedContentContext: GetReferencedContentContext;
};

export const useExpressionHelper = ({
  displaySectionAs,
  run,
  isRun,
  isPreviewMode,
  currentTeamId,
}: UseExpressionHelperProps): UseExpressionHelperReturn => {
  /**
   * Gets the context needed for referenced content in a run
   */
  const getReferencedContentContext: GetReferencedContentContext = useCallback(
    (referencedContentId, _, staticReferences) => {
      if (!run) {
        return null;
      }
      let referencedFromStepId;
      let referencedFromSectionId;
      let referencedFromStepKey;
      let referencedFromSectionKey;
      let sectionRepeatKey;
      let stepRepeatKey;
      let referencedContent;
      let referencedSectionIndex;
      let referencedStepIndex;
      let stepRecordedState;
      let stepEndedTimestamp;
      let isVariable = false;
      let isVariableRecorded = false;

      if (run.variables) {
        run.variables.some((variable) => {
          if ((variable as V2RunVariable).id === referencedContentId) {
            referencedContent = procedureVariableUtil.getFieldInputFromVariable(variable);
            isVariable = true;
            isVariableRecorded = variable.value !== undefined && variable.value !== null;
            return true;
          }
          return false;
        });

        if (referencedContent) {
          return {
            referencedContent,
            isVariable,
            isVariableRecorded,
          };
        }
      }

      run.sections.some((section) => {
        return section.steps.some((step) => {
          return step.content.some((contentBlock, contentIndex) => {
            if (contentBlock.id === referencedContentId) {
              let referencedSectionId;
              let referencedStepId;
              if (staticReferences) {
                referencedSectionId = staticReferences.referenced_section_id;
                referencedStepId = staticReferences.referenced_step_id;
              } else {
                const { sectionId: latestSectionId, stepId: latestStepId } = runUtil.getLatestRepeat(
                  run,
                  section.id,
                  step.id
                );
                referencedSectionId = latestSectionId;
                referencedStepId = latestStepId;
              }

              const latestStep = run.sections
                .find((section) => section.id === referencedSectionId)
                ?.steps.find((step) => step.id === referencedStepId);

              referencedSectionIndex = run.sections.findIndex((section) => section.id === referencedSectionId);
              referencedStepIndex = run.sections[referencedSectionIndex].steps.findIndex(
                (step) => step.id === referencedStepId
              );

              referencedContent = latestStep?.content[contentIndex];
              stepRecordedState = latestStep ? getStepState(latestStep) : undefined;
              stepEndedTimestamp = runUtil.getStepEndedTimestamp(latestStep);
              return true;
            }
            return false;
          });
        });
      });

      if (referencedSectionIndex !== undefined && referencedStepIndex !== undefined) {
        const section = run.sections[referencedSectionIndex];
        const stepIndex = getStepDisplayIndex(section.steps, referencedStepIndex);
        referencedFromStepKey = runUtil.displaySectionStepKey(
          run.sections,
          referencedSectionIndex,
          stepIndex,
          displaySectionAs
        );
        referencedFromSectionId = run.sections[referencedSectionIndex].id;
        referencedFromStepId = run.sections[referencedSectionIndex].steps[referencedStepIndex].id;
        sectionRepeatKey = runUtil.displayRepeatKey(run.sections, referencedSectionIndex);
        stepRepeatKey = runUtil.displayRepeatKey(run.sections[referencedSectionIndex].steps, referencedStepIndex);
        referencedFromSectionKey =
          sectionRepeatKey && runUtil.displaySectionKey(run.sections, referencedSectionIndex, displaySectionAs);
      }

      return {
        referencedFromStepKey,
        referencedFromSectionKey,
        sectionRepeatKey,
        stepRepeatKey,
        referencedContent,
        stepRecordedState,
        stepEndedTimestamp,
        isVariable,
        isVariableRecorded,
        referencedFromSectionId,
        referencedFromStepId,
      };
    },
    [displaySectionAs, run]
  );

  const _getTokens = useCallback(
    (tokens: Array<ExpressionToken>): Array<ExpressionToken> => {
      return tokens.flatMap((token) => {
        const referencedContent = getReferencedContentContext(token.reference_id || '')?.referencedContent;
        const childTokens = referencedContent?.type === 'expression' ? _getTokens(referencedContent.tokens) : [];
        return [token, ...childTokens];
      });
    },
    [getReferencedContentContext]
  );

  const getReferencedContentContextMap = useCallback(
    ({
      state,
      tokens,
    }: {
      state: DefaultRootState;
      tokens: Array<ExpressionToken>;
    }): {
      [sourceContentId: string]: {
        referencedContent?: ReferenceContext;
        sourceContentId?: string;
        referencedSectionId?: string;
        referencedStepId?: string;
        referencedStepState?: StepState;
      };
    } => {
      if (!run) {
        return {};
      }
      if (isPreviewMode) {
        const procedureMap = validateUtil.getProcedureMap(run) as {
          [id: string]: ExpressionBlock | FieldInputBlock | undefined;
        };
        const isCyclic = hasCyclicReference({ tokens, procedureMap });
        if (isCyclic) {
          return {};
        }
      }
      const allTokens = _getTokens(tokens);
      const referencedContent = allTokens.map((token) => {
        const referencedContentContext = getReferencedContentContext(token.reference_id || '');
        const liveRunStep =
          run?._id && isRun
            ? selectRunStep(
                state as { runs: { [runId: string]: unknown } },
                currentTeamId,
                run._id,
                referencedContentContext?.referencedFromSectionId || '',
                referencedContentContext?.referencedFromStepId || ''
              )
            : null;

        const referencedContent = liveRunStep
          ? liveRunStep.content?.find((content) => content.id === referencedContentContext?.referencedContent?.id)
          : referencedContentContext?.referencedContent;

        return {
          referencedContent,
          sourceContentId: token.reference_id,
          referencedSectionId: referencedContentContext?.referencedFromSectionId || '',
          referencedStepId: referencedContentContext?.referencedFromStepId || '',
        };
      });

      return (referencedContent ?? []).reduce(
        (map, { referencedContent, sourceContentId, referencedStepId, referencedSectionId }) => {
          if (sourceContentId && referencedContent) {
            map[sourceContentId] = {
              referencedContent,
              referencedStepId,
              referencedSectionId,
            };
          }
          return map;
        },
        {}
      );
    },
    [_getTokens, currentTeamId, getReferencedContentContext, isPreviewMode, isRun, run]
  );

  const getExpressionResult: GetExpressionResult = useCallback(
    ({ state, tokens = [], recorded, findDefinedUnit, shouldEvaluate = true }) => {
      if (recorded) {
        return recorded;
      }

      if (isPreviewMode) {
        const procedureMap = validateUtil.getProcedureMap(run) as {
          [id: string]: ExpressionBlock | FieldInputBlock | undefined;
        };
        const isCyclic = hasCyclicReference({ tokens, procedureMap });
        if (isCyclic) {
          return {
            value: `Error: ${EXPRESSION_CYCLIC_ERROR}`,
          };
        }
      }

      const referencedContentContextMap = getReferencedContentContextMap({
        state,
        tokens,
      });
      const referencedContentMap = mapValues(referencedContentContextMap, 'referencedContent') as {
        [sourceContentId: string]: ReferenceContext;
      };
      const formula = resolveTokens({
        tokens,
        referenceContext: referencedContentMap,
        findDefinedUnit,
        getRecorded: (block) =>
          isPreviewMode
            ? (block as RunExpressionReferenceTargetBlock)?.recorded ?? block?.['preview_recorded']
            : (block as RunExpressionReferenceTargetBlock)?.recorded,
      });
      if (!formula.isResolved) {
        return {
          display: formula.displayText,
        };
      }
      const references = mapValues(referencedContentContextMap, (context) => ({
        referenced_section_id: context.referencedSectionId ?? '',
        referenced_step_id: context.referencedStepId ?? '',
      }));
      return {
        display: formula.displayText,
        value: shouldEvaluate ? evaluate(formula.resolvedExpression, formula.context) : formula.displayText,
        references,
      };
    },
    [isPreviewMode, getReferencedContentContextMap, run]
  );

  return {
    getExpressionResult,
    getReferencedContentContext,
  };
};

type useExpressionReturn = {
  expressionResult: ReturnType<GetExpressionResult>;
};

interface UseExpressionProps {
  tokens?: Array<ExpressionToken>;
  recorded?: RunExpressionBlock['recorded'];
  findDefinedUnit?: (unit: string) => Unit | undefined;
  shouldEvaluate?: boolean;
}

const useExpression = ({
  tokens = [],
  recorded,
  findDefinedUnit,
  shouldEvaluate,
}: UseExpressionProps): useExpressionReturn => {
  const { getSetting } = useSettings();
  const { run, isRun, isPreviewMode } = useRunContext();
  const { currentTeamId } = useDatabaseServices();
  const { getExpressionResult } = useExpressionHelper({
    displaySectionAs: getSetting('display_sections_as', 'letters'),
    run,
    isRun,
    isPreviewMode,
    currentTeamId,
  });

  const expressionResult = useSelector((state) => {
    return getExpressionResult({
      state,
      tokens,
      recorded,
      findDefinedUnit,
      shouldEvaluate,
    });
  });

  return {
    expressionResult,
  };
};

export default useExpression;
