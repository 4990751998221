import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Fragment, useCallback, useMemo, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useMixpanel } from '../contexts/MixpanelContext';
import { useNavState } from '../contexts/NavContext';
import { useRunContext } from '../contexts/RunContext';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';
import { VIEW_MODES } from './FieldSetViewModeEditSelect';
import Label from './Label';
import RunHeaderActions from './StickyHeader/RunHeaderActions';
import BackTo from './StickyHeader/Buttons/BackTo';
import { Run } from 'shared/lib/types/views/procedures';
import RunLabel from './RunLabel';

// TODO fill in these props
export type RunStickyHeaderProps = {
  run: Run;
  scrollTo;
  scrollToId;
  expandAll;
  collapseAll;
  setShowPauseModal;
  endRun;
  viewMode;
  setViewMode;
  projectId;
  isPreviewMode;
  onEdit;
  onReview;
  onAddIssue;
  onAutomationChange;
  backTo;
};

export default function RunStickyHeader({
  run,
  scrollTo,
  scrollToId,
  expandAll,
  collapseAll,
  setShowPauseModal,
  endRun,
  viewMode,
  setViewMode,
  projectId,
  isPreviewMode,
  onEdit,
  onReview,
  onAddIssue,
  onAutomationChange,
  backTo,
}: RunStickyHeaderProps) {
  const navState = useNavState();
  const { mixpanel } = useMixpanel();
  const { isUserParticipant } = useRunContext();

  // The below checks are used to determine if the user is in the preview mode of edit draft flow or review draft flow.
  const { url } = useRouteMatch();
  const isEditPreview = useMemo<boolean>(() => url.endsWith('/edit/run-preview'), [url]);
  const isReviewPreview = useMemo<boolean>(() => url.endsWith('/review/run-preview'), [url]);

  const bgStyle = isPreviewMode ? 'bg-cyan-50' : 'bg-gray-100';

  const op = useRef<OverlayPanel>(null);

  const handleChangeViewMode = useCallback(
    (viewMode) => {
      if (mixpanel) {
        mixpanel.track('View Mode Changed', { 'View Mode': viewMode });
      }
      setViewMode(viewMode);
    },
    [mixpanel, setViewMode]
  );

  const handlePauseClick = useCallback(
    (show: boolean) => {
      op?.current?.hide();
      setShowPauseModal(show);
    },
    [setShowPauseModal]
  );

  return (
    <div
      data-testid="runHeader"
      className={`fixed top-0 ${
        navState.collapsed ? 'left-16' : 'left-64'
      } transition-all right-0 pl-2 pr-4 h-10 flex flex-row justify-between shadow-md ${bgStyle} z-50 print:hidden`}
    >
      <div className="h-full flex items-center whitespace-nowrap text-ellipsis overflow-hidden">
        {backTo && <BackTo label={backTo.title} onClick={backTo.onClick} />}
        {isPreviewMode && (
          <Fragment>
            {isEditPreview && (
              <button
                className={stickyHeaderUtil.getButtonClassNames(false, false)}
                type="button"
                title="Edit Draft"
                disabled={false}
                onClick={() => onEdit('list')}
              >
                <FontAwesomeIcon icon="pencil-alt" />
              </button>
            )}
            {isReviewPreview && (
              <button
                className={stickyHeaderUtil.getButtonClassNames(false, false)}
                type="button"
                title="Review Draft"
                disabled={false}
                onClick={() => onReview('')}
              >
                <FontAwesomeIcon icon="clipboard" />
              </button>
            )}
            <button
              className={stickyHeaderUtil.getButtonClassNames(false, true)}
              type="button"
              title="Preview Procedure"
              aria-label="Preview Procedure"
              disabled={true}
            >
              <FontAwesomeIcon icon="eye" />
            </button>
            <button
              className={stickyHeaderUtil.getButtonClassNames(false, false)}
              type="button"
              title="Flow View"
              disabled={false}
              onClick={() => {
                if (isEditPreview) {
                  onEdit('chart');
                } else if (isReviewPreview) {
                  onReview('/flow-view');
                }
              }}
            >
              <FontAwesomeIcon icon="sitemap" />
            </button>
            <div className="bg-slate-300 min-w-[1px] w-[1px] h-8 ml-1 mr-2" />
            <Label text="Preview Run" color="bg-cyan-200" enableTruncate={false} />
          </Fragment>
        )}
        <button
          className={`${stickyHeaderUtil.getButtonClassNames(false, viewMode === VIEW_MODES.LIST)} ml-2`}
          type="button"
          title="Switch to List View"
          disabled={viewMode === VIEW_MODES.LIST}
          onClick={() => handleChangeViewMode(VIEW_MODES.LIST)}
        >
          <FontAwesomeIcon icon="list" />
        </button>
        <button
          className={stickyHeaderUtil.getButtonClassNames(false, viewMode === VIEW_MODES.SINGLE_CARD)}
          type="button"
          title="Switch to Single Card View"
          disabled={viewMode === VIEW_MODES.SINGLE_CARD}
          onClick={() => handleChangeViewMode(VIEW_MODES.SINGLE_CARD)}
          style={{}}
        >
          <FontAwesomeIcon icon="list-alt" />
        </button>
        <div className="flex flex-row truncate text-sm">
          <div className="font-bold pr-1 mt-[2px]">
            <RunLabel code={run.code} runNumber={run.run_number} runNumberBg="medium" />
          </div>
          <div>{run.name}</div>
        </div>
      </div>

      <div className="lg:hidden h-full flex items-center">
        <button
          type="button"
          title="More actions"
          className={` ${stickyHeaderUtil.getButtonClassNames(false, false)} p-2`}
          aria-label="More actions"
          onClick={(e) => op?.current?.toggle(e)}
        >
          <FontAwesomeIcon fixedWidth={true} icon="ellipsis-v" />
        </button>
        <OverlayPanel ref={op} className="p-4">
          <RunHeaderActions
            run={run}
            scrollTo={scrollTo}
            scrollToId={scrollToId}
            expandAll={expandAll}
            collapseAll={collapseAll}
            setShowPauseModal={(show: boolean) => handlePauseClick(show)}
            endRun={endRun}
            viewMode={viewMode}
            projectId={projectId}
            isPreviewMode={isPreviewMode}
            onAddIssue={onAddIssue}
            onAutomationChange={onAutomationChange}
            isVertical={true}
            isUserParticipant={isUserParticipant}
          />
        </OverlayPanel>
      </div>

      <div className="lg:flex hidden">
        <RunHeaderActions
          run={run}
          scrollTo={scrollTo}
          scrollToId={scrollToId}
          expandAll={expandAll}
          collapseAll={collapseAll}
          setShowPauseModal={setShowPauseModal}
          endRun={endRun}
          viewMode={viewMode}
          projectId={projectId}
          isPreviewMode={isPreviewMode}
          onAddIssue={onAddIssue}
          onAutomationChange={onAutomationChange}
          isVertical={false}
          isUserParticipant={isUserParticipant}
        />
      </div>
    </div>
  );
}
