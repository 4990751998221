import { useCallback, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import labels from 'shared/lib/labelUtil';
import { useSettings } from '../../contexts/SettingsContext';
import Modal from '../Modal';
import { CouchLikeOperation } from 'shared/lib/types/operations';

const REQUIRED_MSG = 'Required.';
const NAME_EXISTS_MSG = 'Operation already exists.';
const INVALID_CHAR_MSG = '"%" is not allowed.';

export type OperationFormValues = {
  name: string;
  description?: string;
};

export type SaveResult = {
  success: boolean;
  message?: string;
};

interface OperationFormModalProps {
  type: 'new' | 'duplicate';
  operation?: CouchLikeOperation;
  onPrimaryAction: (values: OperationFormValues) => Promise<SaveResult>;
  onSecondaryAction: () => void;
  secondaryActionTitle?: string;
}

const OperationFormModal = ({
  type,
  operation,
  onPrimaryAction,
  onSecondaryAction,
  secondaryActionTitle,
}: OperationFormModalProps) => {
  const { operations } = useSettings();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onValidate = useCallback(
    (values: OperationFormValues) => {
      const errors: { name?: string } = {};
      if (!values.name || !values.name.trim()) {
        errors.name = REQUIRED_MSG;
      }
      if (values.name.indexOf('%') !== -1) {
        errors.name = INVALID_CHAR_MSG;
      }
      // Check that operation name is unique
      const key = labels.getLabelKey(values.name);
      if (operations?.operations[key]) {
        errors.name = NAME_EXISTS_MSG;
      }
      return errors;
    },
    [operations]
  );

  return (
    <Formik
      initialValues={{
        name: `${operation?.name || ''}${type === 'duplicate' ? ' (Duplicate)' : ''}`,
        description: operation?.description || '',
      }}
      onSubmit={(values, { setErrors }) => {
        setIsSubmitting(true);
        onPrimaryAction(values).then((result) => {
          if (!result.success) {
            if (result.message === 'Request failed with status code 409') {
              setErrors({ name: NAME_EXISTS_MSG });
              setIsSubmitting(false);
            }
          }
        });
      }}
      validate={onValidate}
    >
      {({ dirty, isValid, errors }) => (
        <Form>
          <Modal
            title={`${type === 'new' ? 'New' : 'Duplicate'} Operation`}
            primaryActionTitle="Save"
            onSecondaryAction={onSecondaryAction}
            isPrimaryActionEnabled={isValid && (type === 'new' ? dirty : true) && !isSubmitting}
            secondaryActionTitle={secondaryActionTitle}
          >
            <div className="flex flex-col w-full">
              <label htmlFor="name" className="font-semibold text-sm uppercase">
                Name
              </label>
              <Field
                id="name"
                name="name"
                placeholder="Name"
                type="text"
                className="text-sm border-1 border-gray-400 rounded"
              />
              {errors.name && <div className="text-red-700">{errors.name}</div>}

              <label htmlFor="description" className="mt-2 font-semibold text-sm uppercase">
                Description
              </label>
              <Field
                id="description"
                name="description"
                placeholder="Description"
                type="text"
                className="text-sm border-1 border-gray-400 rounded"
              />
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default OperationFormModal;
