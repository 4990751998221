import React, { useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';

const EventSelect = ({ field, form, options, placeholder, isEditing }) => {
  const handleChange = (newVal) => {
    // Happens on clear
    if (!newVal) {
      form.setFieldValue(field.name, null);
      return;
    }
    form.setFieldValue(field.name, newVal.value);
  };

  const value = useMemo(() => {
    return options.find((o) => o.value === field.value);
  }, [options, field]);

  return (
    <div className="flex flex-row items-center relative">
      <div className={`absolute ${isEditing ? 'invisible' : ''}`}>
        {!field.value && <span className="italic px-2 text-gray-400">None</span>}
        {value && <span className="px-2">{value.label}</span>}
      </div>
      <div className={`${isEditing ? '' : 'invisible'}`}>
        <CreatableSelect
          value={value}
          aria-label={placeholder}
          placeholder={placeholder}
          classNamePrefix="react-select"
          className="w-96 max-w-prose text-sm border-1 border-gray-400 rounded"
          onChange={handleChange}
          options={options}
          isClearable={true}
        />
      </div>
    </div>
  );
};

export default EventSelect;
