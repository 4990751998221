import React, { useState, useMemo } from 'react';
import { useGanttContext } from '../../components/Gantt/GanttContext';
import { Event } from 'shared/schedule/types/event';
import EventPopup from '../EventPopup';
import useEventNav from '../../hooks/useEventNav';
import useSingleClick from '../../hooks/useSingleClick';
import Tooltip from '../../../elements/Tooltip';

interface MilestoneProps {
  event: Event;
}

const Milestone = ({ event }: MilestoneProps) => {
  const { dateToXPos } = useGanttContext();
  const [showPopOver, setShowPopOver] = useState(false);
  const { navigateToEventPage } = useEventNav();
  const { handleClick, clickPosition } = useSingleClick(() => setShowPopOver(true));
  const xPos = useMemo(() => {
    const datePos = dateToXPos(event.start);
    return datePos - 6; // Adjust for padding
  }, [event.start, dateToXPos]);

  return (
    <Tooltip content={event.name}>
      <>
        <div
          className="flex absolute top-0 bottom-0 px-2 hover:bg-sky-500/5 rounded"
          style={{ transform: `translate3d(${xPos}px, 0, 0)` }}
          onClick={handleClick}
          onDoubleClick={() => navigateToEventPage(event)}
          data-testid={`${event.id}-marker`}
        >
          <div className="border-sky-800 opacity-50 border-r-[2px] border-dotted z-10" />
        </div>
        <EventPopup
          showPopOver={showPopOver}
          event={event}
          position={clickPosition}
          closePopup={() => setShowPopOver(false)}
        />
      </>
    </Tooltip>
  );
};

export default Milestone;
