import ItemSelect from '../ItemSelect';
import { getUsageTypeLabel, isPartRestricted } from '../../lib/parts';
import SubstepNumber from '../../../components/SubstepNumber';
import { generateHiddenClassString } from '../../../lib/styles';
import isNumber from '../../../lib/number';
import { Item } from '../../types';
import DiffContainer from '../../../components/Diff/DiffContainer';
import sharedDiffUtil from 'shared/lib/diffUtil';
import PartAndUsageTypeSelect from '../PartAndUsageTypeSelect';
import ReviewPartLabel from './ReviewPartLabel';
import useParts from '../../hooks/useParts';
import RestrictedInfo, { RESTRICTED_TEXT } from '../RestrictedInfo';

const INVALID_AMOUNT_ERROR = 'Enter a non-negative number';

const ReviewPartUsage = ({
  content,
  errors,
  recorded,
  blockLabel,
  teamId,
  onRecordValuesChanged,
  onRecordErrorsChanged,
  isEnabled,
  isHidden,
}) => {
  const { getPart } = useParts();
  const part = content.part ? getPart(content.part.id) : undefined;
  const partRestricted = content.part === null ? false : isPartRestricted(part);

  let usageTypeSelected;
  if (content && content.usage_types && content.usage_types.length > 0) {
    usageTypeSelected = content.usage_types[0];
  }

  const getAmount = (): string => {
    if (recorded && recorded.length > 0) {
      return recorded[0].amount;
    }
    return '';
  };

  const onChangeItem = (item: Item): void => {
    const values = [
      {
        item_id: item?.id,
        item,
        amount: getAmount(),
      },
    ];
    onRecordValuesChanged?.(content?.id, values);
  };

  const clearErrors = () => onRecordErrorsChanged({});

  const checkErrors = (amount: string): void => {
    if (!isNumber(amount) || Number(amount) < 0) {
      onRecordErrorsChanged({ amount: INVALID_AMOUNT_ERROR });
    } else {
      clearErrors();
    }
  };

  const updateAmount = (amount: string): void => {
    let item = null;
    let item_id = null;
    if (recorded && recorded.length > 0) {
      item = recorded[0].item;
      item_id = recorded[0].item_id;
    }
    const values = [
      {
        item_id,
        item,
        amount,
      },
    ];
    onRecordValuesChanged?.(content?.id, values);
  };

  const onChangeAmount = (amount: string): void => {
    checkErrors(amount);
    updateAmount(amount);
  };

  const itemSelected = recorded && recorded.length > 0 && recorded[0].item;
  const itemIdSelected = itemSelected?.id;

  return (
    <tr>
      <td></td>
      <td colSpan={2}>
        <div className={generateHiddenClassString('', isHidden)} />
        <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
          <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
          <DiffContainer label="Part usage" diffChangeState={content.diff_change_state} isTextSticky={false}>
            <div className="flex flex-col grow gap-y-6">
              <div>
                <div className="flex flex-col">
                  <div className={`text-gray-500 text-sm tracking-wide ${content?.part !== null ? 'pt-6' : ''}`}>
                    Part Usage
                  </div>

                  {!partRestricted && (
                    <div>
                      {!content?.part ? (
                        <PartAndUsageTypeSelect
                          content={content}
                          onChangePartDuringRun={null}
                          onChangeUsage={null}
                          recorded={recorded}
                          isEnabled={false}
                        />
                      ) : (
                        <ReviewPartLabel teamId={teamId} part={content?.part} />
                      )}
                    </div>
                  )}
                  {partRestricted && (
                    <div className="mt-2 ml-0.5">
                      <RestrictedInfo text={RESTRICTED_TEXT} />
                    </div>
                  )}
                </div>
              </div>
              {!partRestricted && (
                <div className="flex gap-x-2">
                  <div className="w-56">
                    <div className="field-title">Item</div>
                    <ItemSelect
                      itemId={itemIdSelected}
                      partId={sharedDiffUtil.getDiffValue(content, 'part_id', 'new')}
                      isDisabled={!isEnabled}
                      onChangeItem={onChangeItem}
                      usageTypeName={usageTypeSelected?.name || ''}
                    />
                  </div>
                  <div>
                    <div className="field-title">Usage</div>
                    <input
                      data-testid="usage-amount"
                      className="px-2 h-[2.4rem] w-16 border border-gray-400 rounded disabled:bg-gray-200 disabled:bg-opacity-50"
                      disabled={!isEnabled}
                      onChange={(e) => onChangeAmount(e.target.value)}
                      value={getAmount()}
                    />
                  </div>
                  <div className="mt-6">
                    <div className="self-end">{usageTypeSelected ? `${getUsageTypeLabel(usageTypeSelected)}` : ''}</div>
                    {errors && errors.amount && (
                      <div className="whitespace-nowrap text-sm self-end text-red-700">{errors.amount}</div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </DiffContainer>
        </div>
      </td>
    </tr>
  );
};

export default ReviewPartUsage;
