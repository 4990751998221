import {
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_MB,
} from 'shared/lib/types/api/files/requests';
import { SourceType } from 'shared/lib/types/attachments';
import apm from '../lib/apm';
import idUtil from '../lib/idUtil';
import { AttachmentMetadata } from '../lib/views/attachments';
import AttachmentsApi from './api';
import AttachmentCache, { DownloadCacheEntry, UploadCacheEntry } from './cache';
import { createUploadCacheEntry } from './lib';
import {
  AttachmentResponse,
  Attachments,
  BatchSyncResults,
  UploadFileResponse,
} from './types';

export const MAX_FILE_SIZE_EXCEEDED_MESSAGE = `Max file size is ${MAX_FILE_SIZE_MB} MB`;

const ON_DEMAND_ENTRY_MAX_AGE_MS = 24 * 60 * 60 * 1000; // Time until an on-demand entry expires (1 day)
const ON_DEMAND_CLEANUP_INTERVAL_MS = 5 * 60 * 1000; // Time between on-demand cache cleanup attempts (5 minutes)

type StorageHandler = (entry: DownloadCacheEntry) => Promise<void>;

class AttachmentService implements Attachments {
  private static instances = {};

  /**
   * Get a shared instance of the attachment service for a team.
   */
  static getInstance = (teamId: string): Attachments => {
    if (!AttachmentService.instances[teamId]) {
      AttachmentService.instances[teamId] = new AttachmentService(teamId);
    }
    return AttachmentService.instances[teamId];
  };

  private static removeInstance = (teamId: string): void => {
    delete AttachmentService.instances[teamId];
    AttachmentsApi.removeInstance(teamId);
  };

  private teamId: string;
  private apiService: AttachmentsApi;

  private uploadCache: AttachmentCache<UploadCacheEntry>;

  /**
   * A localforage instance for downloading procedure data.
   */
  private managedCache: AttachmentCache<DownloadCacheEntry>;

  /**
   * A local cache of attachments that were requested "on demand"
   *
   * This mainly happens from files attached to running procedures.
   * These will get expired after a 1 day ttl.  This gets checked
   * for every 5 minutes.
   */
  private onDemandCache: AttachmentCache<DownloadCacheEntry>;
  private onDemandCleanTimer: NodeJS.Timer;

  private activeDownloads = new Map<string, Promise<Blob>>();

  private resetOnDemandCleanTimer(): void {
    if (this.onDemandCleanTimer) {
      clearInterval(this.onDemandCleanTimer);
    }
    this.onDemandCleanTimer = setInterval(() => {
      this.onDemandCache.deleteExpired();
    }, ON_DEMAND_CLEANUP_INTERVAL_MS);
  }

  constructor(teamId: string) {
    this.teamId = teamId;
    this.apiService = AttachmentsApi.getInstance(teamId);

    const prefix = `files_${teamId}`;
    this.uploadCache = new AttachmentCache(`${prefix}_upload`);
    this.managedCache = new AttachmentCache(`${prefix}_managed`);
    this.onDemandCache = new AttachmentCache(
      `${prefix}_on_demand`,
      ON_DEMAND_ENTRY_MAX_AGE_MS
    );
    this.resetOnDemandCleanTimer();
  }

  close(): void {
    clearInterval(this.onDemandCleanTimer);

    AttachmentService.removeInstance(this.teamId);
  }

  /**
   * Clears all local data.
   */
  async clear(): Promise<void> {
    await Promise.all([
      this.uploadCache.clear(),
      this.managedCache.clear(),
      this.onDemandCache.clear(),
    ]);

    // clear any legacy databases
    const legacyDatabasePrefixes = [
      `_pouch_attachments_${this.teamId}`,
      `attachments_${this.teamId}`,
    ];
    const dbs = await window.indexedDB.databases();
    for (const db of dbs) {
      for (const legacyDatabasePrefix of legacyDatabasePrefixes) {
        if (db.name?.startsWith(legacyDatabasePrefix)) {
          window.indexedDB.deleteDatabase(db.name);
        }
      }
    }
  }

  private static createAttachment(
    file: File,
    source: SourceType
  ): AttachmentMetadata {
    /**
     * The browser guesses file types with `type`. If the browser doesn't supply
     * a type, fall back to binary.
     */
    const contentType = file.type || 'application/octet-stream';

    return {
      _id: `attm_${idUtil.generateUuidEquivalentId()}`,
      source,
      name: file.name,
      content_type: contentType,
    };
  }

  /**
   * Add (upload) an attachment to the local or remote database.
   */
  private async addAttachment(
    attachment: AttachmentMetadata,
    file: File,
    options?: { remote: boolean }
  ): Promise<void> {
    const _options = options || { remote: true };
    const downloadEntry: DownloadCacheEntry = {
      id: attachment._id,
      data: file,
    };
    await this.managedCache.set(downloadEntry);

    const uploadEntry = createUploadCacheEntry(attachment, file);
    await this.uploadCache.set(uploadEntry);

    if (_options?.remote) {
      const entry = createUploadCacheEntry(attachment, file);
      await this.uploadCache.set(entry);
      await this.syncAttachment(attachment._id);
    }
  }

  /**
   * Syncs an attachment between the local or remote databases. Can be used
   * either way, to sync local to remote or remote to local.
   */
  async syncAttachment(attachmentId: string): Promise<void> {
    if (!attachmentId) {
      return Promise.reject('No attachment id.');
    }

    try {
      const uploadAttach = await this.uploadCache.get(attachmentId);
      if (uploadAttach) {
        await this.apiService.add(
          attachmentId,
          uploadAttach.name,
          uploadAttach.source,
          uploadAttach.data
        );
        await this.uploadCache.delete(attachmentId);
        return;
      }

      const attach = await this.managedCache.get(attachmentId);
      // if the attachment does not exist in the managed cache, fetch it from the backend
      if (!attach) {
        const attachment = await this.apiService.get(attachmentId);
        const entry: DownloadCacheEntry = {
          id: attachmentId,
          data: attachment,
        };
        await this.onDemandCache.set(entry);
        return Promise.resolve();
      }
    } catch (err) {
      apm.captureError(err);
    }
  }

  /**
   * Syncs multiple attachments.
   */
  async syncAllAttachments(
    attachmentIds: Array<string>
  ): Promise<Array<void | string>> {
    // No attachments to sync, just resolve
    if (attachmentIds.length < 1) {
      return Promise.resolve([]);
    }

    // Resolve only if all attachments are successfully uploaded
    const promises = attachmentIds.map((attachmentId) => {
      return this.syncAttachment(attachmentId);
    });
    return Promise.all(promises);
  }

  /**
   * Downloads a remote attachment for local offline use.
   *
   * This is intended to be called ahead of time to make attachments available for
   * offline use based on analaysis of the user's current working set of data.
   *
   * Can safely be called multiple times with the same attachment. Files that
   * are already downloaded will not be downloaded again.
   *
   * If this was downloaded on demand first, this will promote the attachment
   * to the managed local forage.
   */
  private async downloadAttachment(attachmentId: string): Promise<void> {
    const isDownloaded = this.managedCache.has(attachmentId);
    if (isDownloaded) {
      return;
    }

    const storeManagedAttachment: StorageHandler = async (entry) => {
      await this.managedCache.set(entry);
    };

    const onDemandEntry = await this.readFromOnDemandCache(attachmentId);
    if (onDemandEntry) {
      await this.moveOnDemandAttachment(
        attachmentId,
        onDemandEntry,
        storeManagedAttachment
      );
      return;
    }

    await this.coordinatedDownload(attachmentId, storeManagedAttachment);
  }

  /**
   * A faster, simpler version of {@link syncAllAttachments} for downloading remote
   * attachment docs to local storage.
   */
  async downloadAllAttachments(
    attachments: Array<{ attachment_id?: string }>
  ): Promise<BatchSyncResults> {
    const success: Array<{ id?: string }> = [];
    const errors: Array<{ id?: string; message: string }> = [];
    const promises = attachments.map((attachment) => {
      return attachment.attachment_id
        ? this.downloadAttachment(attachment.attachment_id)
        : undefined;
    });

    const results = await Promise.allSettled(promises);

    results.forEach((promise, index) => {
      const attachmentId = attachments[index].attachment_id;
      if (promise.status === 'fulfilled') {
        success.push({ id: attachmentId });
      } else {
        errors.push({
          id: attachmentId,
          message: promise.reason.response.statusText,
        });
      }
    });

    return {
      attachments: success,
      errors,
    };
  }

  /**
   * Store the given attachment in a new location using storageHandler
   * If storage handler is successful (doesn't throw) the attachment will be
   * removed from the onDemand cache.
   */
  private async moveOnDemandAttachment(
    id: string,
    entry: DownloadCacheEntry,
    storageHandler: StorageHandler
  ): Promise<void> {
    await storageHandler(entry);
    await this.onDemandCache.delete(id);
  }

  /**
   * Clears (removes) the attachment from the local database.
   */
  async clearAttachment(attachmentId: string): Promise<void> {
    if (this.managedCache.has(attachmentId)) {
      await this.managedCache.delete(attachmentId);
    }
    if (this.onDemandCache.has(attachmentId)) {
      await this.onDemandCache.delete(attachmentId);
    }
  }

  /**
   * Deletes the attachment from the remote database and clears it from the local database.
   */
  async deleteAttachment(attachmentId: string): Promise<void> {
    try {
      await this.apiService.delete(attachmentId);
      await this.clearAttachment(attachmentId);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Creates an attachment and uploads it to the local or remote database. The
   * options object supports a `remote` flag which is true by default.
   */
  async uploadFile(
    file: File,
    source: SourceType,
    options?: { remote: boolean }
  ): Promise<UploadFileResponse | undefined> {
    if (!file) {
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      throw new Error('File size limit exceeded');
    }
    const attachment = AttachmentService.createAttachment(file, source);
    await this.addAttachment(attachment, file, options);
    return {
      attachment_id: attachment._id,
      name: file.name,
      content_type: attachment?.content_type,
    };
  }

  /**
   * Attempts to download the attachment with the given `id`.  If there is
   * already a download happening for that attachment, all further attempts
   * on that id will be given a promise that will resolve (or reject) when
   * the original request finishes.
   *
   * Only the storage handler that originated the download will be called.
   * This means if a caller wants to override this behavior, it will need
   * to be considered at that point.
   */
  coordinatedDownload(
    id: string,
    storageHandler: StorageHandler
  ): Promise<Blob> {
    if (this.activeDownloads.has(id)) {
      const existingPromise = this.activeDownloads.get(id);
      if (existingPromise) {
        return existingPromise;
      }
    }
    const downloadPromise = this.apiService.get(id);
    this.activeDownloads.set(id, downloadPromise);

    const promise = new Promise<Blob>((resolve, reject) => {
      downloadPromise
        .then((attachment) => {
          const entry: DownloadCacheEntry = { id, data: attachment };
          storageHandler(entry)
            .then(() => {
              resolve(attachment);
            })
            .catch(() => {
              // there was an error storing but we still have the data so we should still resolve
              resolve(attachment);
            });
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          // always remove from the active downloads
          this.activeDownloads.delete(id);
        });
    });

    return promise;
  }

  /**
   * Gets an attachment Blob object from the local or remote database.
   * This should be called when the attachment is needed on-demand.
   *
   * Checks local database first, falling back to remote database.
   */
  async getAttachment(id: string): Promise<AttachmentResponse> {
    const existingEntry =
      (await this.readFromManagedAttachmentCache(id)) ||
      (await this.readFromOnDemandCache(id));

    if (existingEntry) {
      return {
        blob: existingEntry.data,
        id,
      };
    }

    const onDemandStorageHandler: StorageHandler = async (entry) => {
      await this.onDemandCache.set(entry);
    };

    const promise = new Promise<AttachmentResponse>((resolve, reject) => {
      // Metadata is not in the caches so we have to fetch it here
      Promise.resolve()
        .then(() => {
          // store any attachments fetched from here in onDemand cache
          this.coordinatedDownload(id, onDemandStorageHandler)
            .then((attachment) => {
              resolve({
                blob: attachment,
                id,
              });
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          // If offline and we have the blob in a cache, return just the blob without metadata
          if (existingEntry) {
            /*
             * It is also possible that the attachment was not saved remotely.
             * In that case, try to sync it here, but do not await it.
             */
            this.syncAttachment(id).catch((e) => {
              // Fail gracefully in the case of offline or other errors.
              apm.captureError(e);
            });
            resolve({ blob: existingEntry, id });
          } else {
            apm.captureError(error);
            reject(error);
          }
        });
    });
    return promise;
  }

  private async readFromManagedAttachmentCache(
    id: string
  ): Promise<DownloadCacheEntry | null> {
    try {
      const blob = await this.managedCache.get(id);
      if (blob) {
        return blob;
      }
    } catch {
      // Unexpected error accessing localforage local, try fallbacks.
    }
    return null;
  }

  private async readFromOnDemandCache(
    id: string
  ): Promise<DownloadCacheEntry | null> {
    try {
      const entry = await this.onDemandCache.get(id);
      if (entry) {
        return entry;
      }
    } catch {
      // Unexpected error accessing localforage local, try fallbacks.
    }
    return null;
  }
}

export default AttachmentService;
