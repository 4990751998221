import { useCallback, useEffect, useState } from 'react';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useSettings } from '../../contexts/SettingsContext';
import {
  Metadata,
  ReferenceType,
  Severity,
  Status,
  SubStatus,
} from 'shared/lib/types/postgres/issues';
import { mapServerIssueToClient } from '../api/issues';
import { Issue } from '../types';

interface IssueState {
  severities: Array<Severity>;
  statuses: Array<Status>;
  subStatuses: Array<SubStatus>;
  issues: Array<Issue>;
}

const useIssues = (referenceType?: ReferenceType): IssueState => {
  const [severities, setSeverities] = useState<Array<Severity>>([]);
  const [statuses, setStatuses] = useState<Array<Status>>([]);
  const [subStatuses, setSubStatuses] = useState<Array<SubStatus>>([]);
  const [issues, setIssues] = useState<Array<Issue>>([]);
  const { services } = useDatabaseServices();
  const { isIssuesEnabled } = useSettings();

  const refreshMetadata = useCallback(() => {
    return services.ncr
      .getMetadata()
      .then(
        ({
          severity: severities,
          status: statuses,
          sub_status: subStatuses,
        }: Metadata) => {
          setSeverities(severities);
          setStatuses(statuses);
          setSubStatuses(subStatuses);
        }
      )
      .catch(() => undefined);
  }, [services.ncr]);

  useEffect(() => {
    if (!isIssuesEnabled?.()) {
      return;
    }
    refreshMetadata();
  }, [isIssuesEnabled, refreshMetadata]);

  const refreshIssues = useCallback(() => {
    if (!isIssuesEnabled() || !referenceType) {
      return;
    }
    services.ncr.listOpenIssues(undefined, referenceType).then((result) => {
      setIssues(result.map((issue) => mapServerIssueToClient(issue)));
    });
  }, [isIssuesEnabled, services.ncr, referenceType]);

  useEffect(() => {
    if (!isIssuesEnabled?.()) {
      return;
    }
    refreshIssues();
  }, [isIssuesEnabled, refreshIssues]);

  return {
    severities,
    statuses,
    subStatuses,
    issues,
  };
};

export default useIssues;
