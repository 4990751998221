import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

export const BUTTON_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
} as const;

export type ButtonType = 'primary' | 'secondary' | 'tertiary';

export const BUTTON_SIZE_CLASSES = {
  xs: `text-xs`,
  sm: 'text-sm',
  base: 'text-sm',
  lg: 'text-base',
  xl: 'text-lg',
} as const;

export const BUTTON_PADDING_BY_SIZE = {
  xs: `px-1 py-0`,
  sm: 'px-1.5 py-0.5',
  base: 'px-2 py-2',
  lg: 'px-3 py-3',
  xl: 'px-4 py-4',
} as const;

/** For buttons that can't use {@link Button}, e.g. "submit" buttons, that still need the same styling. */
export const BUTTON_CLASS = ({
  type,
  size,
  removePadding = false,
  width,
  height,
  isTextBold = false,
}: {
  type: ButtonType;
  size: ButtonSize;
  removePadding?: boolean;
  width?: 'full';
  height?: 'full';
  isTextBold?: boolean;
}) =>
  `group items-center space-x-1 ${removePadding ? '' : BUTTON_PADDING_BY_SIZE[size]} ${
    BUTTON_SIZE_CLASSES[size]
  } rounded ${BUTTON_COLOR_CLASSES[type]} ${BUTTON_DISABLED_CLASSES[type]} ${width === 'full' ? 'w-full' : ''} ${
    height === 'full' ? 'h-full' : ''
  } ${isTextBold ? 'font-medium' : ''}`;

export type ButtonSize = keyof typeof BUTTON_SIZE_CLASSES;

const INLINE_STYLES = {
  sm: 'sm:inline hidden transition-all',
  md: 'md:inline hidden transition-all',
  lg: 'lg:inline hidden transition-all',
  xl: 'xl:inline hidden transition-all',
  '2xl': '2xl:inline hidden transition-all',
} as const;

export type InlineStyle = keyof typeof INLINE_STYLES;

const BUTTON_COLOR_CLASSES = {
  [BUTTON_TYPES.PRIMARY]:
    'whitespace-nowrap text-white bg-blue-500 border border-blue-500 hover:bg-blue-600 hover:border-blue-600',
  [BUTTON_TYPES.SECONDARY]:
    'whitespace-nowrap text-gray-700 bg-white border border-gray-300 hover:text-gray-900 hover:border-gray-400',
  [BUTTON_TYPES.TERTIARY]:
    'whitespace-nowrap text-gray-700 bg-transparent border border-transparent hover:text-gray-900',
};

const BUTTON_DISABLED_CLASSES = {
  [BUTTON_TYPES.PRIMARY]: 'disabled:text-gray-400 disabled:bg-gray-200 disabled:border-gray-200',
  [BUTTON_TYPES.SECONDARY]: 'disabled:text-gray-400 disabled:bg-gray-100 disabled:border-gray-100',
  [BUTTON_TYPES.TERTIARY]: 'disabled:text-gray-400 disabled:bg-gray-0 disabled:border-gray-0',
};

const ICON_COLOR_CLASSES = {
  [BUTTON_TYPES.PRIMARY]: '',
  [BUTTON_TYPES.SECONDARY]: 'text-blue-500',
  [BUTTON_TYPES.TERTIARY]: 'text-blue-500 group-hover:brightness-50',
};

const ICON_DISABLED_CLASSES = {
  [BUTTON_TYPES.PRIMARY]: '',
  [BUTTON_TYPES.SECONDARY]: 'text-gray-400',
  [BUTTON_TYPES.TERTIARY]: 'text-gray-400',
};

interface ButtonProps {
  ariaLabel?: string;
  SvgIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  leadingIcon?: IconProp;
  children?: React.ReactNode;
  trailingIcon?: IconProp;
  isDisabled?: boolean;
  showDisabledPointer?: boolean;
  iconTextColor?: string;
  onClick?: (e) => void;
  title?: string;
  type: ButtonType;
  /** If set, this button is rendered as a link. */
  to?: string | { pathname: string; search?: string };
  iconOnlyThreshold?: InlineStyle;
  width?: 'full';
  height?: 'full';
  size?: ButtonSize;
  removePadding?: boolean;
  buttonTypeAttribute?: 'button' | 'submit';
  isTextBold?: boolean;
}

const Button = ({
  ariaLabel,
  SvgIcon,
  leadingIcon,
  children,
  trailingIcon,
  iconTextColor,
  isDisabled,
  showDisabledPointer,
  onClick,
  title,
  type,
  to,
  iconOnlyThreshold,
  width,
  height,
  size = 'base',
  removePadding = false,
  isTextBold = false,
  buttonTypeAttribute = 'button', // Needed to make sure forms are not submitted by mistake.
}: ButtonProps) => {
  if (!Object.values(BUTTON_TYPES).includes(type)) {
    throw new Error('Invalid type');
  }

  const buttonClass = BUTTON_CLASS({
    type,
    size,
    removePadding,
    width,
    height,
    isTextBold,
  });
  const linkClass = `inline-block ${buttonClass}`;
  const iconClass = (pos: 'left' | 'right') =>
    `${children ? (pos === 'left' ? 'mr-1' : 'ml-1') : ''} ${
      isDisabled ? ICON_DISABLED_CLASSES[type] : ICON_COLOR_CLASSES[type]
    } ${isDisabled && showDisabledPointer ? 'cursor-not-allowed' : ''} fa-fw`;

  const ButtonContents = () => (
    <>
      {SvgIcon && <SvgIcon className="mx-auto w-[16px] group-hover:brightness-75 group-hover:w-[18px]" />}
      {leadingIcon && (
        <FontAwesomeIcon className={`${iconClass('left')} ${isDisabled ? '' : iconTextColor}`} icon={leadingIcon} />
      )}
      {children && iconOnlyThreshold ? (
        <span className={`${INLINE_STYLES[iconOnlyThreshold]}`}>{children}</span>
      ) : (
        children
      )}
      {trailingIcon && <FontAwesomeIcon className={`${iconClass('right')} ${iconTextColor}`} icon={trailingIcon} />}
    </>
  );

  if (to) {
    return (
      <Link className={linkClass} to={to} title={title} aria-label={ariaLabel} onClick={onClick}>
        <ButtonContents />
      </Link>
    );
  }
  return (
    <button
      className={buttonClass}
      disabled={isDisabled}
      onClick={onClick}
      title={title}
      aria-label={ariaLabel}
      type={buttonTypeAttribute}
    >
      <ButtonContents />
    </button>
  );
};

export default React.memo(Button);
