import { Event } from '../schedule/types/event';

export const createAdjList = (events: Event[]): { [key: string]: string[] } => {
  const adjList: { [key: string]: string[] } = {};

  for (const event of events) {
    if (event.predecessor_id) {
      if (!adjList[event.predecessor_id]) {
        adjList[event.predecessor_id] = [];
      }
      adjList[event.predecessor_id].push(event.id);
    }
  }

  return adjList;
};

export const hasCycle = (
  eventId: string,
  adjList: { [key: string]: string[] }
): boolean => {
  const stack: string[] = [eventId];
  const visited = new Set<string>();
  const recStack = new Set<string>();

  while (stack.length) {
    const node = stack[stack.length - 1];

    if (!visited.has(node)) {
      visited.add(node);
      recStack.add(node);
      const neighbors = adjList[node] || [];
      for (const neighbor of neighbors) {
        if (!visited.has(neighbor)) {
          stack.push(neighbor);
        } else if (recStack.has(neighbor)) {
          return true;
        }
      }
    } else {
      recStack.delete(node);
      stack.pop();
    }
  }

  return false;
};

export const filterPredecessors = (
  events: Event[],
  currentEventId: string
): Event[] => {
  const adjList = createAdjList(events);
  const availablePredecessors: Event[] = [];

  for (const event of events) {
    if (event.id !== currentEventId) {
      if (!adjList[event.id]) {
        adjList[event.id] = [];
      }
      adjList[event.id].push(currentEventId);

      if (!hasCycle(currentEventId, adjList)) {
        availablePredecessors.push(event);
      }

      adjList[event.id].pop();
    }
  }
  return availablePredecessors;
};

export const compareEvents = (e1: Event, e2: Event): number => {
  if (!e1.start && !e2.start) {
    return e1.name.localeCompare(e2.name);
  }
  // sort events without a start before events with a start
  if (!e1.start && e2.start) {
    return -1;
  }
  if (e1.start && !e2.start) {
    return 1;
  }
  if (e1.start && e2.start) {
    return e1.start < e2.start ? -1 : e1.start > e2.start ? 1 : 0;
  }
  // this can never happen but the compiler doesn't recognize this
  return 0;
};
