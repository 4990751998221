import { useMemo } from 'react';
import SelectDisplay from './SelectDisplay';
import { useSettings } from '../../../contexts/SettingsContext';
import { FieldInputSettingsListBlock } from 'shared/lib/types/views/procedures';

interface SettingsListDisplayProps {
  block: FieldInputSettingsListBlock;
  referenceId?: string;
}

const SettingsListDisplay = ({ block, referenceId }: SettingsListDisplayProps) => {
  const { config } = useSettings();

  const values = useMemo(
    () => config?.lists && config.lists[block.list] && config.lists[block.list].options,
    [block.list, config?.lists]
  );

  return <SelectDisplay block={block} values={values} referenceId={referenceId} />;
};

export default SettingsListDisplay;
