import React, { useMemo } from 'react';
import { Field } from 'formik';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import procedureUtil from '../lib/procedureUtil';
import { reactSelectStyles } from '../lib/styles';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useSettings } from '../contexts/SettingsContext';
import { selectProceduresLoading, selectProceduresNoDraftsForReleased } from '../contexts/proceduresSlice';

export const CONTENT_TYPE_PROCEDURE_LINK = 'procedure_link';
export const INITIAL_CONTENT_PROCEDURE_LINK = {
  type: CONTENT_TYPE_PROCEDURE_LINK,
  procedure: '',
  section: '',
};

const FieldSetProcedureLink = ({
  content,
  contentIndex,
  contentErrors,
  path,
  setFieldValue,
  excludedProcedureIds = [],
  showLabels = true,
  isDisabled = false,
  isClearable = false,
  filterUnreleased = false,
}) => {
  const fieldName = `${path ? path : 'content'}[${contentIndex}]`;
  const { getSetting } = useSettings();
  const { currentTeamId } = useDatabaseServices();
  const isProceduresLoading = useSelector((state) => selectProceduresLoading(state, currentTeamId));
  const proceduresObject = useSelector((state) => selectProceduresNoDraftsForReleased(state, currentTeamId));
  const procedures = Object.values(proceduresObject).filter(
    (proc) => (!filterUnreleased || proc.state === 'released') && !proc.archived
  );

  const procedure = useMemo(() => {
    const id = content.procedure;
    return (procedures || []).find((p) => p._id === id);
  }, [content, procedures]);

  const sections = useMemo(() => procedure && procedure.sections, [procedure]);

  const onChangeProcedure = (option) => {
    setFieldValue(fieldName, {
      ...INITIAL_CONTENT_PROCEDURE_LINK,
      id: content.id,
      procedure: option?.value,
      section: '',
    });
  };

  const onChangeSection = (option) => {
    setFieldValue(`${fieldName}.section`, option.value);
  };

  const linkedProcedureOptions = useMemo(() => {
    if (!procedures) {
      return [];
    }
    return procedures
      .filter((procedure) => !excludedProcedureIds.includes(procedure._id))
      .map((procedure) => ({
        value: procedure._id,
        label: `${procedureUtil.getProcedureTitle(procedure.code, procedure.name)}`,
      }));
  }, [procedures, excludedProcedureIds]);

  const linkedProcedureSelected = useMemo(() => {
    return linkedProcedureOptions.find((option) => option.value === content.procedure);
  }, [linkedProcedureOptions, content]);

  const linkedSectionOptions = useMemo(() => {
    if (!sections) {
      return [];
    }
    const allSectionsOption = {
      value: '',
      label: 'All Sections',
    };

    const sectionOptions = sections.map((section, index) => ({
      value: section.id,
      label: `Section ${procedureUtil.displaySectionKey(index, getSetting('display_sections_as', 'letters'))}: ${
        section.name
      }`,
    }));
    return [allSectionsOption].concat(sectionOptions);
  }, [sections, getSetting]);

  const linkedSectionSelected = useMemo(() => {
    return linkedSectionOptions.find((option) => option.value === content.section);
  }, [linkedSectionOptions, content]);

  return (
    <fieldset disabled={isProceduresLoading ? true : undefined}>
      <div className="flex flex-wrap items-start">
        <div className="flex flex-col mr-2">
          {showLabels && <span className="field-title">Procedure</span>}
          <Field name={`${fieldName}.procedure`}>
            {() => (
              <Select
                key={linkedProcedureSelected}
                value={linkedProcedureSelected}
                aria-label="Select Procedure"
                placeholder="Select Procedure"
                classNamePrefix="react-select"
                className="w-96 max-w-prose text-sm border-1 border-gray-400 rounded"
                onChange={onChangeProcedure}
                options={linkedProcedureOptions}
                styles={reactSelectStyles}
                isDisabled={isDisabled}
                isClearable={isClearable}
              />
            )}
          </Field>
          {contentErrors && contentErrors.procedure && <div className="text-red-700">{contentErrors.procedure}</div>}
        </div>
        <div className="flex flex-col mr-2">
          {showLabels && <span className="field-title">Section</span>}
          <Field name={`${fieldName}.section`}>
            {() => (
              <Select
                key={linkedSectionSelected}
                value={linkedSectionSelected}
                placeholder="Select Section"
                disabled={!sections}
                classNamePrefix="react-select"
                className="w-48 max-w-prose text-sm border-1 border-gray-400 rounded disabled:bg-gray-100"
                onChange={onChangeSection}
                options={linkedSectionOptions}
                styles={reactSelectStyles}
                isDisabled={isDisabled}
              />
            )}
          </Field>
        </div>
      </div>
    </fieldset>
  );
};

export default React.memo(FieldSetProcedureLink);
