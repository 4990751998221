import { ExpressionTokenDiffElement } from 'shared/lib/types/views/procedures';
import React, { useMemo } from 'react';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import ReviewReferenceToken from './Blocks/ReviewReferenceToken';
import { isEqual, mapValues } from 'lodash';
import { DIFF_COLOR_CLASSES } from '../../lib/diffUtil';

interface ReviewReferenceDetailProps {
  tokens: Array<ExpressionTokenDiffElement>;
}

const ReviewReferenceDetail = ({ tokens }: ReviewReferenceDetailProps) => {
  const oldTokens = useMemo(() => {
    return tokens.flatMap((token) => {
      if (
        !token.diff_change_state ||
        token.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED ||
        token.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED
      ) {
        return token;
      }
      if (token.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED) {
        return {
          ...mapValues(token, (value) => sharedDiffUtil.getDiffValue({ value }, 'value', 'old')),
          diff_change_state: ARRAY_CHANGE_SYMBOLS.REMOVED,
          reference_id: sharedDiffUtil.getDiffValue(token, 'reference_id', 'old'),
        } as ExpressionTokenDiffElement;
      }
      return [];
    });
  }, [tokens]);

  const newTokens = useMemo(() => {
    return tokens.flatMap((token) => {
      if (
        !token.diff_change_state ||
        token.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED ||
        token.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED
      ) {
        return token;
      }
      if (token.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED) {
        return {
          ...mapValues(token, (value) => sharedDiffUtil.getDiffValue({ value }, 'value', 'new')),
          diff_change_state: ARRAY_CHANGE_SYMBOLS.ADDED,
          reference_id: sharedDiffUtil.getDiffValue(token, 'reference_id', 'new'),
        } as ExpressionTokenDiffElement;
      }
      return [];
    });
  }, [tokens]);

  const hasChanged = useMemo(() => !isEqual(oldTokens, newTokens), [newTokens, oldTokens]);

  return (
    <div className="flex flex-col gap-y-2 ">
      {newTokens && newTokens.length > 0 && (
        <div
          aria-label={hasChanged ? 'New Expression' : 'Expression'}
          className={`max-w-max break-all text-sm flex flex-row items-center flex-wrap ${
            hasChanged
              ? `py-1 px-2 rounded ${DIFF_COLOR_CLASSES[ARRAY_CHANGE_SYMBOLS.ADDED].backgroundColor} ${
                  DIFF_COLOR_CLASSES[ARRAY_CHANGE_SYMBOLS.ADDED].borderColor
                }`
              : ''
          }`}
        >
          {newTokens.map((token, index) => {
            return token.type === 'reference' && token.reference_id ? (
              <ReviewReferenceToken
                key={index}
                originalReferencedContentId={token.reference_id}
                diffChangeState={token.diff_change_state}
              />
            ) : (
              <div key={index} className="py-1 mx-0.5 text-sm">
                {token.value}
              </div>
            );
          })}
        </div>
      )}
      {hasChanged && oldTokens && oldTokens.length > 0 && (
        <div
          aria-label="Old Expression"
          className={`max-w-max py-1 px-2 break-all text-sm flex flex-row items-center flex-wrap rounded ${
            DIFF_COLOR_CLASSES[ARRAY_CHANGE_SYMBOLS.REMOVED].backgroundColor
          } ${DIFF_COLOR_CLASSES[ARRAY_CHANGE_SYMBOLS.REMOVED].borderColor}`}
        >
          {oldTokens.map((token, index) => {
            return token.type === 'reference' && token.reference_id ? (
              <ReviewReferenceToken
                key={index}
                originalReferencedContentId={token.reference_id}
                diffChangeState={token.diff_change_state}
              />
            ) : (
              <div key={index} className="py-1 mx-0.5 text-sm">
                {token.value}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ReviewReferenceDetail;
