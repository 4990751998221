import React, { ReactNode } from 'react';
import { MARKDOWN_MARGIN_CLASS } from './MarkdownView';

const codeClassName = 'bg-gray-200 font-mono text-sm py-1 px-2 rounded whitespace-pre-wrap';

interface MarkdownCodeWrapperProps {
  isInline?: boolean;
  children: ReactNode;
}

/**
 * For inline code we render a code tag,
 * but for non-inline/blocks,
 * we wrap the code tag in a div to give it block styling.
 *
 * code tags work like spans, with inline styles.
 */
const MarkdownCodeWrapper = React.memo(({ isInline = false, children }: MarkdownCodeWrapperProps) => {
  if (isInline) {
    return <code className={codeClassName}>{children}</code>;
  }

  return (
    <div className={`${codeClassName} ${MARKDOWN_MARGIN_CLASS}`}>
      <code>{children}</code>
    </div>
  );
});

export default MarkdownCodeWrapper;
