import React, { useState, useMemo, useCallback, useRef, useEffect, MouseEvent } from 'react';
import { Field, getIn } from 'formik';
import validateUtil from '../../lib/validateUtil';
import TextAreaAutoHeight from '../TextAreaAutoHeight';
import { ExpressionToken, ReleaseStepBlock, RepeatedSection } from 'shared/lib/types/views/procedures';
import { TypedFieldSetProps } from '../Blocks/BlockTypes';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import { useSettings } from '../../contexts/SettingsContext';
import procedureUtil from '../../lib/procedureUtil';
import ReferenceSelectMenu from './ReferenceSelectMenu';
import FieldSetCheckbox from '../FieldSetCheckbox';
import expression, { NAMESPACE_DELIMITER, ReferenceOption } from '../../lib/expression';

const ExpressionEditPlainText = ({
  setFieldValue,
  path,
  content,
  contentErrors,
  disabledFields,
  pendingStep,
  precedingStepId,
}: TypedFieldSetProps<'expression'>) => {
  const { getAllSections, getAllVariables } = useProcedureContext();
  const { config } = useSettings();

  const [tokens, setTokens] = useState(content.tokens);
  const [menuState, setMenuState] = useState({ isMenuOpen: false });
  const [parseError, setParseError] = useState(expression.validate(content.tokens));
  const [isCorrecting, setIsCorrecting] = useState(false);
  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const [initialTokens, setInitialTokens] = useState(content.tokens);

  const displaySecionAs = (config && config.display_sections_as) || 'letters';
  const menuRef = useRef(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const selectionStartRef = useRef<number | null>(null);
  const selectionEndRef = useRef<number | null>(null);
  const referenceOptionsHaveChanged = useRef(false);

  const isSummaryHidden = useMemo(() => getIn(disabledFields, 'include_in_summary'), [disabledFields]);

  const referenceOptions = useMemo<ReferenceOption[]>(() => {
    referenceOptionsHaveChanged.current = true;
    const options: ReferenceOption[] = [];

    getAllVariables().forEach((variable) => {
      if (variable.type === 'input' && variable.inputType === 'number') {
        options.push({
          ...variable,
          referenceLabel: 'Variable',
          textToSearch: `Variable${NAMESPACE_DELIMITER}${variable.name}`,
        });
      }
    });

    getAllSections().forEach((section, sectionIndex: number) => {
      if (!(section as RepeatedSection).repeat_of) {
        section.steps.forEach((step, stepIndex: number) => {
          if (!step.repeat_of) {
            step.content.forEach((block: ReleaseStepBlock) => {
              if (block.id === content.id) {
                // Cannot have self-referential expressions.
                return;
              }
              const id = procedureUtil.displaySectionStepKey(sectionIndex, stepIndex, displaySecionAs);
              if ((block.type === 'input' && block.inputType === 'number') || block.type === 'expression') {
                options.push({
                  // need to strip colons from block.name to not collide with namespacing
                  id: block.id,
                  name: block.name,
                  referenceLabel: id,
                  textToSearch: `${id}${NAMESPACE_DELIMITER}${block.name}`,
                });
              }
            });
            if (pendingStep && step.id === precedingStepId) {
              pendingStep.content.forEach((block) => {
                if (block.id === content.id) {
                  // Cannot have self-referential expressions.
                  return;
                }
                if ((block.type === 'input' && block.inputType === 'number') || block.type === 'expression') {
                  options.push({
                    // need to strip colons from block.name to not collide with namespacing
                    id: content.id,
                    name: block.name,
                    referenceLabel: '--',
                    textToSearch: `--${NAMESPACE_DELIMITER}${block.name}`,
                  });
                }
              });
            }
          }
        });
      }
    });
    return options;
  }, [displaySecionAs, getAllSections, pendingStep, precedingStepId, getAllVariables, content.id]);

  const updateField = useCallback(
    (setField = true) => {
      if (inputRef && inputRef.current) {
        const updatedTokens = expression.textToTokens(inputRef.current.value, referenceOptions);
        let parseError: string;
        setTokens(updatedTokens);
        if (setField) {
          parseError = expression.validate(updatedTokens);
          setFieldValue && setFieldValue(`${path}.tokens`, updatedTokens);
          setParseError(parseError);
          setIsCorrecting(parseError !== '');
        } else if (isCorrecting) {
          setParseError(expression.validate(updatedTokens));
        }
      }
    },
    [isCorrecting, path, referenceOptions, setFieldValue]
  );

  const closeMenuHandler = useCallback(() => {
    menuRef.current = false;
    setMenuState(() => {
      return { isMenuOpen: false };
    });
  }, []);

  const documentClickHandler = useCallback(() => {
    document.removeEventListener('click', documentClickHandler);
    if (menuRef.current) {
      closeMenuHandler();
      updateField(true);
    }
  }, [closeMenuHandler, updateField]);

  const openMenuHandler = useCallback(
    (fromAutocomplete = false) => {
      if (inputRef && inputRef.current) {
        selectionStartRef.current = inputRef.current.selectionStart;
        selectionEndRef.current = inputRef.current.selectionEnd;
      }
      menuRef.current = true;
      setIsAutocomplete(fromAutocomplete);
      setMenuState(() => {
        document.addEventListener('click', documentClickHandler);
        return { isMenuOpen: true };
      });
    },
    [documentClickHandler]
  );

  const handleAddReferenceClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    openMenuHandler();
  };

  const handleBlur = () => {
    // Interacting with the reference menu with the mouse should not trigger a field update
    if (!menuState.isMenuOpen) {
      updateField(true);
    }
  };

  const onSelectReference = useCallback(
    (reference: ReferenceOption) => {
      if (inputRef && inputRef.current) {
        let textValue = inputRef.current.value;
        if (isAutocomplete && textValue.includes('{')) {
          textValue = textValue.substring(0, textValue.lastIndexOf('{'));
        }
        const referenceName = expression.getNamespacedReference(reference.name, reference.id, referenceOptions);

        if (selectionStartRef.current !== null && selectionEndRef.current !== null) {
          const start = selectionStartRef.current;
          const end = selectionEndRef.current;
          inputRef.current.value = `${textValue.slice(0, start)}${referenceName}${textValue.slice(end)}`;
          inputRef.current.setSelectionRange(start + referenceName.length, start + referenceName.length);
        }

        inputRef.current.focus();
        updateField(false);
      }

      closeMenuHandler();
    },
    [closeMenuHandler, isAutocomplete, referenceOptions, updateField]
  );

  /*
   * If the steps are reordered, the step labels change and need to be updated
   * in the formula text, but we don't want to do this whenever the tokens change
   * or it ends up taking over the input box and disallowing free-typing.
   * So here I use a dirty flag only on reference options to limit the updates
   */
  useEffect(() => {
    if (referenceOptionsHaveChanged.current && inputRef && inputRef.current) {
      inputRef.current.value = expression.tokensToText(tokens, referenceOptions);
      referenceOptionsHaveChanged.current = false;
    }
  }, [referenceOptions, referenceOptionsHaveChanged, tokens]);

  /*
   * Initial tokens are stored in order to compare against incoming tokens
   * in props from the parent.  This happens in undo, redo.  Only update tokens
   * when this happens to prevent fighting with the user input in the input field
   */
  useEffect(() => {
    if (initialTokens !== content.tokens) {
      setInitialTokens(content.tokens);
      setTokens(content.tokens);
      if (inputRef && inputRef.current) {
        inputRef.current.value = expression.tokensToText(content.tokens, referenceOptions);
      }
    }
  }, [content.tokens, initialTokens, referenceOptions]);

  /*
   * If the referenced fields change their names, update the token values to match
   * and update the input text as well.  Don't try to update unaltered tokens
   */
  useEffect(() => {
    let referencesHaveChanged = false;
    const updatedTokens = [...tokens];
    updatedTokens.forEach((token, index) => {
      if (token.type === 'reference' && token.reference_id) {
        const match = referenceOptions.find((item) => item.id === token.reference_id);
        if (!match) {
          token.value = `{${token.value}}`;
          token.type = 'text';
          token.reference_id = undefined;
          referencesHaveChanged = true;
        } else {
          if (token.value !== match.name) {
            token.value = match.name;
            referencesHaveChanged = true;
          }
        }
      }
    });

    if (referencesHaveChanged) {
      if (inputRef && inputRef.current) {
        inputRef.current.value = expression.tokensToText(updatedTokens, referenceOptions);
      }
      setTokens(updatedTokens);
      setParseError(expression.validate(updatedTokens));
    }
  }, [content, referenceOptions, tokens]);

  const DisplayToken = ({ token }: { token: ExpressionToken }) => {
    if (token.type === 'reference' && token.reference_id) {
      return (
        <div className="font-medium text-xs px-1.5 py-0.5 my-1 rounded-xl bg-slate-300">
          {expression.getNamespacedTokenText(token.value, token.reference_id, referenceOptions)}
        </div>
      );
    } else {
      return <div className="py-1 mx-0.5 text-sm">{token.value}</div>;
    }
  };

  const Preview = () => {
    return (
      <div className="flex flex-col">
        <div>
          <div data-testid="formula-preview" className="flex flex-row items-center flex-wrap">
            {tokens && tokens.length > 0 ? (
              tokens.map((token, index) => <DisplayToken key={index} token={token} />)
            ) : (
              <div className="text-sm h-7"></div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const ErrorDisplay = () => {
    let errorMessage = '';
    let errorDetail = '';
    if (contentErrors) {
      if (contentErrors.formula) {
        errorMessage = contentErrors.formula;
      } else if (contentErrors.parse) {
        errorMessage = 'Expression Error';
        errorDetail = contentErrors.parse;
      } else if (contentErrors.cyclic) {
        errorMessage = contentErrors.cyclic;
      }
    } else if (parseError) {
      errorMessage = 'Expression Error';
      errorDetail = parseError;
    }
    if (errorMessage) {
      return (
        <span title={errorDetail} className="ml-2 text-xs font-medium text-red-700">
          {errorMessage}
        </span>
      );
    }
    return <></>;
  };

  return (
    <div className="flex flex-wrap w-full">
      <div className="flex flex-col mr-2">
        <div>
          <span className="field-title">Expression</span>
        </div>
        <Field name={path ? `${path}.name` : 'name'} validate={validateUtil.validateFieldInputName}>
          {({ field }) => (
            <>
              <TextAreaAutoHeight
                aria-label="Expression name"
                placeholder="Expression name*"
                disabled={getIn(disabledFields, 'name') ? true : null}
                {...field}
                style={{ minWidth: '20rem' }}
              />
              {contentErrors && contentErrors.name && <div className="text-red-700">{contentErrors.name}</div>}
            </>
          )}
        </Field>
      </div>

      <div className="flex grow flex-col">
        <div>
          <span className="field-title">Formula</span>
          <ErrorDisplay />
        </div>
        <div className="flex-row">
          <div
            data-testid="formula-builder-field"
            className="flex flex-row bg-white border border-gray-400 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600 rounded p-0.5 w-full items-center relative"
          >
            <div>
              {menuState.isMenuOpen && (
                <ReferenceSelectMenu
                  onSelect={onSelectReference}
                  close={closeMenuHandler}
                  menuOptions={referenceOptions}
                />
              )}
            </div>
            <input
              aria-label="Formula"
              ref={inputRef}
              className="outline-none w-full py-1 px-2 text-sm"
              placeholder="Formula*"
              onChange={() => {
                updateField(false);
              }}
              onBlur={handleBlur}
              defaultValue={expression.tokensToText(content.tokens, referenceOptions)}
            />
            <div>
              <button
                type="button"
                className="bg-blue-500 text-white text-lg font-semibold tracking-wide uppercase whitespace-nowrap px-2 py-0.5 rounded"
                onClick={handleAddReferenceClick}
                title="Insert reference"
              >
                +
              </button>
            </div>
          </div>
          <Preview />
        </div>
      </div>
      {/* Include in summary checkbox */}
      <div className={`ml-2 mb-9 self-end flex flex-row ${isSummaryHidden ? 'hidden' : ''}`}>
        <FieldSetCheckbox
          text="Include in Summary"
          fieldName={path ? `${path}.include_in_summary` : 'include_in_summary'}
          setFieldValue={setFieldValue}
        />
      </div>
    </div>
  );
};

export default React.memo(ExpressionEditPlainText);
