import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FormStepComment from './FormStepComment';
import { useMixpanel } from '../contexts/MixpanelContext';
import CommentsList from './CommentsList';
import { RunAddedStep } from 'shared/lib/types/views/procedures';
import TooltipOverlay from './TooltipOverlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/*
 * Component for rendering step comment form and existing comments.
 *
 * isRedlineFeatureEnabled: Feature flag to enable showing "Suggest Edits"
 *                          (redline) button. This should be enabled with
 *                          new run documents that have `procedureRev` property.
 * isRedlineActive: True if redlining is currently "on/active".
 * isRedlineEnabled: True if "Suggest Edits" (redline) button is enabled,
 *                   otherwise false if disabled.
 * onToggleRedline: Click handler for toggling redlining between "on/active" and
 *                  "off/inactive".
 */

interface StepCommentingProps {
  redlineComments;
  isRedlineActive: boolean;
  isEditing: boolean;
  reasonRedlineIsDisabled: string | null;
  onToggleRedline: () => void;
  onSaveRedlineStepComment: (comment) => Promise<void>;
  onEditRedlineStepComment: (comment, id) => void;
  onDirtyRedlineCommentChanged: () => void;
  onRefChanged: (ref) => void;
  step?: RunAddedStep;
}

const StepCommenting = ({
  redlineComments,
  isRedlineActive,
  isEditing,
  reasonRedlineIsDisabled,
  onToggleRedline,
  onSaveRedlineStepComment,
  onEditRedlineStepComment,
  onDirtyRedlineCommentChanged,
  onRefChanged,
  step,
}: StepCommentingProps) => {
  const [formVisible] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const isMounted = useRef(true);
  const { mixpanel } = useMixpanel();

  const runOnly = useMemo(() => step?.run_only, [step?.run_only]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const mixpanelTrack = useCallback(
    (name) => {
      if (mixpanel && name) {
        mixpanel.track(name);
      }
    },
    [mixpanel]
  );

  const trimLeadingAndTrailing = (str) => str.replace(/(^\s+|\s+$)/g, '');

  useEffect(() => {
    if (formVisible && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [formVisible, textAreaRef]);

  const saveRedlineStepComment = (values, { resetForm, setSubmitting }) => {
    const trimmed = trimLeadingAndTrailing(values.comment);
    if (!trimmed || typeof onSaveRedlineStepComment !== 'function') {
      setSubmitting(false);
      return;
    }
    return onSaveRedlineStepComment(trimmed).then(() => {
      mixpanelTrack('Suggested Edit Comment Saved');
      resetForm();
    });
  };

  return (
    <div className="mr-8 flex flex-row page-break mb-2">
      {/* Left spacing to align with content. */}
      <div className="w-4 shrink-0"></div>
      <div className="flex flex-col grow">
        {isRedlineActive && (
          <>
            {redlineComments && (
              <CommentsList comments={redlineComments} onRefChanged={onRefChanged} onEdit={onEditRedlineStepComment} />
            )}
            {!isEditing && !runOnly && (
              <>
                <FormStepComment
                  onSubmit={saveRedlineStepComment}
                  onDirtyChanged={onDirtyRedlineCommentChanged}
                  placeholder="Comment on suggested edits"
                  mentionEnabled={false}
                  autoFocus={false}
                />
              </>
            )}

            <div className="flex gap-x-1">
              <TooltipOverlay
                content={<span>{reasonRedlineIsDisabled}</span>}
                visible={Boolean(reasonRedlineIsDisabled)}
              >
                <button
                  className="btn-link px-3 py-2 my-1.5 text-sm font-bold rounded hover:bg-gray-200 disabled:bg-transparent disabled:text-gray-300 disabled:cursor-default"
                  onClick={onToggleRedline}
                >
                  <FontAwesomeIcon icon="pencil-alt" />
                  Close Edits
                </button>
              </TooltipOverlay>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(StepCommenting);
