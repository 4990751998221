import React from 'react';
import { Event } from 'shared/schedule/types/event';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface EventBarProps {
  event: Event;
}

const FixedBar = ({ event }: EventBarProps) => {
  return (
    <div className="flex px-2 text-sky-600 h-full items-center -ml-2" data-testid={`${event.id}-marker`}>
      <div className="h-full border border-sky-600" />
      <FontAwesomeIcon className="-ml-[9px]" icon="diamond" />
    </div>
  );
};

export default FixedBar;
