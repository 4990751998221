import React, { useMemo, forwardRef } from 'react';
import { useGanttContext } from '../../../components/Gantt/GanttContext';
import { Event } from 'shared/schedule/types/event';
import swimlaneLib, { CollapseLevels } from '../../../lib/swimlane';
import { Duration } from 'luxon';
import { INSTANTANEOUS_EVENT_PADDING } from '../../../config';
import Row from './Row';
import { compareEvents } from 'shared/lib/scheduleUtil';
import MultiEvent from '../../../lib/MultiEvent';
import Milestone from '../Milestone';

interface SwimlaneProps {
  bgColorClass: string;
  events: Array<Event>;
  collapseLevel: number;
  expandSwimlane: () => void;
}

const Swimlane = forwardRef<HTMLDivElement, SwimlaneProps>(
  ({ bgColorClass, events, collapseLevel, expandSwimlane }, ref) => {
    const { ganttWidth, timeScale } = useGanttContext();
    const instantaneousEventPadding = Duration.fromObject({ seconds: INSTANTANEOUS_EVENT_PADDING / timeScale });

    const [rows, milestones] = useMemo(() => {
      const milestones = events.filter((e) => e.milestone);
      const orderedNonMilestones = events.filter((e) => !e.milestone).sort(compareEvents);

      let rows: Array<Array<Event | MultiEvent>>;
      switch (collapseLevel) {
        case CollapseLevels.Collapsed:
          rows = [swimlaneLib.collapseSortedEvents(orderedNonMilestones, instantaneousEventPadding)];
          break;
        case CollapseLevels.Expanded:
          rows = swimlaneLib.sortedEventsToMinRows(orderedNonMilestones, instantaneousEventPadding);
          break;
        default:
          rows = orderedNonMilestones.map((event) => [event]);
      }
      return [rows, milestones];
    }, [collapseLevel, events, instantaneousEventPadding]);

    return (
      <div ref={ref} className={`relative overflow-hidden ${bgColorClass}`} style={{ width: `${ganttWidth}px` }}>
        <div className="py-4 space-y-4">
          {rows.map((events, idx) => (
            <Row key={idx} events={events} expandSwimlane={expandSwimlane} />
          ))}
        </div>
        {milestones.map((event, idx) => (
          <Milestone key={idx} event={event} />
        ))}
      </div>
    );
  }
);

export default Swimlane;
