import React, { useEffect, useState } from 'react';
import { Event } from 'shared/schedule/types/event';
import { useGanttContext } from '../../../components/Gantt/GanttContext';
import { DateTime, Interval } from 'luxon';
import { REFRESH_INTERVAL } from '../../../config';
import FixedTime from './FixedTime';

const OngoingBar = ({ event }: { event: Event }) => {
  const [currentTime, setCurrentTime] = useState(DateTime.utc({ locale: 'utc' }));
  const { intervalWidth } = useGanttContext();

  // Initialize current time clock
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(DateTime.utc({ locale: 'utc' }));
    }, REFRESH_INTERVAL);

    return () => clearInterval(intervalId);
  }, [setCurrentTime]);

  // Render instantaneous event if start time has not passed
  if (event.start && currentTime < event.start) {
    return <FixedTime event={event} />;
  }

  const width = intervalWidth(Interval.fromDateTimes(event.start, currentTime));
  return (
    <div
      className="flex items-center shadow-lg h-full pl-2 rounded bg-blue-500/30 hover:bg-blue-600/30 ring-1"
      style={{ width: `${width}px` }}
    >
      <span className="line-clamp-2">{event.name}</span>
    </div>
  );
};

export default OngoingBar;
