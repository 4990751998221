import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelectionContext, SelectionReturnValue } from '../../contexts/Selection';
import { ContentBlock, Header, Section, SectionHeader, Step, StepHeader } from 'shared/lib/types/views/procedures';

type SelectableReturn = {
  isSelected: boolean;
  styles: string;
};

export enum Boundary {
  Header = 0,
  Section = 10,
  SectionHeader = 20,
  Step = 30,
  StepHeader = 40,
  ContentBlock = 50,
}

type SelectableProps = {
  block: Section | Step | ContentBlock | Header | SectionHeader | StepHeader;
  boundary: Boundary;
  onKeyboard?: (event: React.KeyboardEvent) => void;
  children(params: SelectableReturn): React.ReactElement;
};

const Selectable = ({ block, boundary, onKeyboard, children }: SelectableProps) => {
  const {
    isSelected: isSelectedHelper,
    addSelection,
    removeSelection,
    onSelectionChanged,
  }: SelectionReturnValue = useSelectionContext();
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const selectable = useMemo(() => {
    return {
      id: block.id,
      type: boundary,
      context: block,
    };
  }, [block, boundary]);

  const divId = useMemo(() => `selectable-${block.id}`, [block.id]);

  const updateIsSelected = useCallback(() => {
    if (isSelectedHelper(selectable)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [isSelectedHelper, selectable]);

  useEffect(() => {
    const unsubscribeSelection = onSelectionChanged(updateIsSelected);
    return () => {
      unsubscribeSelection();
    };
  }, [onSelectionChanged, updateIsSelected]);

  const setSelection = useCallback(
    (e) => {
      /*
       * We want the event to bubble all the way up to the document
       * but we don't want each parent selectable to select itself
       * only add the selection for the closest selectable from the original target
       * Do not deselect the region when clicking on the Add Content button (prevents transparent content menu)
       */

      if (e.target.closest('div[aria-label="Annotation Editor"]')) {
        return;
      }

      if (e.target.closest('.selectable-block')?.id === divId) {
        addSelection(selectable);
      }
    },
    [addSelection, divId, selectable]
  );

  const getSelectedStyles = (selectableType) => {
    if (selectableType === Boundary.ContentBlock) {
      return 'rounded bg-blue-100';
    }
    return 'rounded border border-blue-400';
  };

  const getUnselectedStyles = (selectableType) => {
    if (selectableType === Boundary.ContentBlock) {
      return 'group-hover/content:rounded group-hover/content:bg-gray-600/10';
    }
    if (selectableType === Boundary.Step) {
      return 'border border-transparent group-hover/step:rounded group-hover/step:border-gray-400';
    }
    if (selectableType === Boundary.StepHeader) {
      return 'border border-transparent hover:rounded hover:bg-transparent';
    }
    if (selectableType === Boundary.Section) {
      return 'border border-transparent group-hover/section:rounded group-hover/section:bg-gray-100';
    }
    if (selectableType === Boundary.Header) {
      return 'border border-transparent hover:rounded hover:bg-gray-100';
    }
    if (selectableType === Boundary.SectionHeader) {
      return 'border border-transparent hover:rounded hover:bg-gray-200';
    }
    return ''; // should not get here
  };

  const styles = useMemo(() => {
    if (isSelected) {
      return getSelectedStyles(selectable.type);
    }
    return getUnselectedStyles(selectable.type);
  }, [isSelected, selectable.type]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // This fires ALL THE TIME - need to isolate and not react when it's bubbling up
    if ((event.target as HTMLDivElement).id !== divId) {
      return;
    }
    if (event.code === 'Escape') {
      removeSelection(selectable);
      return;
    }
    if (onKeyboard && isSelected) {
      onKeyboard(event);
    }
  };

  return (
    <div id={divId} className="outline-none selectable-block" tabIndex={0} onClick={setSelection} onKeyDown={onKeyDown}>
      {children({
        isSelected,
        styles,
      })}
    </div>
  );
};

export default React.memo(Selectable);
