import {
  RunTableInputBlock,
  RunTableInputRecorded,
  TableCell,
  TableCellRecorded,
  TableCells,
  TableColumn,
  TableInputBlock,
  TableSignoff,
  TableSignoffAction,
} from './types/views/procedures';
import signoffUtil from './signoffUtil';
import lodash from 'lodash';
import { ACTION_TYPE } from './runUtil';

export const STATIC_COLUMN_TYPES = ['text', 'test_point'] as const;

const tableUtil = (() => {
  const getInitialRecordedCells = (content: TableInputBlock): TableCells => {
    const rowsArray = new Array(Number(content.rows)).fill('');

    return rowsArray.map((row, rowIndex) =>
      content.columns.map((column, columnIndex) => {
        // For backwards compatibility, keep 'text' column type in recorded values.
        if (column.column_type === 'signoff' || column.column_type === 'text') {
          if (content.cells && content.cells[rowIndex][columnIndex]) {
            return content.cells[rowIndex][columnIndex];
          }
        }

        // Never include comments in table values.
        if (column.column_type === 'comment') {
          return [];
        }

        // If the column is any other type, fill cell with an empty string value.
        return '';
      })
    );
  };

  const getInitialCells = (content: TableInputBlock): TableCells => {
    if (!content.cells) {
      const numCols = content.columns.length;
      content.cells = Array(Number(content.rows)).fill(Array(numCols).fill(''));
    }
    const cells = lodash.cloneDeep(content.cells);
    return cells.map((row) =>
      row.map((cell, columnIndex) => {
        const column = content.columns[columnIndex];
        if (
          ['signoff', 'test_point', 'text'].includes(
            column.column_type as string
          )
        ) {
          return cell;
        }

        // Clear comments
        if (column.column_type === 'comment') {
          return [];
        }

        // If the column is any other type, fill cell with an empty string value.
        return '';
      })
    );
  };

  const getAllCommentColumnIndices = (columns: Array<TableColumn>) => {
    return columns.flatMap((column, columnIndex) =>
      column.column_type === 'comment' ? [columnIndex] : []
    );
  };

  const isSignoffComplete = (signoff: TableSignoff | undefined): boolean => {
    if (!signoff) {
      return false;
    }

    return signoffUtil.isSignoffCompleted(
      {
        signoffs: [signoff],
        actions: signoff.actions.map((a) => ({
          ...a,
          signoff_id: signoff.id,
        })),
      },
      signoff.id
    );
  };

  const isAnySignoffComplete = (cell: TableCell): boolean => {
    if (!cell || !isSignoffCell(cell)) {
      return false;
    }
    return (cell as Array<TableSignoff>).some((s) => isSignoffComplete(s));
  };

  const areAllSignoffsComplete = (cell: TableCell): boolean => {
    if (!cell || !isSignoffCell(cell)) {
      return false;
    }
    return (cell as Array<TableSignoff>).every((s) => isSignoffComplete(s));
  };

  const getAllSignoffColumnIndices = (columns: Array<TableColumn>) => {
    return columns.flatMap((column, columnIndex) =>
      column.column_type === 'signoff' ? [columnIndex] : []
    );
  };

  const getRequiredSignoffColumnIndices = (columns: Array<TableColumn>) => {
    return columns.flatMap((column, columnIndex) =>
      column.column_type === 'signoff' && !column.allow_input_after_signoff
        ? [columnIndex]
        : []
    );
  };

  const isAnyRequiredRowSignoffComplete = ({
    columns,
    row,
  }: {
    columns: Array<TableColumn>;
    row: Array<TableCell>;
  }) => {
    const signoffColumnIndices = getRequiredSignoffColumnIndices(columns);
    if (signoffColumnIndices.length === 0) {
      return false;
    }
    return signoffColumnIndices.some((signoffColumnIndex) => {
      const signoffs = row[signoffColumnIndex];
      return isAnySignoffComplete(signoffs);
    });
  };

  const areAllRequiredRowSignoffsComplete = ({
    columns,
    row,
  }: {
    columns: Array<TableColumn>;
    row: Array<TableCell>;
  }) => {
    const signoffColumnIndices = getRequiredSignoffColumnIndices(columns);
    if (signoffColumnIndices.length === 0) {
      return false;
    }
    return signoffColumnIndices.every((signoffColumnIndex) => {
      const signoffs = row[signoffColumnIndex];
      return areAllSignoffsComplete(signoffs);
    });
  };

  const isSignoffCell = (cell?: TableCell) => {
    return (
      cell &&
      Array.isArray(cell) &&
      cell[0] &&
      (cell[0] as TableSignoff).operators &&
      (cell[0] as TableSignoff).actions
    );
  };

  /**
   * Number has stricter parsing than parseFloat. Eg, '1a' is NaN for
   * Number but '1' for parseFloat.
   *
   * Reference:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseFloat
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number
   */
  const isInvalidNumber = (value: string | number | null | undefined) =>
    isNaN(Number(value));

  const isValidNumber = (value: string | number | null | undefined) =>
    !isInvalidNumber(value);

  const _canUpdateSignoff = ({
    previousSignoffCell,
    previousSignoff,
    updatedSignoff,
    userOperatorRoleSet,
    block,
    previousRecorded,
    rowIndex,
  }: {
    previousSignoffCell?: Array<TableSignoff>;
    previousSignoff?: TableSignoff;
    updatedSignoff?: TableSignoff;
    userOperatorRoleSet: Set<string>;
    block: RunTableInputBlock;
    previousRecorded: RunTableInputRecorded;
    rowIndex: number;
  }): boolean => {
    if (!previousSignoff || !updatedSignoff) {
      return false;
    }
    const latestPreviousAction = signoffUtil.getLatestSignoffAction(
      previousSignoff.actions.map((a) => ({
        ...a,
        signoff_id: updatedSignoff.id,
      })),
      previousSignoff.id
    );

    const latestUpdatedAction = signoffUtil.getLatestSignoffAction(
      updatedSignoff.actions.map((a) => ({
        ...a,
        signoff_id: updatedSignoff.id,
      })),
      updatedSignoff.id
    );

    // Validate against invalid number values
    const someCellIsInvalid = previousRecorded.values[rowIndex]
      .filter(
        (cell, columnIndex): cell is string | null =>
          block.columns[columnIndex].column_type === 'input' &&
          block.columns[columnIndex].input_type === 'number'
      )
      .some(isInvalidNumber);

    if (someCellIsInvalid) {
      throw new Error('Number cells must have numeric values.');
    }

    if (latestUpdatedAction?.type === ACTION_TYPE.SIGNOFF) {
      if (
        latestPreviousAction &&
        latestPreviousAction.type !== ACTION_TYPE.REVOKE_SIGNOFF
      ) {
        throw new Error(
          'Signoff must be un-signed-off or revoked in order to approve a signoff.'
        );
      }

      if (
        !signoffUtil.isGenericSignoffRequired([updatedSignoff]) &&
        !userOperatorRoleSet.has(latestUpdatedAction.operator)
      ) {
        throw new Error('User does not have permission to sign off.');
      }
    }

    if (latestUpdatedAction?.type === ACTION_TYPE.REVOKE_SIGNOFF) {
      if (
        !latestPreviousAction ||
        latestPreviousAction.type !== ACTION_TYPE.SIGNOFF
      ) {
        throw new Error('Signoff cannot be revoked for incomplete signoff.');
      }

      if (
        !signoffUtil.isGenericSignoffRequired([updatedSignoff]) &&
        !updatedSignoff.operators.some((operator) =>
          userOperatorRoleSet.has(operator)
        )
      ) {
        throw new Error('User does not have permission to revoke sign off.');
      }
    }

    if (
      latestPreviousAction &&
      latestUpdatedAction &&
      latestPreviousAction.timestamp >= latestUpdatedAction.timestamp
    ) {
      throw new Error(
        'New action must happen after the latest existing action.'
      );
    }

    if (latestUpdatedAction?.type === 'signoff' && previousSignoffCell) {
      const allActionsWithSignoffId = previousSignoffCell.flatMap((signoff) =>
        signoff.actions.map((action) => ({
          ...action,
          signoff_id: signoff.id,
        }))
      );

      if (
        signoffUtil.isRoleSignedOffAnywhere({
          operator: latestUpdatedAction.operator,
          userId: latestUpdatedAction.user_id,
          signoffable: {
            signoffs: previousSignoffCell,
            actions: allActionsWithSignoffId,
          },
        })
      ) {
        throw new Error('Cannot sign off on same operator role');
      }
    }
    return true;
  };

  const mergeTableRecorded = ({
    block,
    previousRecorded,
    updatedValue,
    userOperatorRoleSet,
  }: {
    block: RunTableInputBlock;
    previousRecorded: RunTableInputRecorded;
    updatedValue: TableCellRecorded;
    userOperatorRoleSet: Set<string>;
  }): TableCellRecorded => {
    const allSignoffColumnIndices = getAllSignoffColumnIndices(block.columns);
    if (allSignoffColumnIndices.length === 0) {
      return updatedValue;
    }

    const isRowSignedOff = isAnyRequiredRowSignoffComplete({
      columns: block.columns,
      row: previousRecorded.values[updatedValue.row],
    });

    // If the row is signed off, and the cell being updated is not a signoff cell (can be required or not required), prevent the update.
    if (
      isRowSignedOff &&
      !allSignoffColumnIndices.includes(updatedValue.column)
    ) {
      throw new Error('Updates cannot be made to signed-off row.');
    }

    // If the cell is a signoff cell, do additional validity checks.
    if (allSignoffColumnIndices.includes(updatedValue.column)) {
      const updatedSignoffCell = updatedValue.value as Array<TableSignoff>;
      const updatedSignoff = updatedSignoffCell.find(
        (updatedSignoff) => updatedSignoff.id === updatedValue.signoff_id
      );

      const previousSignoffCell = previousRecorded.values[updatedValue.row][
        updatedValue.column
      ] as Array<TableSignoff>;
      const previousSignoff = previousSignoffCell.find(
        (signoff) => signoff.id === updatedValue.signoff_id
      );

      const canUpdateSignoff = _canUpdateSignoff({
        previousSignoffCell,
        previousSignoff,
        updatedSignoff,
        userOperatorRoleSet,
        block,
        previousRecorded,
        rowIndex: updatedValue.row,
      });
      if (!updatedSignoff || !canUpdateSignoff) {
        throw new Error('Invalid update to table signoff.');
      }

      const previousValue =
        previousRecorded.values[updatedValue.row][updatedValue.column];
      const mergedValue = lodash.cloneDeep(
        previousValue
      ) as Array<TableSignoff>;
      const updatedIndex = updatedSignoffCell.findIndex(
        (signoff) => signoff.id === updatedSignoff.id
      );
      mergedValue.splice(updatedIndex, 1, updatedSignoff);

      return {
        ...updatedValue,
        value: mergedValue,
      };
    }

    return updatedValue;
  };

  // Public interface
  return {
    getInitialRecordedCells,
    getInitialCells,
    getAllCommentColumnIndices,
    mergeTableRecorded,
    isSignoffComplete,
    isAnySignoffComplete,
    isAnyRequiredRowSignoffComplete,
    areAllSignoffsComplete,
    areAllRequiredRowSignoffsComplete,
    isSignoffCell,
    isInvalidNumber,
    isValidNumber,
    getAllSignoffColumnIndices,
    getRequiredSignoffColumnIndices,
  };
})();

export default tableUtil;
