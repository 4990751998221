import {
  Actions,
  ContentMenuItem,
  MenuActionItem,
  addGroupings,
} from './addContentTypes';

export interface GetProcedureAddContentOptions {
  canAddPartBuild: boolean;
  hasPartBuild: boolean;
  canHaveTestCases: boolean;
  hasTestCases: boolean;
}

const defaultOptions: GetProcedureAddContentOptions = {
  canAddPartBuild: true,
  hasPartBuild: false,
  canHaveTestCases: false,
  hasTestCases: false,
};

const getProcedureAddContentItems = (
  options: GetProcedureAddContentOptions
): Array<ContentMenuItem> => {
  const opts = {
    ...defaultOptions,
    ...options,
  };

  const items: Array<MenuActionItem> = [
    {
      group: 'Add Content',
      action: Actions.AddProcedureVariable,
      label: 'Add Procedure Variable',
      icon: 'square',
      description: 'Add variable at the procedure level',
    },
    {
      group: 'Add Content',
      action: Actions.AddProcedureHeader,
      label: 'Add Procedure Header',
      icon: 'square',
      description: 'Add a header to the procedure',
    },
    {
      group: 'Add Content',
      action: Actions.AddPartBuild,
      label: 'Add Bill of Materials',
      icon: 'screwdriver-wrench',
      description: 'Add parts required for build',
      hidden: opts.hasPartBuild || !opts.canAddPartBuild,
    },
    {
      group: 'Add Content',
      action: Actions.AddTestCases,
      label: 'Add Test Cards',
      icon: 'table',
      description: 'Add previously defined test cards to the test plan',
      hidden: opts.hasTestCases || !opts.canHaveTestCases,
    },
  ];

  return addGroupings(items);
};

export default getProcedureAddContentItems;
