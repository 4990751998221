import {
  ReferenceType,
  Risk as ServerRisk,
  StatusType,
} from 'shared/lib/types/postgres/risks';
import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';
import { AddRiskRequest } from 'shared/lib/types/api/risks/requests';

class RisksService {
  teamId: string;
  restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    const baseUrl = `${API_URL}/teams/${this.teamId}`;
    this.restUrl = `${baseUrl}/risks`;
  }

  async listRisks(
    statusTypes?: StatusType[],
    referenceId?: string,
    referenceType?: ReferenceType
  ): Promise<ServerRisk[]> {
    const params: {
      status_types?: StatusType[];
      reference_id?: string;
      reference_type?: ReferenceType;
    } = {};
    if (statusTypes) {
      params.status_types = statusTypes;
    }
    if (referenceId) {
      params.reference_id = referenceId;
    }
    if (referenceType) {
      params.reference_type = referenceType;
    }
    const response = await superlogin.getHttp().get(this.restUrl, { params });
    return response.data.data;
  }

  async createRisk(riskToCreate: AddRiskRequest): Promise<void> {
    const response = await superlogin
      .getHttp()
      .post(this.restUrl, riskToCreate);
    return response.data;
  }
}

export default RisksService;
