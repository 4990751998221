import LabelDisplay from './LabelDisplay';
import { isEmptyValue } from 'shared/lib/text';
import NoValueRecorded from './NoValueRecordedDisplay';
import RecordedValueDisplay from './RecordedValueDisplay';
import React, { useMemo } from 'react';
import { FieldInputTimestampBlock, RunFieldInputTimestampBlock } from 'shared/lib/types/views/procedures';
import ReferenceTokenTooltip from './ReferenceTokenTooltip';

import { getFormattedRecordedTimestamp, getHasAllValuesRecorded } from '../../../lib/datetime';
import DateTimeTooltipContent from '../../DateTimeTooltipContent';
import Tooltip from '../../../elements/Tooltip';
import TimeFieldInputHelpIcon from '../FieldInput/TimeFieldInputHelpIcon';
import { getTimestampFromRecorded } from 'shared/lib/datetime';

interface TimestampDisplayProps {
  block: FieldInputTimestampBlock;
  referenceId?: string;
}

const TimestampDisplay = ({ block, referenceId }: TimestampDisplayProps) => {
  const recorded = useMemo(() => (block as RunFieldInputTimestampBlock).recorded, [block]);
  const type = useMemo(() => (block as RunFieldInputTimestampBlock).dateTimeType, [block]);

  const { timestamp, zone } = useMemo(
    () => getTimestampFromRecorded((block as RunFieldInputTimestampBlock).recorded),
    [block]
  );
  const formattedTimestamp = useMemo(
    () => getFormattedRecordedTimestamp(type, timestamp, zone),
    [timestamp, type, zone]
  );
  const hasAllValuesRecorded = useMemo(() => getHasAllValuesRecorded(type, recorded), [recorded, type]);

  return (
    <div className="flex flex-row items-start">
      <div className="mt-1">
        <LabelDisplay text={block.name} />
      </div>
      <div className="mt-1 px-1.5 flex items-center">
        <span>=</span>
      </div>
      <div className="h-fit flex flex-nowrap group gap-x-2">
        {referenceId && <ReferenceTokenTooltip referenceId={referenceId} />}
        {(!(block as RunFieldInputTimestampBlock).recorded || isEmptyValue(recorded?.value) || !hasAllValuesRecorded) &&
          (referenceId ? (
            <NoValueRecorded />
          ) : (
            <div className="mt-1 px-2 py-1.5 w-36 text-sm border-2 border-gray-400 rounded bg-gray-300 bg-opacity-50" />
          ))}
        {hasAllValuesRecorded && !isEmptyValue(recorded?.value) && (
          <>
            <Tooltip
              content={
                <DateTimeTooltipContent
                  timestamp={timestamp ?? ''}
                  zone={zone ?? undefined}
                  type={block.dateTimeType as 'time' | 'datetime'}
                />
              }
              visible={block.dateTimeType !== 'date'}
              position="bottom"
            >
              <RecordedValueDisplay value={formattedTimestamp} />
            </Tooltip>
            {block.dateTimeType === 'time' && (
              <div className="self-center">
                <TimeFieldInputHelpIcon />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TimestampDisplay;
