import React from 'react';
import { Event } from 'shared/schedule/types/event';
import { useGanttContext } from '../../../components/Gantt/GanttContext';
import { Interval } from 'luxon';

const FixedBar = ({ event }: { event: Event }) => {
  const { intervalWidth } = useGanttContext();
  const width = intervalWidth(Interval.fromDateTimes(event.start, event.end));

  return (
    <div
      className="flex items-center shadow-lg h-full pl-2 rounded bg-blue-500/30 hover:bg-blue-600/30 ring-1"
      style={{ width: `${width}px` }}
    >
      <span className="line-clamp-2">{event.name}</span>
    </div>
  );
};

export default FixedBar;
