import { AxiosResponse } from 'axios';
import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';
import { Event, EventJson } from 'shared/schedule/types/event';
import { DateTime, Interval, Duration } from 'luxon';
import { SetOptional } from 'type-fest';

class EventService {
  private teamId: string;
  private restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    this.restUrl = `${API_URL}/teams/${this.teamId}/planning/events`;
  }

  private static isoToLuxUTC(date: string): DateTime {
    return DateTime.fromISO(date).toUTC();
  }

  private static jsonToEvent(jsonEvent: EventJson): Event {
    const { start, end, recurrence_date, duration, ...otherProps } = jsonEvent;
    return {
      start: start ? this.isoToLuxUTC(start) : undefined,
      end: end ? this.isoToLuxUTC(end) : undefined,
      recurrence_date: recurrence_date
        ? this.isoToLuxUTC(recurrence_date)
        : undefined,
      duration: duration ? Duration.fromISO(duration) : duration,
      ...(otherProps as Event),
    };
  }

  async getSingleEvents(): Promise<Array<Event>> {
    const params = {
      exclude_recurrences: true,
    };
    const response = await superlogin.getHttp().get(this.restUrl, { params });
    return response.data.events.map((json) => EventService.jsonToEvent(json));
  }

  async getEvents(interval: Interval): Promise<Array<Event>> {
    const params = {
      start: interval.start.toISO(),
      end: interval.end.toISO(),
    };
    const response = await superlogin.getHttp().get(this.restUrl, { params });
    return response.data.events.map((json) => EventService.jsonToEvent(json));
  }

  async getOperationEvents(operationId: string): Promise<Array<Event>> {
    const params = { operation_id: operationId };
    const response = await superlogin.getHttp().get(this.restUrl, { params });
    return response.data.events.map((json) => EventService.jsonToEvent(json));
  }

  async getEvent(eventId: string): Promise<Event> {
    const url = `${this.restUrl}/${eventId}`;
    const response = await superlogin.getHttp().get(url);
    return EventService.jsonToEvent(response.data);
  }

  async updateEvent(
    eventId: string,
    event: SetOptional<Event, 'id'>,
    ignoreResourceConflicts: boolean
  ): Promise<Event> {
    const eventToSend = { ...event };
    delete eventToSend.id;
    delete eventToSend.activity;
    delete eventToSend.operation;
    delete eventToSend.is_recurrence;

    const params = {
      ...(ignoreResourceConflicts ? { ignore_resource_conflicts: true } : {}),
    };
    const url = `${this.restUrl}/${eventId}`;
    const response = await superlogin
      .getHttp()
      .patch(url, eventToSend, { params });
    return EventService.jsonToEvent(response.data);
  }

  async createEvent(
    event: SetOptional<Event, 'id'>,
    ignoreResourceConflicts: boolean
  ): Promise<string> {
    const eventToSend = {
      ...event,
      operation_id: event.operation?.key,
    };
    delete eventToSend.id;
    delete eventToSend.activity;
    delete eventToSend.operation;
    delete eventToSend.is_recurrence;

    const params = {
      ...(ignoreResourceConflicts ? { ignore_resource_conflicts: true } : {}),
    };
    const response = await superlogin
      .getHttp()
      .post(this.restUrl, eventToSend, { params });
    return response.data.id;
  }

  async importEvents(file: File): Promise<AxiosResponse> {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const url = `${this.restUrl}/import-csv`;
    return superlogin.getHttp().post(url, bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async deleteEvent(eventId: string): Promise<number> {
    const url = `${this.restUrl}/${eventId}`;
    const response = await superlogin.getHttp().delete(url);
    return response.status;
  }

  async getUnscheduledEvents(): Promise<Event> {
    const params = {
      eventType: 'unscheduled',
    };
    const response = await superlogin.getHttp().get(this.restUrl, { params });
    return response.data.events.map((json) => EventService.jsonToEvent(json));
  }

  async getEventRunIds(): Promise<Set<string>> {
    const url = `${API_URL}/teams/${this.teamId}/planning/team-event-run-ids`;
    const response = await superlogin.getHttp().get(url);
    return new Set(response.data.run_ids);
  }
}

export default EventService;
