import { faCaretDown, faClone, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SNIPPET_TYPE_SECTION, SNIPPET_TYPE_STEP } from '../../api/settings';
import { useAuth } from '../../contexts/AuthContext';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import Grid, { GridColumn, TextAlign } from '../../elements/Grid';
import SearchInputControlled from '../../elements/SearchInputControlled';
import { PERM } from '../../lib/auth';
import snippetUtil from '../../lib/snippetUtil';
import { Snippet, SnippetType } from '../../lib/views/settings';
import { filterBySearchTerm } from '../../lib/gridUtils';
import Button, { BUTTON_TYPES } from '../Button';
import FlashMessage from '../FlashMessage';
import FieldSetSnippet from './FieldSetSnippet';
import ModalPreviewSnippet from './ModalPreviewSnippet';
import { SortColumn } from 'react-data-grid';
import Label from '../Label';
import { capitalizeFirstLetter } from 'shared/lib/text';
import useMenu from '../../hooks/useMenu';
import MenuContext, { MenuContextAction } from '../MenuContext';
import { MainScrollPanelId } from '../../elements/SidebarLayout';

const SNIPPET_SAVE_SUCCESS_MESSAGE = 'Snippet saved';
const SNIPPET_DELETE_SUCCESS_MESSAGE = 'Snippet deleted';
const MAIN_VERTICAL_PADDING = 120;
const ROW_HEIGHT = 60;

interface SnippetListProps {
  testingModule?: boolean;
}

const SnippetList = ({ testingModule = false }: SnippetListProps) => {
  const [editActiveSnippet, setEditActiveSnippet] = useState<Snippet | null>(null);
  const [showEditSnippet, setShowEditSnippet] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [snippets, setSnippets] = useState<Array<Snippet>>([]);
  const [currentSnippet, setCurrentSnippet] = useState<Snippet>();
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [isDuplicateSnippet, setIsDuplicateSnippet] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { auth } = useAuth();
  const { isMenuVisible, setIsMenuVisible } = useMenu();

  const canEdit = useMemo(() => {
    return auth.hasPermission(PERM.PROCEDURES_EDIT);
  }, [auth]);

  const onEditSnippetChanged = useCallback((snippet) => {
    setEditActiveSnippet(snippet);
    setShowEditSnippet(true);
  }, []);

  const refreshSnippets = useCallback(() => {
    return services.settings
      .listSnippets({ isTestSnippet: testingModule })
      .then((snippets) => snippets.sort((a, b) => a.name.localeCompare(b.name)))
      .then(setSnippets)
      .catch(() => {
        /* Ignore */
      });
  }, [services.settings, testingModule]);

  const onCloseEditSnippet = useCallback(() => {
    setEditActiveSnippet(null);
    setCurrentSnippet(undefined);
    setShowEditSnippet(false);
    setIsDuplicateSnippet(false);
    refreshSnippets();
  }, [refreshSnippets]);

  const onRemoveSnippet = useCallback(
    (id) => {
      setCurrentSnippet(undefined);

      return services.settings
        .deleteSnippet(id)
        .then(() => {
          setEditActiveSnippet(null);
          setShowEditSnippet(false);
        })
        .then(refreshSnippets)
        .then(() => setSuccessMessage(SNIPPET_DELETE_SUCCESS_MESSAGE))
        .catch(() => {
          /* Ignore */
        });
    },
    [services.settings, refreshSnippets]
  );

  const onCreateSnippet = useCallback(
    (snippetType: SnippetType) => {
      if (snippetType === 'section') {
        onEditSnippetChanged(snippetUtil.getNewSectionSnippet());
      }
      if (snippetType === 'step') {
        onEditSnippetChanged(snippetUtil.getNewStepSnippet());
      }
    },
    [onEditSnippetChanged]
  );

  const onSaveSuccess = useCallback(() => {
    setCurrentSnippet(undefined);
    return setSuccessMessage(SNIPPET_SAVE_SUCCESS_MESSAGE);
  }, []);

  const onDuplicateSnippet = useCallback((snippet: Snippet) => {
    snippet = snippetUtil.duplicateSnippet(snippet);
    setEditActiveSnippet(snippet);
    setShowEditSnippet(true);
    setIsDuplicateSnippet(true);
  }, []);

  useEffect(() => {
    refreshSnippets();
  }, [refreshSnippets]);

  const handleCellClick = useCallback(
    (args) => {
      if (args.column.key === 'snippet_type') {
        setSearchTerm(args.row.snippet_type);
      }
    },
    [setSearchTerm]
  );

  const defaultSort = [
    {
      columnKey: 'name',
      direction: 'ASC',
    },
  ] as Array<SortColumn>;

  const columns: readonly GridColumn<Snippet>[] = [
    {
      key: 'name',
      name: 'Name',
      sortable: true,
      renderCell({ row }: { row: Snippet }) {
        return (
          <div
            onClick={() => setCurrentSnippet(row)}
            className="py-3 cursor-pointer text-blue-500 hover:underline leading-4 w-full line-clamp-2 whitespace-normal"
          >
            {row.name}
          </div>
        );
      },
    },
    {
      key: 'description',
      name: 'Description',
      sortable: true,
      renderCell({ row }: { row: Snippet }) {
        return <div className="leading-4 w-full line-clamp-3 whitespace-normal">{row.description}</div>;
      },
    },
    {
      key: 'snippet_type',
      name: 'Type',
      sortable: true,
      renderCell({ row }: { row: Snippet }) {
        return (
          <Label
            clickable={true}
            text={capitalizeFirstLetter(row.snippet_type)}
            color={row.snippet_type === SNIPPET_TYPE_STEP ? 'bg-stone-200' : 'bg-purple-200'}
          />
        );
      },
    },
    {
      key: 'actions',
      name: 'Actions',
      width: '150px',
      align: TextAlign.Center,
      renderCell({ row }: { row: Snippet }) {
        return (
          <div className="flex flex-row justify-end">
            {canEdit && (
              <>
                <Button
                  type={BUTTON_TYPES.TERTIARY}
                  title="Edit Snippet"
                  aria-label="Edit Snippet"
                  leadingIcon={faPencil}
                  onClick={() => onEditSnippetChanged(row)}
                />
                <Button
                  type={BUTTON_TYPES.TERTIARY}
                  title="Duplicate Snippet"
                  aria-label="Duplicate Snippet"
                  leadingIcon={faClone}
                  onClick={() => onDuplicateSnippet(row)}
                />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const displayRows: Array<Snippet> = useMemo(
    () =>
      filterBySearchTerm({
        searchTerm,
        allData: snippets,
        getStrings: (snippet: Snippet) => [snippet.name, snippet.description, snippet.snippet_type],
      }),
    [searchTerm, snippets]
  );

  const menuActions: Array<MenuContextAction> = useMemo(
    () => [
      {
        type: 'label',
        label: 'Step Snippet',
        data: {
          onClick: () => onCreateSnippet(SNIPPET_TYPE_STEP),
        },
      },
      {
        type: 'label',
        label: 'Section Snippet',
        data: {
          onClick: () => onCreateSnippet(SNIPPET_TYPE_SECTION),
        },
      },
    ],
    [onCreateSnippet]
  );

  return (
    <div id={MainScrollPanelId} className="w-full h-screen overflow-auto">
      <Helmet>
        <title>Snippets - {showEditSnippet ? 'Edit' : 'List'}</title>
      </Helmet>
      <div className="flex flex-col px-5 pt-4 gap-2">
        <div className="flex flex-row justify-between">
          <div className="text-xl">Snippets</div>
          {canEdit && !showEditSnippet && (
            <div className="justify-end flex flex-row gap-2">
              <Button
                onClick={() => setIsMenuVisible((current) => !current)}
                type={BUTTON_TYPES.PRIMARY}
                leadingIcon={faPlus}
                trailingIcon={faCaretDown}
              >
                New Snippet
              </Button>
              {isMenuVisible && (
                <div className="absolute top-16 w-36 z-40">
                  <MenuContext menuContextActions={[menuActions]} className="font-medium text-xs" hasDividers={true} />
                </div>
              )}
            </div>
          )}
          <FlashMessage message={successMessage} messageUpdater={setSuccessMessage} />
        </div>
        {!showEditSnippet && (
          <>
            <SearchInputControlled
              placeholder="Search Snippets"
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
            <Grid
              columns={columns}
              defaultSort={defaultSort}
              rows={displayRows}
              onCellClick={handleCellClick}
              usedVerticalSpace={MAIN_VERTICAL_PADDING}
              rowHeight={ROW_HEIGHT}
              emptyRowMessage="No snippets found"
            />
          </>
        )}

        {showEditSnippet && (
          <FieldSetSnippet
            snippet={editActiveSnippet}
            onClose={onCloseEditSnippet}
            onRemove={onRemoveSnippet}
            onSaveSuccess={onSaveSuccess}
            isDuplicate={isDuplicateSnippet}
            testingModule={testingModule}
          />
        )}

        {currentSnippet && !showEditSnippet && (
          <ModalPreviewSnippet
            snippet={currentSnippet}
            onCancel={() => setCurrentSnippet(undefined)}
            onEdit={() => onEditSnippetChanged(currentSnippet)}
            onRemove={onRemoveSnippet}
          />
        )}
      </div>
    </div>
  );
};

export default SnippetList;
