import React, { useCallback, useMemo } from 'react';
import { generateHiddenClassString } from '../../lib/styles';
import telemetryUtil from '../../lib/telemetry';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import diffUtil from '../../lib/diffUtil';
import sharedDiffUtil from 'shared/lib/diffUtil';
import DiffContainer from '../Diff/DiffContainer';
import { RangeDiffElement } from 'shared/lib/types/views/procedures';
import ProcedureDiffText from '../ProcedureDiffText';
import { getRangeStringForDiff } from '../../hooks/useDisplayRule';

const ReviewBlockTelemetry = ({ telemetry, blockLabel, docState, isHidden, isSpacerHidden }) => {
  const oldTelemetryKey = sharedDiffUtil.getDiffValue<string>(telemetry, 'key', 'old').toLowerCase() ?? null;
  const newTelemetryKey = sharedDiffUtil.getDiffValue<string>(telemetry, 'key', 'new')?.toLowerCase() ?? null;

  const oldTelemetryValue = sharedDiffUtil.getDiffValue<string>(telemetry, 'value', 'old');
  const newTelemetryValue = sharedDiffUtil.getDiffValue<string>(telemetry, 'value', 'new');

  const oldTelemetryRule = sharedDiffUtil.getDiffValue<string>(telemetry, 'rule', 'old').toLowerCase();
  const newTelemetryRule = sharedDiffUtil.getDiffValue<string>(telemetry, 'rule', 'new').toLowerCase();

  const oldTelemetryExpression = sharedDiffUtil.getDiffValue(telemetry, 'expression', 'old');
  const newTelemetryExpression = sharedDiffUtil.getDiffValue(telemetry, 'expression', 'new');

  const telemetryRange = diffUtil.getFieldValue(telemetry, 'range') as RangeDiffElement;

  const getParameterDisplayKey = useCallback(
    (version: 'old' | 'new') => {
      const telemetryKey = sharedDiffUtil.getDiffValue<string>(telemetry, 'key', version).toLowerCase() ?? null;
      if (!telemetryKey) {
        return;
      }

      if (telemetryKey === 'custom') {
        return;
      }

      const telemetryUnits = sharedDiffUtil.getDiffValue<string | undefined>(telemetry, 'units', version);

      const unitsSuffix = telemetryUnits ? ` (${telemetryUnits})` : '';
      return `${telemetryUtil.getParameterName(telemetry, version)}${unitsSuffix}`;
    },
    [telemetry]
  );

  const oldParameterDisplayKey = useMemo(() => getParameterDisplayKey('old'), [getParameterDisplayKey]);
  const newParameterDisplayKey = useMemo(() => getParameterDisplayKey('new'), [getParameterDisplayKey]);

  return (
    <>
      <tr>
        <td>
          <div className={generateHiddenClassString('', isHidden)}></div>
        </td>
        <td colSpan={2}>
          {telemetry.key && (
            <div className={generateHiddenClassString('flex items-start mt-2 mr-8 page-break font-mono', isHidden)}>
              <Spacer isHidden={isSpacerHidden} />
              <SubstepNumber blockLabel={blockLabel} isHidden={isSpacerHidden} hasExtraVerticalSpacing={false} />
              <div>
                <DiffContainer label="Telemetry" diffChangeState={telemetry.diff_change_state} isTextSticky={false}>
                  {(oldTelemetryKey === 'custom' || newTelemetryKey === 'custom') && (
                    <ProcedureDiffText
                      diffValue={{
                        __old: oldTelemetryKey === 'custom' ? `${oldTelemetryExpression}` : '',
                        __new: newTelemetryKey === 'custom' ? `${newTelemetryExpression}` : '',
                      }}
                    />
                  )}
                  {((oldTelemetryKey === 'parameter' && oldTelemetryRule === 'range') ||
                    (newTelemetryKey === 'parameter' && newTelemetryRule === 'range')) && (
                    <ProcedureDiffText
                      diffValue={{
                        __old:
                          oldTelemetryRule === 'range'
                            ? getRangeStringForDiff(telemetryRange, 'old', oldParameterDisplayKey)
                            : '',
                        __new:
                          newTelemetryRule === 'range'
                            ? getRangeStringForDiff(telemetryRange, 'new', newParameterDisplayKey)
                            : '',
                      }}
                    />
                  )}
                  {((oldTelemetryKey === 'parameter' && oldTelemetryRule !== 'range') ||
                    (newTelemetryKey === 'parameter' && newTelemetryRule !== 'range')) && (
                    <ProcedureDiffText
                      diffValue={{
                        __old:
                          oldTelemetryKey === 'parameter' && oldTelemetryRule !== 'range'
                            ? `${oldParameterDisplayKey} ${oldTelemetryRule} ${oldTelemetryValue}`
                            : '',
                        __new:
                          newTelemetryKey === 'parameter' && newTelemetryRule !== 'range'
                            ? `${newParameterDisplayKey} ${newTelemetryRule} ${newTelemetryValue}`
                            : '',
                      }}
                    />
                  )}
                </DiffContainer>
              </div>
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default ReviewBlockTelemetry;
