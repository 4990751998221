import React, { useMemo, useCallback } from 'react';
import { Field } from 'formik';
import { cloneDeep } from 'lodash';
import StepDependencySelect from './StepDependencySelect';

/**
 * Renders a step dependency button with adjustable operators.
 *
 * @param {object} props
 * @param {String} props.name - Property path of the field to be updated.
 * @param {String} props.dependencyIndex - Index of current dependency.
 * @param {Object} props.dependency - step dependency object.
 * @param {Function} props.onRemoveDependency - Callback to remove dependency from step.
 * @param {Array} props.dependencyOptions - dependents available to add to dependency.
 * @param {Function} props.onDependencyUpdated - Callback to step function syncing dependency to procedure.
 */
const FieldSetStepDependency = ({
  dependencyIndex,
  dependency,
  onRemoveDependency,
  dependencyOptions,
  onDependencyUpdated,
}) => {
  const onRemove = useCallback(() => {
    onRemoveDependency(dependencyIndex);
  }, [dependencyIndex, onRemoveDependency]);

  const updateDependency = useCallback(
    (values) => {
      const updated = cloneDeep(dependency);
      updated.dependent_ids = values;

      onDependencyUpdated(updated, dependencyIndex);

      if (values.length === 0) {
        onRemove();
      }
    },
    [dependency, dependencyIndex, onRemove, onDependencyUpdated]
  );

  const onChange = useCallback(
    (options) => {
      const values = options.map((option) => option.value);
      updateDependency(values);
    },
    [updateDependency]
  );

  const value = useMemo(
    () =>
      dependency?.dependent_ids.map((id) => {
        const dependencyOption = dependencyOptions.all.find((depOpt) => depOpt.value === id);

        if (!dependencyOption) {
          return null;
        }

        return {
          value: id,
          label: dependencyOption.label,
        };
      }),
    [dependencyOptions.all, dependency?.dependent_ids]
  );

  return (
    <Field>
      {({ field }) => (
        <div className="relative group max-w-xl text-sm">
          <StepDependencySelect
            options={dependencyOptions.remaining}
            name={field.name}
            placeholder="Add Dependency"
            opacity={0.8}
            value={value}
            onChange={onChange}
            onBlur={field.onBlur}
            ariaLabel="Step Dependency Select"
          />
        </div>
      )}
    </Field>
  );
};

export default FieldSetStepDependency;
