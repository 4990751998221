const CompletedToolInstance = ({ content }: { content?: JSX.Element }) => {
  if (content) {
    return (
      <div className="px-1 h-[2.4rem] pt-2 text-blue-600 border border-gray-400 bg-gray-200 rounded-[4px]">
        {content}
      </div>
    );
  }
  return <div className="h-[2.4rem] w-20 text-blue-600 border border-gray-400 bg-gray-200 rounded-[4px]" />;
};

export default CompletedToolInstance;
