import cloneDeep from 'lodash.clonedeep';
import blockUtil from './blockUtil';
import { cannotUpdateStep } from './runUtil';
import {
  Recorded,
  RunExpressionBlock,
  RunFieldInputTableBlock,
  RunStep,
  RunStepBlock,
} from './types/views/procedures';
import tableUtil from './tableUtil';
import signoffUtil from './signoffUtil';

export const getStepDocId = (
  runId: string,
  sectionId: string,
  stepId: string
) => {
  if (!runId || !sectionId || !stepId) {
    throw new Error('Missing run, section or step id.');
  }
  return `index_${runId}:${sectionId}:${stepId}`;
};

export const newStepDoc = (runId: string, sectionId: string, step: RunStep) => {
  const cleanedContent = cloneDeep(step.content).map((block: RunStepBlock) => {
    if (block.type === 'table_input') {
      block.cells = tableUtil.getInitialCells(block);
    }
    return block;
  });
  return {
    _id: getStepDocId(runId, sectionId, step.id),
    run_id: runId,
    section_id: sectionId,
    step_id: step.id,
    content: cleanedContent,
  };
};

/**
 * Gets the recorded content of a step in a map "step recorded" shape.
 *
 * @param {Object} step - A step or run step object.
 * @returns {Object} recorded - The recorded dictionary in the shape
 *   { [block_index]: block_recorded_object }.
 */
export const getStepRecorded = (step) => {
  const recordedContentMap: { [index: number]: any } = {};
  if (!step) return recordedContentMap;

  step.content.forEach((block, index) => {
    if (block.type === 'field_input_table' && block.fields) {
      const fieldRecords: { [fieldIndex: number]: any } = {};
      block.fields.forEach((field, fieldIndex) => {
        if (field.recorded) {
          fieldRecords[fieldIndex] = field.recorded; // Directly use recorded
        }
      });
      recordedContentMap[index] = fieldRecords;
    } else if (block.recorded) {
      recordedContentMap[index] = block.recorded; // Use recorded directly
    }
  });
  return recordedContentMap;
};

export const updateBlockRecorded = ({
  step,
  contentId,
  userId,
  timestamp,
  actionId,
  recorded,
  userOperatorRoleSet,
  fieldIndex,
}: {
  step: RunStep;
  contentId: string;
  userId: string;
  timestamp: string;
  actionId: string;
  recorded: Recorded;
  userOperatorRoleSet: Set<string>;
  fieldIndex?: number;
}): boolean => {
  if (!step) {
    throw new Error('Missing step document');
  }

  let block = step.content.find((block) => block.id === contentId);
  if (!block) {
    throw new Error('Content not found');
  }

  if (typeof fieldIndex === 'number') {
    block = (block as RunFieldInputTableBlock).fields[fieldIndex];
    if (!block) {
      throw new Error('Content not found');
    }
  }

  // If step cannot be updated, drop this request.
  if (cannotUpdateStep(step)) {
    return false;
  }

  if (!recorded) {
    return false;
  }

  return blockUtil.addRecordedAction({
    block,
    userId,
    timestamp,
    actionId,
    recorded,
    userOperatorRoleSet,
  });
};

export const updateStepRecorded = ({
  step,
  recorded,
}: {
  step: RunStep;
  recorded: { [index: number]: any };
}) => {
  if (!step) {
    throw new Error('Missing step document');
  }

  if (cannotUpdateStep(step)) {
    return false;
  }

  if (!recorded) {
    return false;
  }

  let updated = false;
  Object.entries(recorded).forEach(([contentIndex, contentRecorded]) => {
    const block = step.content[+contentIndex];
    if (block && block.type === 'field_input_table') {
      block.fields.forEach((field, fieldIndex) => {
        const fieldRecord = (contentRecorded as { [key: number]: any })[
          fieldIndex
        ];
        if (fieldRecord) {
          updated =
            blockUtil.updateRecorded({
              block: field,
              recorded: fieldRecord,
            }) || updated;
        }
      });
    } else if (contentRecorded) {
      updated =
        blockUtil.updateRecorded({
          block,
          recorded: contentRecorded,
        }) || updated;
    }
  });
  return updated;
};

/**
 If there are no longer any signoffs, remove recorded values from
 expressions in the step.

 Both the step from the run and the stepDoc are needed, because
 the signoff-related info is in the step in the run, but the stepDoc is
 what needs to be updated.
 */
export const updateStepDocForRevokeSignoff = ({
  step,
  stepDoc,
}: {
  step: RunStep;
  stepDoc: RunStep;
}): boolean => {
  // Do nothing if any signoffs are still complete.
  if (signoffUtil.anySignoffsComplete(step)) {
    return false;
  }

  stepDoc.content
    .filter((block): block is RunExpressionBlock => block.type === 'expression')
    .forEach((expressionBlock) => {
      delete expressionBlock.recorded;
    });

  return true;
};
