import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../elements/Tooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Label from '../Label';
import revisions from '../../lib/revisions';

interface SuggestedEditIconProps {
  isBlueline: boolean;
  description?: string;
}
const SuggestedEditIcon = React.memo(({ isBlueline, description }: SuggestedEditIconProps) => {
  const editType = isBlueline ? 'Blueline' : 'Redline';
  const color = isBlueline ? 'bg-blue-200' : 'bg-red-200';
  const iconColor = isBlueline ? 'blue' : 'red';
  const message = revisions.getSuggestedEditMessage(isBlueline, description);

  return (
    <>
      <Label text={editType} color={color} icon="strikethrough" iconColor={iconColor} verticalPadding={false} />
      <Tooltip content={message}>
        <span className="ml-1 text-sm text-gray-700">
          <FontAwesomeIcon icon={'fa-regular fa-circle-question' as IconProp} />
        </span>
      </Tooltip>
    </>
  );
});

export default SuggestedEditIcon;
