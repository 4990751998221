import React from 'react';
import { Link } from 'react-router-dom';
import runUtil from '../../lib/runUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Section } from 'shared/lib/types/views/procedures';

interface SectionSpanProps {
  linkTo: string;
  sectionKey: string;
  sectionRepeatKey?: string;
  currentSection: Section | { name: string };
}

const SectionSpan = React.memo(({ linkTo, sectionKey, sectionRepeatKey, currentSection }: SectionSpanProps) => {
  const repeatSubtitle = runUtil.isSectionRepeat(currentSection) ? `(repeat ${sectionRepeatKey})` : '';
  const sectionKeyDisplay = sectionKey ? `${sectionKey}:` : '';

  return (
    <Link to={linkTo}>
      <div className="w-full my-2 truncate">
        <div className="mx-2 text-lg font-semibold w-56 flex flex-row cursor-pointer hover:text-blue-600">
          <span className="truncate" title={`${currentSection.name} ${repeatSubtitle}`}>
            {`${sectionKeyDisplay} ${currentSection.name}`}
          </span>
          {runUtil.isSectionRepeat(currentSection) && (
            <span className="ml-1 whitespace-nowrap" aria-label={`repeat ${sectionRepeatKey}`}>
              <FontAwesomeIcon icon="redo" className="text-sm" />
              {` ${sectionRepeatKey}`}
            </span>
          )}
        </div>
      </div>
    </Link>
  );
});

export default SectionSpan;
