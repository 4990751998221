import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Attachments } from '../attachments/types';
import ProcedureService, { ProceduresError } from '../api/procedures';
import RunService from '../api/runs';
import SettingsService from '../api/settings';
import RevisionsService from '../api/revisions';
import TelemetryService from '../api/telemetry';
import CommandingService from '../api/commanding';
import ExternalDataService from '../api/externalData';
import UserService from '../api/user';
import OrganizationService from '../api/organizations';
import RolesService from '../api/roles';
import couchdbUtil, { DocChange } from '../lib/couchdbUtil';
import {
  Procedure,
  ProcedureMetadata,
  DraftSection,
  DraftStep,
  DraftStepBlock,
} from 'shared/lib/types/views/procedures';
import EventService from '../schedule/api/events';
import SwimlaneService from '../schedule/api/swimlanes';
import ManufacturingService from '../manufacturing/api/manufacturing';
import ApiKeysService from '../api/apiKeys';
import DictionaryService from '../api/dictionary';
import IssueService from '../api/issue';
import MlService from '../api/ml';
import NotificationsService from '../api/notifications';
import TestingService from '../api/testing';
import NCRService from '../issues/api/issues';
import IssueDetailsService from '../issues/api/issueDetails';
import DataService from '../storage/api/data';
import InfluxService from '../storage/api/influx';
import ToolsService from '../manufacturing/api/tools';
import {
  isReleased,
  getPendingProcedureIndex,
  getProcedureId,
} from 'shared/lib/procedureUtil';
import SearchService from '../api/search';
import AnnotationService from '../storage/api/annotation';
import BuildsService from '../manufacturing/api/builds';
import PasswordsService from '../passwords/api/passwords';
import RisksService from '../risks/api/risks';

export const SYNC_ALL_PROCEDURE_DATA = 'procedures/syncAllProcedureData';
export const SYNC_PROCEDURE_DATA = 'procedures/syncProcedureData';
export const SYNC_PROCEDURE_DATA_SUCCEEDED =
  'procedures/syncProcedureData/succeeded';
export const SYNC_PROCEDURE_DATA_FAILED = 'procedures/syncProcedureData/failed';
export const COPY_ITEM_TO_CLIPBOARD = 'procedures/copyItemToClipboard';

type StoreState = {
  procedures: ProceduresSliceState;
};

export interface DatabaseServices {
  attachments: Attachments;
  procedures: ProcedureService;
  revisions: RevisionsService;
  runs: RunService;
  settings: SettingsService;
  apiKeys: ApiKeysService;
  telemetry: TelemetryService;
  commanding: CommandingService;
  dictionary: DictionaryService;
  externalData: ExternalDataService;
  users: UserService;
  organizations: OrganizationService;
  roles: RolesService;
  manufacturing: ManufacturingService;
  builds: BuildsService;
  tools: ToolsService;
  passwords: PasswordsService;
  events: EventService;
  swimlanes: SwimlaneService;
  influx: InfluxService;
  issue: IssueService;
  ncr: NCRService;
  issueDetails: IssueDetailsService;
  data: DataService;
  testing: TestingService;
  notifications: NotificationsService;
  ml: MlService;
  search: SearchService;
  annotation: AnnotationService;
  risk: RisksService;
}

interface ProceduresMetadataResults {
  teamId: string;
  proceduresMetadata: ProcedureMetadata[];
  deletedDocs: DocChange[];
}

export enum ClipboardItemType {
  SECTION = 'section',
  STEP = 'step',
  BLOCK = 'block',
}

export type ClipboardItem = {
  type: ClipboardItemType;
  [ClipboardItemType.SECTION]?: DraftSection;
  [ClipboardItemType.STEP]?: DraftStep;
  [ClipboardItemType.BLOCK]?: DraftStepBlock;
};

export type DocSynced = {
  id: string;
  rev: string;
  synced: boolean;
};

type ProceduresSliceState = {
  [teamId: string]: {
    loading: boolean;
    docs: {
      [id: string]: Procedure;
    };
    pending: unknown[];
    synced: {
      [id: string]: DocSynced;
    };
    metadata: {
      [id: string]: ProcedureMetadata;
    };
    clipboardItem?: ClipboardItem;
  };
};

const initialState: ProceduresSliceState = {};

const getInitialTeamState = () => {
  return {
    loading: true,
    docs: {},
    pending: [],
    synced: {},
    metadata: {},
  };
};

/**
 * Fetch all metadata for all procedures.
 *
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current ProcedureService.
 */
export const fetchAllProceduresMetadata = createAsyncThunk(
  'procedures/fetchAllProceduresMetadata',
  async ({ services }: { services: DatabaseServices }) => {
    if (!services || !services.procedures) {
      throw new Error('No procedures service found');
    }

    const teamId = services.procedures.getTeamId();

    if (!teamId) {
      throw new Error('No team id found');
    }

    const proceduresMetadata =
      await services.procedures.getAllProceduresMetadata();

    return {
      teamId,
      proceduresMetadata,
    } as ProceduresMetadataResults;
  }
);

/**
 * Fetch all metadata for all procedures.
 *
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current ProcedureService.
 */
export const fetchProceduresMetadata = createAsyncThunk(
  'procedures/fetchProceduresMetadata',
  async ({
    services,
    docs,
  }: {
    services: DatabaseServices;
    docs: DocChange[];
  }) => {
    if (!services || !services.procedures) {
      throw new Error('No procedures service found');
    }

    const teamId = services.procedures.getTeamId();

    if (!teamId) {
      throw new Error('No team id found');
    }

    const proceduresMetadata = await services.procedures.getProceduresMetadata(
      docs
    );

    return {
      teamId,
      proceduresMetadata,
      deletedDocs: docs.filter((doc) => doc.deleted),
    } as ProceduresMetadataResults;
  }
);

export const fetchProcedureById = createAsyncThunk(
  'procedures/fetchProcedureById',
  async (
    {
      services,
      procedureId,
    }: { services: DatabaseServices; procedureId: string },
    { rejectWithValue }
  ) => {
    if (!services || !services.procedures) {
      throw new Error('No procedures service found');
    }

    const teamId = services.procedures.getTeamId();

    if (!teamId) {
      throw new Error('No team id found');
    }

    try {
      const procedure = await services.procedures.getProcedure(procedureId);

      return {
        teamId,
        procedure,
      };
    } catch (err) {
      if (err.status === 404) {
        return rejectWithValue({ status: 404 });
      }

      throw err;
    }
  }
);

const reduceProcedure = (state, action) => {
  const { teamId, procedure } = action.payload;

  if (!state[teamId]) {
    state[teamId] = getInitialTeamState();
  }

  state[teamId].docs[procedure._id] = procedure;
  state[teamId].loading = false;

  return state;
};

const reduceCopyItemToClipboard = (state, action) => {
  const { teamId, clipboardItem } = action.payload;

  if (!state[teamId]) {
    state[teamId] = getInitialTeamState();
  }

  state[teamId].clipboardItem = clipboardItem;
};

const reduceProcedureMetadata = (state, action) => {
  const { teamId, proceduresMetadata } = action.payload;
  const metadata = {};
  // Create a key value pair for procedures.
  proceduresMetadata.forEach((procedureMetadata) => {
    metadata[procedureMetadata._id] = procedureMetadata;
  });

  if (!state[teamId]) {
    state[teamId] = getInitialTeamState();
  }

  state[teamId].loading = false;
  state[teamId].metadata = metadata;
  return state;
};

export const proceduresSlice = createSlice({
  name: 'procedures',
  initialState,
  reducers: {
    syncAllProcedureData: {
      reducer: (state) => state,
      prepare: (teamId, proceduresMetadata) => {
        return {
          payload: {
            teamId,
            proceduresMetadata,
          },
        };
      },
    },
    syncProcedureData: {
      reducer: (state) => state,
      prepare: (teamId, procedure) => {
        return {
          payload: {
            teamId,
            procedure,
          },
        };
      },
    },
    copyItemToClipboard: {
      reducer: reduceCopyItemToClipboard,
      prepare: (teamId, clipboardItem: ClipboardItem) => {
        return {
          payload: {
            teamId,
            clipboardItem,
          },
        };
      },
    },
  },
  /**
   * Use builder callback approach with Typescript
   * https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers
   */
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProceduresMetadata.pending, (state, action) => {
        /**
         * For the pending action, payload is undefined and parameters are passed down via meta.arg.
         * See https://github.com/reduxjs/redux-toolkit/issues/776
         */
        const teamId = action.meta.arg.services.procedures?.getTeamId();

        if (!teamId) {
          return state;
        }

        if (!state[teamId]) {
          state[teamId] = getInitialTeamState();
        }

        state[teamId].loading = true;

        return state;
      })
      .addCase(fetchAllProceduresMetadata.rejected, (state, action) => {
        /**
         * For the rejected action, payload is undefined and parameters are passed down via meta.arg.
         * See https://github.com/reduxjs/redux-toolkit/issues/776
         */
        const teamId = action.meta.arg.services.procedures?.getTeamId();

        if (!teamId) {
          return state;
        }

        if (!state[teamId]) {
          state[teamId] = getInitialTeamState();
        }

        state[teamId].loading = false;

        return state;
      })
      .addCase(fetchAllProceduresMetadata.fulfilled, reduceProcedureMetadata)
      .addCase(fetchProceduresMetadata.rejected, (state, action) => {
        /**
         * For the rejected action, payload is undefined and parameters are passed down via meta.arg.
         * See https://github.com/reduxjs/redux-toolkit/issues/776
         */
        const teamId = action.meta.arg.services.procedures?.getTeamId();
        const deletedDocs = action.meta.arg.docs.filter((doc) => doc.deleted);

        if (!teamId) {
          return state;
        }

        if (!state[teamId]) {
          state[teamId] = getInitialTeamState();
        }

        deletedDocs.forEach((doc) => {
          delete state[teamId].docs[doc._id];
          delete state[teamId].synced[doc._id];
          delete state[teamId].metadata[doc._id];
        });

        state[teamId].loading = false;

        return state;
      })
      .addCase(fetchProceduresMetadata.fulfilled, (state, action) => {
        const { teamId, proceduresMetadata, deletedDocs } = action.payload;

        if (!state[teamId]) {
          state[teamId] = getInitialTeamState();
        }

        proceduresMetadata.forEach((procedureMetadata) => {
          const currentRev =
            state[teamId].metadata?.[procedureMetadata._id]?._rev;
          if (
            !currentRev ||
            couchdbUtil.isEarlierRev(currentRev, procedureMetadata._rev)
          ) {
            state[teamId].metadata[procedureMetadata._id] = procedureMetadata;
          }
        });

        deletedDocs.forEach((doc) => {
          delete state[teamId].docs[doc._id];
          delete state[teamId].synced[doc._id];
          delete state[teamId].metadata[doc._id];
        });

        state[teamId].loading = false;
        return state;
      })
      .addCase(fetchProcedureById.pending, (state, action) => {
        /**
         * For the pending action, payload is undefined and parameters are passed down via meta.arg.
         * See https://github.com/reduxjs/redux-toolkit/issues/776
         */
        const teamId = action.meta.arg.services.procedures?.getTeamId();

        if (!teamId) {
          return state;
        }

        if (!state[teamId]) {
          state[teamId] = getInitialTeamState();
        }

        state[teamId].loading = true;

        return state;
      })
      .addCase(fetchProcedureById.rejected, (state, action) => {
        /**
         * For the rejected action, payload is undefined and parameters are passed down via meta.arg.
         * See https://github.com/reduxjs/redux-toolkit/issues/776
         */
        const teamId = action.meta.arg.services.procedures?.getTeamId();
        const procedureId = action.meta.arg.procedureId;

        if (!teamId) {
          return state;
        }

        if (!state[teamId]) {
          state[teamId] = getInitialTeamState();
        }

        if (
          action.error &&
          action.error.message === 'Rejected' &&
          (action.payload as ProceduresError).status === 404
        ) {
          delete state[teamId].docs[procedureId];
        }

        state[teamId].loading = false;

        return state;
      })
      .addCase(fetchProcedureById.fulfilled, reduceProcedure)
      .addCase(SYNC_PROCEDURE_DATA_SUCCEEDED, (state, action) => {
        const { teamId, procedures } =
          // @ts-ignore, TODO Convert this external action to Typescript
          action.payload;

        // Create a key value pair for procedures

        if (!state[teamId]) {
          state[teamId] = getInitialTeamState();
        }
        if (!state[teamId].synced) {
          state[teamId].synced = {};
        }
        for (const procedure of procedures) {
          state[teamId].docs[procedure._id] = procedure;

          state[teamId].synced[procedure._id] = {
            id: procedure._id,
            rev: procedure._rev,
            synced: true,
          };
        }

        return state;
      })
      .addCase(SYNC_PROCEDURE_DATA_FAILED, (state) => {
        /**
         * Ignored, we currently don't remove synced procedure data, so once
         * procedure data is synced it will stay synced.
         *
         * TODO: Find a way to manage synced procedure data and cleanup data.
         */
        return state;
      });
  },
});

export const { syncAllProcedureData, syncProcedureData, copyItemToClipboard } =
  proceduresSlice.actions;

export const selectProcedures = (
  state: unknown,
  teamId: string
): { [id: string]: Procedure } => {
  // TODO: Convert store.js to Typescript
  const _state = state as StoreState;
  // Returns an empty object because calling code expects an object returned in any case.
  if (!_state.procedures[teamId]) {
    return {};
  }

  return _state.procedures[teamId].docs;
};

/**
 * Selects all procedures but leaves out the drafts for procedures that have a released version
 */
export const selectProceduresNoDraftsForReleased = (
  state: unknown,
  teamId: string
): { [id: string]: Procedure } => {
  // TODO: Convert store.js to Typescript
  const _state = state as StoreState;
  // Returns an empty object because calling code expects an object returned in any case.
  if (!_state.procedures[teamId]) {
    return {};
  }

  const docs = _state.procedures[teamId].docs;
  const filteredDocs = {};
  const seen = new Set();
  for (const docId in docs) {
    if (seen.has(getProcedureId(docs[docId]))) {
      if (!docId.startsWith('index_')) {
        filteredDocs[docId] = docs[docId];
        delete filteredDocs[getPendingProcedureIndex(docId)];
      }
    } else {
      seen.add(docId);
      filteredDocs[docId] = docs[docId];
    }
  }
  return filteredDocs;
};

export const selectReleasedProcedures = (
  state: unknown,
  teamId: string
): Procedure[] => {
  // TODO: Convert store.js to Typescript
  const _state = state as StoreState;
  // Returns an empty object because calling code expects an object returned in any case.
  if (!_state.procedures[teamId]) {
    return [];
  }

  return (
    Object.values(_state.procedures[teamId].docs)
      // @ts-ignore, TODO Convert root state to Typescript in store.js
      .filter((procedure) => !procedure.archived && isReleased(procedure))
  );
};

export const selectProceduresMetadata = (
  state: unknown,
  teamId: string
): { [id: string]: ProcedureMetadata } => {
  // TODO: Convert store.js to Typescript
  const _state = state as StoreState;
  // Returns an empty object because calling code expects an object returned in any case.
  if (!_state.procedures[teamId]) {
    return {};
  }

  return _state.procedures[teamId].metadata;
};

export const selectProcedureById = (
  state: unknown,
  teamId: string,
  procedureId: string
): Procedure | null => {
  // TODO: Convert store.js to Typescript
  const _state = state as StoreState;
  // Returns null to identify procedure does not exist if team is not stored in redux.
  if (!_state.procedures[teamId]) {
    return null;
  }

  return _state.procedures[teamId].docs[procedureId];
};

/**
 * Selects all procedure sync data for a team.
 *
 * @param {String} teamId - Team/workspace id for procedure.
 * @returns {Object} - Map of procedure id to procedure sync metadata object.
 *   See module data structure for details.
 */
export const selectProceduresSynced = (
  state: unknown,
  teamId: string
): { [id: string]: DocSynced } => {
  // TODO: Convert store.js to Typescript
  const _state = state as StoreState;
  if (!_state.procedures[teamId]) {
    return {};
  }

  return _state.procedures[teamId].synced || {};
};

/**
 * Helper method for getting procedure sync status.
 *
 * @param {Object} proceduresSynced - The proceduresSynced dictionary from
 *   `selectProceduresSynced`.
 * @param {String} procedureId - Procedure id.
 * @param {String} procedureRev - Procedure revision.
 * @returns {Boolean} - True if procedure data is synced locally, otherwise false.
 */
export const isProcedureSynced = (
  proceduresSynced: { [id: string]: DocSynced },
  procedureId: string,
  procedureRev: string
): boolean => {
  if (
    !proceduresSynced[procedureId] ||
    proceduresSynced[procedureId].rev !== procedureRev
  ) {
    return false;
  }
  return proceduresSynced[procedureId].synced === true;
};

export const selectProceduresLoading = (
  state: unknown,
  teamId: string
): boolean => {
  // TODO: Convert store.js to Typescript
  const _state = state as StoreState;
  // Returns true as default case of procedures loading (if team data does not exist, it is being loaded).
  if (!_state.procedures[teamId]) {
    return true;
  }

  return _state.procedures[teamId].loading;
};

export const selectClipboardItem = (
  state: unknown,
  teamId: string
): undefined | ClipboardItem => {
  // TODO: Convert store.js to Typescript
  const _state = state as StoreState;

  return _state.procedures[teamId] && _state.procedures[teamId].clipboardItem;
};

export default proceduresSlice.reducer;
