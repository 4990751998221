import { useHistory } from 'react-router-dom';
import { Event } from 'shared/schedule/types/event';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { eventPath } from '../../lib/pathUtil';
import { DateTime } from 'luxon';

const useEventNav = () => {
  const history = useHistory();
  const { currentTeamId } = useDatabaseServices();

  const navigateToEventPage = (event: Event) => {
    if (event) {
      let id = event.id;
      if (event.rrule) {
        id += btoa((event.start as DateTime).toISO());
      }
      history.push(eventPath(currentTeamId, id), { from: 'schedule' });
    }
  };

  return {
    navigateToEventPage,
  };
};

export default useEventNav;
