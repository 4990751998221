import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type BackToProps = {
  label: string;
  onClick: () => void;
};

const BackTo = ({ label, onClick }: BackToProps) => {
  return (
    <div className="flex flex-row gap-x-2 h-full p-1">
      <button
        type="button"
        className="flex flex-row gap-x-1 items-center text-sm cursor-pointer hover:bg-slate-300 rounded-md mr-1 px-1"
        onClick={onClick}
      >
        <FontAwesomeIcon icon="arrow-left" />
        <div className="max-w-[120px] truncate">To {label}</div>
      </button>
      <div className="h-full w-0.5 bg-gray-300"></div>
    </div>
  );
};

export default React.memo(BackTo);
