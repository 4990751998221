import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import couchdbUtil, { CouchDBChanges } from '../lib/couchdbUtil';
import { fetchAllProceduresMetadata, fetchProceduresMetadata } from './proceduresSlice';
import { useDatabaseServices } from './DatabaseContext';
import { io, Socket } from 'socket.io-client';
import { API_URL } from '../config';
import { useUserInfo } from './UserContext';
import { getAllTeamIdsFromSession } from '../api/superlogin';
import RealtimeService from '../api/realtime';

export interface RealtimeContextValues {
  realtimeService: undefined | RealtimeService;
}
type RealtimeServices = {
  [teamId: string]: RealtimeService;
};
const RealtimeContext = React.createContext<RealtimeContextValues | undefined>(undefined);

const createRealtimeServices = (teamIds: Array<string> | null, socket: Socket): RealtimeServices | undefined => {
  if (!teamIds) {
    return undefined;
  }

  const realtimeServices: RealtimeServices = {};

  teamIds.forEach((teamId) => {
    realtimeServices[teamId] = new RealtimeService(teamId, socket);
  });

  return realtimeServices;
};

// Enables realtime syncing of data between API and the redux store.
const RealtimeProvider = ({ children }: { children: ReactNode }) => {
  const { services, currentTeamId } = useDatabaseServices();
  const [realtimeServices, setRealtimeServices] = useState<RealtimeServices | undefined>(undefined); // Use one state for both currentTeamId and teamsServices to avoid double rendering when setting both.

  const realtimeService = useMemo(() => {
    if (!realtimeServices || !currentTeamId) {
      return undefined;
    }

    return realtimeServices[currentTeamId];
  }, [realtimeServices, currentTeamId]);
  const dispatch = useDispatch();
  const { userInfo } = useUserInfo();

  const syncProceduresMetadata = useCallback(
    async (changes?: CouchDBChanges) => {
      try {
        if (changes) {
          // Dispatch a sync with only the changed docs.
          const docs = couchdbUtil.getChangedDocs(changes);
          // @ts-ignore, TODO convert proceduresSlice.js to Typescript
          await dispatch(
            fetchProceduresMetadata({
              services,
              docs,
            })
          );
        } else {
          // Dispatch a full sync of the procedure metadata.
          /* @ts-ignore, TODO convert proceduresSlice.js to Typescript */
          await dispatch(fetchAllProceduresMetadata({ services }));
        }
      } catch {
        // Ignore errors, we may be offline.
      }
    },
    [services, dispatch]
  );

  useEffect(() => {
    if (!services.procedures) {
      return;
    }
    const observer = services.procedures.onProceduresChanged(syncProceduresMetadata);
    syncProceduresMetadata();

    return () => {
      if (observer) {
        observer.cancel();
      }
    };
  }, [services, syncProceduresMetadata]);

  useEffect(() => {
    if (!userInfo.session) {
      return;
    }
    const teamIds = getAllTeamIdsFromSession(userInfo.session);
    const socket = io(`${API_URL}/roomListeners`, {
      auth: {
        token: `Bearer ${userInfo.session.token}:${userInfo.session.password}`,
      },
    });

    // Set up initial services to allow for initial sync and testing
    let initialRealtimeServices = createRealtimeServices(teamIds, socket);
    setRealtimeServices(initialRealtimeServices);

    socket.on('connect', () => {
      initialRealtimeServices = createRealtimeServices(teamIds, socket);
      setRealtimeServices(initialRealtimeServices);
    });
    socket.on('disconnect', (reason) => {
      setRealtimeServices(undefined);
    });

    return () => {
      socket.close();
    };
  }, [userInfo.session, userInfo.session?.userOrgData]);

  return (
    <RealtimeContext.Provider
      value={{
        realtimeService,
      }}
    >
      {children}
    </RealtimeContext.Provider>
  );
};

const useRealtimeContext = () => {
  const context = useContext(RealtimeContext);
  if (context === undefined) {
    throw new Error('useRealtimeContext must be used within a TeamsProvider');
  }
  return context;
};

export { RealtimeProvider, useRealtimeContext };
