import React from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <div className="flex pl-3 last:pl-4">
        <FontAwesomeIcon className="text-gray-500" icon="info-circle" />
      </div>
      {children}
    </components.Control>
  );
};

/**
 * Renders a step dependency multi select.
 *
 * @param {Object} props
 * @param {Array} props.options - Dropdown options to select from.
 * @param {String} props.name - Name to pass thru to react-select component.
 * @param {HTMLElement} props.placeholder - HTML element to use as placeholder.
 * @param {Number} props.opacity - Opacity of select control element.
 * @param {Number} props.hoverOpacity - Opacity of select control element on hover.
 * @param {Array} props.value - Initial value of selector.
 * @param {Function} props.onChange - Callback triggered on selector change.
 * @param {Function} props.onBlur - Callback to pass thru to react-select component.
 * @param {String} props.ariaLabel - Aria label for selector component.
 */
const StepDependencySelect = ({
  options,
  name,
  placeholder,
  opacity,
  hoverOpacity,
  value,
  onChange,
  onBlur,
  ariaLabel,
}) => {
  const styles = {
    control: (base) => ({
      ...base,
      borderColor: 'rgba(156, 163, 175)',
      backgroundColor: 'white',
      opacity,
      '&:hover': { opacity: hoverOpacity },
      borderWidth: 1,
      minWidth: 40,
    }),
    valueContainer: (base) => ({
      ...base,
      minWidth: 0,
      paddingRight: 0,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: 'rgba(156, 163, 175)',
    }),
  };

  const Placeholder = () => {
    return <div className="text-gray-600">{placeholder}</div>;
  };
  return (
    <Select
      classNamePrefix="react-select"
      styles={styles}
      options={options}
      name={name}
      components={{
        Control,
        Placeholder,
        IndicatorSeparator: () => null,
      }}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      isMulti
      placeholder
      isClearable={false}
      aria-label={ariaLabel}
    />
  );
};

export default React.memo(StepDependencySelect);
