import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { PERM } from '../lib/auth';
import { useAuth } from '../contexts/AuthContext';
import { useRunContext } from '../contexts/RunContext';
import Button, { BUTTON_TYPES } from './Button';
import TooltipOverlay from './TooltipOverlay';
import DateTimeDisplay from './DateTimeDisplay';
import { useNavState } from '../contexts/NavContext';
import runUtil from '../lib/runUtil';
import useJumpToStep from '../hooks/useJumpToStep';
import { ACTION_TYPE, isStepEnded } from 'shared/lib/runUtil';
import { useSelector } from 'react-redux';
import { selectOfflineInfo } from '../app/offline';
import { useProcedureContext } from '../contexts/ProcedureContext';
import useProcedureAdapter from '../hooks/useProcedureAdapter';
import procedureUtil from '../lib/procedureUtil';
import { useSettings } from '../contexts/SettingsContext';

// Returns string with "'sectionKey'. 'sectionName'"
const getSectionLabel = (section, allSections, getSetting) => {
  const sectionKey = runUtil.displaySectionKey(
    allSections,
    section.index,
    getSetting('display_sections_as', 'letters')
  );

  if (section.name) {
    return `${sectionKey}. ${section.name}`;
  }

  return `${sectionKey}.`;
};

// Returns string with "'stepKey'. 'stepName'"
const getStepLabel = (section, step, allSections, getSetting) => {
  const stepLabel = runUtil.displaySectionStepKey(
    allSections,
    section.index,
    step.index,
    getSetting('display_sections_as', 'letters')
  );

  if (step.name) {
    return `${stepLabel}. ${step.name}`;
  }

  return `${stepLabel}.`;
};

const PausedStickyHeader = ({ pausedAction, resumeRun, projectId, resumeAutomation, run }) => {
  const { auth } = useAuth();
  const { isUserParticipant } = useRunContext();
  const navState = useNavState();
  const online = useSelector((state) => selectOfflineInfo(state).online);
  const [currentStepId, setCurrentStepId] = useState('');
  const [previousStepId, setPreviousStepId] = useState('');
  const [stepEnded, setStepEnded] = useState(false);
  const jumpToStep = useJumpToStep(currentStepId);
  const { getItemPath } = useProcedureContext();
  const { getAllSections, getSectionSummary, getStepSummary } = useProcedureAdapter();
  const { getSetting } = useSettings();
  const [isResuming, setIsResuming] = useState(false);

  const pauseReasons = {
    user: pausedAction.type === ACTION_TYPE.PAUSE,
    issue: pausedAction.type === ACTION_TYPE.ISSUE_PAUSE,
    automation: pausedAction.type === ACTION_TYPE.AUTOMATION_PAUSE,
    resolved: pausedAction.type === ACTION_TYPE.ALL_ISSUES_RESOLVED,
  };

  const userCanResume = !pauseReasons.issue;

  useEffect(() => {
    // Any change to pauseAction will re-enable the resume button
    setIsResuming(false);
  }, [pausedAction]);

  const onResumeRun = useCallback(() => {
    setIsResuming(true);
    if (pauseReasons.automation) {
      resumeAutomation(previousStepId);
    } else {
      resumeRun();
    }
  }, [previousStepId, resumeAutomation, resumeRun, pauseReasons.automation]);

  const isAutomationDisabled = useCallback(() => {
    return (
      currentStepId &&
      (!auth.hasPermission(PERM.RUNS_EDIT, run.project_id) || !isUserParticipant || !stepEnded || !online)
    );
  }, [auth, isUserParticipant, currentStepId, run.project_id, stepEnded, online]);

  useEffect(() => {
    const result = runUtil.findFirstPausedStep(run);

    if (result) {
      setCurrentStepId(result.id);
      setPreviousStepId(result.id);

      if (result.isStepEnded) {
        setStepEnded(true);
      }
    } else {
      const currentStep = run.sections.flatMap((section) => section.steps).find((step) => step.id === currentStepId);
      if (currentStep && isStepEnded(currentStep)) {
        setStepEnded(false);
        setCurrentStepId('');
      }
    }
  }, [run, currentStepId]);

  const formatJumpToMessage = () => {
    const path = getItemPath(currentStepId);
    const { sectionId, stepId } = procedureUtil.parsePath(path);
    const sectionSummary = getSectionSummary(sectionId);
    if (!sectionSummary) return null;

    const allSections = getAllSections();
    const stepSummary = stepId && getStepSummary(stepId, sectionId);
    return stepSummary
      ? getStepLabel(sectionSummary, stepSummary, allSections, getSetting)
      : getSectionLabel(sectionSummary, allSections, getSetting);
  };

  return (
    <div
      data-testid="pausedHeader"
      className={`fixed top-10 ${navState.collapsed ? 'left-16' : 'left-64'}
      transition-all right-0 px-4 lg:px-8 h-10 flex flex-row justify-between shadow-md bg-amber-100 border-t z-40 print:hidden`}
    >
      <div className="flex flex-row items-center gap-x-2 relative">
        {pauseReasons.user && (
          <span>
            This run has been paused by <span className="font-bold">{pausedAction.user_id}</span>.
          </span>
        )}
        {pauseReasons.issue && <span>Critical issue reported. This run is paused.</span>}
        {pauseReasons.resolved && (
          <span>No more critical issues are currently blocking this run. This run can resume.</span>
        )}
        {pauseReasons.automation && currentStepId && (
          <div className="flex flex-row gap-x-2">
            <span>Automation is paused:</span>
            <Button type={BUTTON_TYPES.TERTIARY} onClick={jumpToStep} removePadding={true}>
              <span className="underline text-blue-600">{formatJumpToMessage()}</span>
            </Button>
            <span> needs review.</span>
          </div>
        )}
        {pauseReasons.automation && !currentStepId && <span>Automation is paused.</span>}

        <TooltipOverlay
          content={
            <div className="flex flex-col p-1 max-w-screen-sm">
              <div className="flex flex-row text-sm gap-x-2">
                {(pauseReasons.user || pauseReasons.issue || pauseReasons.resolved) && (
                  <>
                    {pauseReasons.user && (
                      <>
                        <span className="font-semibold whitespace-nowrap">Run Paused by</span>
                        <span className="text-gray-600 whitespace-nowrap">{pausedAction.user_id}</span>
                      </>
                    )}
                    {pauseReasons.issue && (
                      <span className="font-semibold whitespace-nowrap">Critical issue reported</span>
                    )}
                    {pauseReasons.resolved && <span className="font-semibold whitespace-nowrap">Issues resolved</span>}
                    <DateTimeDisplay timestamp={pausedAction.timestamp} hasTooltip={false} wrap={false} />
                  </>
                )}
                {pauseReasons.automation && (
                  <span className="font-semibold whitespace-nowrap">Paused by Automation</span>
                )}
              </div>
              {pauseReasons.user && <div className="my-2 break-words whitespace-pre-wrap">{pausedAction.comment}</div>}
            </div>
          }
        >
          <FontAwesomeIcon className="cursor-pointer text-blue-600 hover:text-blue-800" icon="info-circle" />
        </TooltipOverlay>

        {userCanResume && (
          <Button
            type={BUTTON_TYPES.SECONDARY}
            title="Resume"
            ariaLabel="Resume"
            onClick={onResumeRun}
            isDisabled={
              pauseReasons.automation
                ? Boolean(isAutomationDisabled())
                : !auth.hasPermission(PERM.RUNS_EDIT, projectId) || !isUserParticipant || isResuming
            }
          >
            <FontAwesomeIcon icon="play" className="text-xs" />
            <span className="text-xs font-semibold uppercase">Resume</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default React.memo(PausedStickyHeader);
