import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const ICONS: { [id: number]: IconProp } = {
  0: 'spinner',
  1: 'refresh',
  2: 'hourglass',
};

export const getIcon = (usageType: string): IconProp => {
  switch (usageType) {
    case 'cycles':
      return 'refresh';
    case 'time':
    case 'seconds':
      return 'hourglass';
    default:
      return 'spinner';
  }
};
