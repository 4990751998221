import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import procedureUtil from '../../../lib/procedureUtil';
import { useProcedureContext } from '../../../contexts/ProcedureContext';
import { useRunContext } from '../../../contexts/RunContext';
import ExpressionTokenDisplay from '../../Expression/ExpressionTokenDisplay';
import useProcedureAdapter from '../../../hooks/useProcedureAdapter';
import InvalidMessage from '../../InvalidMessage';
import { useBlockState } from '../../../contexts/BlockContext';
import { ExpressionReferences } from 'shared/lib/types/views/procedures';

interface ReferenceTokenProps {
  originalReferencedContentId: string;
  references?: ExpressionReferences;
}

const ReferenceToken = ({ originalReferencedContentId, references }: ReferenceTokenProps) => {
  const { scrollTo, getContentItemPath } = useProcedureContext();
  const { isActiveRun } = useRunContext();
  const { getReferencedContentContext } = useProcedureAdapter();
  const { isValid } = useBlockState();

  const referencedContentContext = getReferencedContentContext(
    originalReferencedContentId,
    undefined,
    references?.[originalReferencedContentId]
  );
  const {
    referencedFromStepKey,
    referencedFromSectionKey,
    sectionRepeatKey,
    stepRepeatKey,
    referencedContent,
    stepRecordedState,
    isVariable,
    isVariableRecorded,
  } = referencedContentContext ?? {};

  const goToContent = useCallback(
    (destinationContentId) => {
      const contentPath = getContentItemPath(destinationContentId);
      // If contentPath is null, it could mean a procedure variable is referenced.
      const { sectionId, stepId, stepHeaderId } = procedureUtil.parseContentPath(contentPath);
      scrollTo({
        sectionId,
        stepId,
        stepHeaderId,
        contentId: destinationContentId,
      });
    },
    [getContentItemPath, scrollTo]
  );

  const goToSourceContent = useCallback(
    () => referencedContent && goToContent(referencedContent.id),
    [goToContent, referencedContent]
  );
  return (
    <>
      {isValid && referencedContentContext && referencedContent && (
        <ExpressionTokenDisplay
          referenceName={referencedContent.name}
          onClick={goToSourceContent}
          referencedFromStepKey={referencedFromStepKey}
          referencedFromSectionKey={referencedFromSectionKey}
          sectionRepeatKey={sectionRepeatKey}
          stepRepeatKey={stepRepeatKey}
          stepRecordedState={stepRecordedState}
          inRunningRun={isActiveRun}
          isVariable={Boolean(isVariable)}
          isVariableRecorded={Boolean(isVariableRecorded)}
        />
      )}
      {isValid && !(referencedContentContext && referencedContent) && (
        <span className="mt-2 mr-8 py-2 w-full">
          <FontAwesomeIcon className="mr-2 text-red-500" icon="exclamation-circle" />
          Referenced Input Field Not Found
        </span>
      )}
      {!isValid && (
        <div className="px-2">
          <InvalidMessage>Reference no longer valid</InvalidMessage>
        </div>
      )}
    </>
  );
};

export default ReferenceToken;
