import { Reading, TelemetryParam } from 'shared/lib/types/telemetry';
import Simulator, { REFRESH_INTERVAL_MS } from './simulation';
import TelemetryService from '../api/telemetry';

class BulkParametersObserver {
  service: TelemetryService;
  requestPending: boolean;
  timer: NodeJS.Timer | null;
  simulator: Simulator;
  runId: string;
  onChange: (samples: Record<string, Reading>) => void;
  onSignalLost: () => void;

  constructor(
    telemetryService: TelemetryService,
    runId: string,
    onChange: (samples: Record<string, Reading>) => void,
    onSignalLost: () => void
  ) {
    this.requestPending = false;
    this.service = telemetryService;
    this.timer = null;
    this.simulator = new Simulator();
    this.runId = runId;
    this.onChange = onChange;
    this.onSignalLost = onSignalLost;
  }

  private _getLatestSample(
    identifiers: Array<TelemetryParam>,
    previewProcedureVariables?: Array<object>
  ): Promise<Array<PromiseSettledResult<Record<string, Reading>>>> {
    // fetch simulated and bulk in separate promises.  Shouldn't have simulated in prod
    const samples = [
      this.simulator.getSamples(),
      this.service.getLatestSampleBulk(
        identifiers,
        this.runId,
        previewProcedureVariables
      ),
    ];
    return Promise.allSettled(samples);
  }

  private _refreshSamples(
    identifiers: Array<TelemetryParam>,
    previewProcedureVariables?: Array<object>
  ): void {
    this._getLatestSample(identifiers, previewProcedureVariables)
      .then((samples: Array<PromiseSettledResult<Record<string, Reading>>>) => {
        samples.forEach((sample) => {
          if (sample.status === 'fulfilled') {
            this.onChange(sample.value);
          } else {
            this.onSignalLost();
          }
        });
      })
      .catch(() => {
        /* noop */
      })
      .finally(() => {
        this.requestPending = false;
      });
  }

  observeParameters(
    identifiers: Array<TelemetryParam>,
    previewProcedureVariables?: Array<object>
  ): void {
    this.simulator.setParameters(
      identifiers.filter((identifier) => identifier.isSimulation)
    );
    const nonSimulatedIdentifiers = identifiers.filter(
      (identifier) => !identifier.isSimulation
    );

    // refresh samples on every interval if no request is pending
    this.timer = null;
    this.timer = setInterval(() => {
      if (!this.requestPending) {
        this.requestPending = true;
        this._refreshSamples(
          nonSimulatedIdentifiers,
          previewProcedureVariables
        );
      }
    }, REFRESH_INTERVAL_MS);
  }

  clear(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}

export default BulkParametersObserver;
