import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import procedureUtil from '../lib/procedureUtil';
import { ContentBlock, RunStepComment } from 'shared/lib/types/views/procedures';
import { Mention } from 'shared/lib/types/postgres/util';
import CommentButton from './CommentButton';
import { generateCommentId } from 'shared/lib/idUtil';
import { activityToComment, CommentInterface, commentToActivity } from 'shared/lib/comment';

interface CommentWrapperProps {
  children?: ReactNode;
  content: ContentBlock;
  comments?: Array<RunStepComment>;
  isRun?: boolean;
  isCommentingDisabled?: boolean;
  saveNewComment?: (comment: CommentInterface | Omit<RunStepComment, 'timestamp' | 'user'>) => void;
  editComment?: (comment: RunStepComment) => void;
}

const CommentWrapper = ({
  children,
  comments,
  content,
  isRun,
  isCommentingDisabled = false,
  saveNewComment,
  editComment,
}: CommentWrapperProps) => {
  const [showCommentIcon, setShowCommentIcon] = useState(false);
  const contentRef = useRef<HTMLTableCellElement>(null);

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    const hideCommentIcon = () => {
      setShowCommentIcon(false);
    };

    if (showCommentIcon) {
      document.addEventListener('click', hideCommentIcon);
    }

    return () => {
      document.removeEventListener('click', hideCommentIcon);
    };
  }, [showCommentIcon]);

  const contentActivities = useMemo(() => {
    return comments?.filter((comment) => comment.reference_id === content.id).map(commentToActivity);
  }, [comments, content.id]);

  const saveTextComment = useMemo(() => {
    if (typeof saveNewComment !== 'function') {
      return;
    }
    return async (newComment: string, mentions: Array<Mention>) => {
      const trimmed = procedureUtil.trimLeadingAndTrailing(newComment);
      const newMentions = procedureUtil.getValidMentions(mentions, trimmed);

      const comment = {
        id: generateCommentId(),
        parent_id: '',
        text: trimmed,
        mention_list: newMentions,
        reference_id: content.id,
      };
      return saveNewComment(comment);
    };
  }, [content.id, saveNewComment]);

  const saveEditComment = useMemo(() => {
    if (typeof editComment !== 'function') {
      return;
    }
    return async (editedActivity: CommentInterface, commentId) => {
      const existingActivity = contentActivities?.find((comment) => comment.id === commentId);
      if (!existingActivity) {
        return;
      }
      const comment: RunStepComment = {
        ...activityToComment(existingActivity),
        text: editedActivity.comment,
        mention_list: editedActivity.mentions,
        updated_at: editedActivity.updated_at,
      };

      return editComment(comment);
    };
  }, [contentActivities, editComment]);

  const onWrapperClick = useCallback(() => {
    setShowCommentIcon(true);
  }, []);

  const iconDisplayClass = useMemo(() => {
    const hasContentComments = contentActivities && contentActivities.length > 0;
    if (isMobile) {
      return showCommentIcon || hasContentComments ? 'opacity-100' : 'opacity-0';
    }

    return !hasContentComments ? 'opacity-0 group-hover/commentswrapper:opacity-100' : 'opacity-100';
  }, [showCommentIcon, contentActivities]);

  return (
    <tr className="relative group/commentswrapper" onClick={isMobile ? onWrapperClick : undefined}>
      <td colSpan={4} ref={contentRef} className="relative pl-4">
        {children}
        <div className={`absolute top-1 right-1 ${iconDisplayClass}`}>
          {!isCommentingDisabled && (
            <CommentButton
              comments={contentActivities}
              saveTextComment={saveTextComment}
              saveEditComment={saveEditComment}
              showNewCommentIcon={isRun}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default CommentWrapper;
