import { useNavState } from '../../contexts/NavContext';
import React, { useMemo } from 'react';
import { ProcedureState } from 'shared/lib/types/views/procedures';
import ReviewStatusLabel from '../../components/ReviewStatusLabel';
import DraftStatusLabel from '../../components/DraftStatusLabel';
import ReleaseStatusLabel from '../../components/ReleaseStatusLabel';
import BackTo from '../../components/StickyHeader/Buttons/BackTo';
import EditDeleteButtons from './EditDeleteButtons';

interface StickyHeaderProps {
  code: string;
  name: string;
  onDelete?: () => void;
  resourcePath: string;
  projectId?: string;
  showEditDeleteButtons?: boolean;
  state?: ProcedureState;
  source?: {
    title: string;
    onClick: () => void;
  };
}
const StickyHeader = ({
  code,
  name,
  onDelete,
  resourcePath,
  projectId,
  showEditDeleteButtons,
  state,
  source,
}: StickyHeaderProps) => {
  const navState = useNavState();

  const title = useMemo(() => {
    if (!code && !name) {
      return 'Untitled';
    }
    if (!code || !name) {
      return code || name;
    }
    return `${code} ${name}`;
  }, [code, name]);

  return (
    <div
      data-testid="procedureHeader"
      className={`fixed top-0 ${
        navState.collapsed ? 'left-16' : 'left-64'
      } transition-all right-0 px-3 h-10 flex flex-row justify-between align-middle shadow-md bg-gray-50 z-50 print:hidden`}
    >
      <div className="flex flex-row items-center gap-x-2 w-1/2">
        {source && <BackTo label={source.title} onClick={source.onClick} />}
        <div className="truncate">{title}</div>
        {state === 'draft' && <DraftStatusLabel />}
        {state === 'in_review' && <ReviewStatusLabel isReviewApproved={false} />}
        {state === 'released' && <ReleaseStatusLabel />}
      </div>
      <div className="flex flex-row gap-x-2 mt-1 mb-1">
        {showEditDeleteButtons && (
          <EditDeleteButtons
            onDelete={onDelete}
            isInReview={state === 'in_review'}
            resourcePath={resourcePath}
            projectId={projectId}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(StickyHeader);
