import React, { useMemo, useCallback, useContext } from 'react';
import procedureUtil from '../lib/procedureUtil';
import { useSettings } from './SettingsContext';
import stepConditionals from 'shared/lib/stepConditionals';
import { isInReview } from 'shared/lib/procedureUtil';
import diffUtil from '../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import procedureVariableUtil from '../lib/procedureVariableUtil';

const ReviewContext = React.createContext();

export const ReviewContextProvider = ({ released, review, children, onScrollToDiffRefChanged, isDiffShown }) => {
  const { getSetting } = useSettings();
  const isReview = useMemo(() => !isDiffShown && review && isInReview(review), [isDiffShown, review]);

  const stepIdsChanged = useMemo(() => {
    if (!released || !review) {
      return [];
    }
    return procedureUtil.getIdsStepsChanged(released, review);
  }, [released, review]);

  const getIsStepChanged = useCallback(
    (step) => {
      return Boolean(stepIdsChanged) && stepIdsChanged.includes(step.id);
    },
    [stepIdsChanged]
  );

  // Returns id of the first step in the procedure that changed
  const getFirstStepChangedId = useCallback(() => {
    // Not enough steps to direct to first
    if (!stepIdsChanged || stepIdsChanged.length < 1) {
      return null;
    }

    return stepIdsChanged[0];
  }, [stepIdsChanged]);

  // Returns id of the next step in the procedure that changed
  const getNextStepChangedId = useCallback(
    (step) => {
      // Not enough steps to direct to next change
      if (!stepIdsChanged || stepIdsChanged.length < 2) {
        return null;
      }
      // The given step didn't change
      const index = stepIdsChanged.findIndex((stepId) => step.id === stepId);
      if (index === -1) {
        return null;
      }
      // Last step link directs back to first step changed
      if (index === stepIdsChanged.length - 1) {
        return stepIdsChanged[0];
      }
      return stepIdsChanged[index + 1];
    },
    [stepIdsChanged]
  );

  const reviewHasStepChanges = useMemo(() => stepIdsChanged.length > 0, [stepIdsChanged]);

  const numberOfStepChanges = useMemo(() => stepIdsChanged.length, [stepIdsChanged]);

  const stepIdsToLabelsMap = useMemo(() => {
    if (!review) {
      return {};
    }
    return stepConditionals.getStepIdsToLabelsMapForVersion(
      review.sections,
      getSetting('display_sections_as', 'letters'),
      'new'
    );
  }, [getSetting, review]);

  const oldStepIdsToLabelsMap = useMemo(() => {
    if (!review) {
      return {};
    }
    return stepConditionals.getStepIdsToLabelsMapForVersion(
      review.sections,
      getSetting('display_sections_as', 'letters'),
      'old'
    );
  }, [getSetting, review]);

  const sourceStepConditionalsMap = useMemo(() => {
    if (!review) {
      return {};
    }
    return stepConditionals.getSourceConditionalsMapForVersion(review, 'new');
  }, [review]);

  const oldSourceStepConditionalsMap = useMemo(() => {
    if (!review) {
      return {};
    }
    return stepConditionals.getSourceConditionalsMapForVersion(review, 'old');
  }, [review]);

  const removedSourceStepConditionalsMap = useMemo(() => {
    if (!review) {
      return {};
    }
    return stepConditionals.getSourceOldConditionalsMap(review);
  }, [review]);

  const getIsStepInRemovedSection = useCallback(
    (stepId) => {
      if (!review) {
        return false;
      }
      const section = review.sections.find((section) => section.steps.some((step) => step.id === stepId));
      return section?.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED;
    },
    [review]
  );

  const isStepRemoved = useCallback(
    (step) => {
      return getIsStepInRemovedSection(step.id) || step.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED;
    },
    [getIsStepInRemovedSection]
  );

  /**
   * @type {(version?: 'old' | 'new') => Array<import('shared/lib/types/views/procedures').SectionDiffElement>}
   */
  const getAllSections = useCallback(
    (version = 'new') => {
      return diffUtil.getForVersion(review.sections, version);
    },
    [review]
  );

  /**
   * @type {(version?: 'old' | 'new') => Array<import('shared/lib/types/views/procedures').VariableDiffElement>}
   */
  const getAllVariables = useCallback(
    (version = 'new') => {
      if (!review.variables) {
        return [];
      }

      return diffUtil.getForVersion(review.variables, version);
    },
    [review]
  );

  /**
   * @type {(sectionId: string, version?: 'old' | 'new') => import('shared/lib/types/views/procedures').Section | import('shared/lib/types/views/procedures').SectionDiffElement | undefined}
   */
  const getSection = useCallback(
    (sectionId, version = 'new') => getAllSections(version).find((section) => section.id === sectionId),
    [getAllSections]
  );

  /**
   * @type {(sectionId: string, version?: 'old' | 'new') => import('../lib/procedureUtil').Summary | null}
   */
  const getSectionSummary = useCallback(
    (sectionId, version = 'new') => {
      const allSections = getAllSections(version);

      return diffUtil.getContainerSummary(sectionId, allSections, version);
    },
    [getAllSections]
  );

  /**
   * @type {(sectionId: string, version?: 'old' | 'new') => Array<import('shared/lib/types/views/procedures').StepDiffElement> | null}
   */
  const getAllSteps = useCallback(
    (sectionId, version = 'new') => {
      const section = getSection(sectionId, version);

      if (!section) {
        return null;
      }

      return diffUtil.getForVersion(section.steps, version);
    },
    [getSection]
  );

  /**
   * @type {(stepId: string, sectionId: string, version?: 'old' | 'new') => import('../lib/procedureUtil').Summary | null}
   */
  const getStepSummary = useCallback(
    (stepId, sectionId, version = 'new') => {
      const allSteps = getAllSteps(sectionId, version);

      return diffUtil.getContainerSummary(stepId, allSteps, version);
    },
    [getAllSteps]
  );

  /**
   * @type {import('../hooks/useProcedureAdapter').GetReferencedContentContext}
   */
  const getReferencedContentContext = useCallback(
    (referencedContentId, version = 'new') => {
      if (!review) {
        return null;
      }
      let referencedFromStepKey;
      let referencedContent;
      let isVariable = false;
      let isVariableRecorded = false;

      if (review.variables) {
        /**
         * @type {Array<import('shared/lib/types/views/procedures').VariableDiffElement>}
         */
        const variables = diffUtil.getForVersion(review.variables, version);
        variables.some((variable) => {
          const variableId = sharedDiffUtil.getDiffValue(variable, 'id', version);
          if (variableId === referencedContentId) {
            referencedContent = procedureVariableUtil.getFieldInputFromVariable(variable);
            isVariable = true;
            const variableValue = sharedDiffUtil.getDiffValue(variable, 'value', version);
            isVariableRecorded = variableValue !== undefined && variableValue !== null;
            return true;
          }
          return false;
        });

        if (referencedContent) {
          return {
            referencedContent,
            isVariable,
            isVariableRecorded,
          };
        }
      }

      let referencedSectionIndex;
      let referencedStepIndex;
      /**
       * @type {Array<import('shared/lib/types/views/procedures').SectionDiffElement>}
       */
      const sections = diffUtil.getForVersion(review.sections, version);
      sections.some((section, sectionIndex) => {
        const steps = diffUtil.getForVersion(section.steps, version);
        return steps.some((step, stepIndex) => {
          return step.content.some((contentBlock) => {
            const contentId = sharedDiffUtil.getDiffValue(contentBlock, 'id', version);
            if (contentId === referencedContentId) {
              referencedSectionIndex = sectionIndex;
              referencedStepIndex = stepIndex;
              referencedContent = contentBlock;
              return true;
            }
            return false;
          });
        });
      });

      if (referencedSectionIndex !== undefined && referencedStepIndex !== undefined) {
        referencedFromStepKey = procedureUtil.displaySectionStepKey(
          referencedSectionIndex,
          referencedStepIndex,
          getSetting('display_sections_as', 'letters')
        );
      }
      return {
        referencedFromStepKey,
        referencedContent,
        isVariable,
        isVariableRecorded,
      };
    },
    [getSetting, review]
  );

  const value = {
    getIsStepChanged,
    getNextStepChangedId,
    getFirstStepChangedId,
    reviewHasStepChanges,
    numberOfStepChanges,
    onScrollToDiffRefChanged,
    stepIdsToLabelsMap,
    oldStepIdsToLabelsMap,
    isReview,
    isDiffShown,
    sourceStepConditionalsMap,
    oldSourceStepConditionalsMap,
    removedSourceStepConditionalsMap,
    isStepRemoved,
    getReferencedContentContext,
    getAllSections,
    getAllVariables,
    getSection,
    getSectionSummary,
    getStepSummary,
  };

  return <ReviewContext.Provider value={value}>{children}</ReviewContext.Provider>;
};

export const useReviewContext = () => {
  const context = useContext(ReviewContext);
  if (!context) {
    return {};
  }

  return context;
};
