import { parser, parse, isOperatorNode } from 'mathjs';
import { ExpressionToken } from 'shared/lib/types/views/procedures';

const NO_PARSE_ERRORS = '';
export const NAMESPACE_DELIMITER = ':';

export type ReferenceOption = {
  id: string;
  name: string;
  referenceLabel: string;
  textToSearch: string;
  origin?: {
    isVariable?: boolean;
    sectionId?: string;
    stepId?: string;
  };
};

const expression = {
  getNamespacedReference: (
    name: string,
    id: string,
    options: ReferenceOption[]
  ): string => {
    const matches = options.filter(
      (item) => item.name.toLowerCase() === name.toLowerCase()
    );
    if (matches.length === 0) {
      return '{??}';
    }
    const match = matches.find((item) => item.id === id);
    return match
      ? `{${match?.referenceLabel}${NAMESPACE_DELIMITER}${name}}`
      : `{${name}}`;
  },

  getNamespacedTokenText: (
    name: string,
    id: string,
    options: ReferenceOption[]
  ): string => {
    const match = options.find((item) => item.id === id);
    return match
      ? `${match?.referenceLabel}${NAMESPACE_DELIMITER}${name}`
      : 'Unknown Reference';
  },

  tokensToText: (
    tokens: ExpressionToken[],
    referenceOptions: ReferenceOption[]
  ): string => {
    if (!tokens || tokens.length === 0) {
      return '';
    }
    const displayed: string[] = [];
    tokens.forEach((token) => {
      if (token.type === 'text') {
        displayed.push(token.value);
      } else {
        if (token.reference_id) {
          displayed.push(
            expression.getNamespacedReference(
              token.value,
              token.reference_id,
              referenceOptions
            )
          );
        }
      }
    });
    return displayed.join('');
  },

  findReference: (
    text: string,
    referenceOptions: ReferenceOption[]
  ): ExpressionToken => {
    let match: ReferenceOption | undefined;

    /*
     * by using a colon for namespacing here, if the field referenced has a colon in its name
     * it throws off the parsing and causes the input to refuse to add a token
     * I need to strip colons from the referenceOptions to fix this
     */

    // allow specific step namespacing
    if (text.includes(NAMESPACE_DELIMITER)) {
      const [label, name] = text.split(NAMESPACE_DELIMITER);
      match = referenceOptions.find(
        (item) =>
          item.name.toLowerCase() === name.toLowerCase() &&
          item.referenceLabel.toLowerCase() === label.toLowerCase()
      );
    } else {
      match = referenceOptions.find(
        (item) => item.name.toLowerCase() === text.toLowerCase()
      );
    }

    if (match) {
      return {
        type: 'reference',
        value: match.name,
        reference_id: match.id,
      };
    } else {
      return {
        type: 'text',
        value: `{${text}}`,
      };
    }
  },

  textToTokens: (
    text: string,
    referenceOptions: ReferenceOption[]
  ): ExpressionToken[] => {
    const updatedTokens: ExpressionToken[] = [];
    let leftBracketIndexes: number[] = [];
    let index = 0;
    let textStart = 0;
    for (const char of text) {
      if (char === '{') {
        leftBracketIndexes.unshift(index);
      } else if (char === '}') {
        if (leftBracketIndexes.length > 0) {
          let foundMatch = false;
          let leftIndex = 0;
          while (!foundMatch && leftIndex < leftBracketIndexes.length) {
            const possibleMatch = text.substring(
              leftBracketIndexes[leftIndex] + 1,
              index
            );
            const match = expression.findReference(
              possibleMatch,
              referenceOptions
            );
            if (match.type === 'reference') {
              foundMatch = true;
              if (textStart < leftBracketIndexes[leftIndex]) {
                updatedTokens.push({
                  type: 'text',
                  value: text.substring(
                    textStart,
                    leftBracketIndexes[leftIndex]
                  ),
                });
              }
              textStart = index + 1;
              leftBracketIndexes = [];
              updatedTokens.push(match);
            }
            leftIndex++;
          }
        }
      }
      index++;
    }
    if (textStart < text.length) {
      updatedTokens.push({
        type: 'text',
        value: text.substring(textStart, text.length),
      });
    }
    return updatedTokens;
  },

  validate: (tokens: ExpressionToken[]): string => {
    const mathParser = parser();
    const resolved: string[] = [];
    tokens.forEach((token) => {
      if (token.type === 'text') {
        resolved.push(token.value);
      } else {
        if (token.reference_id) {
          resolved.push(`(${token.reference_id})`);
          mathParser.set(token.reference_id, 1);
        }
      }
    });
    const expression = resolved.join('');

    try {
      const node = parse(expression);
      let implicitError = false;
      node.traverse((node) => {
        if (isOperatorNode(node)) {
          if (node.op === '*' && node['implicit']) {
            implicitError = true;
          }
        }
      });
      if (implicitError) {
        return 'Implicit multiplication not allowed';
      }

      mathParser.evaluate(expression);
      return NO_PARSE_ERRORS;
    } catch (e) {
      return e.message;
    }
  },
};

export default expression;
