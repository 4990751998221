import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo } from 'react';
import { NOTIFICATION_STATUS } from 'shared/lib/notifications';
import { AppNotificationDisplay } from 'shared/lib/types/notifications';
import { NotificationType } from 'shared/lib/types/postgres/notifications';
import Button from '../../components/Button';
import AutomationNotificationLink from '../../components/Notifications/AutomationNotificationLink';
import ProcedureGenerationNotificationLink from '../../components/Notifications/ProcedureGenerationNotificationLink';
import ReviewerNotificationLink from '../../components/Notifications/ReviewerNotificationLink';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useNotifications } from '../../contexts/NotificationContext';
import Table from '../../elements/Table';
import notificationsLib from '../../lib/notifications';
import { notificationsPath } from '../../lib/pathUtil';
import SingleDigitNotificationBadge from '../SingleDigitNotificationBadge';
import AtMentionNotificationLink from './AtMentionNotificationLink';

const MAX_DISPLAYED_NOTIFICATIONS = 10;

const NOTIFICATION_LINK_MAP = {
  reviewers: ReviewerNotificationLink,
  procedure_generation: ProcedureGenerationNotificationLink,
  automation: AutomationNotificationLink,
  at_mention: AtMentionNotificationLink,
};

const getNotificationIcon = (notificationType: NotificationType): IconProp => {
  switch (notificationType) {
    case 'automation': {
      return 'bolt';
    }
    case 'at_mention': {
      return 'comment';
    }
    default: {
      return 'clipboard';
    }
  }
};

const NotificationPanel = () => {
  const { currentTeamId, services } = useDatabaseServices();
  const { notifications } = useNotifications();

  const notificationIdMap = useMemo(() => notificationsLib.getNotificationIdMap(notifications), [notifications]);
  const displayedNotifications = useMemo(
    () => notificationsLib.getUniqLatestSortedNotifications(notifications).slice(0, MAX_DISPLAYED_NOTIFICATIONS),
    [notifications]
  );
  const unresolvedNotificationCount = useMemo(
    () => notifications.filter((notification) => notification.status === NOTIFICATION_STATUS.UNRESOLVED).length,
    [notifications]
  );

  const handleOnClick = useCallback(
    (ids: Array<number>) => {
      if (!services) {
        return;
      }
      services.notifications.resolveNotifications(ids);
    },
    [services]
  );

  const columns = useMemo(() => {
    return [
      {
        key: 'type',
        name: '',
        renderCell(row: AppNotificationDisplay) {
          const icon = getNotificationIcon(row.type);
          return <FontAwesomeIcon className="text-gray-500" fixedWidth={true} icon={icon} />;
        },
      },
      {
        key: 'Notification',
        name: '',
        renderCell(row: AppNotificationDisplay) {
          const NotificationLink = NOTIFICATION_LINK_MAP[row.type];
          return (
            NotificationLink && (
              <NotificationLink
                key={row.id}
                notification={row}
                handleOnClick={handleOnClick}
                duplicateIds={notificationIdMap[notificationsLib.getUniqueIdentifier(row)]}
                truncateCode={true}
              />
            )
          );
        },
      },
    ];
  }, [handleOnClick, notificationIdMap]);

  return (
    <div>
      <div className="w-28 relative top-2">
        <SingleDigitNotificationBadge count={unresolvedNotificationCount} />
      </div>
      <p>Notifications</p>

      <Table columns={columns} rows={displayedNotifications} />
      <Button trailingIcon="chevron-right" type="tertiary" to={notificationsPath(currentTeamId)}>
        See all notifications
      </Button>
    </div>
  );
};

export default NotificationPanel;
