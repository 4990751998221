import useItems from '../hooks/useItems';
import InventoryItemSelect from './InventoryItemSelect';
import useItemIssues from '../hooks/useItemIssues';
import { Item } from '../types';

interface ItemSelectProps {
  /** ID of the selected item */
  itemId?: string;

  /** Item options under this amount will be disabled in select dropdown */
  minAmount?: number;

  onChangeItem?: (Item) => void;
  isDisabled?: boolean;
  usageTypeName?: string;

  /** Used to explicitly specify which items to include as select options */
  itemOptions?: Array<Item>;

  /** (Ignored when itemOptions is set, required when itemOptions is undefined) Only items matching this part ID are included as select options when select is enabled */
  partId?: string;
  /** (Ignored when itemOptions is set, optional when itemOptions is undefined) Only items matching this revision label are included as select options when select is enabled */
  partRevision?: string;
  /** (Ignored when itemOptions is set) Set to 'true' to include scrapped and unavailable items as options. Set to 'false' by default. */
  includeInactive?: boolean;
}

const shouldIncludeItem = (item: Item, partIdFilter: string, partRevisionFilter: string): boolean => {
  if (item.part.id !== partIdFilter) {
    return false;
  }
  if (partRevisionFilter && item.part.rev !== partRevisionFilter) {
    return false;
  }
  return true;
};

const ItemSelect = ({
  itemId,
  partId = '',
  partRevision = '',
  minAmount = 0,
  onChangeItem,
  isDisabled,
  usageTypeName,
  itemOptions,
  includeInactive = false,
}: ItemSelectProps) => {
  const { activeItems, allItems } = useItems();
  const { itemIdsToNumIssues } = useItemIssues();

  if (!itemOptions) {
    if (isDisabled) {
      itemOptions = (allItems || []).filter((item) => item.id === itemId);
    } else {
      const itemsToFilter = (includeInactive ? allItems : activeItems) || [];
      itemOptions = itemsToFilter.filter((item) => shouldIncludeItem(item, partId, partRevision));
    }
  }

  return (
    <InventoryItemSelect
      itemId={itemId}
      itemOptions={itemOptions}
      minAmount={minAmount}
      onChangeItem={onChangeItem}
      isDisabled={isDisabled}
      usageTypeName={usageTypeName}
      itemIdsToNumIssues={itemIdsToNumIssues.itemCounts}
      mapOfCriticalIssues={itemIdsToNumIssues.mapOfCriticalIssues}
    />
  );
};

export default ItemSelect;
