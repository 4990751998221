import runUtil, { getTargetStepDependenciesMap, isStepEnded } from './runUtil';
import signoffUtil from './signoffUtil';
import stepConditionals from './stepConditionals';
import {
  DependencyMaps,
  Run,
  Step,
  StepDependency,
} from './types/views/procedures';
import { RunStep } from './types/views/procedures';
import { Config } from './types/couch/settings';

type PendingAction = {
  runName: string;
  runStartTime: string;
  hasDependentSteps: boolean;
  stepNumber: string;
  stepName: string;
  sectionName: string;
  requiredOperatorSignoffs: string;
};

type GetOpenStepsType = {
  run: Run;
  startingSectionIndex?: number;
  configDoc: Config;
};

const stepNavigation = {
  getAllOpenSteps: ({
    run,
    startingSectionIndex,
    configDoc,
  }: GetOpenStepsType) => {
    const firstSection = startingSectionIndex || 0;
    const dependencyMaps = stepNavigation.getDependencyMaps(run);
    const reverseStepDependencies = getTargetStepDependenciesMap(run);
    const runReport: Array<PendingAction> = [];

    for (
      let sectionIndex = firstSection;
      sectionIndex < run.sections?.length;
      sectionIndex++
    ) {
      const section = run.sections[sectionIndex];

      for (let stepIndex = 0; stepIndex < section.steps.length; stepIndex++) {
        const step = section.steps[stepIndex] as RunStep;
        if (
          !isStepEnded(step) &&
          stepNavigation.areRequirementsMet({ dependencyMaps, step })
        ) {
          const nextStepCode = runUtil.displaySectionStepKey(
            run.sections,
            sectionIndex,
            stepIndex,
            configDoc.display_sections_as || 'letters'
          );
          const row: PendingAction = {
            runName: run.name,
            runStartTime: run.starttime,
            hasDependentSteps: false,
            stepNumber: nextStepCode,
            stepName: step.name,
            sectionName: section.name,
            requiredOperatorSignoffs:
              signoffUtil.stringifyAllOperatorsInSignoffs(step.signoffs),
          };

          if (reverseStepDependencies.hasOwnProperty(step.id)) {
            row.hasDependentSteps = true;
          }
          runReport.push(row);
        }
      }
    }
    return runReport;
  },

  areDependenciesFulfilled: (
    dependencies: Array<StepDependency> | undefined,
    stepsEndedSet: Set<string>
  ): boolean => {
    if (!dependencies || !dependencies.length) {
      return true;
    }

    return dependencies.every((dependency) => {
      if (dependency?.dependent_ids && dependency?.dependent_ids.length) {
        return dependency.dependent_ids.some((dependentId: string) =>
          stepsEndedSet.has(dependentId)
        );
      }

      return true;
    });
  },

  areRequirementsMet: ({
    run,
    step,
    dependencyMaps,
  }: {
    run?: Run;
    step: Step;
    dependencyMaps?: DependencyMaps;
  }) => {
    if (!run && !dependencyMaps) {
      throw new Error('Either run or dependencyMaps must be provided');
    }
    const dependencyMapping =
      dependencyMaps || stepNavigation.getDependencyMaps(run);

    return (
      stepNavigation.areDependenciesFulfilled(
        step.dependencies,
        dependencyMapping.stepsEndedSet
      ) &&
      stepConditionals.checkConditionalsFulfilled({
        step,
        dependencyMaps: dependencyMapping,
      })
    );
  },

  getDependencyMaps: (run): DependencyMaps => {
    const stepMap = {};
    const stepsEndedSet = new Set<string>();
    const sourceConditionalMap = {};

    if (run) {
      run.sections?.forEach((section) => {
        section.steps?.forEach((step: Step) => {
          stepMap[step.id] = step;

          if (isStepEnded(step)) {
            stepsEndedSet.add(step.id);
          }

          if (step.conditionals) {
            step.conditionals.forEach((conditional) => {
              if (!sourceConditionalMap[conditional.target_id]) {
                sourceConditionalMap[conditional.target_id] = {};
              }
              sourceConditionalMap[conditional.target_id][conditional.id] =
                conditional;
            });
          }
        });
      });
    }
    return {
      stepMap,
      stepsEndedSet,
      sourceStepConditionalsMap: sourceConditionalMap,
    };
  },
};

export default stepNavigation;
