import ModalBase from '../ModalBase';
import Button, { BUTTON_TYPES } from '../Button';
import ModalActionBar from '../ModalActionBar';
import ModalContent from '../ModalContent';
import { CommandingBlockArguments } from 'shared/lib/types/views/procedures';

interface CommandingConfirmationModalProps {
  commandName?: string | null;
  displayArgs: CommandingBlockArguments;
  onConfirm: () => void;
  onCancel: () => void;
}

const CommandingConfirmationModal = ({
  commandName,
  displayArgs,
  onConfirm,
  onCancel,
}: CommandingConfirmationModalProps) => {
  return (
    <ModalBase>
      <ModalContent>
        <div className="flex flex-col w-full">
          <div className="font-medium text-lg">Send Command Confirmation</div>
          <div className="flex flex-col my-3">
            <div className="mb-1 font-medium">Command Name</div>
            <code>
              <div>{commandName}</div>
            </code>
          </div>
          <div className="my-2">
            <div className="font-medium">Command Arguments</div>
            <div className="my-1 p-2 overflow-auto bg-gray-200 max-h-40 max-w-lg">
              <code>
                {displayArgs && (
                  <>
                    {!Object.keys(displayArgs).length && <div className="text-gray-500">N/A</div>}
                    {Object.keys(displayArgs).map((name) => (
                      <div key={name} className="flex items-start text-sm">
                        <div className="flex flex-col self-center mb-0.9 my-1">
                          <div>{name}:</div>
                          <div>{displayArgs[name]}</div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </code>
            </div>
          </div>
        </div>
      </ModalContent>

      <ModalActionBar>
        <div className="flex gap-x-2">
          <Button
            type={BUTTON_TYPES.SECONDARY}
            title="Cancel Sending Command"
            ariaLabel="Cancel Sending Command"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            title="Confirm Sending Command"
            ariaLabel="Confirm Sending Command"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </div>
      </ModalActionBar>
    </ModalBase>
  );
};

export default CommandingConfirmationModal;
