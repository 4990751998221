import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type SearchInputControlledProps = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  placeholder: string;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const SearchInputControlled = ({ searchTerm, setSearchTerm, placeholder, onKeyUp }: SearchInputControlledProps) => {
  const searchBarRef = useRef<HTMLInputElement>(null);

  const onSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyUp) {
      onKeyUp(event);
    }
  };

  const clearSearchTerm = () => {
    setSearchTerm('');
  };

  return (
    <div className="flex flex-row items-center relative">
      <FontAwesomeIcon
        icon="search"
        aria-label="Search"
        className="absolute text-blue-500 cursor-pointer hover:text-blue-800 text-smlg:text-base px-2"
        onClick={() => searchBarRef.current && searchBarRef.current.focus()}
      />

      <input
        type="text"
        ref={searchBarRef}
        value={searchTerm}
        placeholder={placeholder}
        onChange={onSearchTermChange}
        onKeyUp={handleKeyUp}
        className="py-1 px-8 h-[38px] rounded border border-gray-400 text-sm lg:text-base lg:w-96"
      />

      {searchTerm && (
        <button
          className="relative right-6 outline-none text-blue-500 focus:text-blue-800 hover:text-blue-800 text-sm lg:text-base"
          onClick={clearSearchTerm}
        >
          <FontAwesomeIcon icon="times-circle" />
        </button>
      )}
    </div>
  );
};

export default SearchInputControlled;
