import { FieldInputProps } from 'formik';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Select from 'react-select';
import ExternalDataService from '../../api/externalData';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import ExternalTypeSelectFieldSet from './ExternalTypeSelectFieldSet';

const filterItemToOption = (item) => {
  if (!item) {
    return null;
  }
  return {
    value: item.name,
    label: item.name,
  };
};

export type ExternalSearchType = {
  data_type: string;
  data_type_dictionary_id?: number;
  filter_options: string[];
};

interface ExternalSearchFieldSetProps {
  field: FieldInputProps<string>;
  isDisabled: boolean;
  onChange: (data_type: ExternalSearchType) => void;
  value: ExternalSearchType;
  contentErrors: {
    external_search_type: string;
  };
}

const ExternalSearchFieldSet = ({ field, isDisabled, onChange, value, contentErrors }: ExternalSearchFieldSetProps) => {
  const {
    services: { externalData },
  }: { services: { externalData: ExternalDataService } } = useDatabaseServices();

  const [filterOptions, setFilterOptions] = useState([]);
  const selectedFilter = useMemo(() => value?.filter_options.map((name) => filterItemToOption({ name })), [value]);

  // return all the detail options for a single data item
  const loadAllDetailOptions = useCallback(
    (option, data_type_dictionary_id) => {
      externalData.getItemTypes(option, data_type_dictionary_id).then((itemTypes) => {
        if (itemTypes && itemTypes.details) {
          setFilterOptions(itemTypes.details.map((option) => filterItemToOption(option)));
        }
      });
    },
    [externalData]
  );

  const onTypeChangeHandler = useCallback(
    (data_type: string, data_type_dictionary_id: number) => {
      onChange({
        data_type,
        filter_options: [],
        data_type_dictionary_id,
      });
      loadAllDetailOptions(data_type, data_type_dictionary_id);
    },
    [onChange, loadAllDetailOptions]
  );

  const onFilterChangeHandler = useCallback(
    (options) => {
      onChange({
        data_type: value.data_type,
        filter_options: options.map((option) => option.value),
        data_type_dictionary_id: value.data_type_dictionary_id,
      });
    },
    [value, onChange]
  );

  useEffect(() => {
    if (!isDisabled && value?.data_type) {
      loadAllDetailOptions(value.data_type, value.data_type_dictionary_id);
    }
  }, [isDisabled, value, loadAllDetailOptions]);

  return (
    <div className="flex flex-row mr-2 items-start">
      {/* External Data Type Select */}
      <div className="flex flex-col mr-2 items-start w-48">
        <span className="field-title">Data Type</span>
        <ExternalTypeSelectFieldSet
          field={field}
          isDisabled={isDisabled}
          onChange={onTypeChangeHandler}
          value={value?.data_type}
          dictionaryId={value?.data_type_dictionary_id}
        />
        {contentErrors && contentErrors.external_search_type && (
          <div className="text-red-700">{contentErrors.external_search_type}</div>
        )}
      </div>

      {/* Filter Options Select */}
      <div className="flex flex-col mr-2 items-start w-64">
        <span className="field-title">Filter Options</span>
        <div className="grow w-full">
          <Select
            isMulti
            name="select-filter"
            options={filterOptions}
            classNamePrefix="react-select"
            onChange={onFilterChangeHandler}
            value={selectedFilter || ''}
            placeholder="Add display filter"
            isDisabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default ExternalSearchFieldSet;
