import React, { useMemo, useEffect, useCallback, useContext, useState, useRef } from 'react';
import { useUserInfo } from './UserContext';
import { useDatabaseServices } from './DatabaseContext';
import { useDispatch } from 'react-redux';
import { addParticipant, removeParticipant } from './runsSlice';
import { FEATURE_OFFLINE_RUN_ACTIONS_ENABLED } from '../config';
import runUtil, { RUN_STATE } from '../lib/runUtil';
import { isStepEnded } from 'shared/lib/runUtil';
import stepConditionals from 'shared/lib/stepConditionals';
import { useSettings } from './SettingsContext';
import procedureUtil from '../lib/procedureUtil';
import { VIEW_MODES } from '../components/FieldSetViewModeEditSelect';
import signoffUtil from 'shared/lib/signoffUtil';
import TelemetryUpdater from './TelemetryUpdater';
import { BatchStepProps, RepeatedSection, Run, RunStep } from 'shared/lib/types/views/procedures';
import stepNavigationLib from 'shared/lib/stepNavigation';
import { getPreviousStepInBatch as _getPreviousStepInBatch, groupBatchStepsInRun } from '../lib/batchSteps';
import configUtil from '../lib/configUtil';
import { useExpressionHelper } from '../hooks/useExpression';

export type RunContextType = {
  getContentBlock;
  run: Run;
  isUserParticipant: boolean;
  toggleIsUserParticipant;
  areDependenciesFulfilled;
  areConditionalsFulfilled;
  areRequirementsMet;
  stepIdsToLabelsMap;
  getSectionAndStepIdPair;
  getReferencedContentContext;
  sourceStepConditionalsMap;
  nextStepIds;
  isSingleCardEnabled;
  isSectionVisible;
  isStepVisible;
  isIntroductionVisible;
  isEndOfRunContentVisible;
  setCurrentStepId;
  isRun;
  isPreviewMode;
  isActiveRun;
  isRunning;
  isPaused;
  showIntroCard;
  advanceStep;
  goToNextFromStep;
  goToPreviousFromStep;
  firstStepId;
  viewStepAsIncomplete;
  previewUserRoles;
  updatePreviewUserRoles;
  onReceiveTelemetry;
  onSignalLost;
  fetchedTelemetryParameters;
  calculatePauseDurationInMs;
  getPreviousStepInBatch(batchProps: BatchStepProps): RunStep | undefined;
  projectId?: string;
};

export type RunContextProviderProps = {
  run: Run;
  viewMode;
  isPreviewMode;
  showStepAction;
  setShowStepAction;
  currentStepId;
  setCurrentStepId;
  telemetryParameters;
  fetchedTelemetryParameters;
  forceUserToParticipate?: boolean;
  children;
};

// @ts-ignore TODO createContext requires an argument
const RunContext = React.createContext<RunContextType>();

export const INTRO_STEP_KEY = 'INTRODUCTION_STEP_KEY';

export const RunContextProvider = ({
  run,
  viewMode,
  isPreviewMode,
  showStepAction,
  setShowStepAction,
  currentStepId,
  setCurrentStepId,
  telemetryParameters,
  fetchedTelemetryParameters,
  forceUserToParticipate = false,
  children,
}: RunContextProviderProps) => {
  const { userInfo } = useUserInfo();
  const { services, currentTeamId } = useDatabaseServices();
  const { config, getSetting, isTelemetryBulkFetchEnabled } = useSettings();
  const dispatch = useDispatch();

  const telemetryUpdaterRef = useRef(new TelemetryUpdater(isPreviewMode));

  /*
   * TODO: EPS-3487 Remove isRun where possible. isRun is needed because RunContext is currently used for displaying procedures and reviews,
   *  and because components are reused in runs, procedures, and reviews.
   */
  const isRun = useMemo(() => runUtil.isRun(run), [run]);

  const { getReferencedContentContext } = useExpressionHelper({
    displaySectionAs: getSetting('display_sections_as', 'letters'),
    run,
    isRun,
    isPreviewMode,
    currentTeamId,
  });

  const isActiveRun = useMemo(() => isRun && runUtil.isRunStateActive(run.state), [isRun, run.state]);

  const isRunning = useMemo(() => isRun && run.state === RUN_STATE.RUNNING, [isRun, run.state]);

  const isPaused = useMemo(() => isRun && run.state === RUN_STATE.PAUSED, [isRun, run.state]);

  const userId = useMemo(() => userInfo.session.user_id, [userInfo.session.user_id]);

  const currentBatchId = useMemo(() => {
    for (const section of run.sections) {
      for (const step of section.steps) {
        if (step.id === currentStepId) {
          return (step as RunStep).batchProps?.batchId;
        }
      }
    }
  }, [currentStepId, run.sections]);

  const isUserParticipant = useMemo(() => {
    if (forceUserToParticipate) {
      return true;
    }

    if (!run || !config) {
      return false;
    }

    // The first time a user enters a run, we need to return the default "participation type" from settings.
    if (
      !runUtil.getIsUserParticipantOrViewing(run, userId) &&
      configUtil.getUserParticipantType(config) === 'participant'
    ) {
      return true;
    }
    return runUtil.getIsUserParticipant(run, userId);
  }, [config, forceUserToParticipate, run, userId]);

  const handleAddParticipant = useCallback(() => {
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      return dispatch(
        addParticipant({
          teamId: currentTeamId,
          runId: run._id,
          userId,
        })
      );
    }
    const createdAt = new Date().toISOString();
    return services.runs.addParticipant(run._id, createdAt);
  }, [services.runs, currentTeamId, dispatch, run, userId]);

  const handleRemoveParticipant = useCallback(() => {
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      return dispatch(
        removeParticipant({
          teamId: currentTeamId,
          runId: run._id,
          userId,
        })
      );
    }
    const createdAt = new Date().toISOString();
    return services.runs.removeParticipant(run._id, createdAt);
  }, [services.runs, currentTeamId, dispatch, run, userId]);

  const toggleIsUserParticipant = useCallback(() => {
    if (!run) {
      return null;
    }
    if (!isUserParticipant) {
      return handleAddParticipant();
    }
    return handleRemoveParticipant();
  }, [run, isUserParticipant, handleAddParticipant, handleRemoveParticipant]);

  const dependencyMaps = useMemo(() => stepNavigationLib.getDependencyMaps(run), [run]);

  const areDependenciesFulfilled = useCallback(
    (step) => {
      if (!isRun) {
        return true;
      }
      return stepNavigationLib.areDependenciesFulfilled(step?.dependencies, dependencyMaps.stepsEndedSet);
    },
    [dependencyMaps.stepsEndedSet, isRun]
  );

  /**
   * Combines both regular and conditional dependencies into a single dependency map
   *
   * @returns Map<> key = step id, value = array of dependency step Ids
   * Absence of a key indicates absence of dependencies
   */
  const stepConditionalAndDependencyMap = useMemo(() => {
    return stepConditionals.stepConditionalAndDependencyMap(
      run,
      dependencyMaps.sourceStepConditionalsMap,
      dependencyMaps.stepMap
    );
  }, [run, dependencyMaps.sourceStepConditionalsMap, dependencyMaps.stepMap]);

  // Check the combined dependency map to determine if any conditional dependencies have completed more recently
  const stepHasNewerDependencyCompletion = useCallback(
    (step) => {
      return stepConditionals.checkStepHasNewerDependencyCompletion(
        step,
        dependencyMaps.stepMap,
        stepConditionalAndDependencyMap
      );
    },
    [dependencyMaps.stepMap, stepConditionalAndDependencyMap]
  );

  const viewStepAsIncompleteMap = useMemo(() => {
    const map = new Map();
    for (const [stepId, step] of Object.entries(dependencyMaps.stepMap)) {
      if (runUtil.isStepComplete(step) && !signoffUtil.isSignoffRequired((step as RunStep).signoffs)) {
        map.set(stepId, stepHasNewerDependencyCompletion(step));
      } else {
        map.set(stepId, false);
      }
    }
    return map;
  }, [dependencyMaps.stepMap, stepHasNewerDependencyCompletion]);

  const viewStepAsIncomplete = useCallback(
    (stepId) => {
      if (!isRun) {
        return false;
      }
      if (viewStepAsIncompleteMap.has(stepId)) {
        return viewStepAsIncompleteMap.get(stepId);
      }
      return false;
    },
    [isRun, viewStepAsIncompleteMap]
  );

  const getContentBlock = useCallback(
    (stepId, contentId) => {
      return dependencyMaps.stepMap[stepId]?.content.find((block) => block.id === contentId);
    },
    [dependencyMaps.stepMap]
  );

  const areConditionalsFulfilled = useCallback(
    (step) => {
      return stepConditionals.checkConditionalsFulfilled({ step, dependencyMaps });
    },
    [dependencyMaps]
  );

  const areRequirementsMet = useCallback(
    (step) => {
      return areDependenciesFulfilled(step) && areConditionalsFulfilled(step);
    },
    [areDependenciesFulfilled, areConditionalsFulfilled]
  );

  const stepIdsToLabelsMap = useMemo(() => {
    const labelsMap = {};

    run.sections.forEach((section, sectionIndex) => {
      const stepsAndBatches = groupBatchStepsInRun(section.steps);
      stepsAndBatches.forEach((stepOrBatch, stepIndex) => {
        const stepsInBatch = Array.isArray(stepOrBatch) ? stepOrBatch : [stepOrBatch];
        for (const step of stepsInBatch) {
          labelsMap[step.id] = runUtil.displaySectionStepKey(
            run.sections,
            sectionIndex,
            stepIndex,
            getSetting('display_sections_as', 'letters')
          );
        }
      });
    });

    return labelsMap;
  }, [run, getSetting]);

  // TODO EPS-3487: Refactor this function to a generic context, since it is used in runs, procedures, and reviews.
  const getSectionAndStepIdPair = useCallback(
    (stepId) => {
      const stepToSectionIdMap = runUtil.getStepToSectionIdMap(run);

      return {
        sectionId: stepToSectionIdMap[stepId],
        stepId,
      };
    },
    [run]
  );

  const nextStepIds = useMemo(() => {
    if (!isRun) {
      return null;
    }
    const runSectionIndex = run.run_section && parseInt(run.run_section);

    const firstSection = runSectionIndex || 0;
    for (let sectionIndex = firstSection; sectionIndex < run.sections.length; sectionIndex++) {
      const section = run.sections[sectionIndex];
      // Don't show "next step" button for sections not displayed in a linked procedure
      if (runSectionIndex && sectionIndex !== runSectionIndex && !(section as RepeatedSection).repeat_of) {
        return null;
      }

      const stepsAndBatches = groupBatchStepsInRun(section.steps);
      for (let stepIndex = 0; stepIndex < stepsAndBatches.length; stepIndex++) {
        const stepOrBatch = stepsAndBatches[stepIndex];
        const stepsAtIndex = Array.isArray(stepOrBatch) ? stepOrBatch : [stepOrBatch];
        for (const step of stepsAtIndex) {
          if (!isStepEnded(step) && areRequirementsMet(step)) {
            const nextStepCode = runUtil.displaySectionStepKey(
              run.sections,
              sectionIndex,
              stepIndex,
              getSetting('display_sections_as', 'letters')
            );
            const nextSectionId = section.id;
            const nextStepId = step.id;
            const nextStepHeaderId = procedureUtil.getStepHeaderId(step);

            return {
              nextStepCode,
              nextSectionId,
              nextStepId,
              nextStepHeaderId,
            };
          }
        }
      }
    }
    return null;
  }, [isRun, run, getSetting, areRequirementsMet]);

  // Stores the step id from which the previous button was pressed, if any
  const [previousFromStepId, setPreviousFromStepId] = useState(null);

  const [nextFromStepId, setNextFromStepId] = useState(null);

  const [previewUserRoles, setPreviewUserRoles] = useState([]);

  const showIntroCard = useCallback(() => {
    setCurrentStepId(INTRO_STEP_KEY);
  }, [setCurrentStepId]);

  const firstStepId = useMemo(() => run.sections[0].steps[0].id, [run]);

  const [advanceStepFlag, setAdvanceStepFlag] = useState(false);
  /*
   *  Advance the current step when flag is set. Workaround since we can't wait
   *  on step completion handler dispatch calls
   */
  useEffect(() => {
    if (advanceStepFlag) {
      if (nextStepIds && currentStepId !== nextStepIds?.nextStepId) {
        setCurrentStepId(nextStepIds.nextStepId);
      }
      setAdvanceStepFlag(false);
    }
  }, [advanceStepFlag, currentStepId, nextStepIds, setCurrentStepId]);

  // Advance to the next incomplete step
  const advanceStep = useCallback(() => {
    setAdvanceStepFlag(true);
  }, []);

  const isNextStepInDependencyTree = useCallback(
    (nextStepId, prevStepId) => {
      const newStep = dependencyMaps.stepMap[nextStepId];
      const prevStep = dependencyMaps.stepMap[prevStepId];

      // check for direct parent conditional
      const targetId = stepConditionals.getFulfilledTargetId(prevStep);
      if (targetId && targetId === nextStepId) {
        return true;
      }

      // check for direct parent dependency
      if (newStep.dependencies) {
        for (let dependencyIndex = 0; dependencyIndex < newStep.dependencies.length; dependencyIndex++) {
          if (newStep.dependencies[dependencyIndex].dependent_ids) {
            if (newStep.dependencies[dependencyIndex].dependent_ids.includes(prevStepId)) {
              return true;
            }
          }
        }
      }
      return false;
    },
    [dependencyMaps.stepMap]
  );

  useEffect(() => {
    if (nextFromStepId) {
      const currentStepId = nextFromStepId;
      setNextFromStepId(null);

      if (!run) {
        return;
      }
      const runSectionIndex = run.run_section && parseInt(run.run_section);
      const firstSection = runSectionIndex || 0;
      for (let sectionIndex = firstSection; sectionIndex < run.sections.length; sectionIndex++) {
        const section = run.sections[sectionIndex];
        if (runSectionIndex && sectionIndex !== runSectionIndex && !(section as RepeatedSection).repeat_of) {
          return;
        }

        const isNextStep = (step: RunStep): boolean =>
          !signoffUtil.isSignoffRequired(step.signoffs) &&
          areRequirementsMet(step) &&
          isNextStepInDependencyTree(step.id, currentStepId);

        const currentStep = section.steps.find(isNextStep);
        if (currentStep) {
          setCurrentStepId(currentStep.id);
        }
      }
      if (nextStepIds) {
        setCurrentStepId(nextStepIds.nextStepId);
      }
    }
  }, [nextFromStepId, run, areRequirementsMet, isNextStepInDependencyTree, nextStepIds, setCurrentStepId]);

  const goToNextFromStep = useCallback((currentStepId) => {
    setNextFromStepId(currentStepId);
  }, []);

  useEffect(() => {
    if (previousFromStepId) {
      const previousStepID =
        runUtil.getPreviousCompletedStepId(previousFromStepId, run) ||
        runUtil.getNeighborSteps(previousFromStepId, run)?.previousStep?.id;
      if (previousStepID) {
        setCurrentStepId(previousStepID);
      }
      setPreviousFromStepId(null);
    }
  }, [previousFromStepId, run, setCurrentStepId]);

  const goToPreviousFromStep = useCallback((step) => {
    setPreviousFromStepId(step.id);
  }, []);

  const isSingleCardEnabled = useMemo(() => viewMode === VIEW_MODES.SINGLE_CARD, [viewMode]);

  const lastStepId = useMemo(() => {
    const lastSection = run.sections[run.sections.length - 1];
    return lastSection.steps[lastSection.steps.length - 1].id;
  }, [run]);

  const calculatePauseDurationInMs = useCallback(
    (startTime) => {
      const pauseTimeArray = runUtil.getPauseTimeArray(run.actions);

      let duration = 0;

      pauseTimeArray.forEach((pause) => {
        if (pause.timestamp >= startTime) {
          duration += pause.duration;
        }
      });

      return duration;
    },
    [run.actions]
  );

  // Initialize current step when entering single card view
  useEffect(() => {
    if (currentStepId === null) {
      if (!nextStepIds) {
        setCurrentStepId(lastStepId);
      } else if (nextStepIds.nextStepId === firstStepId) {
        showIntroCard();
      } else {
        setCurrentStepId(nextStepIds.nextStepId);
      }
    }
  }, [isSingleCardEnabled, nextStepIds, firstStepId, lastStepId, currentStepId, showIntroCard, setCurrentStepId]);

  useEffect(() => {
    if (!showStepAction) {
      return;
    }
    setCurrentStepId(showStepAction);
    setShowStepAction(null);
  }, [showStepAction, setShowStepAction, setCurrentStepId]);

  useEffect(() => {
    if (isRunning) {
      const useBulkFetch = isTelemetryBulkFetchEnabled && isTelemetryBulkFetchEnabled();
      telemetryUpdaterRef.current.start(services, run, telemetryParameters, currentTeamId, useBulkFetch);
    }
    if (isPaused) {
      telemetryUpdaterRef.current.stop();
    }
  }, [currentTeamId, isPaused, isRunning, isTelemetryBulkFetchEnabled, run, services, telemetryParameters]);

  // stop the telemetry updater on unmount of the context
  useEffect(() => () => telemetryUpdaterRef.current.stop(), []);

  const isSectionVisible = useCallback(
    (section) => {
      if (!isSingleCardEnabled) {
        return true;
      }
      for (const step of section.steps) {
        if (step.id === currentStepId) {
          return true;
        }
      }
      return false;
    },
    [isSingleCardEnabled, currentStepId]
  );

  const isStepVisible = useCallback(
    (step: RunStep) => {
      if (!isSingleCardEnabled) {
        return true;
      }
      if (step.id === currentStepId) {
        return true;
      }
      if (!step.batchProps) {
        return false;
      }
      return step.batchProps.batchId === currentBatchId;
    },
    [isSingleCardEnabled, currentStepId, currentBatchId]
  );

  const isIntroductionVisible = useCallback(() => {
    if (!isSingleCardEnabled) {
      return true;
    }
    return currentStepId === INTRO_STEP_KEY;
  }, [isSingleCardEnabled, currentStepId]);

  const isEndOfRunContentVisible = useCallback(() => {
    if (!isSingleCardEnabled) {
      return true;
    }
    return currentStepId === INTRO_STEP_KEY || currentStepId === lastStepId;
  }, [isSingleCardEnabled, currentStepId, lastStepId]);

  const updatePreviewUserRoles = (roles) => {
    setPreviewUserRoles(roles);
  };

  const getPreviousStepInBatch = useCallback(
    (batchProps: BatchStepProps): RunStep | undefined => {
      return _getPreviousStepInBatch(run, batchProps);
    },
    [run]
  );

  return (
    <RunContext.Provider
      value={{
        getContentBlock,
        run,
        isUserParticipant,
        toggleIsUserParticipant,
        areDependenciesFulfilled,
        areConditionalsFulfilled,
        areRequirementsMet,
        stepIdsToLabelsMap,
        getSectionAndStepIdPair,
        getReferencedContentContext,
        sourceStepConditionalsMap: dependencyMaps.sourceStepConditionalsMap,
        nextStepIds,
        isSingleCardEnabled,
        isSectionVisible,
        isStepVisible,
        isIntroductionVisible,
        isEndOfRunContentVisible,
        setCurrentStepId,
        isRun,
        isPreviewMode,
        isActiveRun,
        isRunning,
        isPaused,
        showIntroCard,
        advanceStep,
        goToNextFromStep,
        goToPreviousFromStep,
        firstStepId,
        viewStepAsIncomplete,
        previewUserRoles,
        updatePreviewUserRoles,
        onReceiveTelemetry: telemetryUpdaterRef.current.onReceiveTelemetry,
        onSignalLost: telemetryUpdaterRef.current.onSignalLost,
        fetchedTelemetryParameters,
        calculatePauseDurationInMs,
        getPreviousStepInBatch,
        projectId: run.project_id,
      }}
    >
      {children}
    </RunContext.Provider>
  );
};

// @ts-ignore
export const useRunContext: () => RunContextType = () => {
  const context = useContext(RunContext);
  if (!context) {
    return {}; // TODO create a default context or throw an error (requires updating tests)
  }

  return context;
};
