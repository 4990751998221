import React, { Fragment, useCallback, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { generateHiddenClassString } from '../../lib/styles';
import transpose2DArray from '../../lib/array';
import isEqual from 'lodash.isequal';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import DiffContainer from '../Diff/DiffContainer';
import diffUtil from '../../lib/diffUtil';
import ReviewTable from './ReviewTable';
import tableUtil from 'shared/lib/tableUtil';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

const CELL_ROW_COLUMN_REGEX = /\[(\d+)\]\[(\d+)\]/;

// Parses row and column from formik path name, eg "values[3][2]" -> { row: 3, column: 2 }
const parseCellName = (name) => {
  const [, row, column] = name.match(CELL_ROW_COLUMN_REGEX);
  return {
    row: parseInt(row),
    column: parseInt(column),
  };
};

const ReviewTableInput = function ({
  blockLabel,
  content,
  recorded,
  contentIndex,
  isHidden,
  onFieldValueChange,
  onFieldErrorsChanged,
  isSpacerHidden,
}) {
  /**
   * If there are legacy recorded values (no version property),
   * we will need to transpose the values because they were being stored as columns by rows in the old version,
   * and the current version stores the cell values as rows by columns.
   */
  const initialValues = useMemo(() => {
    if (recorded) {
      if (!recorded.version) {
        return { values: transpose2DArray(recorded.values) };
      }

      return recorded;
    }

    const initialCells = content.cells;

    return { values: initialCells };
  }, [content, recorded]);

  const recordValuesChanged = useCallback(
    (name, value) => {
      const { row, column } = parseCellName(name);
      const currentValue = recorded?.values[row][column];
      if (isEqual(currentValue, value)) {
        return;
      }
      const _recorded = {
        row,
        column,
        value,
      };
      onFieldValueChange(content.id, _recorded);
    },
    [content.id, onFieldValueChange, recorded]
  );

  const generateEmptyRowsArray = (numColumns, numRows) =>
    new Array(numRows).fill('').map(() => new Array(numColumns).fill(''));

  const onValidate = useCallback(
    ({ values }) => {
      let errorsArray;

      values.forEach((row, rowIndex) => {
        row.forEach((value, columnIndex) => {
          const columnType = content.columns[columnIndex].column_type;
          const inputType = content.columns[columnIndex].input_type;

          if (columnType === 'input' && inputType === 'number' && tableUtil.isInvalidNumber(value)) {
            errorsArray = errorsArray || generateEmptyRowsArray(content.columns.length, Number(content.rows));
            errorsArray[rowIndex][columnIndex] = 'Enter valid number';
          }
        });
      });

      const errorsObject = {};

      if (errorsArray) {
        errorsObject.values = errorsArray;
      }

      // Notify observers that errors changed
      if (typeof onFieldErrorsChanged === 'function') {
        onFieldErrorsChanged(contentIndex, errorsObject);
      }

      return errorsObject;
    },
    [onFieldErrorsChanged, content.columns, content.rows, contentIndex]
  );

  const diffChangeState = useMemo(() => {
    return diffUtil.getDiffChangeStateForAddedRemovedOnly(content);
  }, [content]);

  const isAddedOrRemoved = useMemo(() => {
    return diffChangeState === ARRAY_CHANGE_SYMBOLS.ADDED || diffChangeState === ARRAY_CHANGE_SYMBOLS.REMOVED;
  }, [diffChangeState]);

  return (
    <Fragment>
      {/* Empty div for first table column */}
      <tr>
        <td></td>
        <td colSpan={2}>
          <div className={generateHiddenClassString('', isHidden)}></div>

          {/* Content div */}
          <div className={generateHiddenClassString('flex mt-4 mr-4 page-break', isHidden)}>
            <Spacer isHidden={isSpacerHidden} />
            <SubstepNumber blockLabel={blockLabel} isHidden={isSpacerHidden} hasExtraVerticalSpacing={false} />

            {/* Table */}
            <div className={`w-full h-full mr-4 ${isAddedOrRemoved ? '-ml-6' : ''}`}>
              <DiffContainer label="Table" diffChangeState={diffChangeState} isTextSticky={false}>
                <div className={isAddedOrRemoved ? 'pl-6' : ''}>
                  <Formik
                    initialValues={initialValues}
                    validate={onValidate}
                    onSubmit={() => {
                      /* no-op */
                    }}
                    enableReinitialize
                  >
                    {({ values, errors, setFieldValue }) => (
                      <Form>
                        <ReviewTable
                          path="values"
                          uniqueId={content.id}
                          columns={content.columns}
                          rowsCount={content.rows}
                          cells={content.cells}
                          values={values}
                          errors={errors}
                          disableColumnHeaders={true}
                          recordValuesChanged={recordValuesChanged}
                          setFieldValue={setFieldValue}
                          removedRows={content.removed_rows}
                          addedRows={content.added_rows}
                          removedColumns={content.removed_columns}
                          addedColumns={content.added_columns}
                        />
                        {errors && errors.values && <div className="text-red-700 my-1">Enter valid numbers</div>}
                      </Form>
                    )}
                  </Formik>
                </div>
              </DiffContainer>
            </div>
          </div>
        </td>
      </tr>
    </Fragment>
  );
};

export default ReviewTableInput;
