import { Run, RunMetadata } from 'shared/lib/types/views/procedures';
import runUtil from './runUtil';

const summaryUtil = {
  getRunSummary: (run: Run): RunMetadata => {
    const PARTICIPANT_TYPE = {
      PARTICIPATING: 'participant',
      VIEWING: 'viewer',
    };

    const participant_user_ids: Array<string> = [];

    if (run.participants) {
      run.participants.forEach((participant) => {
        if (participant.type === PARTICIPANT_TYPE.PARTICIPATING) {
          participant_user_ids.push(participant.user_id);
        }
      });
    }

    const stepCounts = runUtil.getRunStepCounts(run);

    // Gather run summary object.
    const metadata: RunMetadata = {
      _id: run._id,
      code: run.code,
      run_number: run.run_number,
      completedAt: run.completedAt,
      name: run.name,
      operation: run.operation && run.operation.name,
      project_id: run.project_id,
      run_tags: run.run_tags,
      run_section: run.run_section,
      starttime: run.starttime,
      state: run.state,
      status: run.status,
      procedure_type: run.procedure_type,
      automation_status: run.automation_status,
      skipped_steps: stepCounts.runCounts.skippedCount,
      completed_steps: stepCounts.runCounts.completedCount,
      not_required_steps: stepCounts.runCounts.notRequiredCount,
      failed_steps: stepCounts.runCounts.failedCount,
      total_steps: stepCounts.runCounts.totalCount,
      participant_user_ids,
      source_run: '',
      automation_enabled: run.automation_enabled,
    };

    return metadata;
  },
};

export default summaryUtil;
