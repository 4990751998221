import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveRunsMetadata } from '../../../contexts/runsSlice';
import Select from 'react-select';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { reactSelectStyles } from '../../../lib/styles';
import RunLabel from '../../../components/RunLabel';
import procedureUtil from '../../../lib/procedureUtil';

type Option = {
  value: string;
  code: string;
  name?: string;
  run_number?: number;
};

const RunSelect = ({ form, isDisabled, existingRunIds }) => {
  const { currentTeamId } = useDatabaseServices();
  const activeRuns = useSelector((state) => selectActiveRunsMetadata(state, currentTeamId));
  const [value, setValue] = useState<Option | undefined>();

  const options = useMemo(() => {
    if (!activeRuns || !existingRunIds) {
      return [];
    }

    const options = Object.values(activeRuns)
      .filter((run) => !existingRunIds.has(run._id))
      .map((run) => ({
        value: run._id,
        code: run.code,
        name: run.name,
        run_number: run.run_number,
      }));
    return options;
  }, [activeRuns, existingRunIds]);

  const handleChange = (option) => {
    if (!option) {
      return;
    }
    form.setFieldValue('run_id', option?.value ?? null);

    if (option.value) {
      const selectedRun = activeRuns[option.value];
      if (selectedRun) {
        const title = procedureUtil.getProcedureTitle(selectedRun.code, selectedRun.name);
        form.setFieldValue('name', title);
      }
    }

    // for displaying the selected run omit the name
    setValue({
      value: option.value,
      code: option.code,
      run_number: option?.run_number,
    });
  };

  const isSelectDisabled = isDisabled || form.isSubmitting;

  return (
    <Select
      styles={reactSelectStyles}
      classNamePrefix="react-select"
      className="w-72 max-w-prose text-sm border-1 border-gray-400 rounded"
      value={value}
      placeholder="Select Existing Run"
      options={options}
      aria-label="Select Procedure"
      onChange={handleChange}
      formatOptionLabel={(option) => {
        return (
          <RunLabel
            code={option.code}
            name={option.name}
            runNumber={option.run_number}
            // display darker run number background for selected run (which has white text on dark blue background)
            runNumberBg={value?.value === option.value && option.name ? 'dark' : 'light'}
          />
        );
      }}
      isClearable={true}
      isDisabled={isSelectDisabled}
      isLoading={!existingRunIds}
    />
  );
};

export default RunSelect;
