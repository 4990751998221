import {
  ProcedureType,
  ProcedureState,
  ProcedureMetadata,
} from './types/couch/procedures';
import {
  Draft,
  DraftStep,
  Procedure,
  Section,
  Step,
  StepBlock,
  StepSettings,
} from './types/views/procedures';
import signoffUtil from './signoffUtil';

// TODO remove these and use literals from models/views
export const PROCEDURE_STATE_RELEASED = 'released';
export const PROCEDURE_STATE_DRAFT = 'draft';
export const PROCEDURE_STATE_IN_REVIEW = 'in_review';
export const PROCEDURE_STATE_COMPLETED = 'completed';

/**
 * Returns procedure id given a procedure.
 *
 * The procedure id is taken from the procedure_id field of the procedure if that field
 * exists, otherwise we use the couchdb _id (the index)  as the procedure id.
 * This approach is a temporary solution until we implement our own versioning/id
 * scheme.
 *
 * // TODO: refactor Procedure type to `shared`
 */
export const getProcedureId = (procedure: unknown): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return procedure.procedure_id || procedure._id;
};

/**
 * Appends "index_" to the beginning and ":pending" to the end of a procedure id to
 * create an index (couchdb _id field) that can be used to query the procedure.
 *
 * The "index_" and :pending" bookends are used for the ids of procedures that are
 * not released.
 */
export const getPendingProcedureIndex = (procedureId: string): string => {
  return `index_${procedureId}:pending`;
};

/**
 * Removes any snippet messages that were added during procedure editing.
 *
 * Mutates the given procedure doc.
 * // TODO: refactor Draft type to `shared`
 */
export const stripSnippetMessages = (procedure): void => {
  procedure.sections.forEach((section) => {
    delete section.shows_snippet_detached_message;
    section.steps.forEach((step) => {
      delete step.shows_snippet_detached_message;
    });
  });
};

export const isNotTestingProcedure = (
  procedureType: ProcedureType | undefined
): boolean => procedureType === undefined;

const _getAllStepIds = (procedureDoc) => {
  const stepIds: Array<string> = [];
  procedureDoc.sections.forEach((section) => {
    section.steps.forEach((step) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      stepIds.push(step.id);
    });
  });
  return stepIds;
};

export const hasStep = (procedureDoc, stepId) => {
  const allStepIds = _getAllStepIds(procedureDoc);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return allStepIds.includes(stepId);
};

export const displaySectionKey = (
  index: number,
  style?: 'letters' | 'numbers'
): string => {
  if (style === 'numbers') {
    return String(index + 1);
  }

  // by default, style is 'letters'
  let key = '';
  while (index >= 0) {
    key = String.fromCharCode(65 + (index % 26)) + key;
    index = Math.floor(index / 26 - 1);
  }

  return key;
};

export const displayStepKey = (stepIndex: number): string =>
  String(stepIndex + 1);

export const displaySectionStepKey = (
  sectionIndex: number,
  stepIndex: number,
  style?: 'letters' | 'numbers'
): string => {
  const sectionKey = displaySectionKey(sectionIndex, style);
  const stepKey = displayStepKey(stepIndex);
  return {
    letters: `${sectionKey}${stepKey}`,
    numbers: `${sectionKey}.${stepKey}`,
  }[style || 'letters'];
};

export const getStepById = (
  procedure: Procedure,
  sectionId: string,
  stepId: string
): Step => {
  const section = (procedure.sections as Array<Section>).find(
    (section) => section.id === sectionId
  );
  if (!section) {
    throw new Error('Section not found');
  }
  const step = (section.steps as Array<Step>).find(
    (step) => step.id === stepId
  );
  if (!step) {
    throw new Error('Step not found');
  }
  return step;
};

export const getAllStepIdsInSection = (section: Section): Array<string> => {
  return section.steps.map((step) => step.id);
};
export const getAllStepIds = (procedure: Procedure) => {
  return procedure.sections.flatMap(getAllStepIdsInSection);
};
export const getAllSectionIds = (procedure: Procedure) => {
  return procedure.sections.map((section) => section.id);
};

/**
 * Returns a setting controlled by a default setting at the procedure level and a specific setting at the step level.
 * If the step setting is set, use that, otherwise use the default setting. Undefined default setting returns true.
 */
export const isStepSettingEnabled = (
  stepSettingValue?: boolean,
  procedureSettingValue?: boolean
): boolean => {
  return stepSettingValue ?? procedureSettingValue !== false;
};

export const isSkipStepSettingEnabled = ({
  step,
  procedure,
}: {
  step: Step;
  procedure: Procedure;
}) => {
  return isStepSettingEnabled(
    step.skip_step_enabled,
    procedure.skip_step_enabled
  );
};

export const isRepeatStepSettingEnabled = ({
  step,
  procedure,
}: {
  step: Step;
  procedure: Procedure;
}) => {
  return isStepSettingEnabled(
    step.repeat_step_enabled,
    procedure.repeat_step_enabled
  );
};

export const isSuggestEditsStepSettingEnabled = ({
  step,
  procedure,
}: {
  step: Step;
  procedure: Procedure;
}) => {
  return isStepSettingEnabled(
    step.step_suggest_edits_enabled,
    procedure.step_suggest_edits_enabled
  );
};

/**
 * Updates step in place with disabled settings
 */
export const setDisabledStepSettings = (step: DraftStep) => {
  // Ensure existing batch steps always have invalid settings disabled.
  if (step.runAsBatch) {
    step.repeat_step_enabled = false;
    step.step_suggest_edits_enabled = false;
  }

  // Ensure existing no-signoff steps always have invalid settings disabled.
  if (!signoffUtil.isSignoffRequired(step.signoffs)) {
    step.skip_step_enabled = false;
    step.repeat_step_enabled = false;
  }

  if (step.created_during_run) {
    step.repeat_step_enabled = false;
  }
};

export const toggleProcedureLevelStepSetting = ({
  procedure,
  setting,
}: {
  procedure: Draft;
  setting: keyof Omit<StepSettings, 'runAsBatch'>;
}) => {
  procedure.sections?.forEach((section) => {
    section.steps.forEach((step) => {
      delete step[setting];
      setDisabledStepSettings(step);
    });
  });

  procedure[setting] = !isStepSettingEnabled(undefined, procedure[setting]);
};

export const areAnyStepSettingsDifferent = ({
  setting,
  initialProcedure,
  updatedProcedure,
}: {
  setting: Omit<keyof StepSettings, 'runAsBatch'>;
  initialProcedure: Draft;
  updatedProcedure: Draft;
}) => {
  return initialProcedure.sections.some((initialSection, sectionIndex) => {
    return initialSection.steps.some((initialStep, stepIndex) => {
      const updatedStep =
        updatedProcedure.sections[sectionIndex].steps[stepIndex];
      return initialStep[setting as string] !== updatedStep[setting as string];
    });
  });
};

/**
 * Get the state of the procedure, handling backwards compatibility.
 *
 * @param procedure - A procedure document.
 * @returns Procedure state, one of {@link ProcedureStateModel} values.
 */
export const getState = (
  procedure: Procedure | ProcedureMetadata
): ProcedureState => {
  // Very old procedures don't have the `state` property and are assumed to be released.
  if (!procedure.state || procedure.state === PROCEDURE_STATE_RELEASED) {
    return PROCEDURE_STATE_RELEASED;
  }
  return procedure.state;
};

export const isDraft = (procedure: Procedure | ProcedureMetadata): boolean => {
  return getState(procedure) === PROCEDURE_STATE_DRAFT;
};

export const isInReview = (
  procedure: Procedure | ProcedureMetadata
): boolean => {
  return getState(procedure) === PROCEDURE_STATE_IN_REVIEW;
};

export const isReleased = (
  procedure: Procedure | ProcedureMetadata
): boolean => {
  return getState(procedure) === PROCEDURE_STATE_RELEASED;
};

export const isPending = (
  procedure: Procedure | ProcedureMetadata
): boolean => {
  return isDraft(procedure) || isInReview(procedure);
};

export const isLegacyTextBlock = (block: StepBlock) => {
  return (
    block.type === 'text' &&
    block.text &&
    (!block.tokens || block.tokens.length === 0)
  );
};

export const isTokenizedTextBlock = (block: StepBlock) => {
  return block.type === 'text' && block.tokens && block.tokens.length > 0;
};
