import { useCallback, useEffect, useState } from 'react';
import { Tool } from 'shared/lib/types/api/manufacturing/tools/models';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import fromClient from '../lib/tools/fromClient';
import { sortBy } from 'shared/lib/collections';

interface ToolsState {
  allTools: Array<Tool> | undefined;
  isToolNumberUnique: (toolNumber: string | undefined) => boolean;
}

const useTools = (): ToolsState => {
  const [allTools, setAllTools] = useState<Array<Tool>>();
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const refreshTools = useCallback(async () => {
    const clientTools = await services.tools.getTools();
    setAllTools(
      sortBy(clientTools.map(fromClient.toTool), ['tool_number', 'name'])
    );
  }, [services.tools]);

  useEffect(() => {
    refreshTools();
  }, [refreshTools]);

  const isToolNumberUnique = (toolNumber: string | undefined) => {
    if (!allTools || !toolNumber) {
      return true;
    }
    return !allTools.some((tool) => tool.tool_number === toolNumber);
  };

  return {
    allTools,
    isToolNumberUnique,
  };
};

export default useTools;
