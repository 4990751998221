import React from 'react';
import ReferenceToken from './ReferenceToken';

interface ReferenceTokenTooltipProps {
  referenceId: string;
}
const ReferenceTokenTooltip = ({ referenceId }: ReferenceTokenTooltipProps) => {
  return (
    <div className="hidden absolute shadow-lg -mt-4 ml-2 rounded-xl group-hover:block bg-white bg-opacity-50">
      <ReferenceToken originalReferencedContentId={referenceId} />
    </div>
  );
};

export default React.memo(ReferenceTokenTooltip);
