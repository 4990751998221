import {
  Tool,
  ToolInstance,
} from 'shared/lib/types/api/manufacturing/tools/models';
import { NumberSelectOption as SelectOption } from '../../../lib/formik';

const toolToSelectOption = (tool: Tool): SelectOption => ({
  value: tool.id,
  label: `${tool.tool_number} ${tool.name}`,
});

const toolInstanceToSelectOption = (
  toolInstance: ToolInstance
): SelectOption => ({
  value: toolInstance.id,
  label: toolInstance.serial_number,
});

export { toolInstanceToSelectOption, toolToSelectOption };
