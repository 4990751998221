import {
  RunPartKitBlock,
  RunPartKitRecorded,
  KitItem,
} from 'shared/lib/types/views/procedures';

const itemWasKitted = (item: KitItem): boolean => {
  return item.amount === 0 || Boolean(item.item_id);
};

const getProcedureKit = (recorded?: RunPartKitRecorded): Array<KitItem> => {
  if (!recorded || !recorded.items) {
    return [];
  }
  return Object.values(recorded.items);
};

const hasKittedProcedureItems = (
  block: RunPartKitBlock,
  recorded?: RunPartKitRecorded
): boolean => {
  const numItemsFromProcedure = block.items.length;
  const itemsKittedFromProcedure = getProcedureKit(recorded);
  if (numItemsFromProcedure !== itemsKittedFromProcedure.length) {
    return false;
  }
  return itemsKittedFromProcedure.every(itemWasKitted);
};

const getAddedItems = (recorded?: RunPartKitRecorded): Array<KitItem> => {
  if (!recorded || !recorded.added_items) {
    return [];
  }
  return recorded.added_items;
};

const hasKittedAddedItems = (recorded?: RunPartKitRecorded): boolean => {
  const addedItems = getAddedItems(recorded);
  return addedItems.every(itemWasKitted);
};

const kitHasEmptyValues = (
  block: RunPartKitBlock,
  recorded?: RunPartKitRecorded
): boolean => {
  return (
    !hasKittedProcedureItems(block, recorded) || !hasKittedAddedItems(recorded)
  );
};

export default kitHasEmptyValues;
