// Returns true if string or number passed to function is a valid number
const isNumber = (stringOrNumberInput) => {
  const inputAsString = String(stringOrNumberInput);

  // Number validation in javascript is error prone, but this seems to work
  return !isNaN(inputAsString) && !isNaN(parseFloat(inputAsString));
};

export const isInt = (value) => {
  return !isNaN(value) && String(parseInt(Number(value))) === String(value) && !isNaN(parseInt(value, 10));
};

export default isNumber;
