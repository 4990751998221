type RevisionProps = {
  revision: number | string;
  size?: 'sm' | 'med';
  ml?: number;
  prefix?: boolean;
};

const Revision = ({ revision, size = 'med', ml = 2, prefix = true }: RevisionProps) => {
  const padding = size === 'sm' ? 'px-1' : 'px-2 py-1';
  return (
    <span className={`text-xs bg-blue-100 rounded-3xl ml-${ml} ${padding} relative`}>
      {prefix ? 'Rev ' : ''}
      {revision}
    </span>
  );
};

export default Revision;
