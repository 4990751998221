import React, { useCallback, useMemo } from 'react';

import ExpandCollapseCaret from '../ExpandCollapse/ExpandCollapseCaret';
import ReviewBlockAttachment from './Blocks/ReviewBlockAttachment';
import ProcedureBlockReview from '../Blocks/ProcedureBlockReview';
import { generateHiddenClassString } from '../../lib/styles';
import reviewUtil from '../../lib/reviewUtil';
import ReviewCommenting from '../ReviewCommenting';
import withBlockRedlining from '../../hocs/withBlockRedlining';
import withFieldRedlining from '../../hocs/withFieldRedlining';
import { isRedlineSupported } from '../Blocks/BlockTypes';
import { useRunContext } from '../../contexts/RunContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMixpanel } from '../../contexts/MixpanelContext';
import revisionsUtil from '../../lib/revisions';
import { cloneDeep } from 'lodash';
import ProcedureField from '../ProcedureField';
import validateUtil from '../../lib/validateUtil';
import DiffContainer from '../Diff/DiffContainer';
import { useReviewContext } from '../../contexts/ReviewContext';
import useDiff from '../../hooks/useDiff';
import { useRedline } from '../../hooks/useRedline';
import { RUN_STATE } from '../../lib/runUtil';
import sharedDiffUtil from 'shared/lib/diffUtil';
import {
  DraftRedlineComment,
  Header,
  HeaderDiffElement,
  ReviewComment,
  RunHeader,
} from 'shared/lib/types/views/procedures';
import ReviewBlockText from './ReviewBlockText';
import { AxiosResponse } from 'axios';
import ReviewTableInput from '../TableInput/ReviewTableInput';
import CommentWrapper from '../CommentWrapper';
const BlockTextWithRedlining = withBlockRedlining(ReviewBlockText);
const ProcedureBlockWithRedlining = withBlockRedlining(ProcedureBlockReview);
const HeaderFieldWithRedlining = withFieldRedlining(ProcedureField);

interface ReviewProcedureHeaderParams {
  projectId?: string | null;
  header: Header;
  isCollapsed: boolean;
  onCollapse: (id, collapse) => void;
  docState?: string;
  isRedlineFeatureEnabled?: boolean;
  saveHeaderRedline?: (header, headerRedlineMetadata, isRedline) => undefined | Promise<void>;
  onAcceptPendingRedline?: (headerId, redlineIndex) => Promise<AxiosResponse | void>;
  onRefChanged?: (id: string, element: HTMLElement) => void;
  scrollToBufferRem?: number;
  isPreviewMode?: boolean;
  onResolveReviewComment?: (commentId: string) => void;
  onUnresolveReviewComment?: (commentId: string) => void;
  saveReviewComment?: (comment: ReviewComment) => Promise<void>;
  comments?: Array<DraftRedlineComment | ReviewComment>;
  showReviewComments?: boolean;
}

const ReviewProcedureHeader = ({
  comments,
  projectId = null,
  header,
  isCollapsed,
  onCollapse,
  saveReviewComment,
  onResolveReviewComment,
  onUnresolveReviewComment,
  showReviewComments = false,
  docState = '',
  isRedlineFeatureEnabled = false,
  saveHeaderRedline,
  onAcceptPendingRedline,
  onRefChanged,
  scrollToBufferRem = 0,
  isPreviewMode = false,
}: ReviewProcedureHeaderParams) => {
  const { isUserParticipant, isIntroductionVisible, isRun } = useRunContext();
  const { onScrollToDiffRefChanged } = useReviewContext();
  const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });
  const { mixpanel } = useMixpanel();

  const onHeaderRefChanged = useCallback(
    (element) => {
      if (!onRefChanged) {
        return;
      }
      onRefChanged(header.id, element);
    },
    [header.id, onRefChanged]
  );

  const onExpandCollapse = useCallback(() => {
    onCollapse(header.id, !isCollapsed);
  }, [header, isCollapsed, onCollapse]);

  const { isRedlineEnabled, onToggleRedline, redlineState, setRedlineState, showsRedlineButton } = useRedline({
    docState,
    isPreviewMode,
    isRedlineDisabledBecauseOfSectionSnippet: false,
    isRedlineDisabledBecauseOfStepSnippet: false,
    isRedlineDisabledBecauseOfRepeat: false,
    isRedlineFeatureEnabled,
    isUserParticipant,
    projectId,
  });

  const dirtyContentChangeHandler = useCallback(
    (dirty, contentIndex) => {
      setRedlineState((state) => ({
        ...state,
        dirtyContent: {
          ...state.dirtyContent,
          [contentIndex]: dirty,
        },
      }));
    },
    [setRedlineState]
  );

  const dirtyFieldChangeHandler = useCallback(
    (dirty, field) => {
      setRedlineState((state) => ({
        ...state,
        dirtyFields: {
          ...state.dirtyFields,
          [field]: dirty,
        },
      }));
    },
    [setRedlineState]
  );

  const redlineBlockChanges = useCallback(
    (contentIndex) => {
      // Only show redlines for runs.
      if (!isRun) {
        return [];
      }
      const block = header.content[contentIndex];
      return revisionsUtil.getBlockChanges(block, contentIndex, (header as RunHeader).redlines);
    },
    [isRun, header]
  );

  const redlineHeaderFieldChanges = useCallback(
    (field) => {
      // Only show redlines for runs.
      if (!isRun) {
        return [];
      }
      return revisionsUtil.getHeaderFieldChanges(header, field, (header as RunHeader).redlines);
    },
    [isRun, header]
  );

  const onSaveRedlineBlock = useCallback(
    (contentIndex, block) => {
      const latestHeader = revisionsUtil.getLatestHeaderRevision(header);
      const headerCopy = cloneDeep(latestHeader);
      headerCopy.content[contentIndex] = block;
      if (saveHeaderRedline) {
        return saveHeaderRedline(headerCopy, { contentId: block.id }, false);
      }
    },
    [header, saveHeaderRedline]
  );

  const headerFieldKey = useCallback((headerField) => Object.keys(headerField)[0], []);

  const onSaveRedlineField = useCallback(
    (headerField) => {
      const latestHeader = revisionsUtil.getLatestHeaderRevision(header);
      const headerCopy = {
        ...cloneDeep(latestHeader),
        ...headerField,
      };
      if (saveHeaderRedline) {
        return saveHeaderRedline(headerCopy, { field: headerFieldKey(headerField) }, false);
      }
    },
    [header, headerFieldKey, saveHeaderRedline]
  );

  const saveRedlineHandler = useCallback(
    (contentIndex, block) => {
      if (mixpanel) {
        mixpanel.track('Redline Created', { 'Block Type': block.type });
      }
      if (onSaveRedlineBlock) {
        return (
          onSaveRedlineBlock(contentIndex, block)
            ?.then(() => {
              setRedlineState((state) => ({
                ...state,
                dirtyContent: {
                  ...state.dirtyContent,
                  [contentIndex]: false,
                },
              }));
            })
            // Ignored for now, user can click "Save" again
            .catch(() => undefined)
        );
      }
    },
    [onSaveRedlineBlock, mixpanel, setRedlineState]
  );

  const saveRedlineHeaderFieldHandler = useCallback(
    (headerField) => {
      const key = headerFieldKey(headerField);
      if (mixpanel) {
        mixpanel.track('Redline Created', { 'Field Name': key });
      }
      if (onSaveRedlineField) {
        return (
          onSaveRedlineField(headerField)
            ?.then(() => {
              setRedlineState((state) => ({
                ...state,
                dirtyFields: { [key]: false },
              }));
            })
            // Ignored for now, user can click "Save" again
            .catch(() => undefined)
        );
      }
    },
    [onSaveRedlineField, mixpanel, headerFieldKey, setRedlineState]
  );

  const isAcceptRedlineEnabled = useMemo(() => {
    return docState === RUN_STATE.RUNNING;
  }, [docState]);

  const acceptPendingRedline = useCallback(
    (redlineIndex) => {
      if (onAcceptPendingRedline) {
        return onAcceptPendingRedline(header.id, redlineIndex);
      }
    },
    [header.id, onAcceptPendingRedline]
  );

  if (isRun && !isIntroductionVisible()) {
    return null;
  }

  return (
    <DiffContainer
      label="Procedure header"
      diffChangeState={(header as HeaderDiffElement).diff_change_state}
      onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged(header.id, element)}
    >
      <table
        aria-label="Procedure Header"
        role="region"
        className="table-fixed w-full border-collapse"
        cellSpacing="0"
        cellPadding="0"
        border={0}
      >
        <thead>
          <tr>
            <th className="w-4"></th>
            <th className="w-full"></th>
            <th className="w-0"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="align-top">
              {/* Header name */}
              <div className="mt-3" ref={onHeaderRefChanged} style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}>
                <ExpandCollapseCaret
                  isExpanded={!isCollapsed}
                  onClick={onExpandCollapse}
                  ariaLabel={isCollapsed ? 'Expand header' : 'Collapse header'}
                />
              </div>
            </td>
            <td className="break-words">
              <div className="mt-3 ml-4 text-xl min-w-0">
                <HeaderFieldWithRedlining
                  fieldName="name"
                  fieldValue={header.name}
                  redlines={redlineHeaderFieldChanges('name')}
                  placeholder="Header name*"
                  showsRedlineAction={redlineState.isActive}
                  isEditing={false}
                  setIsEditing={() => null}
                  onSubmitEdit={saveRedlineHeaderFieldHandler}
                  onDirtyChanged={dirtyFieldChangeHandler}
                  validate={validateUtil.validateFieldHeaderName}
                  onLabelClick={onExpandCollapse}
                  onAcceptPendingRedline={isAcceptRedlineEnabled ? acceptPendingRedline : undefined}
                  step={undefined}
                />
              </div>
            </td>
          </tr>
        </tbody>
        {/* Header content */}
        <tbody aria-label="Content Block" role="region" className={generateHiddenClassString('', isCollapsed)}>
          {header.content &&
            header.content.map((content, contentIndex) => {
              return (
                <CommentWrapper key={contentIndex} content={content}>
                  {/* Check type of content.text because empty string is falsey */}
                  {content.type.toLowerCase() === 'text' &&
                    typeof sharedDiffUtil.getDiffValue(content, 'text', 'old') === 'string' &&
                    typeof sharedDiffUtil.getDiffValue(content, 'text', 'new') === 'string' && (
                      <BlockTextWithRedlining
                        block={content}
                        blockLabel={undefined}
                        contentIndex={contentIndex}
                        isHidden={false}
                        redlines={redlineBlockChanges(contentIndex)}
                        showsRedlineAction={redlineState.isActive}
                        onSubmitEdit={(block) => saveRedlineHandler(contentIndex, block)}
                        onDirtyChanged={dirtyContentChangeHandler}
                        onAcceptPendingRedline={isAcceptRedlineEnabled ? acceptPendingRedline : undefined}
                        onRefChanged={onRefChanged}
                        scrollMarginTopValueRem={scrollToBufferRem}
                        isEditing={false}
                        setIsEditing={undefined}
                      />
                    )}
                  {content.type.toLowerCase() === 'alert' && (
                    <ProcedureBlockWithRedlining
                      block={content}
                      blockLabel={undefined}
                      isHidden={false}
                      isDark={true}
                      redlines={redlineBlockChanges(contentIndex)}
                      contentIndex={contentIndex}
                      showsRedlineAction={redlineState.isActive && isRedlineSupported(content.type)}
                      onSubmitEdit={(block) => saveRedlineHandler(contentIndex, block)}
                      onDirtyChanged={dirtyContentChangeHandler}
                      onAcceptPendingRedline={isAcceptRedlineEnabled ? acceptPendingRedline : undefined}
                      onContentRefChanged={onRefChanged}
                      scrollMarginTopValueRem={scrollToBufferRem}
                      isEditing={false}
                      setIsEditing={undefined}
                    />
                  )}
                  {content.type.toLowerCase() === 'attachment' && (
                    <ReviewBlockAttachment attachment={content} isHidden={isCollapsed} isSpacerHidden={false} />
                  )}
                  {content.type.toLowerCase() === 'table_input' && (
                    <ReviewTableInput
                      content={content}
                      contentIndex={contentIndex}
                      blockLabel={null}
                      recorded={null}
                      isHidden={false}
                      onFieldValueChange={null}
                      onFieldErrorsChanged={null}
                      isSpacerHidden={null}
                    />
                  )}
                </CommentWrapper>
              );
            })}
          {/* Review comments */}
          {showReviewComments && onResolveReviewComment && onUnresolveReviewComment && saveReviewComment && (
            <tr>
              <td></td>
              <td colSpan={2}>
                <div className={generateHiddenClassString('mb-2', isCollapsed)}></div>
                <div className={generateHiddenClassString('mb-2', isCollapsed)}>
                  <ReviewCommenting
                    stepId={header.id}
                    onResolveReviewComment={onResolveReviewComment}
                    onUnresolveReviewComment={onUnresolveReviewComment}
                    saveReviewComment={saveReviewComment}
                    reviewComments={reviewUtil.getStepReviewComments(comments ?? [], header.id)}
                  />
                </div>
              </td>
            </tr>
          )}
          {/* Redlining */}
          {showsRedlineButton && (
            <tr>
              <td></td>
              <td colSpan={2}>
                <button
                  className="ml-8 mt-3 btn-link text-sm font-bold uppercase rounded hover:bg-gray-200 disabled:bg-transparent disabled:text-gray-300 disabled:cursor-default whitespace-nowrap print:hidden"
                  onClick={onToggleRedline}
                  disabled={!isRedlineEnabled}
                >
                  <FontAwesomeIcon icon="pencil-alt" />
                  {redlineState.isActive ? 'Close Edits' : 'Suggest Edits'}
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </DiffContainer>
  );
};

export default React.memo(ReviewProcedureHeader);
