import React, { useMemo, useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { calculateEventDuration, calculateNewEndTime, formatDuration } from '../lib/event';
import DurationGenerator from './DurationGenerator';

const EventDurationSelect = ({ field, form, isEditing }) => {
  const [showModal, setShowModal] = useState(false);
  const initialDraft = field.value || '';
  const [draft, setDraft] = useState(initialDraft);
  const isEditable = draft || form.values.start;
  const { setFieldValue } = form;

  useEffect(() => {
    if (form.values.start && form.values.predecessor_id) {
      return;
    } else if (form.values.start && form.values.end) {
      const duration = calculateEventDuration(form.values.start, form.values.end);
      setDraft(duration);
      setFieldValue(field.name, duration);
    } else {
      setDraft(null);
      setFieldValue(field.name, null);
    }
  }, [form.values.start, form.values.end, form.values.predecessor_id, setFieldValue, field.name]);

  const handleLabelClick = () => {
    if (isEditing) {
      setShowModal(true);
    }
  };

  const handleCancel = () => {
    setDraft(initialDraft);
    setShowModal(false);
  };

  const handleOK = () => {
    const newEnd = calculateNewEndTime(form.values, draft);

    form.setFieldValue(field.name, draft);
    form.setFieldValue('end', newEnd);
    setShowModal(false);
  };

  const isSaveEnabled = useMemo(() => {
    // Disable OK button if no change to current rrule
    if (draft === field.value) {
      return false;
    }
    return true;
  }, [draft, field.value]);

  return (
    <div className="flex flex-row items-center">
      {showModal && (
        <Modal
          title="Duration"
          primaryActionTitle="OK"
          secondaryActionTitle="Cancel"
          onPrimaryAction={handleOK}
          onSecondaryAction={handleCancel}
          isPrimaryActionEnabled={isSaveEnabled}
          size="lg"
        >
          <DurationGenerator
            value={draft ? formatDuration(draft) : '0 seconds'}
            onChange={(value) => setDraft(value)}
          />
        </Modal>
      )}
      {isEditable && (
        <div
          className={`flex flex-row items-center w-fit px-2 h-[42px] rounded border group ${
            isEditing ? 'cursor-pointer bg-white border-gray-400' : 'border-transparent'
          }`}
          onClick={handleLabelClick}
        >
          {draft ? formatDuration(draft) : 'N/A'}
          {isEditing && (
            <div className="ml-2 p-1 rounded group-hover:bg-gray-200">
              <FontAwesomeIcon className="text-gray-500" icon="edit" />
            </div>
          )}
        </div>
      )}
      {!isEditable && (
        <div className="flex flex-row w-fit px-2 h-[42px] rounded border items-center group border-transparent">
          <span className="italic text-gray-400">N/A</span>
        </div>
      )}
    </div>
  );
};

export default EventDurationSelect;
