import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSettings } from '../../contexts/SettingsContext';
import { Resources } from 'shared/schedule/types/event';
import { isEqual } from 'lodash';
import AvatarStack from '../../components/AvatarStack';

type Option = {
  optionType: string;
  label: string;
  value: string;
};

const ResourceSelect = ({ field, form, isEditing }) => {
  const { setFieldValue } = form;
  const { users } = useSettings();
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

  useEffect(() => {
    const options: Option[] = [];

    // User Options
    if (users?.users) {
      const userList = Object.keys(users.users);
      const userOptions = userList.map((user) => ({
        optionType: 'users',
        label: user,
        value: user,
      }));
      options.push(...userOptions);
    }

    setOptions((currentOptions) => {
      if (!isEqual(currentOptions, options)) {
        return options;
      }
      return currentOptions;
    });
  }, [users?.users]);

  useEffect(() => {
    const options: Option[] = [];
    if (field.value) {
      for (const [optionType, labels] of Object.entries(field.value)) {
        if (labels) {
          const typeOptions = (labels as string[]).map((label) => ({ optionType, label, value: label }));
          options.push(...typeOptions);
        }
      }
    }
    setSelectedOptions(options);
    setSelectedLabels(options.map((item) => item.label));
  }, [field.value]);

  const onSelectResource = async (value: Option[]) => {
    if (value.length !== 0) {
      const resources: Resources = value.reduce((acc, entry) => {
        if (!acc[entry.optionType]) {
          acc[entry.optionType] = [];
        }
        acc[entry.optionType]?.push(entry.label);
        return acc;
      }, {});
      setFieldValue(field.name, resources);
    } else {
      setFieldValue(field.name, {});
    }
  };

  return (
    <div className="flex flex-row items-center relative w-full">
      {isEditing && (
        <Select
          value={selectedOptions}
          placeholder={isEditing ? 'Select Resources...' : 'None'}
          classNamePrefix="react-select"
          className="w-full text-sm border-1 border-gray-400 rounded"
          onChange={(value) => onSelectResource(value)}
          options={options}
          isMulti
          isClearable
          isDisabled={!isEditing}
        />
      )}
      {!isEditing && (
        <>
          <AvatarStack userIds={selectedLabels} />
          {selectedOptions.length === 0 && <span className="italic text-gray-400 pl-2">None</span>}
        </>
      )}
    </div>
  );
};

export default ResourceSelect;
