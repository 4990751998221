import React, { useMemo, useCallback } from 'react';
import fieldInputUtil from '../lib/fieldInputUtil';
import {
  FieldInputBlock,
  FieldInputBlockDiffElement,
  Range,
  RangeDiffElement,
  RuleDiffElement,
  RunFieldInputNumberBlock,
  RunFieldInputRecordedValue,
} from 'shared/lib/types/views/procedures';
import { isEmptyValue } from 'shared/lib/text';
import ProcedureFieldDiff from '../components/ProcedureFieldDiff';
import sharedDiffUtil from 'shared/lib/diffUtil';
import ProcedureDiffText from '../components/ProcedureDiffText';
import diffUtil from '../lib/diffUtil';

export const getNewRange = (range: RangeDiffElement | null): Range => {
  if (!range) {
    return { min: '', max: '' };
  }
  return {
    ...range,
    min: sharedDiffUtil.getDiffValue(range, 'min', 'new'),
    max: sharedDiffUtil.getDiffValue(range, 'max', 'new'),
    include_min: sharedDiffUtil.getDiffValue(range, 'include_min', 'new'),
    include_max: sharedDiffUtil.getDiffValue(range, 'include_max', 'new'),
  };
};

export const getRangeSpan = (range: Range, inputName?: JSX.Element) => {
  const lowerInequality = range.include_min ? '≤' : '<';
  const upperInequality = range.include_max ? '≤' : '<';

  return (
    <span className="flex flex-row gap-x-2 flex-nowrap">
      {range.min} {lowerInequality} {inputName ?? '(input)'} {upperInequality} {range.max}
    </span>
  );
};

export const getRangeStringForDiff = (range: RangeDiffElement, version: 'old' | 'new', inputName?: string) => {
  const min = sharedDiffUtil.getDiffValue(range, 'min', version);
  const max = sharedDiffUtil.getDiffValue(range, 'max', version);
  const includeMin = sharedDiffUtil.getDiffValue(range, 'include_min', version);
  const includeMax = sharedDiffUtil.getDiffValue(range, 'include_max', version);

  const lowerInequality = includeMin ? '≤' : '<';
  const upperInequality = includeMax ? '≤' : '<';

  return `${min} ${lowerInequality} (${inputName ?? 'input'}) ${upperInequality} ${max}`;
};

type UseDisplayRuleProps = {
  block: FieldInputBlock | FieldInputBlockDiffElement;
  recorded?: { value?: RunFieldInputRecordedValue };
  redlinedBlock?: FieldInputBlock;
};

const useDisplayRule = ({ block, recorded, redlinedBlock }: UseDisplayRuleProps) => {
  const displayPass = useMemo(() => {
    return fieldInputUtil.isNumberFieldInputPassing(block as FieldInputBlock, recorded);
  }, [block, recorded]);

  const getDisplayRuleForDiff = useCallback(
    (checkRangeAdded, checkRangeDeleted, passBlock = undefined) => {
      const currentBlock = passBlock ?? block;
      const rule = diffUtil.getFieldValue(currentBlock, 'rule') as RuleDiffElement;
      const rangeAdded =
        checkRangeAdded &&
        rule &&
        (sharedDiffUtil.wasFieldAdded(rule, 'range') ||
          (sharedDiffUtil.getDiffValue(rule, 'op', 'new') === 'range' &&
            sharedDiffUtil.getDiffValue(rule, 'op', 'old') !== 'range'));
      const rangeDeleted =
        checkRangeDeleted &&
        rule &&
        (sharedDiffUtil.wasFieldDeleted(rule, 'range') ||
          (sharedDiffUtil.getDiffValue(rule, 'op', 'old') === 'range' &&
            sharedDiffUtil.getDiffValue(rule, 'op', 'new') !== 'range'));
      const range = rule ? (diffUtil.getFieldValue(rule, 'range') as RangeDiffElement) : null;
      if (
        !rule ||
        isEmptyValue(rule) ||
        isEmptyValue(rule.op) ||
        (checkRangeAdded && !rangeAdded) ||
        (checkRangeDeleted && !rangeDeleted)
      ) {
        return null;
      }

      // Ranges define a `min` and `max` instead of a `value`.
      if (rule.op === 'range' || rangeAdded || rangeDeleted) {
        if (!range || isEmptyValue(range) || isEmptyValue(range?.min) || isEmptyValue(range?.max)) {
          return null;
        }
        return (
          <span
            className={`${
              sharedDiffUtil.wasFieldAdded(block, 'rule')
                ? 'text-emerald-800 bg-emerald-100'
                : sharedDiffUtil.wasFieldDeleted(block, 'rule')
                ? 'text-red-600 line-through'
                : undefined
            }`}
          >
            <ProcedureDiffText
              diffValue={{
                __old: getRangeStringForDiff(range, rangeAdded ? 'new' : 'old'),
                __new: getRangeStringForDiff(range, rangeDeleted ? 'old' : 'new'),
              }}
            />
          </span>
        );
      }
      // Standard block rules define a single `value`
      if (isEmptyValue(rule.value)) {
        return null;
      }
      let op = rule.op;
      if (
        sharedDiffUtil.isChanged(rule, 'op') ||
        sharedDiffUtil.wasFieldDeleted(rule, 'op') ||
        sharedDiffUtil.wasFieldAdded(rule, 'op')
      ) {
        /*
         * If the rule has changed and one of the rules is a range, prepare a range value with __old and __new
         * properties, so that the range will display with the correct style for added/removed text using
         * `ProcedureDiffText`.
         */
        if (sharedDiffUtil.getDiffValue(rule, 'op', 'old') === 'range') {
          op = {
            __new: sharedDiffUtil.getDiffValue(rule, 'op', 'new'),
            __old: '',
          };
        } else if (sharedDiffUtil.getDiffValue(rule, 'op', 'new') === 'range') {
          op = {
            __old: sharedDiffUtil.getDiffValue(rule, 'op', 'old'),
            __new: '',
          };
        }
      }
      return (
        <>
          <ProcedureDiffText diffValue={op} />
          {/* Ensure that there's a space between the operator and the value. */}
          <span> </span>
          <ProcedureDiffText diffValue={rule.value} />
        </>
      );
    },
    [block]
  );

  const getDisplayRule = useCallback(
    (passBlock = undefined) => {
      let currentBlock = block;

      if (passBlock) {
        currentBlock = passBlock;
      }

      if (currentBlock.inputType !== 'number') return;

      const rule = currentBlock.rule;
      const range = rule?.range;
      const newRange = (redlinedBlock as RunFieldInputNumberBlock)?.rule?.range ?? range;
      if (!rule || isEmptyValue(rule) || isEmptyValue(rule.op)) {
        return null;
      }
      // Ranges define a `min` and `max` instead of a `value`.
      if (rule.op === 'range') {
        if (!range || !newRange || isEmptyValue(range) || isEmptyValue(range?.min) || isEmptyValue(range?.max)) {
          return null;
        }
        return (
          <ProcedureDiffText
            diffValue={{
              __old: getRangeStringForDiff(range, 'old'),
              __new: getRangeStringForDiff(newRange, 'new'),
            }}
          />
        );
      }
      // Standard block rules define a single `value`
      if (isEmptyValue(rule.value)) {
        return null;
      }
      const op = rule.op;

      return (
        <>
          {op}
          {/* Ensure that there's a space between the operator and the value. */}
          <span> </span>
          {redlinedBlock && redlinedBlock.inputType === 'number' && (
            <ProcedureFieldDiff
              original={currentBlock.rule?.value?.toString() ?? ''}
              redlined={redlinedBlock.rule?.value?.toString() ?? ''}
            />
          )}
          {!redlinedBlock && rule.value}
        </>
      );
    },
    [block, redlinedBlock]
  );

  return { displayPass, getDisplayRuleForDiff, getDisplayRule };
};

export default useDisplayRule;
