import React from 'react';
import Error from '../../components/Error';
import TextAreaAutoHeight from '../../components/TextAreaAutoHeight';
import MarkdownView from '../../components/Markdown/MarkdownView';

const EventMarkdown = ({ field, form, type, isEditing }) => {
  const touched = form.touched[field.name];
  const error = form.errors[field.name];

  return (
    <div className="flex flex-col pt-1 pl-3 w-full space-">
      {error && touched && <Error text={error} />}
      <div className="relative">
        <div className={`absolute border border-transparent p-2 ${isEditing ? 'invisible' : ''}`}>
          {!field.value && <span className="italic text-gray-400">None</span>}
          {field.value && <MarkdownView text={field.value || ''} />}
        </div>
        <div className={`w-full text-lg ${isEditing ? '' : 'invisible'}`}>
          <TextAreaAutoHeight
            placeholder="Add notes [markdown supported]"
            defaultRows={3}
            textSize="base"
            {...field}
            value={field.value || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default EventMarkdown;
