import SubstepNumber from '../../../components/SubstepNumber';
import useParts from '../../hooks/useParts';
import { generateHiddenClassString } from '../../../lib/styles';
import DiffContainer from '../../../components/Diff/DiffContainer';
import diffUtil from '../../../lib/diffUtil';
import FieldInputBuildItemsSerial from '../FieldInputBuildItemsSerial';
import ReviewBuildItem from './ReviewBuildItem';
import { noop } from 'lodash';
import { isPartRestricted } from '../../lib/parts';
import RestrictedInfo, { RESTRICTED_TEXT } from '../RestrictedInfo';

const ReviewPartBuild = ({ content, recorded, blockLabel, teamId, isEnabled, isHidden }) => {
  const { parts, getPart } = useParts();

  if (!parts) {
    return null;
  }

  return (
    <tr>
      <td></td>
      <td colSpan={2}>
        <div className={generateHiddenClassString('', isHidden)} />
        <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
          <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />

          <div className="flex items-start w-full py-1 mr-8">
            <DiffContainer
              label="Part inventory"
              diffChangeState={diffUtil.getDiffChangeStateForAddedRemovedOnly(content)}
              isTextSticky={false}
              width="fit"
            >
              {/* Part components */}
              {content?.items?.length > 0 && (
                <table className="w-full table-fixed">
                  <thead>
                    <tr>
                      <td>
                        <div className="p-1">
                          <label htmlFor="components" className="text-sm font-medium uppercase">
                            Parts for Check-In
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="p-1">
                          <span className="text-sm font-medium uppercase">Quantity</span>
                        </div>
                      </td>
                      <td>
                        <div className="p-1">
                          <span className="text-sm font-medium uppercase">Serial / Lot #</span>
                        </div>
                      </td>
                      {/* search locations */}
                      <td></td>
                      {/* remove component button */}
                      <td className="w-6"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {content?.items.map((item, index) => {
                      const part = getPart(item.part_id);
                      const partRestricted = isPartRestricted(part);
                      if (partRestricted) {
                        return (
                          <div className="mt-2 mb-1 ml-1.5">
                            <RestrictedInfo text={RESTRICTED_TEXT} />
                          </div>
                        );
                      }
                      if (part?.tracking === 'serial') {
                        return (
                          <FieldInputBuildItemsSerial
                            key={item.id}
                            item={{
                              ...item,
                              part_index: index,
                            }}
                            part={part}
                            isEnabled={isEnabled}
                            recorded={recorded}
                            teamId={teamId}
                            existingSerialNumbers={[]}
                            onAddItem={noop}
                            onRemoveItem={noop}
                            onRecordItemValuesChanged={noop}
                            onRecordErrorsChanged={noop}
                          />
                        );
                      } else {
                        return (
                          <ReviewBuildItem
                            key={item.id}
                            item={item}
                            part={part}
                            isEnabled={isEnabled}
                            recorded={recorded?.items[item.id]}
                            teamId={teamId}
                          />
                        );
                      }
                    })}
                  </tbody>
                </table>
              )}
            </DiffContainer>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ReviewPartBuild;
