import React from 'react';
import { ReviewComment } from 'shared/lib/types/views/procedures';
import Comment from './Comment';

interface CommentsListProps {
  comments: Array<ReviewComment>;
  onResolveComment?: () => void;
  onUnresolveComment?: () => void;
  onRefChanged?: (id: string, element: HTMLElement) => void;
  onEdit?: (comment, id) => void;
}

const CommentsList = ({ comments, onResolveComment, onUnresolveComment, onRefChanged, onEdit }: CommentsListProps) => {
  return (
    <div className="flex flex-col gap-y-3">
      {comments.map((comment) => (
        <Comment
          key={comment.id}
          comment={comment}
          onResolve={onResolveComment}
          onUnresolve={onUnresolveComment}
          onFieldRefChanged={undefined}
          onRefChanged={onRefChanged}
          editComment={onEdit}
          commentButton={undefined}
          error={undefined}
          isChild={undefined}
          onSetParentCollapsed={undefined}
          parentCollapsed={undefined}
        />
      ))}
    </div>
  );
};

export default React.memo(CommentsList);
