import React, { useCallback, useMemo } from 'react';
import ReviewBlockAttachment from './Blocks/ReviewBlockAttachment';
import ProcedureBlock from '../Blocks/ProcedureBlockReview';
import { generateHiddenClassString } from '../../lib/styles';
import reviewUtil from '../../lib/reviewUtil';
import ReviewCommenting from '../ReviewCommenting';
import withBlockRedlining from '../../hocs/withBlockRedlining';
import withFieldRedlining from '../../hocs/withFieldRedlining';
import { isRedlineSupported } from '../Blocks/BlockTypes';
import { useRunContext } from '../../contexts/RunContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../contexts/AuthContext';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { PERM } from '../../lib/auth';
import { RUN_STATE } from 'shared/lib/runUtil';
import revisionsUtil from '../../lib/revisions';
import { cloneDeep } from 'lodash';
import ProcedureField from '../ProcedureField';
import validateUtil from '../../lib/validateUtil';
import DiffContainer from '../Diff/DiffContainer';
import { useReviewContext } from '../../contexts/ReviewContext';
import useDiff from '../../hooks/useDiff';
import { useRedline } from '../../hooks/useRedline';
import sharedDiffUtil from 'shared/lib/diffUtil';
import ReviewBlockText from './ReviewBlockText';
import CommentWrapper from '../CommentWrapper';

const BlockTextWithRedlining = withBlockRedlining(ReviewBlockText);
const ProcedureBlockWithRedlining = withBlockRedlining(ProcedureBlock);
const SectionHeaderFieldWithRedlining = withFieldRedlining(ProcedureField);

/**
 * @param {Object} props
 * @param {Array} props.comments
 * @param {String} props.projectId
 * @param {import('shared/lib/types/views/procedures').SectionHeaderDiffElement} props.sectionHeader
 * @param {Boolean} props.isCollapsed
 * @param {Boolean} props.saveReviewComment
 * @param {Function} props.onResolveReviewComment
 * @param {Function} props.onUnresolveReviewComment
 * @param {import('shared/lib/types/views/procedures').RunState} props.docState
 * @param {Boolean} props.isRedlineDisabledBecauseOfRepeat
 * @param {Boolean} props.isRedlineFeatureEnabled
 * @param {Boolean} props.isPreviewMode
 * @param {Function} props.saveSectionHeaderRedline
 * @param {Function} props.onAcceptPendingRedline
 * @param {Function} props.onRefChanged
 * @param {Boolean} props.showReviewComments
 * @param {Number} props.scrollToBufferRem
 */
const ReviewSectionHeader = ({
  comments,
  projectId,
  sectionHeader,
  isCollapsed,
  saveReviewComment,
  onResolveReviewComment,
  onUnresolveReviewComment,
  docState,
  isRedlineDisabledBecauseOfRepeat,
  isRedlineFeatureEnabled,
  isPreviewMode,
  saveSectionHeaderRedline,
  onAcceptPendingRedline,
  onRefChanged,
  showReviewComments = false,
  scrollToBufferRem = 0,
}) => {
  const { isUserParticipant, isIntroductionVisible, isRun } = useRunContext();
  const { onScrollToDiffRefChanged } = useReviewContext();
  const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });
  const { auth } = useAuth();
  const { mixpanel } = useMixpanel();

  const onSectionHeaderRefChanged = useCallback(
    (element) => {
      if (!onRefChanged) {
        return;
      }
      onRefChanged(sectionHeader.id, element);
    },
    [sectionHeader.id, onRefChanged]
  );

  const { isRedlineEnabled, onToggleRedline, redlineState, setRedlineState, showsRedlineButton } = useRedline({
    docState,
    isPreviewMode,
    isRedlineDisabledBecauseOfSectionSnippet: false,
    isRedlineDisabledBecauseOfStepSnippet: false,
    isRedlineDisabledBecauseOfRepeat,
    isRedlineFeatureEnabled,
    isUserParticipant,
    projectId,
  });

  const dirtyContentChangeHandler = useCallback(
    (dirty, contentIndex) => {
      setRedlineState((state) => ({
        ...state,
        dirtyContent: {
          ...state.dirtyContent,
          [contentIndex]: dirty,
        },
      }));
    },
    [setRedlineState]
  );

  const dirtyFieldChangeHandler = useCallback(
    (dirty, field) => {
      setRedlineState((state) => ({
        ...state,
        dirtyFields: {
          ...state.dirtyFields,
          [field]: dirty,
        },
      }));
    },
    [setRedlineState]
  );

  const redlineBlockChanges = useCallback(
    (contentIndex) => {
      // Only show redlines for runs.
      if (!isRun || !sectionHeader.redlines) {
        return [];
      }
      const block = sectionHeader.content[contentIndex];
      return revisionsUtil.getBlockChanges(block, contentIndex, sectionHeader.redlines);
    },
    [isRun, sectionHeader]
  );

  const redlineSectionHeaderFieldChanges = useCallback(
    (field) => {
      // Only show redlines for runs.
      if (!isRun || !sectionHeader.redlines) {
        return [];
      }
      return revisionsUtil.getSectionHeaderFieldChanges(sectionHeader, field, sectionHeader.redlines);
    },
    [isRun, sectionHeader]
  );

  const onSaveRedlineBlock = useCallback(
    (contentIndex, block) => {
      const latestSectionHeader = revisionsUtil.getLatestSectionHeaderRevision(sectionHeader);
      const sectionHeaderCopy = cloneDeep(latestSectionHeader);
      sectionHeaderCopy.content[contentIndex] = block;
      return saveSectionHeaderRedline(sectionHeaderCopy, { changedContentIndex: contentIndex });
    },
    [sectionHeader, saveSectionHeaderRedline]
  );

  const sectionHeaderFieldKey = useCallback((sectionHeaderField) => Object.keys(sectionHeaderField)[0], []);

  const onSaveRedlineField = useCallback(
    (sectionHeaderField) => {
      const latestSectionHeader = revisionsUtil.getLatestSectionHeaderRevision(sectionHeader);
      const sectionHeaderCopy = {
        ...cloneDeep(latestSectionHeader),
        ...sectionHeaderField,
      };
      return saveSectionHeaderRedline(sectionHeaderCopy, {
        changedHeaderField: sectionHeaderFieldKey(sectionHeaderField),
      });
    },
    [sectionHeader, sectionHeaderFieldKey, saveSectionHeaderRedline]
  );

  const saveRedlineHandler = useCallback(
    (contentIndex, block) => {
      if (mixpanel) {
        mixpanel.track('Redline Created', { 'Block Type': block.type });
      }
      return (
        onSaveRedlineBlock(contentIndex, block)
          .then(() => {
            setRedlineState((state) => ({
              ...state,
              dirtyContent: {
                ...state.dirtyContent,
                [contentIndex]: false,
              },
            }));
          })
          // Ignored for now, user can click "Save" again
          .catch(() => {})
      );
    },
    [onSaveRedlineBlock, mixpanel, setRedlineState]
  );

  const saveRedlineSectionHeaderFieldHandler = useCallback(
    (sectionHeaderField) => {
      const key = sectionHeaderFieldKey(sectionHeaderField);
      if (mixpanel) {
        mixpanel.track('Redline Created', { 'Field Name': key });
      }
      return (
        onSaveRedlineField(sectionHeaderField)
          .then(() => {
            setRedlineState((state) => ({
              ...state,
              dirtyFields: { [key]: false },
            }));
          })
          // Ignored for now, user can click "Save" again
          .catch(() => {})
      );
    },
    [onSaveRedlineField, mixpanel, sectionHeaderFieldKey, setRedlineState]
  );

  const isAcceptRedlineEnabled = useMemo(() => {
    return auth.hasPermission(PERM.PROCEDURES_EDIT, projectId) && docState === RUN_STATE.RUNNING;
  }, [auth, projectId, docState]);

  const acceptPendingRedline = useCallback(
    (redlineIndex) => {
      return onAcceptPendingRedline(sectionHeader.id, redlineIndex);
    },
    [sectionHeader.id, onAcceptPendingRedline]
  );

  if (isRun && !isIntroductionVisible()) {
    return null;
  }

  return (
    <DiffContainer
      label="Section Header"
      diffChangeState={sectionHeader.diff_change_state}
      onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged(sectionHeader.id, element)}
    >
      <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
        <thead>
          <tr>
            <th className="w-4"></th>
            <th className="w-full"></th>
            <th className="w-0"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="align-top">
              {/* Section header name */}
              <div
                className="mt-3"
                ref={onSectionHeaderRefChanged}
                style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
              ></div>
            </td>
            <td className="break-words">
              <div className="ml-4 text-xl min-w-0">
                <SectionHeaderFieldWithRedlining
                  fieldName="name"
                  fieldValue={sectionHeader.name}
                  redlines={redlineSectionHeaderFieldChanges('name')}
                  placeholder="Section header name*"
                  showsRedlineAction={redlineState.isActive}
                  onSubmitEdit={saveRedlineSectionHeaderFieldHandler}
                  onDirtyChanged={dirtyFieldChangeHandler}
                  validate={validateUtil.validateFieldHeaderName}
                  onAcceptPendingRedline={isAcceptRedlineEnabled ? acceptPendingRedline : undefined}
                />
              </div>
            </td>
          </tr>
        </tbody>

        {/* Section header content */}
        <tbody className={generateHiddenClassString('', isCollapsed)}>
          {sectionHeader.content &&
            sectionHeader.content.map((content, contentIndex) => {
              return (
                <CommentWrapper content={content}>
                  {content.type.toLowerCase() === 'text' &&
                    typeof sharedDiffUtil.getDiffValue(content, 'text', 'old') === 'string' &&
                    typeof sharedDiffUtil.getDiffValue(content, 'text', 'new') === 'string' && (
                      <BlockTextWithRedlining
                        block={content}
                        contentIndex={contentIndex}
                        isHidden={false}
                        redlines={redlineBlockChanges(contentIndex)}
                        showsRedlineAction={redlineState.isActive}
                        onSubmitEdit={(block) => saveRedlineHandler(contentIndex, block)}
                        onDirtyChanged={dirtyContentChangeHandler}
                        onAcceptPendingRedline={isAcceptRedlineEnabled ? acceptPendingRedline : undefined}
                      />
                    )}
                  {(content.type.toLowerCase() === 'alert' || content.type.toLowerCase() === 'requirement') && (
                    <ProcedureBlockWithRedlining
                      block={content}
                      isHidden={false}
                      isDark={true}
                      redlines={redlineBlockChanges(contentIndex)}
                      contentIndex={contentIndex}
                      showsRedlineAction={redlineState.isActive && isRedlineSupported(content.type)}
                      onSubmitEdit={(block) => saveRedlineHandler(contentIndex, block)}
                      onDirtyChanged={dirtyContentChangeHandler}
                      onAcceptPendingRedline={isAcceptRedlineEnabled ? acceptPendingRedline : undefined}
                    />
                  )}
                  {content.type.toLowerCase() === 'attachment' && (
                    <ReviewBlockAttachment attachment={content} isHidden={isCollapsed} isSpacerHidden={false} />
                  )}
                </CommentWrapper>
              );
            })}
          {/* Review comments */}
          {showReviewComments && (
            <tr>
              <td></td>
              <td colSpan={2}>
                <div className={generateHiddenClassString('mb-2', isCollapsed)}></div>
                <div className={generateHiddenClassString('mb-2', isCollapsed)}>
                  <ReviewCommenting
                    stepId={sharedDiffUtil.getDiffValue(sectionHeader, 'id', 'new')}
                    onResolveReviewComment={onResolveReviewComment}
                    onUnresolveReviewComment={onUnresolveReviewComment}
                    saveReviewComment={saveReviewComment}
                    reviewComments={reviewUtil.getStepReviewComments(
                      comments,
                      sharedDiffUtil.getDiffValue(sectionHeader, 'id', 'new')
                    )}
                  />
                </div>
              </td>
            </tr>
          )}
          {/* Redlining */}
          {showsRedlineButton && (
            <button
              className="ml-8 btn-link text-sm font-bold uppercase rounded hover:bg-gray-200 disabled:bg-transparent disabled:text-gray-300 disabled:cursor-default whitespace-nowrap"
              onClick={onToggleRedline}
              disabled={!isRedlineEnabled}
            >
              <FontAwesomeIcon icon="pencil-alt" /> {redlineState.isActive ? 'Close Edits' : 'Suggest Edits'}
            </button>
          )}
        </tbody>
      </table>
    </DiffContainer>
  );
};

export default React.memo(ReviewSectionHeader);
