import { Field, FormikHelpers, FormikValues } from 'formik';
import FieldSetAutomationToggle from './FieldSetAutomationToggle';
import FieldSetToggle from './FieldSetToggle';
import React, { useCallback } from 'react';
import { useSettings } from '../contexts/SettingsContext';
import { Draft } from 'shared/lib/types/views/procedures';
import {
  areAnyStepSettingsDifferent,
  isStepSettingEnabled,
  toggleProcedureLevelStepSetting,
} from 'shared/lib/procedureUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import Tooltip from '../elements/Tooltip';
import { cloneDeep } from 'lodash';

const OverrideSettingInfo = () => (
  <Tooltip content="Overrides can be set in individual step settings.">
    <FontAwesomeIcon className="text-xs" icon={faCircleInfo} />
  </Tooltip>
);

const PROCEDURE_LEVEL_STEP_SETTINGS = [
  {
    setting: 'skip_step_enabled',
    label: 'Skip Step',
    tooltipText: 'Updates setting for all steps',
  },
  {
    setting: 'repeat_step_enabled',
    label: 'Repeat Step',
    tooltipText: 'Updates setting for all steps',
  },
  {
    setting: 'step_suggest_edits_enabled',
    label: 'Suggest Edits',
    tooltipText: 'Updates setting for all steps',
  },
] as const;

interface ProcedureSettingsTogglesProps {
  values: Draft;
  procedure: Draft;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  setValues: FormikHelpers<Draft>['setValues'];
}

const ProcedureSettingsToggles = ({ values, procedure, setFieldValue, setValues }: ProcedureSettingsTogglesProps) => {
  const { isAutomationUIEnabled } = useSettings();

  const getOnChangeProcedureStepSetting = useCallback(
    (setting) => () => {
      const updatedValues = cloneDeep(values);

      toggleProcedureLevelStepSetting({
        procedure: updatedValues,
        setting,
      });
      if (
        areAnyStepSettingsDifferent({
          setting,
          initialProcedure: cloneDeep(values),
          updatedProcedure: updatedValues,
        })
      ) {
        if (!window.confirm('This will update all individual step settings. Ok to continue?')) {
          return;
        }
      }
      setValues(updatedValues, true);
    },
    [setValues, values]
  );

  return (
    <>
      {isAutomationUIEnabled() && (
        <Field
          name="automation_enabled"
          component={FieldSetAutomationToggle}
          value={values.automation_enabled}
          procedure={procedure}
        />
      )}
      <Field
        name="is_strict_signoff_enabled"
        component={FieldSetToggle}
        label="Strict Signoff"
        onChange={() => setFieldValue('is_strict_signoff_enabled', !values.is_strict_signoff_enabled)}
        tooltip="Requires all inputs and linked procedures to be completed"
        value={Boolean(values.is_strict_signoff_enabled)}
        grow={false}
      />
      {PROCEDURE_LEVEL_STEP_SETTINGS.map(({ setting, label, tooltipText }) => (
        <div className="flex flex-row items-center gap-x-2">
          <Field
            name={setting}
            component={FieldSetToggle}
            label={label}
            onChange={getOnChangeProcedureStepSetting(setting)}
            tooltip={tooltipText}
            value={isStepSettingEnabled(undefined, values[setting])}
            grow={false}
          />
          <OverrideSettingInfo />
        </div>
      ))}
    </>
  );
};

export default ProcedureSettingsToggles;
