import React, { ReactElement, ReactNode } from 'react';
import TextLinkify from '../TextLinkify';
import ReactMarkdown from 'react-markdown';
import MarkdownCodeWrapper from './MarkdownCodeWrapper';
import MarkdownLinkWrapper from './MarkdownLinkWrapper';
import MarkdownUListWrapper from './MarkdownUListWrapper';
import MarkdownOListWrapper from './MarkdownOListWrapper';
import { ReactMarkdownProps } from 'react-markdown/src/ast-to-react';
import TooltipOverlay from '../TooltipOverlay';

export const MARKDOWN_MARGIN_CLASS = 'mb-2';
const FORCE_WRAP_LENGTH_THRESHOLD = 60;
const MARKDOWN_MARGIN_LAST_ELEMENT_CLASS = 'last:-mb-2';

const hasVeryLongStrings = (text: string, maxLength = FORCE_WRAP_LENGTH_THRESHOLD) => {
  const parts = text.split(' ');
  return parts.some((part) => part.length > maxLength);
};

const childrenAsText = (children: Array<ReactNode>) => {
  if (children && children.length && children.length === 1 && ['string', 'number'].includes(typeof children[0])) {
    return children[0] as string;
  }
  return null;
};

const breakingStyleForParagraph = (children: Array<ReactNode>) => {
  const text = childrenAsText(children);
  if (text !== null) {
    if (hasVeryLongStrings(text)) {
      return 'break-all';
    }
  }
  return 'break-words';
};

interface CustomComponentsProps {
  h1: (props: ReactMarkdownProps) => ReactElement;
  h2: (props: ReactMarkdownProps) => ReactElement;
  p: (props: ReactMarkdownProps) => ReactElement;
  code: (code: { inline?: boolean; children: ReactNode }) => ReactElement;
  a: (link: { children: ReactNode; href?: string }) => ReactElement;
  ul: (props: ReactMarkdownProps) => ReactElement;
  ol: (props: ReactMarkdownProps) => ReactElement;
}

/**
 * Allows us to return custom components for element types.
 * Note: We return an h2 for every h1, because h1 clashes with our step names.
 *       h2 is ok because we don't have any styles defined for h2, h3 etc.
 *       They will show up as regular text.
 */
const customComponents = (
  truncate: boolean,
  tooltipContent: React.ReactNode,
  tooltipVisible: boolean
): CustomComponentsProps => {
  const Wrapper = ({ children }): JSX.Element =>
    tooltipVisible ? (
      <TooltipOverlay visible={true} content={tooltipContent} delayClose={true}>
        {children}
      </TooltipOverlay>
    ) : (
      children
    );
  return {
    h1: ({ children }) => (
      <Wrapper>
        <h2 className={MARKDOWN_MARGIN_CLASS}>{children}</h2>
      </Wrapper>
    ),
    h2: ({ children }) => (
      <Wrapper>
        <h2 className={MARKDOWN_MARGIN_CLASS}>{children}</h2>
      </Wrapper>
    ),
    p: ({ children }) => {
      return (
        <Wrapper>
          <p
            className={`${MARKDOWN_MARGIN_CLASS} ${
              truncate ? 'truncate' : 'whitespace-pre-wrap'
            } max-w-full ${breakingStyleForParagraph(children)}`}
          >
            <TextLinkify>{children}</TextLinkify>
          </p>
        </Wrapper>
      );
    },
    code: ({ inline, children }) => (
      <Wrapper>
        <MarkdownCodeWrapper isInline={inline}>{children}</MarkdownCodeWrapper>
      </Wrapper>
    ),
    a: ({ children, href }) => (
      <Wrapper>
        <MarkdownLinkWrapper href={href}>{children}</MarkdownLinkWrapper>
      </Wrapper>
    ),
    ul: ({ children }) => (
      <Wrapper>
        <MarkdownUListWrapper>{children}</MarkdownUListWrapper>
      </Wrapper>
    ),
    ol: ({ children }) => (
      <Wrapper>
        <MarkdownOListWrapper>{children}</MarkdownOListWrapper>
      </Wrapper>
    ),
  };
};

interface MarkdownViewProps {
  text: string;
  tooltipContent?: React.ReactNode;
  tooltipVisible?: boolean;
  truncate?: boolean;
}

/**
 * Parses a text string for markdown and renders markdown styled elements.
 * - Heading styles are ignored because they clash with our headings.
 * - Links will get a blue text color and will open in a new tab.
 * - Code blocks/lines will get monospaced font and a light gray background.
 */
const MarkdownView = React.memo(
  ({ text, tooltipContent, tooltipVisible = false, truncate = false }: MarkdownViewProps) => {
    return (
      <ReactMarkdown
        components={customComponents(truncate, tooltipContent, tooltipVisible)}
        className={`${MARKDOWN_MARGIN_LAST_ELEMENT_CLASS} w-fit`}
      >
        {text}
      </ReactMarkdown>
    );
  }
);

export default MarkdownView;
