import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useNavState } from '../contexts/NavContext';
import ToggleDiff from './Diff/ToggleDiff';
import NavigationChanges from './Diff/NavigationChanges';
import diffUtil, { ChangeEntry } from '../lib/diffUtil';
import { Draft, ProcedureDiff, Release } from 'shared/lib/types/views/procedures';
import procedureUtil from '../lib/procedureUtil';
import ReviewStatusLabel from './ReviewStatusLabel';
import ExpandCollapseButtons from './ExpandCollapse/ExpandCollapseButtons';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';

export const REVIEW_STICKY_HEADER_HEIGHT_REM = 2.5;

interface ReviewStickyHeaderProps {
  procedureDiff: ProcedureDiff | null;
  review: Draft | null;
  release: Release | null;
  showDiff: boolean;
  setShowDiff: React.Dispatch<React.SetStateAction<boolean>>;
  isApproved: boolean;
  scrollToDiff: (changeEntry: ChangeEntry) => void;
  onReview: () => void;
  onPreview: () => void;
  onFlowView: () => void;
  onExpandAll: () => void;
  onCollapseAll: () => void;
}
const ReviewStickyHeader = ({
  procedureDiff,
  review,
  release,
  showDiff,
  setShowDiff,
  isApproved,
  scrollToDiff,
  onReview,
  onPreview,
  onFlowView,
  onExpandAll,
  onCollapseAll,
}: ReviewStickyHeaderProps) => {
  const navState = useNavState();

  const { url } = useRouteMatch();
  const isReview = useMemo<boolean>(() => url.endsWith('/review'), [url]);
  const isFlowView = useMemo<boolean>(() => url.endsWith('/review/flow-view'), [url]);

  const newTitle = useMemo(() => {
    if (!review) {
      return '';
    }
    return procedureUtil.getProcedureTitle(review.code, review.name);
  }, [review]);

  const changes = useMemo(() => (procedureDiff ? diffUtil.getChangeEntries(procedureDiff) : []), [procedureDiff]);

  const hasDiff = Boolean(procedureDiff) && Boolean(release);
  const diffLoading = !procedureDiff && Boolean(release);

  return (
    <div
      data-testid="reviewHeader"
      className={`fixed top-0 ${
        navState.collapsed ? 'left-16' : 'left-64'
      } transition-all right-0 pl-2 pr-4 h-10 flex flex-row justify-between shadow-md bg-gray-100 z-50 print:hidden`}
    >
      <div className="h-full flex items-center whitespace-nowrap text-ellipsis overflow-hidden w-1/2">
        <button
          className={stickyHeaderUtil.getButtonClassNames(false, isReview)}
          type="button"
          title="Review Draft"
          aria-label="Review Draft"
          disabled={isReview}
          onClick={onReview}
        >
          <FontAwesomeIcon icon="clipboard" />
        </button>
        <button
          className={stickyHeaderUtil.getButtonClassNames(false, false)}
          type="button"
          title="Preview Procedure"
          aria-label="Preview Procedure"
          disabled={false}
          onClick={onPreview}
        >
          <FontAwesomeIcon icon={'fa-solid fa-eye' as IconProp} />
        </button>
        <button
          className={stickyHeaderUtil.getButtonClassNames(false, isFlowView)}
          type="button"
          title="Flow View"
          aria-label="Flow View"
          disabled={isFlowView}
          onClick={onFlowView}
        >
          <FontAwesomeIcon icon="sitemap" />
        </button>
        <div className="bg-slate-300 min-w-[1px] w-[1px] h-8 ml-1 mr-2" />
        <ReviewStatusLabel isReviewApproved={isApproved} />
        <span className="ml-2 truncate">{newTitle}</span>
      </div>
      <div className="flex flex-row gap-x-2">
        <div className="h-full flex items-center whitespace-nowrap text-ellipsis overflow-hidden">
          <ExpandCollapseButtons
            buttonClasses={stickyHeaderUtil.getButtonClassNames(isFlowView, false)}
            onExpand={onExpandAll}
            onCollapse={onCollapseAll}
            isDisabled={isFlowView}
          />
        </div>
        {isReview && hasDiff && !diffLoading && (
          <>
            <NavigationChanges changes={changes} scrollToDiff={scrollToDiff} isEnabled={showDiff} />
            <div className="flex items-center">
              <ToggleDiff showDiff={showDiff} setShowDiff={setShowDiff} />
            </div>
          </>
        )}
        {isReview && diffLoading && (
          <div className="uppercase text-gray-400 text-sm font-bold flex items-center">Diff is loading</div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ReviewStickyHeader);
