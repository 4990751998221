import { procedurePendingPath } from '../../lib/pathUtil';
import React, { useCallback, useMemo } from 'react';
import procedureUtil from '../../lib/procedureUtil';
import { useSelector } from 'react-redux';
import { selectProceduresMetadata } from '../../contexts/proceduresSlice';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { NOTIFICATION_STATUS, getProcedureGenerationNotificationMessage } from 'shared/lib/notifications';
import Button from '../Button';
import Tooltip from '../../elements/Tooltip';
import { AppNotificationDisplay } from 'shared/lib/types/notifications';
import { NotifyProcedureGenerationContext } from 'shared/lib/types/postgres/notifications';

export interface NotificationLinkProps {
  notification: AppNotificationDisplay;
  duplicateIds: Array<number>;
  handleOnClick: (ids: Array<number>) => void;
  isCodeTruncated?: boolean;
}

const ProcedureGenerationNotificationLink = ({
  notification,
  duplicateIds,
  handleOnClick,
  isCodeTruncated = true,
}: NotificationLinkProps) => {
  const { currentTeamId } = useDatabaseServices();
  const procedureGenerationContext = useMemo(
    () => notification.context as NotifyProcedureGenerationContext,
    [notification.context]
  );
  const code = useSelector((state) => {
    const procedures = selectProceduresMetadata(state, currentTeamId);
    const procedureId = procedureGenerationContext.procedureId;
    const pendingProcedureId = procedureUtil.getPendingProcedureIndex(procedureId);
    return (procedures[procedureId] ?? procedures[pendingProcedureId])?.code;
  });

  const codeDisplay = useMemo(() => code ?? 'A procedure', [code]);

  const onClick = useCallback(() => {
    if (handleOnClick) {
      return handleOnClick(duplicateIds);
    }
  }, [duplicateIds, handleOnClick]);

  const ariaLabel = useMemo(() => getProcedureGenerationNotificationMessage(codeDisplay), [codeDisplay]);
  const linkTo = useMemo(
    () => procedurePendingPath(currentTeamId, procedureGenerationContext.procedureId),
    [currentTeamId, procedureGenerationContext]
  );
  const messageWithoutCode = getProcedureGenerationNotificationMessage('').trim();

  return (
    <Button key={notification.id} ariaLabel={ariaLabel} to={linkTo} onClick={onClick} type="tertiary" width="full">
      <div
        className={`${
          notification.status === NOTIFICATION_STATUS.UNRESOLVED && 'font-semibold'
        } flex flex-row space-x-1`}
      >
        <div className={`${isCodeTruncated ? 'max-w-[5rem]' : ''}`}>
          <Tooltip content={codeDisplay}>
            <p className="text-blue-500 truncate">{codeDisplay}</p>
          </Tooltip>
        </div>
        <div>{messageWithoutCode}</div>
        {duplicateIds && duplicateIds.length > 1 && <div className="text-gray-400"> ({duplicateIds.length})</div>}
      </div>
    </Button>
  );
};

export default React.memo(ProcedureGenerationNotificationLink);
